/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { ModalPopup, Datatable } from "../components/common";
import { Api, Helper, FileUpload, ExportExcel } from "../service";
import {
  AlumniT,
  MappedCareerT,
  SubCareerT,
} from "../interface";
import {
  ALUMNI,
  CreateURL,
  CAREER,
  PAGE_SIZE,
  UPLOAD,
  LANGUAGE,
  S3_URL,
  DATA_BASE_URL,
} from "../constant";
import Select from "react-select";
import Swal from "sweetalert2";

const Alumni = (props: any) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, showLoader] = useState(false);
  const [mappedCareer, getMappedCareer] = useState<MappedCareerT[]>([]);
  const [subCareer, getSubCareer] = useState<SubCareerT[]>([]);
  const [alumnies, getAlumnies] = useState<AlumniT[]>([]);
  const [arrayCount, setArrayCount] = useState(0);
  const [audioUrl, getAudioUrl] = useState("");
  const [language, setLanguage] = useState(LANGUAGE[0]);
  const [fields, setFields] = useState([
    { name: "", language_code: "", description: "" },
  ]);
  const modalRef: any = useRef(null);
  const [isEdit, setisEdit] = useState(false);
  const [currentId, setId] = useState(0);
  const [careerId, getCareerId] = useState(0);
  const [subCareerId, getSubCareerId] = useState(0);
  const [name, getName] = useState("");
  const [imageBlob, getImage] = useState<Blob>(new Blob());
  const [imageName, setImageName] = useState("");
  const [videoName, setVideoName] = useState("");
  const [audioName, setAudioName] = useState("");
  const [videoBlob, getVideo] = useState<Blob>(new Blob());
  const [audioBlob, getAudio] = useState<Blob>(new Blob());
  const [videoFormat, setVideoFormat] = useState("url");
  const [audioFormat, setAudioFormat] = useState("url");
  const [videoUrl, getVideoUrl] = useState("");
  const [description, getDescription] = useState("");
  const [profilePic, getProfilePic] = useState("");
  const [perPage, setPerPage] = useState(PAGE_SIZE);

  const handleSubmit = (e: any) => {
    isEdit ? updateAlumni(e) : addAlumni(e);
    // isEdit ? updateAlumni(e) : resetForm();
  };

  const getCareerSubCareerName = (
    careerId: number,
    subCareerId: number | 0
  ) => {
    const filterCareer: MappedCareerT = mappedCareer.filter(
      (res: MappedCareerT) => res.id === careerId
    )[0];
    const filterSubCareer: SubCareerT =
      filterCareer.sub_career &&
      filterCareer.sub_career.filter(
        (res: SubCareerT) => res.id === subCareerId
      )[0];
      const subCareer = filterSubCareer?.name || '';
    return filterCareer
      ? `Career: ${filterCareer?.name} \n SubCareer: ${subCareer}`
      : `Career: `;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case "name":
        getName(event.target.value);
        break;
      case "video":
        setVideoFormat(event.target.value);
        break;
      case "audio":
        setAudioFormat(event.target.value);
        break;
      case "videoUrl":
        getVideoUrl(event.target.value);
        break;
      case "audioUrl":
        getAudioUrl(event.target.value);
        break;
    }
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    getDescription(event.target.value);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    switch (event.target.name) {
      case "career_id":
        let careerId = parseInt(event.target.value);
        getCareerId(careerId);
        const filterCareer: MappedCareerT = mappedCareer.filter(
          (res: MappedCareerT) => res.id === careerId
        )[0];
        getSubCareer(filterCareer.sub_career);
        break;

      case "sub_career_id":
        let subCareerId = parseInt(event.target.value);
         getSubCareerId(subCareerId);
        break;
    }
  };

  const onImageUpload = (event: any) => {
    let file = event.target.files[0];
    if (!file) return;
    setImageName(file.name.split(".").slice(0, -1).join("."));
    const fTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (fTypes.every((type) => file.type !== type)) {
      alert(`${file.type} is not a supported format`);
      return;
    }
    getImage(file);
    showImagePreview(file);
  };

  const onVideoUpload = (event: any) => {
    let file = event.target.files[0];
    if (!file) return;
    setVideoName(file.name.split(".").slice(0, -1).join("."));
    const fTypes = ["video/mpeg", "video/x-mpeg", "video/mp4"];
    if (fTypes.every((type) => file.type !== type)) {
      alert(`${file.type} is not a supported format`);
      return;
    }
    getVideo(file);
  };

  const onAudioUpload = (event: any) => {
    let file = event.target.files[0];
    if (!file) return;
    setAudioName(file.name.split(".").slice(0, -1).join("."));
    const fTypes = ["audio/mpeg", "audio/x-mpeg-3", "audio/mp3"];
    if (fTypes.every((type) => file.type !== type)) {
      alert(`${file.type} is not a supported format`);
      return;
    }
    getAudio(file);
  };

  const showImagePreview = (image: Blob) => {
    let previewImg: any = document.getElementById("preview_image");
    previewImg.src = "";
    const reader = new FileReader();
    reader.addEventListener("load", (event: any) => {
      previewImg.src = event.target.result;
    });
    reader.readAsDataURL(image);
  };

  const changePage = (selectedPage: number) => {
    setCurrentPage(selectedPage);
    getAlumni(selectedPage, language, perPage);
  };

  const selectPageSize = (event) => {
    setPerPage(parseInt(event.target.value));
    getAlumni(1,language, parseInt(event.target.value))
  }

  useEffect(() => {
    pageLoad(language);
  }, [language]);

  const pageLoad = (language: any) => {
    setLanguage(language);
    Api.get(DATA_BASE_URL,
      `${CAREER.MERGESUBCAREERWITHCAREER}/${language.value}`,
    )
      .then((res: any) => {
        const careers: MappedCareerT[] = res.data;
        getMappedCareer(careers);
        getAlumniCount();
        getAlumni(1, language, perPage);
      })
      .catch((error) => {
        showLoader(false);
        alert(error.message);
      });
  };

  const getAlumniCount = async () => {
    try {
      const countRes = await Api.get(DATA_BASE_URL,ALUMNI.ALUMNICOUNT);
      if (countRes && countRes.data) {
        setArrayCount(countRes.data.count);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getAlumni = async (currentPage: number = 1, lang, pageSize) => {
    let limit = pageSize;
    try {
      showLoader(true);
      let skip = (currentPage - 1) * limit;
      const res = await Api.get(
        DATA_BASE_URL,
        `${ALUMNI.ALUMNILIST}/${language.value}/null/${limit}/${skip}`
      );
      if (res && res.data) {
        getAlumnies(res.data);
      }
      showLoader(false);
    } catch (error) {
      showLoader(false);
      console.log(JSON.stringify(error));
    }
  };

  const handleBlob = (alumniObj) => {
    const imgReq: any = {
      url: UPLOAD + "/alumniprofilepic",
      fileName: alumniObj.profile_pic,
      blob: imageBlob,
    };
    const videoReq: any = {
      url: UPLOAD + "/alumnivideo",
      fileName: alumniObj.video_url,
      blob: videoBlob,
    };
    const audioReq: any = {
      url: UPLOAD + "/alumniaudio",
      fileName: alumniObj.audio_url,
      blob: audioBlob,
    };
    let blobReq: any =
      videoBlob.size !== 0 && audioBlob.size !== 0
        ? [{ ...imgReq }, { ...videoReq }, { ...audioReq }]
        : videoBlob.size !== 0 && audioBlob.size === 0
        ? [{ ...imgReq }, { ...videoReq }]
        : videoBlob.size === 0 && audioBlob.size !== 0
        ? [{ ...imgReq }, { ...audioReq }]
        : videoBlob.size === 0 && audioBlob.size === 0 ? [{ ...imgReq }] : '';
    return blobReq;
  };

  const addAlumni = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    showLoader(true);
    const alumniObj = {
      career_id: careerId !== 0 ? careerId : "",
      sub_career_id: subCareerId,
      profile_pic: Helper.getFileName(imageName, imageBlob),
      video_url:
        videoBlob.size !== 0
          ? Helper.getFileName(videoName, videoBlob)
          : videoUrl,
      audio_url:
        audioBlob.size !== 0
          ? Helper.getFileName(audioName, audioBlob)
          : audioUrl,
    };
    try {
      const response = await Api.post(DATA_BASE_URL,ALUMNI.ALUMNI, alumniObj);
      if (response.status === 200) {
        let blobReq = await handleBlob(alumniObj);
        await FileUpload(blobReq);
        fields.forEach((r: any) => {
          r["alumni_id"] = response.data.id;
        });
        const translationRes = await Api.post(
          DATA_BASE_URL,
          ALUMNI.ALUMNITRANSLATIONBULKUPLOAD,
          fields
        );
        const data = translationRes.data.filter(res => res.language_code === language.value);
        delete data[0].alumni_id;
        delete data[0].id;
        getAlumnies([
          {...response.data, ...data[0]},
          ...alumnies
        ]);
        resetForm();
        showLoader(false);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
      showLoader(false);
    }
  };

  const editAlumni = (alumni) => {
    if (!alumni) return;
    setisEdit(true);
    setId(alumni.id);
    getCareerId(alumni.career_id);
    if (alumni.career_id) {
      const subCareers = mappedCareer.filter((res: MappedCareerT) => {
        return res.id === alumni.career_id;
      })[0]["sub_career"];
      getSubCareer(subCareers);
    } else {
      alert("Career not found");
      setisEdit(false);
    }
    getSubCareerId(alumni.sub_career_id);
    getVideoUrl(alumni.video_url);
    getAudioUrl(alumni.audio_url);
    getName(alumni.name);
    getDescription(alumni.description);
    getProfilePic(alumni.profile_pic);
  };

  const updateAlumni = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    showLoader(true);
    const alumniObj:AlumniT = {
      id:currentId,
      career_id: careerId,
      sub_career_id: subCareerId,
      profile_pic:
        imageBlob.size === 0 ? profilePic : Helper.getFileName(name, imageBlob),
      video_url:
        videoBlob.size === 0
          ? videoUrl
          : Helper.getFileName(videoName, videoBlob),
      audio_url:
        audioBlob.size === 0
          ? audioUrl
          : Helper.getFileName(audioName, audioBlob),
      name:name,
      description:description
    };
    try {
        await Api.post(DATA_BASE_URL,ALUMNI.UPDATEALUMNI, alumniObj);
        let result = alumnies.filter((res) => res.id !== currentId);
        let blobReq = await handleBlob(alumniObj);
        await FileUpload(blobReq);
        const ArrayData:any = [
          ...result, alumniObj];
        ArrayData.sort((a: any, b: any) => b.id - a.id);
        getAlumnies(ArrayData);
        setCurrentPage(currentPage);
        showLoader(false);
    } catch (error) {
      showLoader(false);
      console.log(JSON.stringify(error));
    }
  };

  const deleteAlumni = (alumni_id: number | undefined) => {
    if (!alumni_id) return;
    Swal.fire({
      title: "Are you sure?",
      text: `You will not be able to recover the alumni with ID ${alumni_id}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        Api.delete(DATA_BASE_URL, CreateURL(ALUMNI.ALUMNIBYID, { id: alumni_id })).then(
          (res) => {
            if (res.status === 204) {
              const result = alumnies.filter((r) => r.id !== alumni_id);
              getAlumnies(result);
              Swal.fire(
                "Deleted!",
                `Alumni ID ${alumni_id} has been deleted.`,
                "success"
              );
            }
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          "Cancelled",
          `Your Alumni ID ${alumni_id} detail is safe :)`,
          "info"
        );
      }
    });
  };

  const handleInputFieldChange = (index, event) => {
    const values = [...fields];
    values[index][event.target.name] = event.target.value;
    setFields(values);
  };

  const addFields = () => {
    if (fields.length < LANGUAGE.length) {
      setFields([...fields, { name: "", language_code: "", description: "" }]);
    }
  };

  const removeFields = (i) => {
    const values = [...fields];
    if (values.length > 1) {
      values.splice(i, 1);
    }
    setFields(values);
  };

  const resetForm = () => {
    setisEdit(false);
    setFields([{ name: "", description: "", language_code: "" }]);
    setId(0);
    getCareerId(0);
    getSubCareerId(0);
    setVideoFormat("url");
    setAudioFormat("url");
    getVideoUrl("");
    getAudioUrl("");
    getProfilePic("");
  };

  const languageHandler = (lang: any) => {
    pageLoad(lang);
    setCurrentPage(1);
  };

  return (
    <div className="component-content">
      <ModalPopup
        className="primary"
        buttonLabel="Add More"
        popupTitle={`${isEdit ? "Edit" : "Add"} Alumni`}
        ref={modalRef}
        reset={resetForm}
        callbackMethod={(event: React.FormEvent<HTMLFormElement>) =>
          handleSubmit(event)
        }
      >
        <form onSubmit={() => careerId !== 0 && handleSubmit} method="post">
          <div className="row mb-1">
            <div className="col-sm-4">
              <label className="label-name">
                <span className="">Profile picture</span>
              </label>
            </div>
            <div className="col-sm-8">
              <div className="form">
                <input
                  type="file"
                  accept="image/*"
                  style={{
                    width: "65px",
                    height: "70px",
                    padding: 0,
                    opacity: 0,
                  }}
                  onChange={onImageUpload}
                />
                <img
                  id="preview_image"
                  alt="profile_pic"
                  src={
                    profilePic
                      ? S3_URL + "alumni/profilepic/" + profilePic
                      : require("../assets/images/dummy.png").default
                  }
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "65px",
                    height: "70px",
                    borderRadius: "5px",
                    pointerEvents: "none",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-sm">
              <div className="form ">
                <select
                  name="career_id"
                  value={careerId}
                  onChange={(event) => {
                    handleSelectChange(event);
                  }}
                  required
                >
                  <option value="">Select career</option>
                  {mappedCareer.map((car: MappedCareerT, i) => (
                    <option key={i} value={car.id}>
                      {car.name}
                    </option>
                  ))}
                </select>
                <label className="label-name">
                  <span className="content-name">Career Name</span>
                </label>
              </div>
            </div>
          </div>
          <div className={subCareer.length ? "row mb-2" : "row mb-2 d-none"}>
            <div className="col-sm">
              <div className="form">
                <select
                  name="sub_career_id"
                  value={subCareerId}
                  onChange={(event) => {
                    handleSelectChange(event);
                  }}
                >
                  <option value="">Select sub career</option>
                  {subCareer.map((car: SubCareerT, i) => (
                    <option key={i} value={car.id}>
                      {car.name}
                    </option>
                  ))}
                </select>
                <label className="label-name">
                  <span className="content-name">Sub Career Name</span>
                </label>
              </div>
            </div>
          </div>

          {!isEdit ? (
            <div className="row m-0">
              {fields.map((field, idx) => (
                <div key={idx} className="row">
                  <div className="col-sm-9">
                    <div className="form ">
                    <select
                        name="language_code"
                        value={field.language_code}
                        onChange={(event) => handleInputFieldChange(idx, event)}
                        required
                      >
                        <option value="">Select language</option>
                        {LANGUAGE.map((l: any) => (
                          <option key={l.value} value={l.value}>
                            {l.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-1 icons">
                    <i className="fa fa-plus" onClick={addFields}></i>
                  </div>
                  <div className="col-sm-1 icons">
                    <i
                      className="fa fa-minus align-items-center"
                      onClick={() => removeFields(idx)}
                    ></i>
                  </div>

                  <div className="col-sm-12 m-0">
                    <div className="form">
                      <input
                        type="text"
                        name="name"
                        value={field.name}
                        onChange={(event) => handleInputFieldChange(idx, event)}
                        required
                      />
                      <label className="label-name">
                        <span className="content-name">Name</span>
                      </label>
                    </div>
                  </div>

                  <div className="col-sm-12 m-0">
                    <div className="form">
                      <textarea
                        name="description"
                        value={field.description}
                        onChange={(event) => handleInputFieldChange(idx, event)}
                        required
                      ></textarea>
                      <label className="label-name">
                        <span className="content-name">Description</span>
                      </label>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="row mb-2">
              <div className="col-sm-12">
                <div className="form ">
                  <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={handleChange}
                    required
                  />
                  <label className="label-name">
                    <span className="content-name">Name</span>
                  </label>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form">
                  <textarea
                    name="description"
                    value={description}
                    onChange={handleDescriptionChange}
                    required
                  ></textarea>
                  <label className="label-name">
                    <span className="content-name">Description</span>
                  </label>
                </div>
              </div>
            </div>
          )}
          <div className="row mt-1">
            <div className="col-sm-12">
              <label className="label-name">
                <input
                  type="radio"
                  name="video"
                  value="url"
                  className="k-radio"
                  checked={videoFormat === "url" && true}
                  onChange={handleChange}
                />
                Url
              </label>{" "}
              <label className="label-name">
                <input
                  type="radio"
                  name="video"
                  value="file"
                  className="k-radio"
                  checked={videoFormat === "file" && true}
                  onChange={handleChange}
                />
                File
              </label>
            </div>
          </div>
          {videoFormat === "url" && (
            <div className="row">
              <div className="col-sm-12">
                <div className="form ">
                  <input
                    type="text"
                    name="videoUrl"
                    value={videoUrl}
                    onChange={handleChange}
                  />
                  <label className="label-name">
                    <span className="content-name">Video URL (Embeded)</span>
                  </label>
                </div>
              </div>
            </div>
          )}
          {videoFormat === "file" && (
            <div className="row">
              <div className="col-sm-3">
                <label className="label-name">
                  <span className="">Video File</span>
                </label>
              </div>
              <div className="col-sm-9">
                <input type="file" accept="video/*" onChange={onVideoUpload} />
              </div>
            </div>
          )}
          <div className="row mt-1">
            <div className="col-sm-12">
              <label className="label-name">
                <input
                  type="radio"
                  name="audio"
                  value="url"
                  className="k-radio"
                  checked={audioFormat === "url" && true}
                  onChange={handleChange}
                />
                Url
              </label>{" "}
              <label className="label-name">
                <input
                  type="radio"
                  name="audio"
                  value="file"
                  className="k-radio"
                  checked={audioFormat === "file" && true}
                  onChange={handleChange}
                />
                File
              </label>
            </div>
          </div>
          {audioFormat === "url" && (
            <div className="row">
              <div className="col-sm-12">
                <div className="form ">
                  <input
                    type="text"
                    name="audioUrl"
                    value={audioUrl}
                    onChange={handleChange}
                  />
                  <label className="label-name">
                    <span className="content-name">Audio URL (Embeded)</span>
                  </label>
                </div>
              </div>
            </div>
          )}
          {audioFormat === "file" && (
            <div className="row">
              <div className="col-sm-3">
                <label className="label-name">
                  <span className="">Audio File</span>
                </label>
              </div>
              <div className="col-sm-9">
                <input type="file" accept="audio/*" onChange={onAudioUpload} />
              </div>
            </div>
          )}
        </form>
      </ModalPopup>
      <Datatable
        title="Alumni"
        description="You can view alumnies here"
        isShow={loader}
        currentPage={currentPage}
        pageSize={perPage}
        dataCount={arrayCount}
        callbackMethod={changePage}
        callbackSelectMethod={selectPageSize}
      >
        <div className="row ">
          <div className="col-sm-2">
            <Select
              value={language}
              options={LANGUAGE}
              onChange={languageHandler}
            />
          </div>
          <div className="col-sm-10">
            <div className="float-right">
              <button
                id="download-csv"
                className="btn btn-sm btn-outline-primary"
                onClick={()=>ExportExcel(alumnies, "Alumnies")}
              >
                <i className="fa fa-file-excel-o" /> Download
              </button>
            </div>
          </div>
        </div>
        <table className="datatable-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Career Detail</th>
              <th>Description</th>
              <th>Video</th>
              <th>Audio</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {alumnies.length ? (
              alumnies.map((alumni: AlumniT, idx: number) => (
                <tr key={alumni.id}>
                  <td>{alumni.id}</td>
                  <td>
                    {alumni.profile_pic ? (
                      <figure>
                        <img
                          src={S3_URL + "alumni/profilepic/" + alumni.profile_pic}
                          alt={alumni.name}
                          width="100"
                        />
                        <figcaption>{alumni.name}</figcaption>
                      </figure>
                    ) : (
                      "No preview"
                    )}
                  </td>
                  <td style={{ whiteSpace: "pre-line" }}>
                    {getCareerSubCareerName(
                      alumni.career_id,
                      alumni.sub_career_id
                    )}
                  </td>
                  <td>{alumni.description}</td>
                  <td>
                    {alumni.video_url?.includes(".mp4") ? (
                      <video controls width="250">
                        <source
                          src={S3_URL + "alumni/videos/" + alumni.video_url}
                          type="video/mp4"
                        ></source>
                      </video>
                    ) : alumni.video_url ? (
                      <iframe
                        title={alumni.name}
                        width="250"
                        src={alumni.video_url}
                      ></iframe>
                    ) : (
                      <p> No preview</p>
                    )}
                  </td>
                  <td>
                    {alumni.audio_url?.includes(".mp3") ||
                    alumni.audio_url?.includes(".mpeg") ? (
                      <audio controls>
                        <source
                          src={S3_URL + "alumni/audios/" + alumni.audio_url}
                          type="audio/mpeg"
                        ></source>
                        <source
                          src={S3_URL + "alumni/audios/" + alumni.audio_url}
                          type="audio/mp3"
                        ></source>
                      </audio>
                    ) : (
                      <p> No preview</p>
                    )}
                  </td>
                  <td>
                    <i
                      className="fa fa-edit"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        modalRef.current?.updateModal();
                        editAlumni(alumni);
                      }}
                    ></i>
                    <br />
                    <i
                      className="fa fa-trash"
                      onClick={() => deleteAlumni(alumni.id)}
                    ></i>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5}>No records found</td>
              </tr>
            )}
          </tbody>
        </table>
      </Datatable>
    </div>
  );
};

export default Alumni;
