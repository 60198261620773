import React, {Component} from 'react';
import { Router, Route } from 'react-router-dom';
import LoginPage from './pages/login/LoginPage';
import Routes from './router';
import createBrowserHistory from './createBrowserHistory';
import './App.css';

class App extends Component{
  render(){
    return (
      <Router history={createBrowserHistory}>
        <Route exact path="/" component={LoginPage} />
        <Route exact path="/login" component={LoginPage} />
          <Routes />
      </Router>
    );
  }
}

export default App;
