/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { ModalPopup, Datatable } from "../../components/common";
import { Api, ExportExcel, FileUpload, Helper} from "../../service";
import { CareerAlertT } from "../../interface";
import { CreateURL, PAGE_SIZE, DATA_BASE_URL, UPLOAD, S3_URL} from "../../constant";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import ReactQuill from "react-quill";
import Parser from "html-react-parser";
import Swal from "sweetalert2";

const CareerAlert = () => {
  const [loader, showLoader] = useState(false);
  const [careerAlertPopups, setCareerAlertPopups] = useState<CareerAlertT[]>([]);
  const [currentId, setId] = useState(0);
  const [title, setTitle] = useState("");
  const [examName, setExamName] = useState("");
  const [organisedBy, setOrganisedBy] = useState("");
  const [courseName, setCourseName] = useState("");
  const [minQualification, setMinQualification] = useState("");
  const [minPercentage, setMinPercentage] = useState('');
  const [courseDescription, setCourseDescription] = useState("");
  const [url, setUrl] = useState("");
  const [lastDate, setLastDate] = useState("");
  const [examDate, setExamDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [arrayCount, setArrayCount] = useState(0);
  const [isEdit, setisEdit] = useState(false);
  const [isClone, setIsClone] = useState(false);
  const modalRef: any = useRef(null);
  const [perPage, setPerPage] = useState(PAGE_SIZE);
  const [pdfBlob, getPdf] = useState<Blob>(new Blob());
  const [docName, setDocName] = useState("");
  const [docUrl, setDocUrl] = useState("");
  const [chekFileUpload, setChekFileUpload] = useState(0);

  const handleBlob = (obj) => {
    const pdfReq: any = {
      url: UPLOAD + "/careerAlert",
      fileName: obj.document_url,
      blob: pdfBlob,
    };
    let blobReq: any = [{ ...pdfReq }];
    return blobReq;
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case "title":
        setTitle(event.target.value);
        break;
      case "examname":
        setExamName(event.target.value);
        break;
      case "coursename":
        setCourseName(event.target.value);
        break;
      case "organisedby":
        setOrganisedBy(event.target.value);
        break;
      case "minpercentage":
        setMinPercentage(event.target.value);
        break;
      case "lastdate":
        setLastDate(event.target.value);
        break;
      case "examdate":
        setExamDate(event.target.value);
        break;
      case "url":
        setUrl(event.target.value);
        break;
    }
  };

  const resetForm = () => {
    setisEdit(false);
    setIsClone(false);
    setTitle("");
    setExamName('');
    setOrganisedBy('');
    setCourseName('');
    setCourseDescription('');
    setMinPercentage('');
    setMinQualification('');
    setLastDate('');
    setExamDate('');
    setId(0);
    setDocUrl("");
  };

  const changePage = (selectedPage: number) => {
    setCurrentPage(selectedPage);
    getCareerAlert(selectedPage, perPage);
  };

  const selectPageSize = (event) => {
    setPerPage(parseInt(event.target.value));
    setCurrentPage(1);
    getCareerAlert(currentPage,  parseInt(event.target.value));
  };

  useEffect(() => {
    pageLoad();
  }, []);

  const pageLoad = () => {
    getCareerAlert(currentPage, perPage);
    getCount();
  };

  const getCareerAlert = (currentPage, pageSize) => {
    let limit = pageSize;
    let skip = (currentPage - 1) * limit;
    showLoader(true);
    Api.get(DATA_BASE_URL,
      `career-alerts?filter[where][limit]=${limit}&filter[skip]=${skip}&filter[order]=id%20DESC`
    )
      .then((res: any) => {
        const carerAlert: CareerAlertT[] = res.data;
        setCareerAlertPopups(carerAlert);
        showLoader(false);
      })
      .catch((error) => {
        showLoader(false);
        alert(error.message);
      });
  };

  const getCount = () => {
    Api.get(DATA_BASE_URL, `career-alerts/count`)
      .then((res: any) => {
        setArrayCount(res.data.count);
      })
      .catch((error) => {
        showLoader(false);
        alert(error.message);
      });
  };

  const handleSubmit = (e: any) => {
    isEdit ? updateCareerAlertById(e) : addCareerAlert(e);
  };

  const onPDFUpload = (event: any) => {
    let file = event.target.files[0];
    setDocName(file.name.split(".").slice(0, -1).join("."));
    if (!file) return;
    const fTypes = ["application/pdf"];
    if (fTypes.every((type) => file.type !== type)) {
      alert(`${file.type} is not a supported format`);
      return;
    }
    setChekFileUpload(1); 
    getPdf(file);
  };
  const addCareerAlert = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const Obj = {
        title: title,
        subtitle: organisedBy,
        course_name: examName,
        min_qualification: minQualification,
        min_percentage: parseInt(minPercentage),
        last_date: lastDate,
        exam_date: examDate,
        url: url,
        course_description: courseName,
        document_url: Helper.getFileName(docName, pdfBlob)
      };
      const addAlert = await Api.post( DATA_BASE_URL,`career-alerts`, Obj );
      let blobReq = await handleBlob(Obj);
      await FileUpload(blobReq);

      setCareerAlertPopups([{ ...addAlert.data}, ...careerAlertPopups ]);
      setArrayCount(arrayCount+1);
    } catch (error) {
      console.log(error);
    }
  };

  const editCareerAlertPopups = (epObj) => {
    if (!epObj.id) return;
    setisEdit(true);
    setId(epObj.id);
    setTitle(epObj.title);
    setOrganisedBy(epObj.subtitle);
    setExamName(epObj.course_name);
    setCourseName(epObj.course_description);
    setExamDate(epObj.exam_date);
    setLastDate(epObj.last_date);
    setUrl(epObj.url);
    setMinPercentage(epObj.min_percentage);
    setMinQualification(epObj.min_qualification);
    setDocUrl(epObj.document_url);
    setChekFileUpload(0);
  };

  const updateCareerAlertById = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    try {
     const Obj = {
        id: currentId,
        title: title,
        subtitle: organisedBy,
        course_name: examName,
        min_qualification: minQualification,
        min_percentage: parseInt(minPercentage),
        last_date: lastDate,
        exam_date: examDate,
        url: url,
        course_description: courseName,
        document_url: chekFileUpload ===1?   Helper.getFileName(docName, pdfBlob) : docUrl
      };
      await Api.patch(DATA_BASE_URL,`career-alerts/`+currentId, Obj);
     
      if(chekFileUpload === 1){
        let blobReq = await handleBlob(Obj);
        await FileUpload(blobReq);
      }
     
      let result: CareerAlertT[] = careerAlertPopups.filter(
        (res) => res.id !== currentId
      );
      const ArrayData: any = [...result, { ...Obj, id: currentId }];
      ArrayData.sort((a: any, b: any) => b.id - a.id);
      setCareerAlertPopups(ArrayData);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCareerAlert = async (rowId) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You will not be able to recover the Career Alert with ID ${rowId}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await Api.delete(
            DATA_BASE_URL,
            CreateURL(`career-alerts/`+rowId, { id: rowId })
          );
          if (response.status === 204) {
            let result = careerAlertPopups.filter(event => event.id !== rowId);
            setCareerAlertPopups(result);
            Swal.fire(
              "Deleted!",
              `Event ID ${rowId} has been deleted.`,
              "success"
            );
            setArrayCount(arrayCount-1);
          }
        } catch (error) {
          console.log(error);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          "Cancelled",
          `Your Event ID ${rowId} detail is safe :)`,
          "info"
        );
      }
    });
  }
  const handleDescriptionChange = (value) => {
    setMinQualification(value);
  };

  return (
    <div className="component-content">
      <ModalPopup
        className="primary event-popup"
        ref={modalRef}
        buttonLabel="Add More"
        popupTitle={`${isEdit ? "Edit" : isClone ? "Clone" : "Add"} Career Alert`}
        reset={resetForm}
        callbackMethod={(event: React.FormEvent<HTMLFormElement>) => {
          handleSubmit(event);
        }}
      >
        <form onSubmit={handleSubmit}>
        <div className="row">
            <div className="col-sm">
              <div className="form">
                <input
                  type="text"
                  name="title"
                  value={title}
                  onChange={handleChange}
                  required
                />
                <label className="label-name">
                  <span className="content-name">Title</span>
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <div className="form">
                <input
                  type="text"
                  name="examname"
                  value={examName}
                  onChange={handleChange}
                  required
                />
                <label className="label-name">
                  <span className="content-name">Exam Name</span>
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <div className="form">
                <input
                  type="text"
                  name="organisedby"
                  value={organisedBy}
                  onChange={handleChange}
                  required
                />
                <label className="label-name">
                  <span className="content-name">Organised By</span>
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <div className="form">
                <input
                  type="text"
                  name="coursename"
                  value={courseName}
                  onChange={handleChange}
                  required
                />
                <label className="label-name">
                  <span className="content-name">Course </span>
                </label>
              </div>
            </div>
          </div>
          <div className="col-sm-12 my-2 quill-editor">
                <div className="row m-2">
                  <div className="col-12">
                    <label className="label-name">
                      <span className="content-name">Min Qualifictaion</span>
                    </label>
                  </div>
                </div>
                <ReactQuill
                  value={minQualification}
                  onChange={handleDescriptionChange}
                />
            </div>
              <div className="row">
                <div className="col-sm">
                  <div className="form">
                    <input
                      type="text"
                      name="minpercentage"
                      value={minPercentage}
                      onChange={handleChange}
                      required
                    />
                    <label className="label-name">
                      <span className="content-name">Min Percentage</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  <div className="form">
                    <input
                      type="text"
                      name="url"
                      value={url}
                      onChange={handleChange}
                      required
                    />
                    <label className="label-name">
                      <span className="content-name">URL</span>
                    </label>
                  </div>
                </div>
              </div>
          <div className="row">
            <div className="col-sm">
              <div className="form">
                <input
                  type="date"
                  name="lastdate"
                  value={lastDate}
                  onChange={handleChange}
                  required
                />
                <label className="label-name">
                  <span className="content-name">Last Date</span>
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <div className="form">
                <input
                  type="date"
                  name="examdate"
                  value={examDate}
                  onChange={handleChange}
                  required
                />
                <label className="label-name">
                  <span className="content-name">Exam Date</span>
                </label>
              </div>
            </div>
          </div>
          <div className="row">
                <div className="col-sm">
                  <div className="form">
                  <input
                    type="file"
                    accept=".pdf"
                    placeholder="Upload PDF"
                    onChange={onPDFUpload}
                  />
                    <label className="label-name">
                      <span className="content-name">Upload PDF</span>
                    </label>
                  </div>
                </div>
              </div>
        </form>
      </ModalPopup>
      <Datatable
        title="Add Career Alerts"
        description="You can add / view craeer alert here"
        isShow={loader}
        currentPage={currentPage}
        pageSize={PAGE_SIZE}
        dataCount={arrayCount}
        callbackMethod={changePage}
        callbackSelectMethod={selectPageSize}
      >
        <div className="row ">
         <div className="col-sm-12" style={{textAlign: "end"}}>
            <div>
              <button
                id="download-csv"
                className="btn btn-sm btn-outline-primary"
                onClick={() => ExportExcel(careerAlertPopups, "Career-Alert")}
              >
                <i className="fa fa-file-excel-o" /> Download
              </button>
            </div>
          </div>
        </div>
        <table className="datatable-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Exam Name</th>
              <th>Organised By</th>
              <th>Course Name</th>
              <th>Min qualification</th>
              <th>Min Percentage</th>
              <th>Last Date</th>
              <th>Exam Date</th>
              <th>Doument URL</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {careerAlertPopups.length ? (
              careerAlertPopups.map((ca: CareerAlertT) => (
                <tr key={ca.id}>
                  <td>{ca.id}</td>
                  <td>{ca.title}</td>
                  <td>{ca.url.includes(".") ? (
                      <a
                        href={ca.url}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                       {ca.course_name}
                      </a>
                    ) : (
                      <span>{ca.course_name}</span>
                    )}</td>
                  <td>{ca.subtitle}</td>
                  <td>{(ca.course_description)}</td>
                  <td>{Parser(ca.min_qualification)}</td>
                  <td>{ca.min_percentage}</td>
                  <td>{ca.last_date}</td>
                  <td>{ca.exam_date}</td>
                  <td>
                    {ca.document_url.includes(".") ? (
                      <a
                        href={ S3_URL+"documents/careerAlert/"+ca.document_url}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        View Alert
                      </a>
                    ) : (
                      <span>{ca.document_url}</span>
                    )}
                  </td>
                  <td>
                    <i
                      className="fa fa-edit"
                      style={{ cursor: "pointer" }}
                      title="Edit"
                      onClick={() => {
                        modalRef.current?.updateModal();
                        editCareerAlertPopups(ca);
                      }}
                    ></i>
                    <br />
                    <i
                      className="fa fa-trash"
                      style={{ cursor: "pointer" }}
                      title="Clone"
                      onClick={
                        () => deleteCareerAlert(ca.id)
                      }
                    ></i>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={10}>No records found</td>
              </tr>
            )}
          </tbody>
        </table>
      </Datatable>
    </div>
  );
};

export default CareerAlert;
