/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-concat */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import dateFormat from "dateformat";
import { Datatable } from "../components/common";
import { Api, ExportExcel } from "../service";
import { COUNSELING, PER_PAGE_LIMIT, USER_BASE_URL } from "../constant";
import { CounselingT } from "../interface";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import axios from "axios";

const Counseling = () => {
  const [counselings, setCounselings] = useState([]);
  const [arrayCount, setArrayCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchItem, setSearchItem] = useState("");
  const [channel, setChannel] = useState<any>([]);
  const [perPage, setPerPage] = useState(PER_PAGE_LIMIT);

  const channelOptions = [
    { label: "All", value: "" },
    {
      label: "GetInTouch",
      value: "GetInTouch",
    },
    { label: "Telephonic", value: "Telephonic" },
    { label: "Whatsapp", value: "Whatsapp" },
  ];

  const changePage = (selectedPage: number) => {
    setCurrentPage(selectedPage);
    getCounselings(selectedPage, perPage);
  };

  const selectPageSize = (event) => {
    setPerPage(parseInt(event.target.value));
    setCurrentPage(1);
    getCounselings(currentPage, parseInt(event.target.value));
  };
  useEffect(() => {
    getCounselingsCount();
    getCounselings(currentPage, perPage);
  }, []);

  const getCounselingsCount = () => {
    Api.get(USER_BASE_URL, COUNSELING.COUNT)
      .then((res) => {
        setArrayCount(res.data.count);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
      });
  };
  const getCounselings = (current, pageSize) => {
    setLoader(true);
    let limit = pageSize;
    let offset = (current - 1) * pageSize;
    Api.get(USER_BASE_URL, `${COUNSELING.COUNSELINGUSERS}/null/null/${limit}/${offset}`)
      .then((res) => {
        setCounselings(res.data);
        setLoader(false);
      })
      .catch((error) => {
        alert(error);
        setLoader(false);
      });
  };
  const getUserdetails = (c) => {
    return (
      <>
        <span>Name: {c.name}</span>
        <br />
        <span>Contact: {c.contact}</span>
        <br />
        <span>Email: {c.email}</span>
      </>
    );
  };
  const generateReport = async (counseling) => {
    try {
      const obj = {
        userId: counseling.user_id,
        languageCode: counseling.default_language,
      };
      const response = await Api.post(USER_BASE_URL, COUNSELING.GENERATEREPORT, obj);
      if (response.status === 200) {
        toast.success("Report generated successfully! ", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      toast.error(`${JSON.stringify(error)}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.log(JSON.stringify(error));
    }
  };

  const handleSearch = async (event) => {
    let cancelToken: any;
    let search = event.target.value || null;
    setSearchItem(search);
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    try {
      let limit = 0;
      let offset = (currentPage - 1) * limit;
      const users = await Api.search(
        USER_BASE_URL, 
        `${COUNSELING.COUNSELINGUSERS}/${search}/null/${limit}/${offset}`,
        { cancelToken: cancelToken.token }
      );
      if (users.data.length > 0) {
        setCounselings(users.data);
        setArrayCount(0);
      } else getCounselingsCount();
    } catch (error) {
      console.log(error);
    }
  };

  const handleChannelChange = async (event) => {
    setSearchItem("");
    setChannel(event);
    let value = event.value === "" ? null : `'${event.value}'`;
    try {
      let limit = 0;
      let offset = (currentPage - 1) * perPage;
      const users = await Api.get(
        USER_BASE_URL, 
        `${COUNSELING.COUNSELINGUSERS}/null/${value}/${limit}/${offset}`
      );
      if (value === null) {
        setCounselings(users.data);
        setArrayCount(0);
      } else {
        setCounselings(users.data);
        getCounselingsCount();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="component-content">
      <ToastContainer />
      <Datatable
        title="Counselling"
        description="You can view counselling details"
        isShow={loader}
        currentPage={currentPage}
        pageSize={perPage}
        dataCount={arrayCount}
        callbackMethod={changePage}
        callbackSelectMethod={selectPageSize}
      >
        <div
          className="row"
          style={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <div className="col-sm-4">
            <div style={{ position: "relative" }}>
              <input
                type="text"
                value={searchItem}
                className="form-control"
                placeholder="Search by name | contact | email"
                onChange={(e) => handleSearch(e)}
              />
              {
                searchItem &&
              <span
                style={{
                  position: "absolute",
                  float: "right",
                  marginTop: "-29px",
                  marginLeft: "96%",
                  color: "darkgray",
                  cursor: "pointer",
                }}
              >
                <h6
                  onClick={(e) => {
                    handleSearch(e);
                    setSearchItem("");
                  }}
                >
                  x
                </h6>
              </span>
}
            </div>
          </div>
          <div className="col-sm-2">
            <Select
              value={channel}
              options={channelOptions}
              onChange={handleChannelChange}
            />
          </div>
          <div className="col-sm-3 mr-1">
            <div className="float-right">
              <button
                id="download-csv"
                className="btn btn-sm btn-outline-primary"
                onClick={()=>ExportExcel(counselings, "Counsellings")}
              >
                <i className="fa fa-file-excel-o" /> Download
              </button>
            </div>
          </div>
        </div>
        <table className="datatable-table responsive">
          <thead>
            <tr>
              <th>#</th>
              <th>User Contact</th>
              <th>Schedule</th>
              <th>Report URL</th>
              <th>Channel</th>
              <th>Note</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {counselings.length ? (
              counselings.map((counseling: CounselingT) => (
                <tr key={counseling.id}>
                  <td>{counseling.id}</td>
                  <td>{getUserdetails(counseling)}</td>
                  <td>
                    {counseling.channel === "GetInTouch"
                      ? counseling.schedule
                      : "NA"}
                  </td>
                  <td>
                    <a
                      type="button"
                      className="btn btn-sm btn-warning mb-1"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${USER_BASE_URL}template/reports/${counseling.user_id}.pdf`}
                    >
                      View Report
                    </a>
                    <br />
                    <a
                      type="button"
                      className="btn btn-sm btn-warning"
                      href="#"
                      onClick={() => generateReport(counseling)}
                    >
                      Generate report
                    </a>
                  </td>
                  <td>{counseling.channel}</td>
                  <td style={{ wordBreak: "break-word" }}>{counseling.note}</td>
                  <td>{dateFormat(counseling.booked_date, "mmmm dS, yyyy")}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6}>No records found</td>
              </tr>
            )}
          </tbody>
        </table>
      </Datatable>
    </div>
  );
};

export default Counseling;
