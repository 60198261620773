import React, { Component } from 'react';

class ICSErrorBoundary extends Component {
    state = {
        hasError: false,
        errorMessage: ''
    }
    componentDidCatch = (error: any, info: any) => {
        this.setState({ hasError: true, errorMessage: error })
    }
    render() {
        if (this.state.hasError) {
            return (
                <p>{this.state.errorMessage}</p>
            )
        }
        return this.props.children
    }
}

export default ICSErrorBoundary;