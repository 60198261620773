/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { ModalPopup, Datatable } from "../components/common";
import { Api, ExportExcel } from "../service";
import { PAGE_SIZE, CreateURL, CAREER, TEST, LANGUAGE, DATA_BASE_URL} from "../constant";
import { TestT, MappedCareerT } from "../interface";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import Select from "react-select";

const Test = () => {
  const [loader, setLoader] = useState(false);
  const [testType, setTestType] = useState("");
  const [careerId, setCareerId] = useState(0);
  const [subCareerId, setSubCareerId] = useState(0);
  const modalRef: any = useRef(null);
  const [currentId, setCurrentId] = useState(0);
  const [isEdit, setisEdit] = useState(false);
  const [language, setLanguage] = useState(LANGUAGE[0]);
  const [name, setName] = useState("");
  const [languageCode, setLanguageCode] = useState("");
  const [firstNo, setFirstNo] = useState("");
  const [rangeOneFive, setRangeOneFive] = useState("");
  const [rangeSixSeven, setRangeSixSeven] = useState("");
  const [rangeEightTen, setRangeEightTen] = useState("");
  const [tests, setTests] = useState<any>([]);
  const [careers, setCareers] = useState([]);
  const [subCareers, setSubCareers] = useState<any>([]);
  const [arrayCount, setArrayCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(PAGE_SIZE);
  const [activeTab, setActiveTab] = useState("1");
  const [fields, setFields] = useState([
    {
      name: "",
      first_no: "",
      range_one_five: "",
      range_six_seven: "",
      range_eight_ten: "",
      language_code: "",
    },
  ]);

  const handleChange = (event) => {
    switch (event.target.name) {
      case "career_id":
        setCareerId(event.target.value);
        break;
      case "sub_career_id":
        setSubCareerId(event.target.value);
        break;
      case "test_type":
        setTestType(event.target.value);
        break;
      case "name":
        setName(event.target.value);
        break;
      case "first_no":
        setFirstNo(event.target.value);
        break;
      case "range_one_five":
        setRangeOneFive(event.target.value);
        break;
      case "range_six_seven":
        setRangeSixSeven(event.target.value);
        break;
      case "range_eight_ten":
        setRangeEightTen(event.target.value);
        break;
    }
  };

  const handleFieldInputChange = (event: any, index: number) => {
    const values = [...fields];
    values[index][event.target.name] = event.target.value;
    setFields(values);
  };
  const changePage = (selectedPage: number) => {
    setCurrentPage(selectedPage);
    getTests(selectedPage, language, perPage);
  };

  const selectPageSize = (event) => {
    setPerPage(parseInt(event.target.value));
    setCurrentPage(1);
    getTests(currentPage, language, parseInt(event.target.value))
  }


  useEffect(() => {
    pageLoad(language);
  }, []);

  const languageHandler = (lang: any) => {
    pageLoad(lang);
    setCurrentPage(1);
  };

  const pageLoad = (language) => {
    setLanguage(language);
    getTestsCount();
    getCareers(language);
    getTests(currentPage,language, perPage);
  };

  const getCareers = (lang) => {
    Api.get(DATA_BASE_URL, `${CAREER.MERGESUBCAREERWITHCAREER}/${lang.value}`)
      .then((res) => {
        const careers = res.data;
        setCareers(careers);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getSubCareers = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let cid = parseInt(event.target.value);
    const result: MappedCareerT[] = careers.filter((res: any) => {
      return res.id === cid;
    });
    setSubCareers(result && result[0]?.sub_career);
  };
  const getTestsCount = async () => {
    try {
      const countRes = await Api.get(DATA_BASE_URL, TEST.TESTCOUNT);
      if (countRes && countRes.data) {
        setArrayCount(countRes.data.count);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };
  const getTests = (currentPage,lang, perPage) => {
    setLoader(true);
    let limit = perPage
    let skip = (currentPage - 1) * limit;
    Api.get(DATA_BASE_URL, `${TEST.TESTLIST}/'${lang.value}'/null/${limit}/${skip}`)
      .then((res) => {
        const tests = res.data;
        setTests(tests);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        alert(error);
      });
  };

  const handleSubmit = (e) => {
    isEdit ? updateTest(e) : addTest(e);
  };
  const addTest = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const testObj = {
      test_type: testType,
      career_id: Number(careerId),
      sub_career_id: Number(subCareerId) || 0,
    };

    try {
      const response: any = await Api.post(DATA_BASE_URL, TEST.TEST, testObj);
      if (response.status === 200) {
        fields.forEach((field: any) => {
          field.test_id = response.data.id;
        });
        const responseT = await Api.post(
          DATA_BASE_URL, 
          TEST.TESTTRANSLATIONBULKUPLOAD,
          fields
        );
        const data = responseT.data.filter(
          (res) => res.language_code === language.value
        );
        delete data[0].test_id;
        delete data[0].id;
        setTests([{ ...response.data, ...data[0] }, ...tests]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const editTest = (test: any) => {
    if (!test.id) return;
    setisEdit(true);
    setCurrentId(test.id);
    setCareerId(test.career_id);
    setSubCareerId(test.sub_career_id);
    setTestType(test.test_type);
    setName(test.name);
    setFirstNo(test.first_no);
    setRangeOneFive(test.range_one_five);
    setRangeSixSeven(test.range_six_seven);
    setRangeEightTen(test.range_eight_ten);
    setLanguageCode(test.language_code);
  };

  const updateTest = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const testObj = {
      id: currentId,
      name: name,
      career_id: careerId,
      sub_career_id: subCareerId,
      test_type: testType,
      first_no: firstNo,
      range_one_five: rangeOneFive,
      range_six_seven: rangeSixSeven,
      range_eight_ten: rangeEightTen,
      language_code: languageCode,
    };
    try {
      await Api.post(DATA_BASE_URL, TEST.UPDATETEST, testObj);
      let result = tests.filter((res: any) => res.id !== currentId);
      const ArrayData: any = [...result, testObj];
      ArrayData.sort((a: any, b: any) => b.id - a.id);
      setTests(ArrayData);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const deleteTest = (test_id: number) => {
    Api.delete(DATA_BASE_URL, CreateURL(TEST.DELETE, { id: test_id }))
      .then((res) => {
        if (res.status === 204) {
          const data = tests.filter((r: any) => r.id !== test_id);
          setTests(data);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };
  const addFields = () => {
    if (fields.length < LANGUAGE.length) {
      setFields([
        ...fields,
        {
          name: "",
          first_no: "",
          range_one_five: "",
          range_six_seven: "",
          range_eight_ten: "",
          language_code: "",
        },
      ]);
    }
  };

  const removeFields = (i) => {
    const values = [...fields];
    if (values.length > 1) {
      values.splice(i, 1);
    }
    setFields(values);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggleDetail = (index: number) => {
    let infoDiv: any = document.getElementById("accordion" + index);
    let trBlock: any = document.getElementById("hide-table-padding" + index);
    let classArray = Array.from(infoDiv?.classList);
    if (classArray.indexOf("collapse") !== -1) {
      infoDiv?.classList.remove("collapse");
      trBlock?.classList.remove("d-none");
    } else {
      infoDiv?.classList.add("collapse");
      trBlock?.classList.add("d-none");
    }
  };

  const resetForm = () => {
    setisEdit(false);
    setTestType("");
    setCareerId(0);
    setSubCareerId(0);
    setFields([
      {
        name: "",
        first_no: "",
        range_one_five: "",
        range_six_seven: "",
        range_eight_ten: "",
        language_code: "",
      },
    ]);
  };

  return (
    <div className="component-content">
      <ModalPopup
        className="primary"
        buttonLabel="Add More"
        popupTitle={`${isEdit ? "Edit" : "Add"} Test`}
        ref={modalRef}
        reset={resetForm}
        callbackMethod={(event: React.FormEvent<HTMLFormElement>) =>
          handleSubmit(event)
        }
      >
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm">
              <div className="form ">
                <select
                  name="test_type"
                  value={testType}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select test type</option>
                  <option value="Berkman">Berkman</option>
                  <option value="CareerIdentification">
                    CareerIdentification
                  </option>
                  <option value="DeepDive">DeepDive</option>
                  <option value="Registration">Registration</option>
                </select>
                <label className="label-name">
                  <span className="content-name">Test Type</span>
                </label>
              </div>
            </div>
          </div>
          {isEdit === true ? (
            <div className="row">
              <div className="col-sm">
                <div className="form ">
                  <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={handleChange}
                    required
                  />
                  <label className="label-name">
                    <span className="content-name">Test Name</span>
                  </label>
                </div>
              </div>
            </div>
          ) : (
            <>
              {fields.map((field: any, idx: number) => (
                <div key={idx}>
                  <div className="row">
                    <div className="col-sm-10">
                      <div className="form ">
                      <select
                        name="language_code"
                        value={field.language_code}
                        onChange={(event) => handleFieldInputChange(event, idx)}
                        required
                      >
                        <option value="">Select language</option>
                        {LANGUAGE.map((l: any) => (
                          <option key={l.value} value={l.value}>
                            {l.label}
                          </option>
                        ))}
                      </select>
                      </div>
                    </div>
                    <div className="col-sm-1 icons">
                      <i className="fa fa-plus" onClick={addFields}></i>
                    </div>
                    <div className="col-sm-1 icons">
                      <i
                        className="fa fa-minus align-items-center"
                        onClick={() => removeFields(idx)}
                      ></i>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm">
                      <div className="form ">
                        <input
                          type="text"
                          name="name"
                          value={field.name}
                          onChange={(e) => handleFieldInputChange(e, idx)}
                          required
                        />
                        <label className="label-name">
                          <span className="content-name">
                            Test Name ({field.language_code})
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              ))}{" "}
            </>
          )}
          <div className={testType === "DeepDive" ? "" : "d-none"}>
            <div className="row">
              <div className="col-sm">
                <div className="form ">
                  <select
                    name="career_id"
                    value={careerId}
                    onChange={(e) => {
                      handleChange(e);
                      getSubCareers(e);
                    }}
                    required
                  >
                    <option value="">Select career</option>
                    {careers.map((car: any) => (
                      <option key={car.id} value={car.id}>
                        {car.name}
                      </option>
                    ))}
                  </select>
                  <label className="label-name">
                    <span className="content-name">Career Name</span>
                  </label>
                </div>
              </div>
            </div>
            <div className={"row " + (subCareers.length ? "" : "d-none")}>
              <div className="col-sm">
                <div className="form ">
                  <select
                    name="sub_career_id"
                    value={subCareerId}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select sub career</option>
                    {subCareers.map((subcar: any) => (
                      <option key={subcar.id} value={subcar.id}>
                        {subcar.name}
                      </option>
                    ))}
                  </select>
                  <label className="label-name">
                    <span className="content-name">Sub Career Name</span>
                  </label>
                </div>
              </div>
            </div>
            {isEdit === true ? (
              <>
                <div className="row">
                  <div className="col-sm">
                    <div className="form ">
                      <input
                        type="text"
                        name="first_no"
                        value={firstNo}
                        onChange={handleChange}
                        required
                      />
                      <label className="label-name">
                        <span className="content-name">First No Text</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm">
                    <div className="form ">
                      <input
                        type="text"
                        name="range_one_five"
                        value={rangeOneFive}
                        onChange={handleChange}
                        required
                      />
                      <label className="label-name">
                        <span className="content-name">Range 1 to 5 Text</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm">
                    <div className="form ">
                      <input
                        type="text"
                        name="range_six_seven"
                        value={rangeSixSeven}
                        onChange={handleChange}
                        required
                      />
                      <label className="label-name">
                        <span className="content-name">Range 6 to 7 Text</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm">
                    <div className="form ">
                      <input
                        type="text"
                        name="range_eight_ten"
                        value={rangeEightTen}
                        onChange={handleChange}
                        required
                      />
                      <label className="label-name">
                        <span className="content-name">Range 8 to 10 Text</span>
                      </label>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {fields.map((field: any, idx: number) => (
                  <div key={idx}>
                    <div className="row">
                      <div className="col-sm">
                        <div className="form ">
                          <input
                            type="text"
                            name="first_no"
                            value={field.first_no}
                            onChange={(e) => handleFieldInputChange(e, idx)}
                            required
                          />
                          <label className="label-name">
                            <span className="content-name">
                              First No Text ({field.language_code})
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                        <div className="form ">
                          <input
                            type="text"
                            name="range_one_five"
                            value={field.range_one_five}
                            onChange={(e) => handleFieldInputChange(e, idx)}
                            required
                          />
                          <label className="label-name">
                            <span className="content-name">
                              Range 1 to 5 Text ({field.language_code})
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                        <div className="form ">
                          <input
                            type="text"
                            name="range_six_seven"
                            value={field.range_six_seven}
                            onChange={(e) => handleFieldInputChange(e, idx)}
                            required
                          />
                          <label className="label-name">
                            <span className="content-name">
                              Range 6 to 7 Text ({field.language_code})
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                        <div className="form ">
                          <input
                            type="text"
                            name="range_eight_ten"
                            value={field.range_eight_ten}
                            onChange={(e) => handleFieldInputChange(e, idx)}
                            required
                          />
                          <label className="label-name">
                            <span className="content-name">
                              Range 8 to 10 Text ({field.language_code})
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </form>
      </ModalPopup>
      <Datatable
        title="Test"
        description="You can view different type of test"
        isShow={loader}
        currentPage={currentPage}
        pageSize={perPage}
        dataCount={arrayCount}
        callbackMethod={changePage}
        callbackSelectMethod={selectPageSize}
      >
        <div className="row ">
          <div className="col-sm-2">
            <Select
              value={language}
              options={LANGUAGE}
              onChange={languageHandler}
            />
          </div>
          <div className="col-sm-10">
            <div className="float-right">
              <button
                id="download-csv"
                className="btn btn-sm btn-outline-primary"
                onClick={()=>ExportExcel(tests, "Tests")}
              >
                <i className="fa fa-file-excel-o" /> Download
              </button>
            </div>
          </div>
        </div>
        <table className="datatable-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Test Name</th>
              <th>Test Type</th>
              <th>View Question</th>
              <th>Other Details</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {tests.length ? (
              tests.map((test: TestT, idx: number) => (
                <Fragment key={idx}>
                  <tr key={idx} className="accordion-toggle collapsed shrink">
                    <td>{test.id}</td>
                    <td>{test.name}</td>
                    <td>{test.test_type}</td>
                    <td>
                      <Link
                        to={`/questions/${
                          test.test_type === "DeepDive" ? "deepdive" : "general"
                        }/test/${test.id}/${test.name}/${test.test_type}`}
                      >
                        Question Set
                      </Link>
                    </td>
                    {test.range_one_five !== "" ||
                    test.first_no !== "" ||
                    test.range_six_seven !== "" ||
                    test.range_eight_ten !== "" ? (
                      <td
                        className={
                          test.test_type === "DeepDive" ? "expand-button" : ""
                        }
                        onClick={() => toggleDetail(idx)}
                      ></td>
                    ) : (
                      <td> </td>
                    )}
                    <td>
                      <i
                        className="fa fa-edit"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          modalRef.current?.updateModal();
                          editTest(test);
                        }}
                      ></i>{" "}
                      <i
                        className="fa fa-trash"
                        onClick={() => deleteTest(test.id)}
                      ></i>
                    </td>
                  </tr>
                  <tr
                    id={"hide-table-padding" + idx}
                    style={{ backgroundColor: "#eeeeee" }}
                    className="d-none"
                  >
                    <td colSpan={12}>
                      <div id={"accordion" + idx} className="collapse in">
                        <Nav tabs>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "1",
                              })}
                              onClick={() => {
                                toggle("1");
                              }}
                            >
                              Others Details
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent
                          activeTab={activeTab}
                          style={{ padding: "15px 30px" }}
                        >
                          <TabPane tabId="1">
                            <div className="row">
                              <div className="col-sm-12">
                                <div
                                  className={
                                    test.test_type === "DeepDive"
                                      ? ""
                                      : "d-none"
                                  }
                                >
                                  <div>
                                    <strong style={{ fontWeight: "bold" }}>
                                      No Text:
                                    </strong>{" "}
                                    {test.first_no}
                                  </div>
                                  <div>
                                    <strong
                                      style={{
                                        fontWeight: "bold",
                                        color: "#0082FF",
                                      }}
                                    >
                                      Ranges
                                    </strong>
                                  </div>
                                  <div>
                                    <strong style={{ fontWeight: "bold" }}>
                                      1 to 5:{" "}
                                    </strong>{" "}
                                    {test.range_one_five}
                                  </div>
                                  <div>
                                    <strong style={{ fontWeight: "bold" }}>
                                      6 to 7:{" "}
                                    </strong>{" "}
                                    {test.range_six_seven}
                                  </div>
                                  <div>
                                    <strong style={{ fontWeight: "bold" }}>
                                      8 to 10:
                                    </strong>{" "}
                                    {test.range_eight_ten}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                    </td>
                  </tr>
                </Fragment>
              ))
            ) : (
              <tr>
                <td colSpan={5}>No records found</td>
              </tr>
            )}
          </tbody>
        </table>
      </Datatable>
    </div>
  );
};

export default Test;
