import React, { useState, useEffect, useRef } from "react";
import { Multiselect } from "multiselect-react-dropdown";
import { ModalPopup, Datatable, Loader } from "../../components/common";
import {
  CampusT,
  CountryT,
  DegreeT,
  MappedCareerT,
  MultiSelectItemT,
  SubCareerT,
} from "../../interface";
import { Api, ExportExcel, FileUpload, Helper } from "../../service";
import {
  CreateURL,
  CAREER,
  CAMPUS,
  COUNTRY,
  DEGREE,
  PER_PAGE_LIMIT,
  UPLOAD,
  LANGUAGE,
  S3_URL,
  DATA_BASE_URL,
} from "../../constant";
import {
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
} from "reactstrap";
import dateFormat from "dateformat";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import Switch from "react-switch";
import Swal from "sweetalert2";
import Select from "react-select";
import "./Campus.css";
import XLSX from "xlsx";
import { toast } from "react-toastify";
import axios from "axios";

const _ = require("lodash");
const selectedIds = new Set<number>();

const Campus = (props: any) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, showLoader] = useState(false);
  const [mappedCareer, getMappedCareer] = useState<MappedCareerT[]>([]);
  const [subCareer, getSubCareer] = useState<SubCareerT[]>([]);
  const [campuses, getCampuses] = useState<CampusT[]>([]);
  const [countries, getCountries] = useState<CountryT[]>([]);
  const [degrees, getDegrees] = useState<DegreeT[]>([]);
  const [arrayCount, setArrayCount] = useState(0);
  const [career, getCareer] = useState<any[]>([]);
  const [selectedCId, setSelectedCId] = useState(0);
  const [isOpen, setIsOpen]: any = useState(false);
  const [careerId, getCareerId] = useState(0);
  const [subCareerId, getSubCareerId] = useState(0);
  const [name, getName] = useState("");
  const [imageBlob, getImage] = useState<Blob>(new Blob());
  const [url, getUrl] = useState("");
  const [selectedCountry, getSelectedCountry] = useState(0);
  const [selectedDegrees, setSelectedDegrees] = useState<MultiSelectItemT[]>([]);
  const [modal, setModal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [focusedInput, setFocusedInput] = useState(null);
  const [featured, setFeatured] = useState(false);
  const modalRef: any = useRef(null);
  const [isEdit, setisEdit] = useState(false);
  const [currentId, setId] = useState(0);
  const [logoUrl, setLogoUrl] = useState("");
  const [imageName, setImageName] = useState("");
  const [searchItem, setSearchItem] = useState("");
  const [workBookItem, setWorkBookItem] = useState<any[]>([]);
  const [workBookColumn, setWorkBookColumn] = useState<any[]>([]);
  const [campusUpload, setCampusUpload] = useState(false);
  const [campusTranslationUpload, setCampusTranslationUpload] = useState(false);
  const [language, setLanguage] = useState(LANGUAGE[0]);
  const [fields, setFields] = useState([{ name: "", language_code: "" }]);
  const [countryList, setCountryList] = useState<String[]>([]);
  const [countryName, setCountryName] = useState<String[]>([]);
  const [perPage, setPerPage] = useState(PER_PAGE_LIMIT);

  const handleCareerChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (!event.target.value) {
      getSubCareerId(0);
      getSubCareer([]);
    } else {
      let careerId = parseInt(event.target.value);
      getCareerId(careerId);
      if (!careerId) return;
      const subCareers = mappedCareer.filter((res: MappedCareerT) => {
        return res.id === careerId;
      })[0]["sub_career"];
      getSubCareer(subCareers);
    }
  };

  const handleSubCareerChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    let subCareerid = parseInt(event.target.value);
    getSubCareerId(subCareerid);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    getName(event.target.value);
  };
  const handleInputFieldChange = (index, event) => {
    const values = [...fields];
    values[index][event.target.name] = event.target.value;
    setFields(values);
  };
  const handleSubmit = (e: any) => {
    isEdit ? updateCampus(e) : addCampus(e);
  };

  const onImageUpload = (event: any) => {
    let file = event.target.files[0];
    setImageName(file.name.split(".").slice(0, -1).join("."));
    if (!file) return;
    const fTypes = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
    if (fTypes.every((type) => file.type !== type)) {
      alert(`${file.type} is not a supported format`);
      return;
    }
    getImage(file);
    showImagePreview(file);
  };

  const showImagePreview = (image: Blob) => {
    let previewImg: any = document.getElementById("preview_image");
    previewImg.src = "";
    const reader = new FileReader();
    reader.addEventListener("load", (event: any) => {
      previewImg.src = event.target.result;
    });
    reader.readAsDataURL(image);
  };

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    getUrl(event.target.value);
  };

  const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    getSelectedCountry(parseInt(event.target.value));
  };

  const onSelect = (
    selectedList: MultiSelectItemT[],
    selectedItem: MultiSelectItemT
  ) => {
    selectedIds.add(selectedItem.id);
    setSelectedDegrees(selectedList);
  };

  const onRemove = (
    selectedList: MultiSelectItemT[],
    removedItem: MultiSelectItemT
  ) => {
    selectedIds.delete(removedItem.id);
    setSelectedDegrees(selectedList);
  };

  const getLocation = (country_id: number) => {
    const result = countries.filter((res) => res.country_id === country_id);
    return result.length ? result[0].name : "";
  };

  const getDegreeName = (degree_id: number) => {
    const result = degrees.filter((res) => res.id === degree_id);
    return result.length ? result[0].name : "";
  };

  const makeDegreeString = (deg: Array<number>) => {
    let degreeStr: any = "";
    const degreesArr: any[] = [];
    if (!deg) return;
    deg.forEach((r) => {
      degreesArr.push(getDegreeName(r));
    });
    degreeStr = degreesArr.join(", ");
    return degreeStr;
  };

  const getSubCareerName = (career_id: number, sub_career_id: number) => {
    const careerResult = mappedCareer.filter(
      (res: any) => res.id === career_id
    );
    if (!careerResult[0]["sub_career"]) return;
    const result = careerResult[0]["sub_career"].filter(
      (res) => res.sub_career_id === sub_career_id
    );
    return result.length ? result[0].name : "";
  };

  const changePage = (selectedPage: number) => {
    setCurrentPage(selectedPage);
    setLanguage(language);
    getCampusesByCareerId(selectedCId, selectedPage, language, perPage);
  };

  const selectPageSize = (event) => {
    setPerPage(parseInt(event.target.value));
    setCurrentPage(1);
    getCampusesByCareerId(
      selectedCId,
      currentPage,
      language,
      parseInt(event.target.value)
    );
  };

  const languageHandler = (lang) => {
    setIsOpen(false);
    setSearchItem("");
    pageLoad(lang);
  };

  const pageLoad = (language) => {
    showLoader(true);
    setLanguage(language);
    Api.all(DATA_BASE_URL,[
      `${CAREER.MERGESUBCAREERWITHCAREER}/${language.value}`,
      `${COUNTRY.COUNTRY}?filter[where][language_code]=${language.value}`,
      `${DEGREE.DEGREE}?filter[where][language_code]=${language.value}`
    ])
      .then((res: any) => {
        const careers: MappedCareerT[] = res[0].data;
        getMappedCareer(careers);
        getCareer(careers);
        const countries: CountryT[] = res[1].data;
        getCountries(countries);
        let names: any = [];
        countries.forEach((c: any) =>
          names.push({
            value: c.id,
            label: c.name,
          })
        );
        setCountryList(names);
        const degrees: DegreeT[] = res[2].data;
        getDegrees(degrees);
        getCareer(careers);
        showLoader(false);
      })
      .catch((error) => {
        showLoader(false);
        console.log(JSON.stringify(error));
        Swal.fire("Error!", `${error}`, "error");
      });
  };

  useEffect(() => {
    pageLoad(language);
  }, [language]);

  const getCampusCount = async (cId: number) => {
    try {
      const countRes = await Api.get(
        DATA_BASE_URL,
        `${CAMPUS.CAMPUSCOUNT}?where[career_id]=${cId}&where[language_code]="`
      );
      if (countRes && countRes.data) {
        setArrayCount(countRes.data.count);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
      Swal.fire("Error!", `${error}`, "error");
    }
  };

  const getCampusesByCareerId = async (
    cId: number,
    currentPage: number = 1,
    lang,
    pageSize
  ) => {
    try {
      showLoader(true);
      setSelectedCId(cId);
      setLanguage(lang);
      let limit = pageSize;
      let skip = (currentPage - 1) * limit;
      const campusRes = await Api.get(
        DATA_BASE_URL,
        `${CAMPUS.CAMPUSLIST}/${cId}/${lang.value}/null/0/${limit}/${skip}`
      );
      if (campusRes.status === 200 && campusRes.data) {
        const campuses: CampusT[] = campusRes.data;
        getCampuses(campuses);
        showLoader(false);
      }
      getCampusCount(cId);
    } catch (error) {
      Swal.fire("Error!", `${error}`, "error");
      showLoader(false);
    }
  };

  const toggle = async (index: number, car: any) => {
    if (isOpen === index) {
      getCampuses([]);
      return setIsOpen(null);
    }
    setIsOpen(index);
    setCurrentPage(1);
    getCampusesByCareerId(car, currentPage, language, perPage);
  };

  const handleBlob = (campusObj) => {
    const imgReq: any = {
      url: UPLOAD + "/campuslogo",
      fileName: campusObj.logo,
      blob: imageBlob,
    };
    let blobReq: any = [{ ...imgReq }];
    return blobReq;
  };

  const addCampus = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsOpen(false);
    const campusObj = {
      career_id: careerId,
      sub_career_id: subCareerId,
      logo: Helper.getFileName(imageName, imageBlob),
      campus_url: url,
      country_id: selectedCountry,
      degrees: Array.from(selectedIds),
      featured: featured,
      start_date:
        startDate && featured === true
          ? dateFormat(startDate, "yyyy-mm-dd'T'HH:MM:ss")
          : "",
      end_date:
        endDate && featured === true
          ? dateFormat(endDate, "yyyy-mm-dd'T'HH:MM:ss")
          : "",
      is_active: true,
    };
    try {
      const campusRes = await Api.post(DATA_BASE_URL, CAMPUS.CAMPUS, campusObj);
      let blobReq = await handleBlob(campusObj);
      await FileUpload(blobReq);
      fields.forEach((r: any) => {
        r["campus_id"] = campusRes.data.id;
      });
      await Api.post(DATA_BASE_URL, CAMPUS.CAMPUSTRANSLATIONBULKUPLOAD, fields);
      toast.success("Campus added successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      toast.error(`${error}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const reteriveDegree = (arr: number[]) => {
    let degree: any = [...degrees];
    return degree.filter((c1: any) => arr.some((c2) => c1.id === c2));
  };

  const editCampus = (campusObj: any) => {
    if (!campusObj.id) return;
    setisEdit(true);
    setId(campusObj.id);
    getCareerId(campusObj.career_id);
    if (campusObj.career_id) {
      const subCareers = mappedCareer.filter((res: MappedCareerT) => {
        return res.id === campusObj.career_id;
      })[0]["sub_career"];
      getSubCareer(subCareers);
    } else {
      alert("Career not found");
      setisEdit(false);
    }
    getSubCareerId(campusObj.sub_career_id);
    getName(campusObj.name);
    setLogoUrl(campusObj.logo);
    getSelectedCountry(campusObj.country_id);
    JSON.parse(campusObj.degrees).forEach((degreeId) =>
      selectedIds.add(degreeId)
    );
    setSelectedDegrees(reteriveDegree(JSON.parse(campusObj.degrees)));
    getUrl(campusObj.campus_url);
    setFeatured(campusObj.featured === 1 ? true : false);
    setStartDate(campusObj.start_date !== "" ? campusObj.start_date : "");
    setEndDate(campusObj.end_date !== "" ? campusObj.end_date : "");
  };

  const updateCampus = async (event: React.FormEvent<HTMLFormElement>) => {
    if (!currentId) return;
    event.preventDefault();
    const campusObj: CampusT = {
      id: currentId,
      career_id: careerId,
      sub_career_id: subCareerId,
      logo: logoUrl,
      campus_url: url,
      country_id: selectedCountry,
      degrees: Array.from(selectedIds),
      featured: featured,
      start_date: startDate
        ? dateFormat(startDate, "yyyy-mm-dd'T'HH:MM:ss")
        : "",
      end_date: endDate ? dateFormat(endDate, "yyyy-mm-dd'T'HH:MM:ss") : "",
      is_active: true,
      name: name,
      language_code: language.value,
    };
    try {
      await Api.post(DATA_BASE_URL, CAMPUS.UPDATECAMPUS, campusObj);
      if(campusObj.logo !== ""){
        let blobReq = await handleBlob(campusObj);
        await FileUpload(blobReq);
      }
      let result = campuses.filter((res) => res.id !== currentId);      
      const ArrayData: any = [...result, {...campusObj, degrees: `[${campusObj.degrees}]`}];
      ArrayData.sort((a: any, b: any) => b.id - a.id);
      getCampuses(ArrayData);
    } catch (error) {
      console.log(JSON.stringify(error));
      Swal.fire("Error!", `${error}`, "error");
    }
  };

  const handleSwitch = async (nextState: boolean, event, campus) => {
    if (!campus.id) return;
    let featuredStatus: boolean = campus.featured;
    try {
      if (nextState === true) featuredStatus = campus.featured;
      else featuredStatus = false;
      const campusObj = {
        featured: featuredStatus === true ? true : false,
        is_active: nextState,
      };
      await Api.patch(
        DATA_BASE_URL,
        CreateURL(CAMPUS.CAMPUSBYID, { id: campus.id }),
        campusObj
      );
      getCampusesByCareerId(selectedCId, currentPage, language, perPage);
    } catch (error) {
      Swal.fire("Error!", `${error}`, "error");
    }
  };

  const deleteCampus = (campus: any) => {
    if (!campus.id) return;
    Swal.fire({
      title: "Are you sure?",
      text: `You will not be able to recover the campus with ID ${campus.id}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        Api.delete(DATA_BASE_URL, CreateURL(CAMPUS.CAMPUSBYID, { id: campus.id })).then(
          (res) => {
            if (res.status === 204) {
              const updatedCampus = campuses.filter((c) => c.id !== campus.id);
              getCampuses(updatedCampus);
              Swal.fire(
                "Deleted!",
                `Campus ID ${campus.id} has been deleted.`,
                "success"
              );
            }
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          "Cancelled",
          `Your Campus ID ${campus.id} detail is safe :)`,
          "info"
        );
      }
    });
  };

  const uploadExcel = async () => {
    try {
      setIsOpen(false);
      showLoader(true);
      const subCareerResponse = await Api.get(DATA_BASE_URL, CAREER.SUBCAREER);
      if (subCareerResponse.status === 200) {
        const subCareerData = subCareerResponse.data;
        const campusCopy = JSON.parse(JSON.stringify(workBookItem));
        campusCopy.forEach((r) => {
          if (r.career_id) r.career_id = parseInt(r.career_id);
          else r.career_id = null;
          if (r.sub_career_id) r.sub_career_id = parseInt(r.sub_career_id);
          else r.sub_career_id = 0;
          if (r.country_id) r.country_id = parseInt(r.country_id);
          else r.country_id = null;
          if (r.degrees) {
            let deg = r.degrees.trim().charAt(r.degrees.length - 1);
            if (deg === ",") {
              r.degrees = r.degrees.slice(0, -1);
              r.degrees = r.degrees.split(",").map((x: number) => +x);
            } else r.degrees = r.degrees.split(",").map((x: number) => +x);
          }
          if (
            r.featured === "TRUE" ||
            r.featured === "true" ||
            r.featured === "True"
          )
            r.featured = true;
          else if (
            r.featured === "FALSE" ||
            r.featured === "false" ||
            r.featured === "False"
          )
            r.featured = false;
          else r.featured = false;
          if (
            r.is_active === "TRUE" ||
            r.is_active === "true" ||
            r.is_active === "True"
          )
            r.is_active = true;
          else if (
            r.is_active === "FALSE" ||
            r.is_active === "false" ||
            r.is_active === "False"
          )
            r.is_active = false;
          else r.is_active = false;
          if (r.start_date)
            r.start_date = dateFormat(r.start_date, "yyyy-mm-dd'T'HH:MM:ss");
          else r.start_date = "";
          if (r.end_date)
            r.end_date = dateFormat(r.end_date, "yyyy-mm-dd'T'HH:MM:ss");
          else r.start_date = "";
        });
        // validate campus data
        const cIdArr1 = _.map(mappedCareer, "career_id");
        const cIdArr2 = _.map(campusCopy, "career_id");
        const invalidCIdArr = cIdArr2
          .filter((x: number) => !cIdArr1.includes(x))
          .filter((item, i, ar) => ar.indexOf(item) === i)
          .toString();
        const subcIdArr1 = [..._.map(subCareerData, "sub_career_id"), 0];
        const subcIdArr2 = _.map(campusCopy, "sub_career_id");
        const invalidSubcIdArr = subcIdArr2
          .filter((x: number) => !subcIdArr1.includes(x))
          .toString();
        const countryIdArr1 = _.map(countries, "id");
        const countryIdArr2 = _.map(campusCopy, "country_id");
        const invalidCountryIdArr = countryIdArr2
          .filter((x: number) => !countryIdArr1.includes(x))
          .toString();
        const degreeIdArr1 = _.map(degrees, "id");
        const degreeIdArr2 = _.map(campusCopy, "degrees")
          .toString()
          .split(",")
          .map((x: number) => +x);
        const invalidDegreeIdArr = degreeIdArr2
          .filter((x: number) => !degreeIdArr1.includes(x))
          .filter((item, i, ar) => ar.indexOf(item) === i)
          .toString();
        if (invalidCIdArr.length > 0)
          var invalidCid = `Career-id: ${invalidCIdArr};`;
        else invalidCid = "";
        if (invalidSubcIdArr.length > 0)
          var invalidSid = `Sub-career-id: ${invalidSubcIdArr};`;
        else invalidSid = "";
        if (invalidCountryIdArr.length > 0 && invalidCountryIdArr.includes(0))
          var invalidCountryid = `Country-id: ${invalidCountryIdArr} or empty and`;
        else if (
          invalidCountryIdArr.length > 0 &&
          !invalidCountryIdArr.includes(0)
        )
          invalidCountryid = `Country-id: ${invalidCountryIdArr};`;
        else invalidCountryid = "";
        if (invalidDegreeIdArr.length > 0 && invalidDegreeIdArr.includes(0))
          var invalidDid = `Degree-id cannot be ${invalidDegreeIdArr} or empty and`;
        else if (
          invalidDegreeIdArr.length > 0 &&
          !invalidDegreeIdArr.includes(0)
        )
          invalidDid = `Degree-id: ${invalidDegreeIdArr};`;
        else invalidDid = "";
        if (
          invalidCIdArr ||
          invalidSubcIdArr ||
          invalidCountryIdArr ||
          invalidDegreeIdArr
        ) {
          showLoader(false);
          Swal.fire(
            "Error!",
            `${invalidCid} ${invalidSid} ${invalidCountryid} ${invalidDid} is/are invalid! Please enter valid data`,
            "error"
          );
        } else if (
          !invalidCIdArr &&
          !invalidSubcIdArr &&
          !invalidCountryIdArr &&
          !invalidDegreeIdArr
        ) {
          const uploadRes = await Api.post(DATA_BASE_URL, CAMPUS.CAMPUSBULKUPLOAD, campusCopy);
          if (uploadRes.status === 200) {
            uploadRes.data.forEach((r) => (r.degrees = r.degrees.toString()));
            const ws1 = XLSX.utils.json_to_sheet(uploadRes.data);
            const ws2 = XLSX.utils.json_to_sheet(
              uploadRes.data.map((c) => c["id"])
            );
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws1, "Campuses");
            XLSX.utils.book_append_sheet(wb, ws2, "Campus_Translation");
            XLSX.writeFile(wb, "Campuses.xlsx");
            setModal(false);
            showLoader(false);
          }
        }
      }
    } catch (error) {
      Swal.fire("Failed!", `${error}`, "error");
      showLoader(false);
    }
  };

  const uploadCampusTranslation = async () => {
    try {
      const campusCopy = JSON.parse(JSON.stringify(workBookItem));
      const campusResponse = await Api.post(
        DATA_BASE_URL,
        CAMPUS.CAMPUSTRANSLATIONBULKUPLOAD,
        campusCopy
      );
      if (campusResponse.status === 200) {
        setModal(false);
        Swal.fire("Success!", `Campuses data upload is successful`, "success");
        showLoader(false);
        window.location.reload();
      }
    } catch (error) {
      Swal.fire("Failed!", `${error}`, "success");
    }
  };

  const handleFile = (e) => {
    const columns = [
      "career_id",
      "sub_career_id",
      "campus_url",
      "country_id",
      "degrees",
      "featured",
      "start_date",
      "end_date",
      "is_active",
    ];
    try {
      setCampusUpload(true);
      setModal(true);
      const file = e.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target?.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data: any = XLSX.utils.sheet_to_json(ws, { raw: false });
        if (data.length > 0) {
          setWorkBookColumn(columns);
          setWorkBookItem(data);
          setModal(true);
        } else {
          Swal.fire("Warning!", "Data cannot be empty", "warning");
          setModal(false);
        }
      };
    } catch (error) {
      Swal.fire("Error", `${error}`, "error");
      setModal(false);
    }
  };

  const handleFile2 = (e) => {
    const columns = ["campus_id", "name", "language_code"];
    try {
      setCampusTranslationUpload(true);
      setModal(true);
      const file = e.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target?.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[1];
        const ws = wb.Sheets[wsname];
        const data: any = XLSX.utils.sheet_to_json(ws, { raw: false });
        if (data.length > 0) {
          setWorkBookColumn(columns);
          setWorkBookItem(data);
          setModal(true);
        } else {
          Swal.fire("Warning!", "Data cannot be empty", "warning");
          setModal(false);
        }
      };
    } catch (error) {
      Swal.fire("Error", `${error}`, "error");
      setModal(false);
    }
  };

  const exportFile = () => {
    showLoader(true);
    Api.get(DATA_BASE_URL, CAMPUS.DOWNLOADALLCAMPUSES).then(
      (res: any) => {
        if (res.status === 200) {
          res.data.map((e) => {
            const obj = { ...e };
            obj.degrees = e.degrees.map((d) => {
              const deg = degrees.find((de) => de.id === d);
              return deg ? deg.name_en : "";
            });
            return obj;
          });
          Api.get(DATA_BASE_URL, CAMPUS.CAMPUSTRANSLATION).then((response: any) => {
            const ws1 = XLSX.utils.json_to_sheet(res.data);
            const ws2 = XLSX.utils.json_to_sheet(response.data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws1, "Campuses");
            XLSX.utils.book_append_sheet(wb, ws2, "Campus_Translation");
            XLSX.writeFile(wb, "Campuses.xlsx");
            showLoader(false);
          });
        }
      },
      (error) => {
        Swal.fire("Error!", `${error}`, "error");
        showLoader(false);
      }
    );
  };

  const renderCampusTable = () => {
    return (
      <Table className="table-responsive">
        <thead>
          <tr>
            {workBookColumn.map((c: any) => (
              <th key={c}>{c}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {workBookItem.map((r, i) => (
            <tr key={i}>
              <td>{r.career_id}</td>
              <td>{r.sub_career_id}</td>
              <td>{r.campus_url}</td>
              <td>{r.country_id}</td>
              <td>{r.degrees}</td>
              <td>{r.featured}</td>
              <td>{r.start_date}</td>
              <td>{r.end_date}</td>
              <td>{r.is_active ? r.is_active : ""}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  const renderTranslationTable = () => {
    return (
      <Table className="table-responsive">
        <thead>
          <tr>
            {workBookColumn.map((c: any) => (
              <th key={c}>{c}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {workBookItem.map((r, i) => (
            <tr key={i}>
              <td>{r.campus_id}</td>
              <td>{r.name}</td>
              <td>{r.language_code}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  const handleSearch = async (event) => {
    setCountryName([]);
    let cancelToken: any;
    if (event.target.value === undefined) {
    }
    let search = event.target.value || null;
    setSearchItem(search);
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    try {
      let skip = (currentPage - 1) * perPage;
      const campusNames = await Api.search(
        DATA_BASE_URL,
        `${CAMPUS.CAMPUSLIST}/${selectedCId}/'${language.value}'/${search}/0/${perPage}/${skip}`,
        { cancelToken: cancelToken.token }
      );
      if (campusNames.status === 200 && search !== null) {
        getCampuses(campusNames.data);
        setArrayCount(0);
      } else {
        getCampuses(campusNames.data);
        getCampusCount(selectedCId);
      }
    } catch (error) {
      Swal.fire("Error!", `${error}`, "error");
    }
  };

  const handleSearchCountry = async (event: any) => {
    setSearchItem("");
    setCountryName(event);
    let countryID = event?.value || 0;
    setCountryName(event.value);
    try {
      let skip = (currentPage - 1) * perPage;
      const campusNames = await Api.get(
        DATA_BASE_URL,
        `${CAMPUS.CAMPUSLIST}/${selectedCId}/${language.value}/null/${countryID}/${perPage}/${skip}`
      );
      if (campusNames?.data.length) {
        getCampuses(campusNames.data);
        setArrayCount(campusNames.data.length);
      }
    } catch (error) {
      Swal.fire("Error!", `${error}`, "error");
    }
  };

  const renderExcelModal = () => {
    return (
      <Modal className="csv-modal" isOpen={modal} size="lg">
        <ModalHeader charCode="X">Upload EXCEL</ModalHeader>
        <ModalBody className="csv-modal-body">
          {campusUpload ? renderCampusTable() : renderTranslationTable()}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={
              campusTranslationUpload ? uploadCampusTranslation : uploadExcel
            }
          >
            Upload
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => {
              setModal(false);
              setCampusTranslationUpload(false);
              setCampusUpload(false);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const resetForm = () => {
    setisEdit(false);
    setId(0);
    getCareerId(0);
    getSubCareerId(0);
    getSubCareer([]);
    getName("");
    setLogoUrl("");
    getUrl("");
    getSelectedCountry(0);
    selectedIds.clear();
    setSelectedDegrees([]);
    setStartDate("");
    setEndDate("");
    setFeatured(false);
    setFields([{ name: "", language_code: "" }]);
  };

  const addFields = () => {
    if (fields.length < LANGUAGE.length) {
      setFields([...fields, { name: "", language_code: "" }]);
    }
  };

  const removeFields = (i) => {
    const values = [...fields];
    if (values.length > 1) {
      values.splice(i, 1);
    }
    setFields(values);
  };

  return (
    <div className="component-content">
      <Loader className="spinner" show={loader} />
      <div></div>
      {renderExcelModal()}
      <ModalPopup
        className="primary"
        ref={modalRef}
        buttonLabel="Add More"
        popupTitle={`${isEdit ? "Edit" : "Add"} Campuses`}
        reset={resetForm}
        callbackMethod={(event: React.FormEvent<HTMLFormElement>) => {
          handleSubmit(event);
          resetForm();
        }}
      >
        <form onSubmit={handleSubmit}>
          <div className="row mb-2">
            <div className="col-sm">
              <div className="form ">
                <select
                  name="career_id"
                  onChange={(event) => {
                    handleCareerChange(event);
                  }}
                  value={careerId}
                  required
                >
                  <option value="">Select career</option>
                  {mappedCareer.map((car: MappedCareerT, i) => (
                    <option key={i} value={car.id}>
                      {car.name}
                    </option>
                  ))}
                </select>
                <label className="label-name">
                  <span className="content-name">Career Name</span>
                </label>
              </div>
            </div>
          </div>
          <div className={subCareer.length ? "row mb-2" : "row mb-2 d-none"}>
            <div className="col-sm">
              <div className="form">
                <select
                  name="sub_career_id"
                  value={subCareerId}
                  onChange={(event) => {
                    handleSubCareerChange(event);
                  }}
                >
                  <option value="">Select sub career</option>
                  {subCareer.map((car: SubCareerT) => (
                    <option key={car.id} value={car.id}>
                      {car.name}
                    </option>
                  ))}
                </select>
                <label className="label-name">
                  <span className="content-name">Sub Career Name</span>
                </label>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form">
              <input
                type="file"
                accept="image/*"
                style={{
                  width: "65px",
                  height: "70px",
                  padding: 0,
                  opacity: 0,
                }}
                onChange={onImageUpload}
              />
              <img
                id="preview_image"
                className="form-image-logo"
                alt="logo"
                src={
                  logoUrl ? S3_URL + "campus/logo/" + logoUrl : require("../../assets/images/campus.png").default
                }
              />
            </div>
          </div>
          {!isEdit ? (
            <div>
              {fields.map((field, idx) => (
                <div key={idx} className="row ml-1">
                  <div className="col-sm-3" style={{ padding: 0 }}>
                    <div className="form">
                      <select
                        name="language_code"
                        value={field.language_code}
                        onChange={(event) => handleInputFieldChange(idx, event)}
                        required
                      >
                        <option value="">language</option>
                        {LANGUAGE.map((l: any) => (
                          <option key={l.value} value={l.value}>
                            {l.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6 m-0">
                    <div className="form">
                      <input
                        type="text"
                        name="name"
                        value={field.name}
                        onChange={(event) => handleInputFieldChange(idx, event)}
                        required
                      />
                      <label className="label-name">
                        <span className="content-name">Campus Name</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-1 icons">
                    <i className="fa fa-plus" onClick={addFields}></i>
                  </div>
                  <div className="col-sm-1 icons">
                    <i
                      className="fa fa-minus align-items-center"
                      onClick={() => removeFields(idx)}
                    ></i>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="col-sm-9">
              <div className="form ">
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={handleNameChange}
                  required
                />
                <label className="label-name">
                  <span className="content-name">Campus Name</span>
                </label>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-sm">
              <div className="form">
                <input
                  type="text"
                  name="url"
                  value={url}
                  onChange={handleUrlChange}
                  required
                />
                <label className="label-name">
                  <span className="content-name">Campus Url</span>
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <div className="form">
                <select
                  name="selectedCountry"
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  required
                >
                  <option value="">Select country</option>
                  {countries.map((con: CountryT) => (
                    <option key={con.id} value={con.id}>
                      {con.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="form" style={{ overflow: "visible" }}>
            <Multiselect
              options={degrees}
              selectedValues={selectedDegrees}
              onSelect={onSelect}
              onRemove={onRemove}
              displayValue="name"
              closeIcon="cancel"
              showCheckbox="true"
              placeholder="Select degree"
              style={{
                searchBox: {
                  border: "none",
                  padding: 0,
                  marginTop: "20px",
                  height: "50px",
                },
                inputField: {
                  paddingTop: 0,
                },
                option: {
                  borderBottom: "1px solid #dbdbdb",
                  padding: "0 10px",
                },
              }}
            />
          </div>
          <div className="row align-item">
            <div className="col-sm-4 text-left">
              <h6>Featured: {featured ? "Yes" : "No"}</h6>
            </div>
            <div className="col-sm-8 text-left">
              <label>
                <input
                  type="checkbox"
                  name="featured"
                  checked={featured}
                  onChange={(e) => {
                    setFeatured(e.target.checked);
                  }}
                />
              </label>
            </div>
          </div>
          {featured === true && (
            <div className="row">
              <div className="col-sm">
                <div className="date-picker" style={{ marginTop: "20px" }}>
                  <DateRangePicker
                    startDate={startDate ? moment(startDate) : null}
                    startDateId="start"
                    endDate={endDate ? moment(endDate) : null}
                    endDateId="end"
                    onDatesChange={({ startDate, endDate }) => {
                      setStartDate(startDate);
                      setEndDate(endDate);
                    }}
                    focusedInput={focusedInput}
                    onFocusChange={(e) => setFocusedInput(e)}
                    displayFormat="DD-MMM-YYYY"
                  />
                </div>
              </div>
            </div>
          )}
        </form>
      </ModalPopup>
      <div className="datatable-panel">
        <div className="datatable-header">
          <div className="datatable-header-title">
            <h4 className="datatable-title">Campus</h4>
            <p className="datatable-subtitle">You can view campus</p>
          </div>
        </div>
        <div className="row ">
          <div className="col-sm-2 ml-3">
            <Select
              value={language}
              options={LANGUAGE}
              onChange={languageHandler}
            />
          </div>
          <div className="col mr-3">
            <div className="btn-group btn-sm float-right">
              <a
                title="sample excel"
                href="./assets/sample_campuses.xlsx"
                download
                type="button"
                className="btn btn-sm btn-outline-primary"
              >
                <i className="fa fa-download" />
              </a>
              <div className="upload-btn-wrapper">
                <input
                  type="file"
                  accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={(e) => {
                    handleFile(e);
                  }}
                />
                <button className="btn btn-sm btn-outline-primary">
                  Upload Campuses
                </button>
              </div>
              <div className="upload-btn-wrapper">
                <input
                  type="file"
                  accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={(e) => {
                    handleFile2(e);
                  }}
                />
                <button className="btn btn-sm btn-outline-primary">
                  Upload Campuses Translation
                </button>
              </div>
              <button
                id="download-csv"
                className="btn btn-sm btn-outline-primary"
                onClick={exportFile}
              >
                Download Excel
              </button>
            </div>
          </div>
        </div>
        <br />
        {career.length
          ? career.map((career: any, idx: number) => (
              <Card
                className="accordion-toggle collapsed shrink career-card"
                key={idx}
              >
                <CardBody
                  className="campus-header"
                  onClick={() => toggle(idx, career.id)}
                  key={idx}
                >
                  <CardTitle tag="h5" style={{ cursor: "pointer" }}>
                    {career.name}
                    <span className="toggle-button float-right align-center mt-3 mr-5">
                      {isOpen === idx ? (
                        <i className="fa fa-minus" />
                      ) : (
                        <i className="fa fa-plus" />
                      )}
                    </span>
                  </CardTitle>
                </CardBody>
                {isOpen === idx ? (
                  <CardBody className="table-card">
                    <div className="row">
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search by campus name"
                          value={searchItem}
                          onChange={handleSearch}
                        />
                        <span
                          style={{
                            position: "absolute",
                            float: "right",
                            marginTop: "-29px",
                            marginLeft: "90%",
                            color: "darkgray",
                            cursor: "pointer",
                          }}
                        >
                          <h6
                            onClick={(e) => {
                              handleSearch(e);
                              setSearchItem("");
                            }}
                          >
                            x
                          </h6>
                        </span>
                      </div>
                      <div className="col-sm-3">
                        <div className="search-select">
                          <Select
                            value={countryName}
                            options={countryList}
                            isClearable
                            onChange={handleSearchCountry}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="float-right">
                          <button
                            id="download-csv"
                            className="btn btn-sm btn-outline-primary"
                            onClick={() =>
                              ExportExcel(
                                campuses,
                                `Campuses in ${career.name}`
                              )
                            }
                          >
                            <i className="fa fa-file-excel-o" /> Download
                          </button>
                        </div>
                      </div>
                    </div>
                    <Datatable
                      currentPage={currentPage}
                      pageSize={perPage}
                      dataCount={arrayCount}
                      callbackMethod={changePage}
                      callbackSelectMethod={selectPageSize}
                    >
                      <table className="datatable-table table-responsive campus-table">
                        <thead>
                          <tr>
                            <th style={{ width: "6%" }}>#</th>
                            <th style={{ width: "10%" }}>Logo</th>
                            <th style={{ width: "12%" }}>Campus Name</th>
                            <th style={{ width: "12%" }}>URL</th>
                            <th style={{ width: "10%" }}>Sub Career</th>
                            <th style={{ width: "8%" }}>Location</th>
                            <th style={{ width: "10%" }}>Degrees</th>
                            <th style={{ width: "8%" }}>Featured</th>
                            <th style={{ width: "12%" }}>Duration</th>
                            <th style={{ width: "6%" }}>Status</th>
                            <th style={{ width: "6%" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {campuses.length ? (
                            campuses.map((campus: any) => (
                              <tr key={campus.id}>
                                <td>{campus.id}</td>
                                <td>
                                  {campus.logo ? (
                                    <img
                                      className="image-logo"
                                      src={
                                        S3_URL + "campus/logo/" + campus.logo
                                      }
                                      width="100"
                                      alt="campus_logo"
                                    />
                                  ) : (
                                    "No preview"
                                  )}
                                </td>
                                <td>{campus.name}</td>
                                <td>
                                  <a
                                    className="campus-url"
                                    href={campus.campus_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {campus.campus_url}
                                  </a>
                                </td>
                                <td>
                                  {getSubCareerName(
                                    campus.career_id,
                                    campus.sub_career_id
                                  )}
                                </td>
                                <td>{getLocation(campus.country_id)}</td>
                                <td>
                                  {campus.degrees && makeDegreeString(JSON.parse(campus.degrees))}
                                </td>
                                <td>
                                  {campus.featured === true ? "Yes" : "No"}
                                </td>
                                <td>
                                  {campus.start_date && campus.end_date
                                    ? `${dateFormat(
                                        campus.start_date,
                                        "mmmm dS, yyyy"
                                      )} to ${dateFormat(
                                        campus.end_date,
                                        "mmmm dS, yyyy"
                                      )} `
                                    : ""}
                                </td>
                                <td>
                                  <Switch
                                    onChange={(checked, event) => {
                                      handleSwitch(checked, event, campus);
                                    }}
                                    checked={campus.is_active}
                                    onColor="#9FE6A0"
                                    onHandleColor="#519872"
                                    handleDiameter={20}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={12}
                                    width={40}
                                    className="react-switch"
                                    id="material-switch"
                                  />
                                </td>
                                <td>
                                  <i
                                    className="fa fa-edit"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      modalRef.current?.updateModal();
                                      editCampus(campus);
                                    }}
                                  ></i>
                                  <br />
                                  <i
                                    className="fa fa-trash"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      deleteCampus(campus);
                                    }}
                                  ></i>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={8}>No records found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </Datatable>
                  </CardBody>
                ) : null}
              </Card>
            ))
          : ""}
      </div>
    </div>
  );
};

export default Campus;
