import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export const ModalPopup = forwardRef((props: any, ref: any) => {
  const { buttonLabel, className, isHide, popupTitle, modalOpenCallback } = props;
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const modalOpen = () => {
    modalOpenCallback && modalOpenCallback();
  }
  useImperativeHandle(ref, () => ({
    updateModal: () => {
      toggle();
    }
  }));
  return (
    <div>
      <Button color="danger add-more" style={{display: isHide?'none':'block'}} onClick={toggle}>{buttonLabel}</Button>
      <Modal isOpen={modal} toggle={toggle} className={className} onOpened={modalOpen}>
        {/* <form onSubmit={props.onSubmit} method='post'> */}
        <ModalHeader toggle={toggle} onClick={() => props.reset()}>{popupTitle}</ModalHeader>
        <ModalBody>
          {props.children}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" onClick={(evt) => { props.callbackMethod(evt); toggle(); }}>Save</Button>{' '}
          <Button color="secondary" type="reset" onClick={()=> {props.reset(); toggle();}}>Cancel</Button>
        </ModalFooter>
        {/* </form> */}
      </Modal>
    </div>
  );
});