/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Multiselect } from "multiselect-react-dropdown";
import { ModalPopup, Datatable } from "../../components/common";
import { Api, Helper } from "../../service";
import {
  PAGE_SIZE,
  COLOR,
  CAREER,
  LANGUAGE,
  DATA_BASE_URL,
} from "../../constant";
import { CareerT, ColorT, SubCareerT, MultiSelectItemT } from "../../interface";
import Select from "react-select";

const selectedIds = new Set<number>();

const SubCareer = () => {
  const [isEdit, setIsEdit] = useState(false);
  const modalRef: any = useRef();
  const [currentId, setCurrentId] = useState(0);
  const [subCareerId, setSubCareerId] = useState(0);
  const [careers, setCareers] = useState<CareerT[]>([]);
  const [colors, setColors] = useState<ColorT[]>([]);
  const [careerId, setCareerId] = useState(0);
  const [arrayCount, setArrayCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(PAGE_SIZE);
  const [language, setLanguage] = useState(LANGUAGE[0]);
  const [fields, setFields] = useState([
    { name: "", detail: "", language_code: "" },
  ]);
  const [selectedValues, setSelectedValues] = useState<MultiSelectItemT[]>([]);
  const [name, setName] = useState("");
  const [detail, setDetail] = useState("");
  const [isMerge, setIsMerge] = useState(false);
  const [subCareers, setSubCareers] = useState<SubCareerT[]>([]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCareerId(parseInt(event.target.value, 10));
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case "name":
        setName(event.target.value);
        break;

      case "detail":
        setDetail(event.target.value);
    }
  };
  const changePage = (selectedPage: number) => {
    setCurrentPage(selectedPage);
    getSubCareers(selectedPage, language, perPage);
  };

  const selectPageSize = (event) => {
    setPerPage(parseInt(event.target.value));
    getSubCareers(1, language, parseInt(event.target.value));
  };
  const onSelect = (
    selectedList: MultiSelectItemT[],
    selectedItem: MultiSelectItemT
  ) => {
    selectedIds.add(selectedItem.id);
    setSelectedValues(selectedList);
  };

  const onRemove = (
    selectedList: MultiSelectItemT[],
    removedItem: MultiSelectItemT
  ) => {
    selectedIds.delete(removedItem.id);
    setSelectedValues(selectedList);
  };

  useEffect(() => {
    pageLoad(language);
  }, []);

  const pageLoad = (lang) => {
    pageData(lang);
    getSubCareerCount();
    getSubCareers(currentPage, lang, perPage);
  };

  const languageHandler = (lang) => {
    pageLoad(lang);
    setCurrentPage(1);
  };
  const pageData = (language) => {
    Api.all(DATA_BASE_URL, [
      `${CAREER.CAREER}?filter[where][language_code]=${language.value}`,
      COLOR.COLOR,
    ])
      .then((res: any) => {
        const careers: CareerT[] = res[0].data;
        setCareers(careers);
        const colors: ColorT[] = res[1].data;
        setColors(colors);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const getSubCareerCount = async () => {
    try {
      const countRes = await Api.get(DATA_BASE_URL, CAREER.SUBCAREERCOUNT);
      if (countRes && countRes.data) {
        setArrayCount(countRes.data.count);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };
  const getSubCareers = (currentPage, language, pageSize) => {
    setLanguage(language);
    setLoader(true);
    setPerPage(pageSize);
    let skip = (currentPage - 1) * PAGE_SIZE;
    Api.get(
      DATA_BASE_URL,
      `${CAREER.SUBCAREER}?filter[where][language_code]=${language.value}&filter[limit]=${pageSize}&filter[skip]=${skip}`
    )
      .then((res) => {
        const subCareers: SubCareerT[] = res.data;
        setSubCareers(subCareers);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        alert(error.message);
      });
  };

  const getCareerName = (cid: number | undefined): string => {
    if (cid) {
      return Helper.getValueFromArray(careers, "career_id", cid, "name");
    } else return "";
  };
  const getColorName = (cids: any) => {
    if (cids.length === 0) {
      return "";
    } else if (cids.length === 1) {
      return Helper.getValueFromArray(colors, "id", cids[0], "name");
    } else {
      return Helper.makeStringFromArray(colors, "id", cids);
    }
  };

  const handleInputFieldChange = (index, event) => {
    const values = [...fields];
    values[index][event.target.name] = event.target.value;
    setFields(values);
  };

  const addFields = () => {
    if (fields.length < LANGUAGE.length) {
      setFields([...fields, { name: "", detail: "", language_code: "" }]);
    }
  };

  const removeFields = (i) => {
    const values = [...fields];
    if (values.length > 1) {
      values.splice(i, 1);
    }
    setFields(values);
  };

  const handleSubmit = (e) => {
    isEdit ? updateSubCareer(e) : addSubCareer(e);
  };
  const addSubCareer = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const subCareerObj = {
      career_id: careerId,
      color_id: Array.from(selectedIds),
      is_merge: isMerge,
    };
    try {
      const response = await Api.post(
        DATA_BASE_URL,
        CAREER.SUBCAREER,
        subCareerObj
      );
      if (response.status === 200) {
        fields.forEach((r: any) => {
          r["sub_career_id"] = response.data.id;
        });
        console.log(fields);
        const translationRes = await Api.post(
          DATA_BASE_URL,
          CAREER.SUBCAREERTRANSLATIONBULKUPLOAD,
          fields
        );
        if (translationRes.status === 200) {
          const data = translationRes.data.filter(
            (res) => res.language_code === language.value
          );
          delete data[0].id;
          const result = [{ ...response.data, ...data[0] }, ...subCareers];
          result.sort((a: any, b: any) => a.career_id - b.career_id);
          setSubCareers(result);
          resetForm();
        }
      }
    } catch (error) {
      alert(error);
    }
  };
  const reteriveColor = (arr: number[]) => {
    let color: any = [...colors];
    return color.filter((c1: any) => arr.some((c2) => c1.id === c2));
  };

  const editSubCareer = (subCareer) => {
    if (!subCareer.id) return;
    setIsEdit(true);
    setCurrentId(subCareer.id);
    setCareerId(subCareer.career_id);
    setSubCareerId(subCareer.sub_career_id);
    setIsMerge(subCareer.is_merge);
    setName(subCareer.name);
    setDetail(subCareer.detail);
    const filteredColors = colors.filter(
      (color) => subCareer.color_id?.indexOf(color.id) != -1
    );
    for (let item of filteredColors) {
      selectedIds.add(item.id);
    }
    setSelectedValues(filteredColors);
  };

  const updateSubCareer = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const subCareerObj: any = {
      id: subCareerId,
      career_id: careerId,
      color_id: Array.from(selectedIds),
      is_merge: isMerge,
      name: name,
      detail: detail,
      language_code: language.value,
    };
    Api.post(DATA_BASE_URL, CAREER.UPDATESUBCAREER, subCareerObj)
      .then((res) => {
        if (res.status === 200) {
          delete subCareerObj.id;
          let result = subCareers.filter((res) => res.id !== currentId);
          const ArrayData: any = [
            ...result,
            { id: currentId, sub_career_id: subCareerId, ...subCareerObj },
          ];
          ArrayData.sort((a: any, b: any) => a.career_id - b.career_id);
          setSubCareers(ArrayData);
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const resetForm = () => {
    setIsEdit(false);
    selectedIds.clear();
    setName("");
    setDetail("");
    setFields([{ name: "", detail: "", language_code: "" }]);
    setSelectedValues([]);
    setCareerId(0);
    setSubCareerId(0);
    setCurrentId(0);
  };

  return (
    <div className="component-content">
      <ModalPopup
        className="primary"
        ref={modalRef}
        buttonLabel="Add More"
        popupTitle={`${isEdit ? "Edit" : "Add"} Sub Career`}
        reset={resetForm}
        callbackMethod={(event: React.FormEvent<HTMLFormElement>) =>
          handleSubmit(event)
        }
      >
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm">
              <div className="form ">
                <select
                  name="career_id"
                  value={careerId}
                  onChange={(event) => {
                    handleSelectChange(event);
                    // getColors(event);
                  }}
                  required
                >
                  <option value="">Select career</option>
                  {careers.map((car: CareerT) => (
                    <option key={car.id} value={car.id}>
                      {car.name}
                    </option>
                  ))}
                </select>
                <label className="label-name">
                  <span className="content-name">Career Name</span>
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <div className="form" style={{ overflow: "visible" }}>
                <Multiselect
                  options={colors} // Options to display in the dropdown
                  selectedValues={selectedValues} // Preselected value to persist in dropdown
                  onSelect={onSelect} // Function will trigger on select event
                  onRemove={onRemove} // Function will trigger on remove event
                  displayValue="name"
                  closeIcon="cancel"
                  showCheckbox="true"
                  style={{
                    searchBox: {
                      border: "none",
                      padding: 0,
                      marginTop: "20px",
                      height: "50px",
                    },
                    inputField: {
                      paddingTop: 0,
                    },
                    option: {
                      borderBottom: "1px solid #dbdbdb",
                      padding: "0 10px",
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row align-item">
            <div className="col-sm-5 text-left">
              <h6>Merge with Career: {isMerge ? "Yes" : "No"}</h6>
            </div>
            <div className="col-sm-7 text-left">
              <label>
                <input
                  type="checkbox"
                  name="is_merge"
                  checked={isMerge}
                  onChange={(e) => {
                    setIsMerge(e.target.checked);
                  }}
                />
              </label>
            </div>
          </div>
          <div>
            {!isEdit ? (
              <div className="row mx-1">
                {fields.map((field, idx) => (
                  <div key={idx} className="row">
                    <div className="col-sm-4">
                      <div className="form ">
                        <select
                          name="language_code"
                          value={field.language_code}
                          onChange={(event) =>
                            handleInputFieldChange(idx, event)
                          }
                          required
                        >
                          <option value="">Select language</option>
                          {LANGUAGE.map((l: any) => (
                            <option key={l.value} value={l.value}>
                              {l.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6 ">
                      <div className="form">
                        <input
                          type="text"
                          name="name"
                          value={field.name}
                          onChange={(event) =>
                            handleInputFieldChange(idx, event)
                          }
                          required
                        />
                        <label className="label-name">
                          <span className="content-name">Sub Career Name</span>
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-1 icons">
                      <i className="fa fa-plus" onClick={addFields}></i>
                    </div>
                    <div className="col-sm-1 icons">
                      <i
                        className="fa fa-minus align-items-center"
                        onClick={() => removeFields(idx)}
                      ></i>
                    </div>
                    <div className="col-sm-12">
                      <div className="form">
                        <input
                          type="text"
                          name="detail"
                          value={field.detail}
                          onChange={(event) =>
                            handleInputFieldChange(idx, event)
                          }
                          required
                        />
                        <label className="label-name">
                          <span className="content-name">Description</span>
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form ">
                      <input
                        type="text"
                        name="name"
                        value={name}
                        onChange={handleChange}
                        required
                      />
                      <label className="label-name">
                        <span className="content-name">Sub Career Name</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form ">
                      <input
                        type="text"
                        name="detail"
                        value={detail}
                        onChange={handleChange}
                        required
                      />
                      <label className="label-name">
                        <span className="content-name">Detail</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </form>
      </ModalPopup>
      <Datatable
        title="Sub Career"
        description="You can view sub career"
        isShow={loader}
        currentPage={currentPage}
        pageSize={perPage}
        dataCount={arrayCount}
        callbackMethod={changePage}
        callbackSelectMethod={selectPageSize}
      >
        <div className="row ">
          <div className="col-sm-2">
            <Select
              value={language}
              options={LANGUAGE}
              onChange={languageHandler}
            />
          </div>
        </div>
        <table className="datatable-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Career Name</th>
              <th>Sub Careers</th>
              <th>Description</th>
              <th>Color</th>
              <th>Merge with Career</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {subCareers.length ? (
              subCareers.map((subcareer: SubCareerT, i) => (
                <tr key={subcareer.id}>
                  <td>{subcareer.sub_career_id}</td>
                  <td>{getCareerName(subcareer.career_id)}</td>
                  <td>{subcareer.name}</td>
                  <td>{subcareer.detail}</td>
                  <td>{getColorName(subcareer.color_id)}</td>
                  <td>{subcareer.is_merge === false ? "No" : "Yes"}</td>
                  <td>
                    <i
                      className="fa fa-edit"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        modalRef.current.updateModal();
                        editSubCareer(subcareer);
                      }}
                    ></i>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7}>No records found</td>
              </tr>
            )}
          </tbody>
        </table>
      </Datatable>
    </div>
  );
};

export default SubCareer;
