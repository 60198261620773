import React from "react";
import Spinner from "../../../assets/images/logo.png";
import "./style.css";

export const Loader = (props: any) => {
    const { show } = props;
    if(show){
        return (            
            <div>
                <div className="overlay"></div>
                    <div  className="spinner">
                    <img alt="spinner" src={Spinner} className='center-block' />
                </div>
            </div> 
        );
    } else {
        return (<></>)
    }
}