import React, { useEffect, useRef, useState } from "react";
import { ModalPopup, Datatable } from "components/common";
import Switch from "react-switch";
import Swal from "sweetalert2";
import { DateRangePicker } from "react-dates";
import dateFormat from "dateformat";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import { CouponT } from "interface/Coupon";
import { Api } from "../../service";
import { COUPON, CreateURL, PAGE_SIZE, PRODUCT, USER, USER_BASE_URL } from "../../constant";
import "./Coupon.css";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { parse } from "path";

const CreateCoupon = (props: any) => {
  const [loader, showLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [couponData, setCouponsData] = useState<CouponT[]>([]);
  const modalRef: any = useRef(null);
  let userDebounceTimeOut;
  const [couponID, setCouponID] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const [couponType, setCouponType] = useState("");
  const [couponValue, setCouponValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [userID, setUserID] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [productData, setProductData] = useState([]);
  const [productID, setProductID] = useState("");
  const [focusedInput, setFocusedInput] = useState(null);
  const type_options = [
    { value: "Percent", label: "Percent" },
    { value: "Amount", label: "Amount" },
    { value: "UserPercent", label: "UserPercent" },
    { value: "UserAmount", label: "UserAmount" },
  ];
  const [userSearchItem,setUserSearchItem] = useState([])
  const [isEdit, setisEdit] = useState(false);
  const changePage = (selectedPage: number) => {
    setCurrentPage(selectedPage);
  };

  const selectPageSize = (event: { target: { value: string } }) => {
    setCurrentPage(1);
  };
  useEffect(() => {
    getCouponsData();
    getProdname();
    getUsersCount();
  }, []);
  const getCouponsData = () => {
    showLoader(true);
    Api.get(USER_BASE_URL, COUPON.GET)
      .then((res: any) => {
        showLoader(false);
        setCouponsData(res.data);
      })
      .catch((error) => {
        showLoader(false);
        alert(error.message);
      });
  };
  const resetForm = () => {
    setisEdit(false);
    setCouponID(0);
    setCouponCode("");
    setCouponType("");
    setCouponValue("");
    setIsActive(false);
    setUserID("");
  };
  const editEventPopups = (cpObj: CouponT) => {
    if (!cpObj.id) return;
    setisEdit(true);
    setCouponID(cpObj.id);
    setCouponCode(cpObj.coupon_code);
    setCouponType(cpObj.coupon_type);
    setCouponValue(cpObj.coupon_value);
    setProductID(cpObj.product_id);
    setIsActive(cpObj.is_active);
    setUserID( cpObj.user_id);
  };
  const deleteEvent = async (couponID: number | undefined) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You will not be able to recover the coupon with ID ${couponID}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await Api.delete(
            USER_BASE_URL,
            CreateURL(COUPON.COUPONSBYID, { id: couponID })
          );
          if (response.status === 204) {
            getCouponsData();
            Swal.fire(
              "Deleted!",
              `Coupon ID ${couponID} has been deleted.`,
              "success"
            );
          }
        } catch (error) {
          console.log(error);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          "Cancelled",
          `Your Coupon ID ${couponID} detail is safe :)`,
          "info"
        );
      }
    });
  };
  const handleSubmit = (e: any) => {
    isEdit ? updateCouponPopupsById(e) : addCouponPopups(e);
  };

  const addCouponPopups = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const addResponse = await Api.post(USER_BASE_URL, COUPON.CREATE, createRequestObj());
      if (addResponse) {
        getCouponsData();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const createRequestObj = () => {
    return {
      coupon_code: couponCode,
      coupon_type: couponType,
      coupon_value: parseInt(couponValue),
      is_active: isActive,
      user_id: parseInt(userID) || '',
      valid_from: startDate,
      valid_to: endDate,
      product_id: parseInt(productID),
    };
  };
  const updateCouponPopupsById = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    try {
      const obj = createRequestObj();
      const res = await Api.update(
        USER_BASE_URL,
        CreateURL(COUPON.UPDATE, { id: couponID }),
        obj
      );
      if (res) {
        getCouponsData();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleChange = (event) => {
    switch (event.target.name) {
      case "coupon_code":
        setCouponCode(event.target.value);
        break;
      case "coupon_value":
        setCouponValue(event.target.value);
        break;
      case "coupon_type":
        setCouponType(event.target.value);
        break;
      case "user_id":
        setUserID(event.target.value);
        break;
      case "product_id":
        setProductID(event.target.value);
        break;
    }
  };
  const handleSwitch = async (nextState: boolean, Cid: number | undefined) => {
    if (!Cid) return;
    try {
      const advObj = {
        is_active: nextState,
      };
      const response = await Api.patch(
        USER_BASE_URL,
        CreateURL(COUPON.UPDATE, { id: Cid }),
        advObj
      );
      if (response.status === 204) {
        getCouponsData();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getProdname = async () => {
    try {
      const resp = await Api.get(USER_BASE_URL, `${PRODUCT.GET}`);
      if (resp?.data) {
        setProductData(resp.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const checkPercentRs = (val) => {
    return val === "Percent" || val === "UserPercent";
  };
  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    getUsers(string);
  };

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result);
  };

  const handleOnSelect = (item) => {
    // the item selected
    setUserID(item.id)
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };
  
  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>
          id: {item.id}
        </span>
        <span style={{ display: "block", textAlign: "left" }}>
          name: {item.name}
        </span>
        <span style={{ display: "block", textAlign: "left" }}>
          email: {item.email}
        </span>
      </>
    );
  };
  const getUsers = (val) => {
    clearTimeout(userDebounceTimeOut);
    showLoader(true);
    userDebounceTimeOut = setTimeout(() => {
      Api.get(USER_BASE_URL, `${USER.USERDETAILS}/${val}/null/null/0/0/0`)
        .then((res) => {
          showLoader(false);
          console.log(res.data);
          setUserSearchItem(res.data);
        })
        .catch((error) => {
          showLoader(false);
          console.log(JSON.stringify(error));
        });
    },600);
  };
  const getUsersCount = () => {
    Api.get(USER_BASE_URL, USER.COUNT)
      .then((res) => {
        console.log(res.data.count);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
      });
  };
  const readProdName = (pId: any) => {
    const filteredProd = productData.filter((p: any) => p.id === pId);
    return filteredProd.length ? filteredProd[0]['name'] : "Unknown Product";
  }
  return (
    <div className="component-content">
      <ModalPopup
        className="primary event-popup"
        ref={modalRef}
        buttonLabel="Add Coupon"
        popupTitle={`${isEdit ? "Edit" : "Add"} Coupon`}
        reset={resetForm}
        callbackMethod={(event: React.FormEvent<HTMLFormElement>) => {
          handleSubmit(event);
        }}
      >
        <form onSubmit={handleSubmit}>
          <div className="row mb-2">
            <div className="col-sm-12">
              <div className="form ">
                <input
                  type="text"
                  name="coupon_code"
                  value={couponCode}
                  onChange={handleChange}
                  required
                />
                <label className="label-name">
                  <span className="content-name">Coupon Code</span>
                </label>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form">
                <input
                  type="text"
                  name="coupon_value"
                  value={couponValue ? couponValue : ""}
                  onChange={handleChange}
                  required
                />
                <label className="label-name">
                  <span className="content-name">Coupon Value</span>
                </label>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form">
                <select
                  name="coupon_type"
                  value={couponType}
                  onChange={(e) => handleChange(e)}
                  required
                >
                  <option value="">Select Type</option>
                  {type_options.map((l: any) => (
                    <option key={l.value} value={l.value}>
                      {l.label}
                    </option>
                  ))}
                </select>
                <label className="label-name">
                  <span className="content-name">Coupon Type</span>
                </label>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form">
                <select
                  name="product_id"
                  value={productID}
                  onChange={(e) => handleChange(e)}
                  required
                >
                  <option value="">Select Product</option>
                  {productData.map((l: any) => (
                    <option key={l.id} value={l.id}>
                      {l.price}
                    </option>
                  ))}
                </select>
                <label className="label-name">
                  <span className="content-name">Coupon Type</span>
                </label>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="autocomplete">
                <ReactSearchAutocomplete
                  items={userSearchItem}
                  onSearch={handleOnSearch}
                  onHover={handleOnHover}
                  onSelect={handleOnSelect}
                  onFocus={handleOnFocus}
                  autoFocus
                  styling={{ borderRadius: "0px" }}
                  placeholder={"Select user"}
                  formatResult={formatResult}
                />
              </div>
              {/* <div className="form"> */}
              {/* <input
                  type="text"
                  name="user_id"
                  value={userID ? userID : ""}
                  onChange={handleChange}
                /> */}
              {/* <label className="label-name">
                  <span className="content-name">User ID</span>
                </label> */}
              {/* </div> */}
            </div>

            <div className="col-sm">
              <div className="date-picker" style={{ marginTop: "20px" }}>
                <DateRangePicker
                  startDate={startDate ? moment(startDate) : null}
                  startDateId="start"
                  endDate={endDate ? moment(endDate) : null}
                  endDateId="end"
                  onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(e) => setFocusedInput(e)}
                  displayFormat="DD-MMM-YYYY"
                />
              </div>
            </div>
          </div>
        </form>
      </ModalPopup>
      <Datatable
        title="Coupons"
        description="You can track coupon code here."
        isShow={loader}
        currentPage={currentPage}
        pageSize={PAGE_SIZE}
        dataCount={0}
        callbackMethod={changePage}
        callbackSelectMethod={selectPageSize}
      >
        <table className="datatable-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Coupon Code</th>
              <th>Coupon Type</th>
              <th>Coupon Value</th>
              <th>Created Date</th>
              <th>Active</th>
              <th>User ID</th>
              <th>Valid From</th>
              <th>Valid To</th>
              <th>Product Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {couponData.length ? (
              couponData.map((p: CouponT, index: number) => (
                <tr key={p.id}>
                  <td>{p.id}</td>
                  <td>{p.coupon_code}</td>
                  <td>{p.coupon_type}</td>
                  <td>
                    {!checkPercentRs(p.coupon_type) && <span>&#x20B9;</span>}
                    {" " + p.coupon_value}
                    {checkPercentRs(p.coupon_type) && <span>%</span>}
                  </td>
                  <td>{dateFormat(p.created_date, "mmmm dS, yyyy")}</td>
                  <td>
                    <Switch
                      onChange={(checked) => handleSwitch(checked, p.id)}
                      checked={p.is_active ? true : false}
                      onColor="#9FE6A0"
                      onHandleColor="#519872"
                      handleDiameter={20}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={12}
                      width={40}
                      className="react-switch"
                      id="material-switch"
                    />
                  </td>
                  <td>{p.user_id}</td>
                  <td>{dateFormat(p.valid_from, "mmmm dS, yyyy")}</td>
                  <td>{dateFormat(p.valid_to, "mmmm dS, yyyy")}</td>
                  <td>{readProdName(p.product_id)}</td>
                  <td>
                    <i
                      className="fa fa-edit"
                      style={{ cursor: "pointer" }}
                      title="Edit"
                      onClick={() => {
                        modalRef.current?.updateModal();
                        editEventPopups(p);
                      }}
                    ></i>
                    <i
                      className="fa fa-trash"
                      style={{ cursor: "pointer" }}
                      title="Clone"
                      onClick={() => deleteEvent(p.id)}
                    ></i>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5}>No records found</td>
              </tr>
            )}
          </tbody>
        </table>
      </Datatable>
    </div>
  );
};

export default CreateCoupon;
