import React from "react";
import { RouteComponentProps } from "react-router";
import { ModalPopup, Datatable } from "../../components/common";
import { Api, Helper } from "../../service";
import {
  CreateURL,
  QUESTION,
  GRADE,
  SUBJECT,
  HOBBY,
  BERKMAN,
  CAREERIDENTIFICATION,
  PERSONALITY,
  CAREER,
  LANGUAGE,
  DATA_BASE_URL, 
} from "../../constant";
import {
  QuestionT,
  GradeT,
  SubjectT,
  HobbyT,
  BerkmanChoiceT,
  CareerIdentificationT,
  PersonalityT,
  SubCareerT,
} from "../../interface";
import Select from "react-select";
import Swal from "sweetalert2";
interface RouteComponetPath {
  path?: string;
}

class Question extends React.Component {
  state = {
    formInput: {
      que_id: 0,
      que_label: "",
      que_format: "",
      que_sequence: 0,
      ans_choice: "",
      ans_label: "",
      ans_score: 0,
      min_select: 0,
      max_select: 0,
    },
    questions: [],
    grades: [],
    subjects: [],
    hobbies: [],
    berkmanChoices: [],
    careers: [],
    careerIdentification: [],
    personality: [],
    loader: false,
    language: LANGUAGE[0],
    fields: [
      {
        que_label: "",
        ans_choice: "",
        language_code: "",
      },
    ],
    isEdit: false,
  };
  params: any = {};
  test_id: number = 0;
  testType: string = "";
  multiSelect: boolean = false;
  modalRef: any = React.createRef();
  constructor(props: any | RouteComponentProps<RouteComponetPath>) {
    super(props);
    this.params = (
      this.props as RouteComponentProps<RouteComponetPath>
    ).match.params;
    this.test_id = this.params.id;
    this.testType = this.params.type;
    this.multiSelect = false;
  }
  handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.updateStateValue<React.ChangeEvent<HTMLSelectElement>>(event);
  };
  handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.updateStateValue<React.ChangeEvent<HTMLTextAreaElement>>(event);
  };
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.updateStateValue<React.ChangeEvent<HTMLInputElement>>(event);
  };
  updateStateValue = <T,>(event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      formInput: {
        ...this.state.formInput,
        [name]: value,
      },
    });
  };
  handleFieldInputChange = (index, event) => {
    const values = [...this.state.fields];
    values[index][event.target.name] = event.target.value;
    this.setState({
      fields: values,
    });
  };
  addFields = () => {
    if (this.state.fields.length < LANGUAGE.length) {
      this.setState({
        fields: [
          ...this.state.fields,
          {
            que_label: "",
            ans_choice: "",
            language_code: "",
          },
        ],
      });
    }
  };

  removeFields = (i) => {
    const values = [...this.state.fields];
    if (values.length > 1) {
      values.splice(i, 1);
    }
    this.setState({ fields: values });
  };
  componentDidMount() {
    this.pageData(LANGUAGE[0]);
  }
  pageData = (lang) => {
    this.setState({ loader: true, language: lang });
      Api.all(DATA_BASE_URL, [
        `${GRADE.GRADE}?filter[where][language_code]=${lang.value}`,
        `${SUBJECT.SUBJECT}?filter[where][language_code]=${lang.value}`,
        `${HOBBY.HOBBY}?filter[where][language_code]=${lang.value}`,
        `${BERKMAN.BERKMAN}?filter[where][language_code]=${lang.value}`,
        `${CAREERIDENTIFICATION.CAREERIDENTIFICATION}?filter[where][language_code]=${lang.value}`,
        `${CAREER.SUBCAREER}?filter[where][language_code]=${lang.value}`,
        `${PERSONALITY.PERSONALITY}?filter[where][language_code]=${lang.value}`,
        `${QUESTION.QUESTIONLIST}/${this.test_id}/'${lang.value}'/null`,
      ])
        .then((res: any) => {
          const grades: GradeT[] = res[0].data;
          this.setState({ grades });
          const subjects: SubjectT[] = res[1].data;
          this.setState({ subjects });
          const hobbies: HobbyT[] = res[2].data;
          this.setState({ hobbies });
          const berkmanChoices: BerkmanChoiceT[] = res[3].data;
          this.setState({ berkmanChoices });
          const careerIdentification: CareerIdentificationT[] = res[4].data;
          this.setState({ careerIdentification });
          const careers: SubCareerT[] = res[5].data;
          this.setState({ careers });
          const personality: PersonalityT[] = res[6].data;
          this.setState({ personality });
          const question: QuestionT[] = res[7].data;
          this.setState({ questions: question });
          this.setState({ loader: false });
        })
        .catch((error) => {
          console.log(error.message);
          this.setState({ loader: false });
        });
  };

  getStringFromArray<T>(arr: T[], filterKey1: string, filterKey2?: string) {
    if (filterKey2) {
      return Object.values(arr).map((k: any) => (
        <div key={k.id} className="berkman-test">
          <span>{k[filterKey1]}</span>
          <span>{k[filterKey2]}</span>
        </div>
      ));
    }
    return Object.values(arr).map((k: any) => (
      <div key={k.id} className="badge ics-badge">
        {k[filterKey1]}
      </div>
    ));
  }
  getString(data: string): string {
    if (!Helper.verifyJson(data)) {
      return data;
    }
    return "";
  }
  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    this.state.isEdit ? this.updateQuestion(event) : this.addQuestion(event);
  };
  addQuestion = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const queObj = {
      test_id: Number(this.test_id),
      que_format: this.state.formInput.que_format,
      ans_label: this.state.formInput.ans_label,
      que_sequence: Number(this.state.formInput.que_sequence) || 0,
      ans_score: Number(this.state.formInput.ans_score) || 0,
      min_select: Number(this.state.formInput.min_select) || 1,
      max_select: Number(this.state.formInput.max_select) || 1,
    };
    try {
      const response = await Api.post(DATA_BASE_URL, QUESTION.QUESTION, queObj);
      if (response.status === 200) {
        this.state.fields.forEach((field: any) => {
          field.que_id = response.data.que_id;
        });
        const responseT = await Api.post(
          DATA_BASE_URL, 
          QUESTION.QUESTIONTRANSLATION,
          this.state.fields
        );
        const data = responseT.data.filter(
          (res) => res.language_code === this.state.language.value
        );
        let ArrayData = [
          { ...response.data, ...data[0] },
          ...this.state.questions,
        ];
        ArrayData.sort((a: any, b: any) => a.que_sequence - b.que_sequence);
        this.setState({
          questions: ArrayData,
          loader: false,
        });
        this.resetForm();
      }
    } catch (error) {
      this.setState({ loader: false });
      alert(error?.message);
    }
  };

  editQuestion = (ques) => {
    if (!ques.que_id) return;
    this.setState({
      formInput: {
        que_id: ques.que_id,
        que_label: ques.que_label,
        que_sequence: ques.que_sequence,
        que_format: ques.que_format,
        ans_choice: ques.ans_choice,
        ans_label: ques.ans_label,
        ans_score: ques.ans_score,
        min_select: ques.min_select,
        max_select: ques.max_select,
      },
      isEdit: true,
    });
    if (ques.que_format === "MultiSelect")
      this.multiSelect = true;
       else this.multiSelect = false;
  };

  updateQuestion = async (event) => {
    event.preventDefault();
    const obj = {
      ...this.state.formInput,
      language_code: this.state.language.value,
    };
    try {
      await Api.post(DATA_BASE_URL, QUESTION.UPDATEQUESTIONAIRE, obj);
      let result = this.state.questions.filter(
        (res: any) => res.que_id !== this.state.formInput.que_id
      );
      const ArrayData: any = [...result, obj];
      ArrayData.sort((a: any, b: any) => a.que_sequence - b.que_sequence);
      this.setState({
        questions: ArrayData,
      });
      this.resetForm();
    } catch (error) {
      console.log(error.message);
    }
  };

  deleteQuestion = (que_id: number | undefined) => {
    if (!que_id) return;
    Swal.fire({
      title: "Are you sure?",
      text: `You will not be able to recover the question`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
    Api.delete(DATA_BASE_URL, CreateURL(QUESTION.DELETE, { id: que_id }))
      .then((res) => {
        if (res.status === 204) {
          const data = this.state.questions.filter((r: any) => r.que_id !== que_id);
          this.setState({
            questions: data,
          });
        }
      })
      .catch((error) => {
        alert(error.message);
      });
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        "Cancelled",
        `Your question is safe :)`,
        "info"
      );
    }
  });
  };

  multiSelectValidation = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value === "MultiSelect") {
      this.multiSelect = true;
    } else {
      this.multiSelect = false;
    }
  };

  languageHandler = (lang: string) => {
    this.setState({ language: lang });
    this.pageData(lang);
  };

  resetForm = () => {
    this.multiSelect = false;
    this.setState({
      formInput: {
        que_label: "",
        que_format: "",
        que_sequence: 0,
        ans_choice: "",
        ans_label: "",
        ans_score: 0,
        min_select: 0,
        max_select: 0,
      },
      isEdit: false,
      fields: [
        {
          que_label: "",
          ans_choice: "",
          language_code: "",
        },
      ]
    });
    
  };
  render() {
    return (
      <div className="component-content">
        <ModalPopup
          className="primary"
          buttonLabel="Add More"
          ref={(ref) => (this.modalRef = ref)}
          popupTitle={`${this.state.isEdit ? "Edit" : "Add"} Question`}
          reset={this.resetForm}
          callbackMethod={(event: React.FormEvent<HTMLFormElement>) =>
            {this.handleSubmit(event)}
          }
        >
          <form onSubmit={this.handleSubmit}>
            {this.state.isEdit === true ? (
              <div className="row">
                <div className="col-sm">
                  <div className="form ">
                    <input
                      type="text"
                      name="que_label"
                      value={this.state.formInput.que_label}
                      onChange={this.handleChange}
                      required
                    />
                    <label className="label-name">
                      <span className="content-name">Question</span>
                    </label>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {this.state.fields.map((field: any, idx: number) => (
                  <div key={idx}>
                    <div className="row">
                      <div className="col-sm-10">
                        <div className="form ">
                        <select
                        name="language_code"
                        value={field.language_code}
                        onChange={(e) => this.handleFieldInputChange(idx, e)}
                        required
                      >
                        <option value="">Select language</option>
                        {LANGUAGE.map((l: any) => (
                          <option key={l.value} value={l.value}>
                            {l.label}
                          </option>
                        ))}
                      </select>
                        </div>
                      </div>
                      <div className="col-sm-1 icons">
                        <i className="fa fa-plus" onClick={this.addFields}></i>
                      </div>
                      <div className="col-sm-1 icons">
                        <i
                          className="fa fa-minus align-items-center"
                          onClick={() => this.removeFields(idx)}
                        ></i>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                        <div className="form ">
                          <input
                            type="text"
                            name="que_label"
                            value={field.que_label}
                            onChange={(e) =>
                              this.handleFieldInputChange(idx, e)
                            }
                            required
                          />
                          <label className="label-name">
                            <span className="content-name">Question</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
            <div className="row">
              <div className="col-sm-6">
                <div className="form ">
                  <select
                    name="que_format"
                    value={this.state.formInput.que_format}
                    onChange={(event) => {
                      this.handleSelectChange(event);
                      this.multiSelectValidation(event);
                    }}
                    required
                  >
                    <option value="">Select format</option>
                    <option value="Manual">Manual</option>
                    <option value="MultiSelect">Multi Select</option>
                  </select>
                  <label className="label-name">
                    <span className="content-name">Question Format</span>
                  </label>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form ">
                  <input
                    type="text"
                    value={this.state.formInput.que_sequence}
                    name="que_sequence"
                    onChange={this.handleChange}
                    maxLength={2}
                    required
                  />
                  <label className="label-name">
                    <span className="content-name">Question Sequence</span>
                  </label>
                </div>
              </div>
            </div>
            {this.state.isEdit === true ? (
              <div className={"row" + (this.multiSelect === true ? "" : " d-none")}>
                <div className="col-sm">
                  <div className="form ">
                    <input
                      name="ans_choice"
                      value={this.state.formInput.ans_choice}
                      onChange={this.handleChange}
                    />
                    <label className="label-name">
                      <span className="content-name">Question Information</span>
                    </label>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {this.state.fields.map((field: any, idx: number) => (
                  <div className="row" key={idx}>
                    <div className="col-sm">
                      <div className="form ">
                        <input
                          name="ans_choice"
                          value={field.ans_choice}
                          onChange={(e) =>
                            this.handleFieldInputChange(idx, e)
                          }
                        />
                        <label className="label-name">
                          <span className="content-name">
                            Question Information({field.language_code})
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
            <div className={"row" + (this.multiSelect === true ? "" : " d-none")}>
              <div className="col-sm-6">
                <div className="form ">
                  <input
                    type="text"
                    name="min_select"
                    value={this.state.formInput.min_select}
                    onChange={this.handleChange}
                    maxLength={2}
                    required
                  />
                  <label className="label-name">
                    <span className="content-name">Minimum Select</span>
                  </label>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form ">
                  <input
                    type="text"
                    name="max_select"
                    value={this.state.formInput.max_select}
                    onChange={this.handleChange}
                    maxLength={2}
                    required
                  />
                  <label className="label-name">
                    <span className="content-name">Maximum Select</span>
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                {this.multiSelect ? (
                  <div className="form ">
                    <select
                      name="ans_label"
                      value={this.state.formInput.ans_label}
                      onChange={this.handleSelectChange}
                      required
                    >
                      <option value="">Select table</option>
                      <option value="berkmanchoice">Berkman Choice</option>
                      <option value="careerIdentification">
                        Career Identification
                      </option>
                      <option value="grade">Grade</option>
                      <option value="hobbies">Hobbies</option>
                      <option value="subjects">Subjects</option>
                    </select>
                    <label className="label-name">
                      <span className="content-name">Answer</span>
                    </label>
                  </div>
                ) : (
                  <div className="form ">
                    <textarea
                      name="ans_label"
                      value={this.state.formInput.ans_label}
                      onChange={this.handleTextAreaChange}
                      required
                    ></textarea>
                    <label className="label-name">
                      <span className="content-name">Answer</span>
                    </label>
                  </div>
                )}
              </div>
            </div>
            <div className={"row" + (this.multiSelect ? " d-none" : "")}>
              <div className="col-sm">
                <div className="form ">
                  <input
                    type="text"
                    name="ans_score"
                    value={this.state.formInput.ans_score}
                    onChange={this.handleChange}
                    required
                  />
                  <label className="label-name">
                    <span className="content-name">Answer Score</span>
                  </label>
                </div>
              </div>
            </div>
          </form>
        </ModalPopup>
        <Datatable
          title="Question"
          description="You can view berkman test question"
          isShow={this.state.loader}
        >
          <div className="row ">
            <div className="col-sm-2">
              <Select
                value={this.state.language}
                options={LANGUAGE}
                onChange={this.languageHandler}
              />
            </div>
          </div>
          <table className="datatable-table">
            <thead>
              <tr>
                <th style={{ width: "20%" }}>Question</th>
                <th style={{ width: "9%" }}>Format</th>
                <th style={{ width: "15%" }}>Answer Choice</th>
                <th style={{ width: "30%" }}>Answer</th>
                <th style={{ width: "7" }}>Question Sequence</th>
                <th style={{ width: "7%" }}>Validation</th>
                <th style={{ width: "7%" }}>Score</th>
                <th style={{ width: "5%" }}>Action</th>
              </tr>
            </thead>
            <tbody className="">
              {this.state.questions.length ? (
                this.state.questions.map((que: QuestionT) => (
                  <tr key={que.que_id}>
                    <td>{que.que_label}</td>
                    <td>{que.que_format}</td>
                    <td>{que.ans_choice}</td>
                    <td>
                      {que.ans_label === "grade"
                        ? this.getStringFromArray<GradeT>(
                            this.state.grades,
                            `name_${this.state.language.value}`
                          )
                        : que.ans_label === "subjects"
                        ? this.getStringFromArray<SubjectT>(
                            this.state.subjects,
                            `name_${this.state.language.value}`
                          )
                        : que.ans_label === "hobbies"
                        ? this.getStringFromArray<HobbyT>(
                            this.state.hobbies,
                            `name_${this.state.language.value}`
                          )
                        : que.ans_label === "berkmanchoice"
                        ? this.getStringFromArray<BerkmanChoiceT>(
                            this.state.berkmanChoices,
                            `choiceA_${this.state.language.value}`,
                            `choiceB_${this.state.language.value}`
                          )
                        : que.ans_label === "careerIdentification"
                        ? this.getStringFromArray<CareerIdentificationT>(
                            this.state.careerIdentification,
                            `name_${this.state.language.value}`
                          )
                        : que.ans_label === "careers"
                        ? this.getStringFromArray<SubCareerT>(
                            this.state.careers,
                            "name"
                          )
                        : que.ans_label === "personality"
                        ? this.getStringFromArray<PersonalityT>(
                            this.state.personality,
                            `name_${this.state.language.value}`
                          )
                        : this.getString(que.ans_label)}
                    </td>
                    <td>{que.que_sequence}</td>
                    <td>
                      Min: {que.min_select}
                      <br /> Max: {que.max_select}
                    </td>
                    <td>{que.ans_score === 0 ? "N/A" : que.ans_score}</td>
                    <td>
                      <i
                        className="fa fa-edit"
                        onClick={() => {
                          this.modalRef?.updateModal();
                          this.editQuestion(que);
                        }}
                      />
                      <i
                        className="fa fa-trash"
                        onClick={() => this.deleteQuestion(que.que_id)}
                      ></i>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6}>No records found</td>
                </tr>
              )}
            </tbody>
          </table>
        </Datatable>
      </div>
    );
  }
}

export default Question;
