/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { ModalPopup, Datatable } from "../components/common";
import { Api, Helper, FileUpload } from "../service";
import { AdvertisementsT, LanguageT } from "../interface";
import {
  CreateURL,
  ADVERTISEMENTS,
  LANGUAGE,
  PAGE_SIZE,
  UPLOAD,
  S3_URL,
  USER_BASE_URL,
} from "../constant";
import dateFormat from "dateformat";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import Switch from "react-switch";
import XLSX from "xlsx";
import Swal from "sweetalert2";

const Advertisements = () => {
  const [loader, showLoader] = useState(false);
  const [advertisements, setAdvertisements] = useState<AdvertisementsT[]>([]);
  const [currentId, setId] = useState(0);
  const [bannerImg, setBannerImg] = useState("");
  const [titleEn, setTitleEn] = useState("");
  const [titleHi, setTitleHi] = useState("");
  const [externalUrl, setExternalUrl] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [arrayCount, setArrayCount] = useState(0);
  const [focusedInput, setFocusedInput] = useState(null);
  const [isEdit, setisEdit] = useState(false);
  const modalRef: any = useRef(null);
  const [perPage, setPerPage] = useState(PAGE_SIZE);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case "title_en":
        setTitleEn(event.target.value);
        break;
      case "title_hi":
        setTitleHi(event.target.value);
        break;
      case "external_url":
        setExternalUrl(event.target.value);
        break;
      case "start_date":
        setStartDate(event.target.value);
        break;
      case "end_date":
        setEndDate(event.target.value);
        break;
    }
  };
  const resetForm = () => {
    setisEdit(false);
    setBannerImg("");
    setTitleEn("");
    setTitleHi("");
    setExternalUrl("");
    setStartDate("");
    setEndDate("");
    setId(0);
    getImage(new Blob());
  };

  const changePage = (selectedPage: number) => {
    setCurrentPage(selectedPage);
    getAdvertisements(selectedPage, perPage);
  };
  const selectPageSize = (event) => {
    setPerPage(parseInt(event.target.value));
    setCurrentPage(1);
    getAdvertisements(currentPage, parseInt(event.target.value));
  };

  useEffect(() => {
    getAdvertisements(currentPage, perPage);
    getAddvertisementsCount();
  }, []);

  const getAdvertisements = async (currentPage, pageSize) => {
    setPerPage(pageSize);
    showLoader(true);
    let skip = (currentPage - 1) * pageSize;
    try {
    const res = await Api.get(
      USER_BASE_URL,
      `${ADVERTISEMENTS.ADVERTISEMENTS}?filter[limit]=${pageSize}&filter[skip]=${skip}`
    );
      if(res.data){
        showLoader(false);
        setAdvertisements(res.data);
        setCurrentPage(currentPage);
      }
    } catch (error) {
        showLoader(false);
      };
  };

  const getAddvertisementsCount = () => {
    Api.get(USER_BASE_URL, `${ADVERTISEMENTS.COUNT}`)
      .then((res: any) => {
        showLoader(false);
        setArrayCount(res.data.count);
      })
      .catch((error) => {
        showLoader(false);
      });
  };

  const handleSubmit = (e: any) => {
    isEdit ? updateAdvertisementsById(e) : addAdvertisements(e);
  };

  const [imageBlob, getImage] = useState<Blob>(new Blob());
  const [imageName, setImageName] = useState("");
  const onImageUpload = (event: any) => {
    let file = event.target.files[0];
    setImageName(file.name.split(".").slice(0, -1).join("."));
    if (!file) return;
    const fTypes = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
    if (fTypes.every((type) => file.type !== type)) {
      alert(`${file.type} is not a supported format`);
      return;
    }
    getImage(file);
    showImagePreview(file);
  };

  const showImagePreview = (image: Blob) => {
    let previewImg: any = document.getElementById("preview_image");
    previewImg.src = "";
    const reader = new FileReader();
    reader.addEventListener("load", (event: any) => {
      previewImg.src = event.target.result;
    });
    reader.readAsDataURL(image);
  };

  const handleBlob = (adObj) => {
    const imgReq: any = {
      url: UPLOAD + "/advertisement",
      fileName: adObj.banner_img,
      blob: imageBlob,
    };
    let blobReq: any = [{ ...imgReq }];
    return blobReq;
  };

  const addAdvertisements = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const adObj: AdvertisementsT = {
        title_en: titleEn,
        title_hi: titleHi,
        banner_img: Helper.getFileName(imageName, imageBlob),
        external_url: externalUrl,
        start_date: dateFormat(startDate, "yyyy-mm-dd'T'HH:MM:ss"),
        end_date: dateFormat(endDate, "yyyy-mm-dd'T'HH:MM:ss"),
        is_active: true,
      };
      const response = await Api.post(USER_BASE_URL, ADVERTISEMENTS.ADVERTISEMENTS, adObj);
      if (response.status === 200) {
        let blobReq = await handleBlob(adObj);
        const fileResponse: any = await FileUpload(blobReq);
        if (fileResponse) {
          // await Api.patch(CreateURL(ADVERTISEMENTS.ADVERTISEMENTSBYID, { id: response.data.id }), {banner_img: fileResponse[0].files})
          setAdvertisements([...advertisements, response.data]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const editAdvertisements = (adObj) => {
    if (!adObj.id) return;
    setId(adObj.id);
    setisEdit(true);
    setBannerImg(adObj.banner_img);
    setTitleEn(adObj.title_en);
    setTitleHi(adObj.title_hi);
    setExternalUrl(adObj.external_url);
    setStartDate(adObj.start_date);
    setEndDate(adObj.end_date);
  };

  const updateAdvertisementsById = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    try {
      const adObj: AdvertisementsT = {
        title_en: titleEn,
        title_hi: titleHi,
        banner_img:
          imageBlob.size === 0
            ? bannerImg
            : Helper.getFileName(imageName, imageBlob),
        external_url: externalUrl,
        start_date: dateFormat(startDate, "yyyy-mm-dd'T'HH:MM:ss"),
        end_date: dateFormat(endDate, "yyyy-mm-dd'T'HH:MM:ss"),
        is_active: true,
      };
      const response = await Api.patch(
        USER_BASE_URL,
        CreateURL(ADVERTISEMENTS.ADVERTISEMENTSBYID, { id: currentId }),
        adObj
      );
      if (response.status === 204) {
        const result = advertisements.filter((res) => res.id !== currentId);
        if (imageBlob.size === 0) {
          const ArrayData = [...result, { ...adObj, id: currentId }];
          ArrayData.sort((a: any, b: any) => a.id - b.id);
          setAdvertisements(ArrayData);
        } else {
          let blobReq = await handleBlob(adObj);
          const fileResponse: any = await FileUpload(blobReq);
          // if (fileResponse) {
          //   await Api.patch(
          //     CreateURL(ADVERTISEMENTS.ADVERTISEMENTSBYID, { id: currentId }),
          //     { banner_img: fileResponse[0].files }
          //   );
            const ArrayData = [
              ...result,
              { ...adObj, id: currentId, banner_img: fileResponse[0].files },
            ];
            ArrayData.sort((a: any, b: any) => a.id - b.id);
            setAdvertisements(ArrayData);
          // }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const isActive = true;
  const handleSwitch = async (nextState, event, Aid) => {
    if (!Aid) return;
    try {
      const advObj = {
        is_active: nextState,
      };
      const response = await Api.patch(
        USER_BASE_URL,
        CreateURL(ADVERTISEMENTS.ADVERTISEMENTSBYID, { id: Aid }),
        advObj
      );
      if (response.status === 204) {
        getAdvertisements(currentPage, perPage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteAdvertisement = async (advId) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You will not be able to recover the Advertisement with ID ${advId}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await Api.delete(
            USER_BASE_URL,
            CreateURL(ADVERTISEMENTS.ADVERTISEMENTSBYID, { id: advId })
          );
          if (response.status === 204) {
            let result = advertisements.filter((adv) => adv.id !== advId);
            setAdvertisements(result);
            Swal.fire(
              "Deleted!",
              `Advertisement ID ${advId} has been deleted.`,
              "success"
            );
          }
        } catch (error) {
          console.log(error);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          "Cancelled",
          `Your Advertisement ID ${advId} detail is safe :)`,
          "info"
        );
      }
    });
  };

  const exportFile = () => {
    const ws1 = XLSX.utils.json_to_sheet(advertisements);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, "Advertisements");
    XLSX.writeFile(wb, "Advertisements.xlsx");
  };

  return (
    <div className="component-content">
      <ModalPopup
        className="primary"
        ref={modalRef}
        buttonLabel="Add More"
        popupTitle={`${isEdit ? "Edit" : "Add"} Advertisements`}
        reset={resetForm}
        callbackMethod={(event: React.FormEvent<HTMLFormElement>) => {
          handleSubmit(event);
          resetForm();
        }}
      >
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm">
              <div className="form ">
                <input
                  type="file"
                  accept="image/*"
                  style={{
                    width: "65px",
                    height: "70px",
                    padding: 0,
                    opacity: 0,
                    cursor: "pointer",
                  }}
                  onChange={onImageUpload}
                />
                <img
                  id="preview_image"
                  alt="banner_img"
                  src={
                    bannerImg
                      ? bannerImg
                      : require("../assets/images/dummy.png").default
                  }
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "65px",
                    height: "70px",
                    borderRadius: "5px",
                    pointerEvents: "none",
                  }}
                />
              </div>
            </div>
          </div>
          {LANGUAGE.map((lang: LanguageT) => (
            <div key={lang.value} className="row">
              <div className="col-sm">
                <div className="form ">
                  <input
                    type="text"
                    name={`title_${lang.value}`}
                    value={lang.value === "en" ? titleEn : titleHi}
                    onChange={handleChange}
                    required
                  />
                  <label className="label-name">
                    <span className="content-name">Title({lang.label})</span>
                  </label>
                </div>
              </div>
            </div>
          ))}
          <div className="row">
            <div className="col-sm">
              <div className="form ">
                <input
                  type="text"
                  name="external_url"
                  value={externalUrl}
                  onChange={handleChange}
                  required
                />
                <label className="label-name">
                  <span className="content-name">External URL</span>
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <div className="date-picker" style={{ marginTop: "20px" }}>
                <DateRangePicker
                  startDate={startDate ? moment(startDate) : null}
                  startDateId="start"
                  endDate={endDate ? moment(endDate) : null}
                  endDateId="end"
                  onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(e) => setFocusedInput(e)}
                  displayFormat="DD-MMM-YYYY"
                  required
                />
              </div>
            </div>
          </div>
        </form>
      </ModalPopup>
      <Datatable
        title="Advertisements"
        description="You can view advertisements here"
        isShow={loader}
        currentPage={currentPage}
        pageSize={perPage}
        dataCount={arrayCount}
        callbackMethod={changePage}
        callbackSelectMethod={selectPageSize}
      >
        <div className="row">
          <div className="col-sm-12 mr-1">
            <div className="float-right">
              <button
                id="download-csv"
                className="btn btn-sm btn-outline-primary"
                onClick={exportFile}
              >
                <i className="fa fa-file-excel-o" /> Download
              </button>
            </div>
          </div>
        </div>
        <table className="datatable-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Banner Image</th>
              <th>Title</th>
              <th>External URL</th>
              <th>From</th>
              <th>To</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {advertisements.length ? (
              advertisements.map((ad: AdvertisementsT) => (
                <tr key={ad.id}>
                  <td>{ad.id}</td>
                  <td>
                    {ad.banner_img ? (
                      <figure>
                        <img
                          src={S3_URL + "advertisement/" + ad.banner_img}
                          alt={ad.banner_img}
                          width="100"
                        />
                        {/* <figcaption>{ad.banner_img}</figcaption> */}
                      </figure>
                    ) : (
                      "No preview"
                    )}
                  </td>
                  <td>
                    {ad.title_en} | {ad.title_hi}
                  </td>
                  <td>
                    <a
                      href={ad.external_url}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {ad.external_url}
                    </a>
                  </td>
                  <td>{dateFormat(ad.start_date, "mmmm dS, yyyy")}</td>
                  <td>{dateFormat(ad.end_date, "mmmm dS, yyyy")}</td>
                  <td>
                    <Switch
                      onChange={(checked, event) =>
                        handleSwitch(checked, event, ad.id)
                      }
                      checked={ad.is_active === true ? isActive : !isActive}
                      onColor="#9FE6A0"
                      onHandleColor="#519872"
                      handleDiameter={20}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={12}
                      width={40}
                      className="react-switch"
                      id="material-switch"
                    />
                  </td>
                  <td>
                    <i
                      className="fa fa-edit"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        modalRef.current?.updateModal();
                        editAdvertisements(ad);
                      }}
                    ></i>
                    <i
                      className="fa fa-trash"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        deleteAdvertisement(ad.id);
                      }}
                    ></i>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5}>No records found</td>
              </tr>
            )}
          </tbody>
        </table>
      </Datatable>
    </div>
  );
};

export default Advertisements;
