/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { ModalPopup, Datatable } from "../../components/common";
import { Api } from "../../service";
import {
  DEGREE,
  LANGUAGE,
  PAGE_SIZE,
  DATA_BASE_URL,
} from "../../constant";
import { DegreeT, LanguageT } from "../../interface";
import Select from "react-select";
const Degree = () => {
  const [language, setLanguage] = useState(LANGUAGE[0]);
  const [loader, showLoader] = useState(false);
  const [nameEn, setNameEn] = useState("");
  const [nameHi, setNameHi] = useState("");
  const [name, setName] = useState("");
  const [degrees, setDegrees] = useState<DegreeT[]>([]);
  const [isEdit, setisEdit] = useState(false);
  const [perPage, setPerPage] = useState(PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(1);
  const [arrayCount, setArrayCount] = useState(0);

  const [id, setId] = useState();
  const languageHandler = (lang) => {
    pageLoadData(lang);
    setCurrentPage(1);
  };
  const modalRef: any = useRef(null);
  const resetForm = () => {
    setNameEn("");
    setNameHi("");
    setName("");
    setisEdit(false);
  };
  const handleSubmit = (e) => {
    isEdit ? updateDegreeById(e) : addDegree(e);
  };
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case "name_en":
        setNameEn(event.target.value);
        break;
      case "name_hi":
        setNameHi(event.target.value);
        break;
      case "name":
        setName(event.target.value);
        break;
    }
  };
  useEffect(() => {
    return pageLoadData(language);
  }, []);

  const pageLoadData = (lang) => {
    getDegrees(currentPage, perPage, lang);
    getCount();
    setLanguage(lang);
  };

  const getDegrees = (currentPage, pageSize, lang) => {
    showLoader(true);
    setLanguage(lang);
    let limit = pageSize;
    let skip = (currentPage - 1) * limit;
    Api.get(
      DATA_BASE_URL,
      `${DEGREE.DEGREE}?filter[where][language_code]=${lang.value}&filter[limit]=${limit}&filter[skip]=${skip}`
    )
      .then((res: any) => {
        setDegrees(res.data);
        showLoader(false);
      })
      .catch((error) => {
        console.log(error.message);
        showLoader(false);
      });
  };

  const getCount = () => {
    Api.get(DATA_BASE_URL, DEGREE.COUNT)
      .then((res) => setArrayCount(res.data.count))
      .catch((error) => console.log(error.message));
  };
  const changePage = (selectedPage: number) => {
    setCurrentPage(selectedPage);
    getDegrees(selectedPage, perPage, language);
  };
  const selectPageSize = (event: any) => {
    setPerPage(parseInt(event.target.value));
    setCurrentPage(1);
    getDegrees(1, parseInt(event.target.value), language);
  };
  const addDegree = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    //const degreeObj: DegreeT = { name_en: nameEn, name_hi: nameHi, name: name };
    const degreeBlankObj = {};

    Api.post(DATA_BASE_URL, DEGREE.DEGREE, degreeBlankObj)
      .then((res) => {
        const degreeInsObj = [
          {
            degree_id: res.data.id,
            language_code: "en",
            name: nameEn,
          },
          {
            degree_id: res.data.id,
            language_code: "hi",
            name: nameHi,
          },
        ];

        Api.post(DATA_BASE_URL, DEGREE.DEGREETRANSALATION, degreeInsObj)
          .then((resT) => {
            console.log("Added");
          })
          .catch((error) => {
            console.log(error.message);
          });
        setDegrees([...degrees, res.data]);
        getDegrees(currentPage, perPage, language);
        resetForm();
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const editDegree = (degree) => {
    if (!degree.id) return;
    setisEdit(true);
    setId(degree.id);
    setNameEn(degree.name_en);
    setNameHi(degree.name_hi);
    setName(degree.name);
  };
  const updateDegreeById = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const degreeObj = {
      name: name,
      language_code: language.value,
      degree_id: id,
    };
    Api.patch(DATA_BASE_URL, DEGREE.DEGREEUPDATE, degreeObj)
      .then((res) => {
        if (res.status === 200) {
          getDegrees(currentPage, perPage, language);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  // const deleteDegree = (degree_id: (number | undefined)) => {
  // 	if(!degree_id) return;
  // 	Api.delete(CreateURL(DEGREE.DELETE, {id: degree_id}))
  // 		.then(res => {
  // 			const filterResult = degrees.filter(res => res.id !== degree_id);
  //             setDegrees(filterResult);
  // 		}).catch(error => {
  // 			console.log(error.message);
  // 		})
  // }
  return (
    <div className="component-content">
      <ModalPopup
        ref={modalRef}
        className="primary"
        buttonLabel="Add More"
        popupTitle={`${isEdit ? "Edit" : "Add"} Degree`}
        reset={resetForm}
        callbackMethod={(event: React.FormEvent<HTMLFormElement>) =>
          handleSubmit(event)
        }
      >
        <form onSubmit={handleSubmit}>
          {LANGUAGE.map((lang: LanguageT) => {
            if (isEdit && lang.value === language.value) {
              return (
                <fieldset key={lang.value}>
                  <legend>{lang.label}</legend>
                  <div className="row">
                    <div className="col-sm">
                      <div className="form ">
                        <input
                          type="text"
                          name={`name`}
                          value={name}
                          onChange={handleChange}
                          required
                        />
                        <label className="label-name">
                          <span className="content-name">
                            Name({lang.label})
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>
              );
            }
            if (!isEdit) {
              return (
                <fieldset key={lang.value}>
                  <legend>{lang.label}</legend>
                  <div className="row">
                    <div className="col-sm">
                      <div className="form ">
                        <input
                          type="text"
                          name={`name_${lang.value}`}
                          onChange={handleChange}
                          required
                        />
                        <label className="label-name">
                          <span className="content-name">
                            Name({lang.label})
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>
              );
            }
          })}
        </form>
      </ModalPopup>
      <Datatable
        title="Degree"
        description="You can view degree"
        isShow={loader}
        dataCount={arrayCount}
        currentPage={currentPage}
        pageSize={perPage}
        callbackMethod={changePage}
        callbackSelectMethod={selectPageSize}
      >
        <div className="row ">
          <div className="col-sm-2">
            <Select
              value={language}
              options={LANGUAGE}
              onChange={languageHandler}
            />
          </div>
        </div>
        <table className="datatable-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Degree Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {degrees.length ? (
              degrees.map((degree: DegreeT) => (
                <tr key={degree.id}>
                  <td>{degree.id}</td>
                  <td>{degree.name}</td>
                  <td>
                    <i
                      className="fa fa-edit"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        modalRef.current?.updateModal();
                        editDegree(degree);
                      }}
                    ></i>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3}>No records found</td>
              </tr>
            )}
          </tbody>
        </table>
      </Datatable>
    </div>
  );
};

export default Degree;
