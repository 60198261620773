import React, { useEffect, useState } from "react";
import { Datatable } from "components/common";

import { RedeemT } from "interface/Coupon";
import { Api } from "../../service";
import { COUPON, PAGE_SIZE, USER_BASE_URL } from "../../constant";
import "./Coupon.css";
import dateFormat from "dateformat";

const RedeemCoupon = (props: any) => {
  const [loader, showLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [couponData, setCouponsData] = useState<RedeemT[]>([]);
  const changePage = (selectedPage: number) => {
    setCurrentPage(selectedPage);
  };
  const selectPageSize = (event) => {
    setCurrentPage(1);
  };
  useEffect(() => {
    getCouponsData();
  }, []);
  const getCouponsData = () => {
    Api.get(USER_BASE_URL, COUPON.REDEEMLIST)
      .then((res: any) => {
        showLoader(false);
        setCouponsData(res.data);
      })
      .catch((error) => {
        showLoader(false);
        alert(error.message);
      });
  };
  return (
    <div className="component-content">
      <Datatable
        title="Coupons"
        description="You can track coupon code here."
        isShow={loader}
        currentPage={currentPage}
        pageSize={PAGE_SIZE}
        dataCount={0}
        callbackMethod={changePage}
        callbackSelectMethod={selectPageSize}
      >
        <table className="datatable-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Coupon Code</th>
              <th>User Details</th>
              <th>Redeem Date</th>
            </tr>
          </thead>
          <tbody>
            {couponData.length ? (
              couponData.map((p: RedeemT, index: number) => (
                <tr key={p.id}>
                  <td>{p.id}</td>
                  <td>{p.coupon_code}</td>
                  <td>
                    <div>User ID: {p.userId}</div>
                    <div>{p.userName}</div>
                    <div>{p.contact}</div>
                    <div>{p.email}</div>
                  </td>
                  <td>{dateFormat(p.redeem_date, "mmmm dS, yyyy")}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5}>No records found</td>
              </tr>
            )}
          </tbody>
        </table>
      </Datatable>
    </div>
  );
};

export default RedeemCoupon;
