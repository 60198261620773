export const Helper = {
    getValueFromArray<T>(arr: Array<T>, 
            filterOn: string, 
            filterWith: (string | number), 
            key:string): string {
        if(!arr.length) return "";
        const result: any[] = arr.filter((res: any) => {
            return res[filterOn] === filterWith;
        });
        return result.length ? result[0][key] : "";
    },
    makeStringFromArray<T>(arr: Array<T>, 
            filterOn: string, 
            filterWith: string): string {
        if (!arr.length) return '';
        let result: any[string] = [];
        arr.forEach((res: any) => {
            if(filterWith && (filterWith.includes(res[filterOn]))){
                result.push(res.name);
            }
        });
        return result.length ? result.join(', ') : "";
    },
    verifyJson(str: string): boolean { 
        try { JSON.parse(str); return true; } 
        catch (e) { return false; } 
    },
    getFileName(name: string, blob: Blob) {
        let newName = '';
        if(name && blob.size >0){
            newName = name + "." + blob.type.split("/")[1];       
        }
        return newName;
    },
    getDataFromLocalStorage(key: string) {
        let keyName: any = window.localStorage;
        return JSON.parse(keyName.getItem(key))
    }
}