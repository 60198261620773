import React, { useState, useEffect } from "react";
import { Datatable } from "../../components/common";
import { Api, ExportExcel } from "../../service";
import {
  EVENTPOPUP, USER_BASE_URL} from "../../constant";
import Select from "react-select";

const ViewResponse = () => {
  const [loader, setLoader] = useState(false);
  const [events, setEvents] = useState("");
  const [searchItem, setSearchItem] = useState<String[]>([]);
  const [usersList, getUsersList] = useState<String[]>([]);

  useEffect(() => {
    setLoader(true);
    Api.all(
      USER_BASE_URL,
      [`${EVENTPOPUP.EVENTPOPUPTRANSLATION}?filter[where][language_code]=en`,
    ]).then((res: any) => {
      const eventList = res[0].data;
      let names: any = [];
      eventList.forEach((c: any) =>
        names.push({
          value: c.id,
          label: c.title,
        })
      );
      setEvents(names);
      setLoader(false);
    });
  }, []);

  const handleSearch = async (search: any) => {
    setSearchItem(search);    
    try{
      setLoader(true);
      const users = await Api.get(USER_BASE_URL, `${EVENTPOPUP.EVENTUSERSREGISTRATIONLIST}/${search.value}`);
      getUsersList(users.data);
      setLoader(false);
    } catch(error) {
      console.log(error);
      setLoader(false);
    }
  };

  const exportExcel = (users) => {    
    users.map((user:any) => {return user['event_name'] = searchItem['label']})
    ExportExcel(users, "event-response-user-list")
  }

  return (
    <div className="component-content">
      <Datatable
        title="View Response"
        description="You can view responses here"
        isShow={loader}
      >
        <div className="row">
            <div className="col-sm-6" style={{position: "absolute"}} >
          <div>
            <Select
              value={searchItem}
              options={events}
              onChange={handleSearch}
            />
            </div>
          </div>
          <div className="col-sm-6" style={{"marginLeft": "50%"}}>
            <div className="float-right">
              <button
                id="download-csv"
                className="btn btn-md btn-outline-primary"
                onClick={()=>exportExcel(usersList)}
              >
                <i className="fa fa-file-excel-o" /> Download
              </button>
            </div>
          </div>
          <hr/>
        </div>
        <table className="datatable-table">
          <thead>
            <tr>
              <th>User Id</th>
              <th>Name</th>
              <th>Contact</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {usersList.length > 0 ? usersList.map((user:any) =>
            <tr key={user.id}>
              <td>{user.user_id}</td>
              <td>{user.name}</td>
              <td>{user.contact}</td>
              <td>{user.email}</td>
            </tr>
            ):<tr>
            <td colSpan={10}>No records found</td>
          </tr>}
          </tbody>
        </table>
      </Datatable>
    </div>
  );
};

export default ViewResponse;
