import React, { createRef } from "react";
import { ModalPopup, Datatable } from "../../components/common";
import { Api } from "../../service";
import {
  PAGE_SIZE,
  CreateURL,
  SUBJECT,
  LANGUAGE,
  DATA_BASE_URL,
} from "../../constant";
import { LanguageT, SubjectT } from "../../interface";
import Swal from "sweetalert2";
import Select from "react-select";
class Subject extends React.Component {
  state = {
    formInput: {
      name_en: "",
      name_hi: "",
      name: "",
    },
    subjects: [],
    currentId: 0,
    arrayCount: 0,
    currentPage: 1,
    loader: false,
    perPage: PAGE_SIZE,
    lang: LANGUAGE[0],
  };
  isEdit = false;
  modalRef: any = createRef();
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      formInput: {
        ...this.state.formInput,
        [name]: value,
      },
    });
  };
  changePage = (selectedPage: number) => {
    this.setState(
      {
        currentPage: selectedPage,
      },
      () => {
        this.getSubjects(selectedPage, this.state.perPage, this.state.lang);
      }
    );
  };
  selectPageSize = (event) => {
    this.setState({
      perPage: parseInt(event.target.value),
      currentPage: 1,
    });
    this.getSubjects(
      this.state.currentPage,
      parseInt(event.target.value),
      this.state.lang
    );
  };
  handleSubmit = (e: any) => {
    this.isEdit ? this.updateSubjectById(e) : this.addSubject(e);
  };
  componentDidMount() {
    this.getSubjectsCount();
    this.getSubjects(
      this.state.currentPage,
      this.state.perPage,
      this.state.lang
    );
  }
  getSubjectsCount = async () => {
    const countRes = await Api.get(DATA_BASE_URL, SUBJECT.SUBJECTCOUNT);
    if (countRes && countRes.data) {
      this.setState({ arrayCount: countRes.data.count });
    }
  };
  languageHandler = (lang) => {
    this.getSubjects(this.state.currentPage, this.state.perPage, lang);
    this.state.lang = lang;
  };
  getSubjects = (currentPage, pageSize, lang) => {
    this.setState({ loader: true });
    let limit = pageSize;
    let skip = (currentPage - 1) * limit;
    Api.get(
      DATA_BASE_URL,
      `${SUBJECT.SUBJECT}?filter[where][language_code]=${lang.value}&filter[limit]=${limit}&filter[skip]=${skip}`
    )
      .then((res) => {
        const subjects: SubjectT[] = res.data;
        this.setState({ subjects: subjects, loader: false });
      })
      .catch((error) => {
        this.setState({ loader: false });
        console.log(error.message);
      });
  };
  addSubject = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const setData = (subject: SubjectT) => {
      this.setState({
        formInput: {
          name_en: subject.name_en,
          name_hi: subject.name_hi,
          name: subject.name,
        },
      });
    };
    const subjectTabObj = {};
    Api.post(DATA_BASE_URL, SUBJECT.SUBJECT, subjectTabObj)
      .then((res) => {
        const subjectInsObj = [
          {
            name: this.state.formInput.name_en,
            language_code: "en",
            subject_id: res.data.id,
          },
          {
            name: this.state.formInput.name_hi,
            language_code: "hi",
            subject_id: res.data.id,
          },
        ];
        Api.post(DATA_BASE_URL, SUBJECT.SUBJECTTRANSLATION, subjectInsObj)
          .then((resT) => {
            console.log("added");
          })
          .catch((error) => {
            console.log(error.message);
          });
        if (res.status === 200) {
          this.setState({
            subjects: [...this.state.subjects, res.data],
            loader: false,
          });
          this.resetForm();
        }
      })
      .catch((error) => {
        this.setState({ loader: false });
        console.log(error.message);
      });
  };
  editSubject = (subject: SubjectT) => {
    if (!subject.id) return;
    this.isEdit = true;
    this.setState({ currentId: subject.id });
    this.setState({
      formInput: {
        name_en: subject.name_en,
        name_hi: subject.name_hi,
        name: subject.name,
      },
    });
  };
  updateSubjectById = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const subjectObj: SubjectT = { ...this.state.formInput };
    Api.patch(
      DATA_BASE_URL,
      CreateURL(SUBJECT.SUBJECTBYID, { id: this.state.currentId }),
      subjectObj
    )
      .then((res) => {
        if (res.status === 204) {
          const result = this.state.subjects.filter(
            (res: any) => res.id !== this.state.currentId
          );
          const Array = [
            ...result,
            { id: this.state.currentId, ...subjectObj },
          ];
          Array.sort((a, b) => a.id - b.id);
          this.setState({ subjects: Array, loader: false });
          this.resetForm();
          console.log("Updated");
        }
      })
      .catch((error) => {
        this.setState({ loader: false });
        console.log(error.message);
      });
  };
  deleteSubject = (subject_id: number | undefined) => {
    if (!subject_id) return;
    Swal.fire({
      title: "Are you sure?",
      text: `You will not be able to recover the subject with ID ${subject_id}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        Api.delete(DATA_BASE_URL, CreateURL(SUBJECT.DELETE, { id: subject_id }))
          .then((res) => {
            if (res.status === 204) {
              const result = this.state.subjects.filter((res: any) => {
                return res.id !== subject_id;
              });
              this.setState({
                subjects: result,
                currentPage: this.state.currentPage,
                loader: false,
              });
              Swal.fire(
                "Deleted!",
                `Subject ID ${subject_id} has been deleted.`,
                "success"
              );
            }
          })
          .catch((error) => {
            console.log(error.message);
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          "Cancelled",
          `Your Subject ID ${subject_id} detail is safe :)`,
          "info"
        );
      }
    });
  };
  resetForm = () => {
    this.isEdit = false;
    this.setState({
      formInput: {
        name_en: "",
        name_hi: "",
      },
    });
  };
  render() {
    return (
      <div className="component-content">
        <ModalPopup
          ref={this.modalRef}
          className="primary"
          buttonLabel="Add More"
          reset={this.resetForm}
          popupTitle={`${this.isEdit ? "Edit" : "Add"} Subject`}
          callbackMethod={(event: React.FormEvent<HTMLFormElement>) =>
            this.handleSubmit(event)
          }
        >
          <form onSubmit={this.handleSubmit}>
            {LANGUAGE.map((lang: LanguageT) => {
              if (this.isEdit && lang.value === this.state.lang.value) {
                return (
                  <fieldset key={lang.value}>
                    <legend>{lang.label}</legend>
                    <div key={lang.value} className="row">
                      <div className="col-sm">
                        <div className="form ">
                          <input
                            type="text"
                            name={`name`}
                            value={this.state.formInput.name}
                            onChange={this.handleChange}
                            required
                          />
                          <label className="label-name">
                            <span className="content-name">Name</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                );
              }
              if (!this.isEdit) {
                return (
                  <fieldset key={lang.value}>
                    <legend>{lang.label}</legend>
                    <div key={lang.value} className="row">
                      <div className="col-sm">
                        <div className="form ">
                          <input
                            type="text"
                            name={`name_${lang.value}`}
                            onChange={this.handleChange}
                            required
                          />
                          <label className="label-name">
                            <span className="content-name">Name</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                );
              }
            })}
          </form>
        </ModalPopup>
        <Datatable
          title="Subject"
          description="You can view subject list here"
          isShow={this.state.loader}
          currentPage={this.state.currentPage}
          pageSize={this.state.perPage}
          dataCount={this.state.arrayCount}
          callbackMethod={this.changePage}
          callbackSelectMethod={this.selectPageSize}
        >
          <div className="row ">
            <div className="col-sm-2">
              <Select
                value={this.state.lang}
                options={LANGUAGE}
                onChange={this.languageHandler}
              />
            </div>
          </div>
          <table className="datatable-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Subject</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.state.subjects.length ? (
                this.state.subjects.map((sub: SubjectT, idx: number) => (
                  <tr key={idx}>
                    <td>{sub.id}</td>
                    <td>{sub.name}</td>
                    <td>
                      <i
                        className="fa fa-edit"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.editSubject(sub);
                          this.modalRef.current.updateModal();
                        }}
                      ></i>
                      {/* <i
                        className="fa fa-trash"
                        style={{ cursor: "pointer" }}
                        title="Clone"
                        onClick={() => this.deleteSubject(sub.subject_id)}
                      ></i> */}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3}>No records found</td>
                </tr>
              )}
            </tbody>
          </table>
        </Datatable>
      </div>
    );
  }
}

export default Subject;
