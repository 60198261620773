import React, { Fragment } from 'react';
import { Redirect, Route } from 'react-router-dom';
import {AuthService} from './service'
import Header from './components/layout/header/Header';
import Sidebar from './components/layout/sidebar/Sidebar';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const isLoggedIn: boolean = AuthService.checkLoggedIn();
    return (
        <section id="contents">
        <Route
            {...rest}
            render={(props: any) =>
                isLoggedIn ? (
                    <Fragment>
                        <Sidebar />
                        <Header {...props} />
                        <Component {...props} />
                    </Fragment>
                ) : (
                    <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                    )
                }
                />
                </section>
    )
}

export default PrivateRoute