import React from 'react';

const SmallTile = (props: any) => {
	const { icon, title, content } = props;
	return(
		<div className="tile-style">
			<div className="tile-header">
				<div className="tile-icon-up">
					<span className="bootstrap-icons" aria-hidden="true">
						<i className={icon} aria-hidden="true"></i>
					</span>
				</div>
				<p className="tile-para">{title}</p>
				<h3 className="tile-data-value">{content}</h3>
			</div>
			<div className="tile-footer">
				<div className="footer-data">
					<div className="footer-icon">
						<i className="fa fa-warning"></i>
					</div>
					<button className="footer-link">View insight...</button>
				</div>
			</div>
		</div>
	);
}

export default SmallTile;
