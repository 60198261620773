import React, { createRef } from "react";
import { ModalPopup, Datatable } from "../../components/common";
import { Api } from "../../service";
import { CreateURL, COLOR, PAGE_SIZE, DATA_BASE_URL } from "../../constant";
import { ColorT } from "../../interface";

class Color extends React.Component {
  state = {
    formInput: {
      name: "",
    },
    currentId: 0,
    colors: [],
    loader: false,
    perPage: PAGE_SIZE,
    arrayCount: 0,
    currentPage: 1,
  };
  isEdit = false;
  modalRef: any = createRef();
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ formInput: { ...this.state.formInput, [name]: value } });
  };
  resetForm = () => {
    this.setState({
      formInput: {
        name: "",
      },
      currentId: 0,
    });
    this.isEdit = false;
  };
  handleSubmit = (e) => {
    this.isEdit ? this.updateColorById(e) : this.addColor(e);
  };
  componentDidMount() {
    this.getColors(this.state.currentPage, this.state.perPage);
    this.getCount();
  }
  getColors = (currentPage, pageSize) => {
    this.setState({ loader: true });
    let limit = pageSize;
    let skip = (currentPage - 1) * limit;
    this.setState({ loader: true });
    Api.get(
      DATA_BASE_URL,
      `${COLOR.COLOR}?filter[limit]=${limit}&filter[skip]=${skip}`
    )
      .then((res) => {
        const colors: ColorT[] = res.data;
        this.setState({ colors: colors, loader: false });
        this.setState({ loader: false });
      })
      .catch((error) => {
        alert(error.message);
        this.setState({ loader: false });
      });
  };
  getCount = () => {
    Api.get(DATA_BASE_URL, COLOR.COUNT)
      .then((res) => {
        this.setState({ arrayCount: res.data.count });
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  changePage = (selectedPage: number) => {
    this.setState({ currentPage: selectedPage });
    this.getColors(selectedPage, this.state.perPage);
  };
  selectPageSize = (event) => {
    this.setState({
      perPage: parseInt(event.target.value),
      currentPage: 1,
    });
    this.getColors(this.state.currentPage, parseInt(event.target.value));
  };
  addColor = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const colorObj: ColorT = { name: this.state.formInput.name };
    Api.post(DATA_BASE_URL, COLOR.COLOR, colorObj)
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            colors: [...this.state.colors, res.data],
            loader: false,
          });
          this.resetForm();
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  // deleteColor = (color_id: (number | undefined)) => {
  // 	if(!color_id) return;
  // 	Api.delete(CreateURL(COLOR.DELETE, {id: color_id}))
  // 		.then(res => {
  // 			if(res.status === 204){
  //        const result: ColorT[] = this.state.colors.filter(res => res.id !== color_id);
  // 				this.setState({ colors: res.data, loader: false });
  // 			}
  // 		}).catch(error => {
  // 			alert(error.message);
  // 		})
  // }
  editColor = (color) => {
    if (!color.id) return;
    this.isEdit = true;
    this.setState({
      formInput: {
        name: color.name,
      },
      currentId: color.id,
    });
  };
  updateColorById = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const colorObj: ColorT = {
      ...this.state.formInput,
    };
    Api.patch(
      DATA_BASE_URL,
      CreateURL(COLOR.COLORBYID, { id: this.state.currentId }),
      colorObj
    )
      .then((res) => {
        if (res.status === 204) {
          const result: ColorT[] = this.state.colors.filter(
            (res: any) => res.id !== this.state.currentId
          );
          const Array = [...result, { id: this.state.currentId, ...colorObj }];
          Array.sort((a: any, b: any) => a.id - b.id);
          this.setState({ colors: Array, loader: false });
          this.resetForm();
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  render() {
    return (
      <div className="component-content">
        <ModalPopup
          className="primary"
          ref={this.modalRef}
          buttonLabel="Add More"
          reset={this.resetForm}
          popupTitle={`${this.isEdit ? "Edit" : "Add"} Color`}
          callbackMethod={(event: React.FormEvent<HTMLFormElement>) =>
            this.handleSubmit(event)
          }
        >
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-sm">
                <div className="form ">
                  <input
                    type="text"
                    name="name"
                    value={this.state.formInput.name}
                    onChange={this.handleChange}
                    required
                  />
                  <label className="label-name">
                    <span className="content-name">Color Name</span>
                  </label>
                </div>
              </div>
            </div>
          </form>
        </ModalPopup>
        <Datatable
          title="Color"
          description="You can view berkman test color"
          isShow={this.state.loader}
          dataCount={this.state.arrayCount}
          currentPage={this.state.currentPage}
          pageSize={this.state.perPage}
          callbackMethod={this.changePage}
          callbackSelectMethod={this.selectPageSize}
        >
          <table className="datatable-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Color Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.state.colors.length ? (
                this.state.colors.map((color: ColorT) => (
                  <tr key={color.id}>
                    <td>{color.id}</td>
                    <td>{color.name}</td>
                    <td>
                      {/* <i className="fa fa-edit"></i> */}
                      <i
                        className="fa fa-edit"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.modalRef.current.updateModal();
                          this.editColor(color);
                        }}
                      ></i>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3}>No records found</td>
                </tr>
              )}
            </tbody>
          </table>
        </Datatable>
      </div>
    );
  }
}

export default Color;
