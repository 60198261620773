import React, { useState, useEffect, useRef } from "react";
import { ModalPopup, Datatable } from "../../components/common";
import {  LANGUAGE, COUNTRY , DEGREE, SCHOLARSHIP, PAGE_SIZE,  CreateURL, DATA_BASE_URL} from "../../constant";
import {  CountryT, DegreeT, MultiSelectItemT, ScholarshipT } from "../../interface";
import { Multiselect } from "multiselect-react-dropdown";
import Swal from "sweetalert2";
import { Api, ExportExcel, FileUpload, Helper } from "../../service";
import { toast } from "react-toastify";
import Select from "react-select";
import { disposeEmitNodes } from "typescript";
const selectedIds = new Set<number>();

const Scholarship = (props: any) => {  
  const [loader, showLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const [arrayCount, setArrayCount] = useState(0);
  const [degrees, setDegrees] = useState<DegreeT[]>([]);
  const [fields, setFields] = useState([
    { name: "", language_code: "", scholarship_id: "", overview: "", eligibility : "", eligible_programme: "", how_to_apply: "", scholarship_value: "" },
  ]);
  const [addLanguageFields, setFieldsLanguage] = useState([
    { name_en: "", 
    language_code: "", 
    scholarship_id: "",
    overview_en: "",
    eligibility_en : "", 
    eligible_programme_en: "",
    how_to_apply_en: "",
    scholarship_value_en: "",
    name_hi: "", 
    overview_hi: "",
    eligibility_hi : "", 
    eligible_programme_hi: "",
    how_to_apply_hi: "",
    scholarship_value_hi: "",
    name: "",
    overview: "", 
    eligibility : "", 
    eligible_programme: "", 
    how_to_apply: "", 
    scholarship_value: ""
  },
  ]);
  const resetForm = () => {
    setFields([ { name: "", language_code: "", scholarship_id: "", overview: "", eligibility : "", eligible_programme: "", how_to_apply: "", scholarship_value: "" }]);

    setisEdit(true);
    setId(0);
    getName("");
    getSelectedCountry(0);
    getSelectedDegree(0);
    getOverview("");
    getEligibility("");
    getEligibleProgramme("");
    getHowToApply("")
    getScholarshipValue("");
  };
  const [perPage, setPerPage] = useState(PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(1);
  const [countries, getCountries] = useState<CountryT[]>([]);
  const [countryList, setCountryList] = useState<String[]>([]);
  const [countryName, setCountryName] = useState<String[]>([]);
  const [language, setLanguage] = useState(LANGUAGE[0]);
  const [scholarships, getScholarships] = useState<ScholarshipT[]>([]);
  const [name, getName] = useState("");
  const [overview, getOverview] = useState("");
  const [eligibility, getEligibility] = useState("");
  const [how_to_apply, getHowToApply] = useState("");
  const [eligible_programme, getEligibleProgramme] = useState("");
  const [scholarship_value, getScholarshipValue] = useState("");

  const [name_en, getNameEn] = useState("");
  const [overview_en, getOverviewEn] = useState("");
  const [eligibility_en, getEligibilityEn] = useState("");
  const [how_to_apply_en, getHowToApplyEn] = useState("");
  const [eligible_programme_en, getEligibleProgrammeEn] = useState("");
  const [scholarship_value_en, getScholarshipValueEn] = useState("");

  const [name_hi, getNameHi] = useState("");
  const [overview_hi, getOverviewHi] = useState("");
  const [eligibility_hi, getEligibilityHi] = useState("");
  const [how_to_apply_hi, getHowToApplyHi] = useState("");
  const [eligible_programme_hi, getEligibleProgrammeHi] = useState("");
  const [scholarship_value_hi, getScholarshipValueHi] = useState("");



  const [selectedCountry, getSelectedCountry] = useState(0);
  const [selectedDegree, getSelectedDegree] = useState(0);
  const [url, getUrl] = useState("");
  const [currentId, setId] = useState(0);
  const [currentScholarshipId, setScholarshipId] = useState(0);

  const [scholarshipname, setScholarshipName] = useState("");
  const [languagecode, setLanguagecode] = useState(LANGUAGE[0]);
  const [editeligibility , setEligibility] = useState("");
  const [editoverview, setOverview] = useState("");
  // const [featured, setFeatured] = useState(false);
  // const [logoUrl, setLogoUrl] = useState("");
  // const [imageName, setImageName] = useState("");
  // const [searchItem, setSearchItem] = useState("");
  // const [workBookItem, setWorkBookItem] = useState<any[]>([]);
  // const [workBookColumn, setWorkBookColumn] = useState<any[]>([]);
  // const [campusUpload, setCampusUpload] = useState(false);
  // const [campusTranslationUpload, setCampusTranslationUpload] = useState(false);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
   // getName(event.target.value);
    switch (event.target.name) {
      case "name_en":
        getNameEn(event.target.value);
        break;
      case "name_hi":
        getNameHi(event.target.value);
        break;
      case "name":
        getName(event.target.value);
        break;
    }
  }
  const handleOverview = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    switch (event.target.name) {
      case "overview_en":
        getOverviewEn(event.target.value);
        break;
      case "overview_hi":
        getOverviewHi(event.target.value);
        break;
      case "overview":
        getOverview(event.target.value);
        break;
    }
  }
  const handleEligibility = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    switch (event.target.name) {
      case "eligibility_en":
        getEligibilityEn(event.target.value);
        break;
      case "eligibility_hi":
        getEligibilityHi(event.target.value);
        break;
      case "eligibility":
        getEligibility(event.target.value);
        break;
    }
  }
  const handleHowToApply = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    switch (event.target.name) {
      case "how_to_apply_en":
        getHowToApplyEn(event.target.value);
        break;
      case "how_to_apply_hi":
        getHowToApplyHi(event.target.value);
        break;
      case "how_to_apply":
        getHowToApply(event.target.value);
        break;
    }
  }
  const handleEligibleProgramme = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    switch (event.target.name) {
      case "eligible_programme_en":
        getEligibleProgrammeEn(event.target.value);
        break;
      case "eligible_programme_hi":
        getEligibleProgrammeHi(event.target.value);
        break;
      case "eligible_programme":
        getEligibleProgramme(event.target.value);
        break;
    }
  }
  const handleScholarshipValue = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    getScholarshipValue(event.target.value);
  }
  const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    getSelectedCountry(parseInt(event.target.value));
  };
  const handleDegreeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    getSelectedDegree(parseInt(event.target.value));
  };
  const handleInputFieldChange = (index, event) => {
    const values = [...fields];
    values[index][event.target.name] = event.target.value;
    setFields(values);
    // setFieldsLanguage(values)
  };
  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    getUrl(event.target.value);
  };
  // const changePage = (selectedPage: number) => {
  //   setCurrentPage(selectedPage);
  //   getScholarship(selectedPage, language, perPage);
  // };
  const onSelect = (
    selectedList: MultiSelectItemT[],
    selectedItem: MultiSelectItemT
  ) => {
    selectedIds.add(selectedItem.id);
    setSelectedDegrees(selectedList);
  };
  const modalRef: any = useRef(null);
  
  const getCountry = async (lang) => {
    try{
        const response: any = await Api.get(DATA_BASE_URL, `${COUNTRY.COUNTRY}?filter[where][language_code]=${lang.value}`);
        if(response && response.data){
            getCountries(response.data);
        }
    } catch (error) {
        console.log(JSON.stringify(error));
    }
}
const getScholarCount = async (lang) => {
  try {
    const countRes = await Api.get(DATA_BASE_URL, `${SCHOLARSHIP.SCHOLARSHIPCOUNT}?filter[where][language_code]=${lang.value}`);
    if (countRes && countRes.data) {
      setArrayCount(countRes.data.count);
    }
  } catch (error) {
    console.log(JSON.stringify(error));
  }
};


useEffect(() => {
  getCountry(language);
  getDegrees(language);
  getScholarCount(language);
  getScholarship(1,language,perPage);
}, [language]);

const getDegrees = async (lang) => {
  try{
    const response: any = await Api.get(DATA_BASE_URL, `${DEGREE.DEGREE}?filter[where][language_code]=${lang.value}`);
    if(response && response.data){
      setDegrees(response.data);
    }
  } catch (error) {
      console.log(JSON.stringify(error));
  }
}
const handleSubmit = (e: any) => {
  isEdit ? updateScholarship(e) : addScholarship(e);
};

const getScholarship = async (currentPage: number = 1, lang, pageSize) => {
  let limit = pageSize;
  try {
    let skip = (currentPage - 1) * limit;
    const res = await Api.get(
      DATA_BASE_URL, 
      `${SCHOLARSHIP.SCHOLARSHIPLIST}?filter[where][language_code]=${lang.value}&filter[limit]=${limit}&filter[skip]=${skip}`
    );
    if (res && res.data) {
      getScholarships(res.data);
    }
  } catch (error) {
    console.log(JSON.stringify(error));
  }
};

const [isOpen, setIsOpen]: any = useState(false);
// const updateScholarship = async (event: React.FormEvent<HTMLFormElement>) => {}
const addScholarship = async (event: React.FormEvent<HTMLFormElement>) => {
  event.preventDefault();
 
  setIsOpen(false);
  const scholarshipObj = {
    country_id: selectedCountry,
    degree_id: selectedDegree , // todo : chnage into array
    url: url,
    is_active: true,
  };
  try {
    const scholarshipRes = await Api.post(DATA_BASE_URL, SCHOLARSHIP.SCHOLARSHIP, scholarshipObj);
    // fields.forEach((r: any) => {
    //   r["scholarship_id"] = scholarshipRes.data.id;
    //   r["language_code"] = language.value;
    //   r["name"] = name;
    //   r["overview"] = overview;
    //   r["eligibility"] = eligibility;
    //   r["eligible_programme"] = eligible_programme;
    //   r["how_to_apply"] = how_to_apply;
    //   r["scholarship_value"] = scholarship_value;
    // });

const scholarshipTranslationObj = [{
  scholarship_id : scholarshipRes.data.id,
  language_code: 'en',
  name : name_en,
  overview: overview_en,
  eligibility : eligibility_en,
  eligible_programme : eligible_programme_en,
  how_to_apply : how_to_apply_en,
  scholarship_value : scholarship_value
},{
  scholarship_id : scholarshipRes.data.id,
  language_code: 'hi',
  name : name_hi,
  overview: overview_hi,
  eligibility : eligibility_hi,
  eligible_programme : eligible_programme_hi,
  how_to_apply : how_to_apply_hi,
  scholarship_value : scholarship_value
}]; 

    await Api.post(DATA_BASE_URL, SCHOLARSHIP.SCHOLARSHIPTRANSLATIONBULKUPLOAD, scholarshipTranslationObj);
    toast.success("Scholarship added successfully!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } catch (error) {
    toast.error(`${error}`, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
const pageLoad = (language: any) => {
  setLanguage(language);
  getScholarship(1, language, perPage);
  // Api.all(DATA_BASE_URL, [
  //   `${SCHOLARSHIP.SCHOLARSHIPLIST}/'${language.value}'/null/1/null`
  // ])
  //   .then((res: any) => {
      
  //   })
  //   .catch((error) => {
  //     alert(error.message);
  //   });
};
const languageHandler = (lang: any) => {
  pageLoad(lang);
  setCurrentPage(1);
};

const changePage = (selectedPage: number) => {
  setCurrentPage(selectedPage);
  setLanguage(language);
  getScholarship(selectedPage, language, perPage);
};

const selectPageSize = (event) => {
  setPerPage(parseInt(event.target.value));
  setCurrentPage(1);
  getScholarship(
    currentPage,
    language,
    parseInt(event.target.value)
  );
};


const deleteScholarship = (scholarship: any) => {

  if (!scholarship.id) return;
  Swal.fire({
    title: "Are you sure?",
    text: `You will not be able to recover the scholarship with ID ${scholarship.id}`,
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "No, keep it",
  }).then((result) => {
    if (result.isConfirmed) {
      Api.delete(DATA_BASE_URL, CreateURL(SCHOLARSHIP.SCHOLARSHIPBYID, { id: scholarship.id })).then(
        (res) => {
          if (res.status === 204) {
            const updatedScholarship = scholarships.filter((c) => c.id !== scholarship.id);
            getScholarships(updatedScholarship);
            Swal.fire(
              "Deleted!",
              `Scholarship ID ${scholarship.id} has been deleted.`,
              "success"
            );
          }
        }
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        "Cancelled",
        `Your Scholarship ID ${scholarship.id} detail is safe :)`,
        "info"
      );
    }
  });
};

const editScholarship = (scholarshipObj: any) => {
  if (!scholarshipObj.id) return;
  setisEdit(true);
  setId(scholarshipObj.id);
  setScholarshipId(scholarshipObj.scholarship_id)
  getName(scholarshipObj.name);
  setLanguagecode(scholarshipObj.language_code);
  getSelectedCountry(scholarshipObj.country_id);
  getSelectedDegree(scholarshipObj.degree_id)
  getOverview(scholarshipObj.overview);
  getEligibility(scholarshipObj.eligibility);
  getEligibleProgramme(scholarshipObj.eligible_programme);
  getHowToApply(scholarshipObj.how_to_apply)
  getScholarshipValue(scholarshipObj.scholarship_value);
  getUrl(scholarshipObj.url);
};


const updateScholarship = async (event: React.FormEvent<HTMLFormElement>) => {
  if (!currentId) return;
  event.preventDefault();
  const scholarshipObj= {
    id: currentId,
    name: name,
    url: url,
    // language_code: language,
    scholarship_id: 0,
    overview: overview ,
    eligibility:eligibility,
    eligible_programme: eligible_programme,
    how_to_apply: how_to_apply,
    scholarship_value: scholarship_value
  };

const scholarEditObj ={
  id: currentId,
  country_id: selectedCountry,
  degree_id: selectedDegree , // todo : chnage into array
  url: url,
  is_active: true,
};

try {
    await Api.post(DATA_BASE_URL, `${SCHOLARSHIP.UPDATESCHOLARSHIP}/${currentId}`, scholarEditObj);
    // await Api.patch(DATA_BASE_URL, `${SCHOLARSHIP.UPDATESCHOLARSHIPTRANSALATION}`, scholarEditObj);
    
    let result = scholarships.filter((res) => res.id !== currentId);      
    const ArrayData: any = [...result, {...scholarshipObj, name: `[${scholarshipObj.name}]`}];
    ArrayData.sort((a: any, b: any) => b.id - a.id);
    getScholarships(ArrayData);
  } catch (error) {
    console.log(JSON.stringify(error));
    Swal.fire("Error!", `${error}`, "error");
  }
};
const addFields = () => {
  if (fields.length < LANGUAGE.length) {
    setFields([...fields, { name: "", language_code: "", scholarship_id: "", overview: "", eligibility : "", eligible_programme: "", how_to_apply: "", scholarship_value: "" }]);
  }
};

const removeFields = (i) => {
  const values = [...fields];
  if (values.length > 1) {
    values.splice(i, 1);
  }
  setFields(values);
};

   return (
    <div className="component-content">
       <ModalPopup
        className="primary"
        ref={modalRef}
        buttonLabel="Add More"
        popupTitle={`${isEdit ? "Edit" : "Add"} Scholarship`}
        reset={resetForm}
        callbackMethod={(event: React.FormEvent<HTMLFormElement>) => {
          handleSubmit(event);
          // resetForm();
        }}
      >
        <form onSubmit={() =>  handleSubmit} method="post"  >
        {LANGUAGE.map((lang: LanguageT) => {

            if (!isEdit && currentId ===0) {
              return (
          <fieldset key={lang.value}>
            <legend>{lang.label}</legend>
            <div className="row m-0">
              {fields.map((field, idx) => (
                <div key={idx} className="row">
                  <div className="col-sm-12 m-0">
                    <div className="form">
                      <input
                        type="text"
                        name={`name_${lang.value}`}  
                        value={lang.value === "en" ? name_en : name_hi}     
                        required
                        onChange={(event) => {
                          handleNameChange(event);
                        }}
                      />
                      <label className="label-name">
                        <span className="content-name">Name</span>
                      </label>
                    </div>
                  </div>

                  <div className="col-sm-12 m-0">
                    <div className="form">
                      <textarea
                        name={`overview_${lang.value}`}
                        value={lang.value === "en" ? overview_en : overview_hi}     
                        onChange={(event) => {
                          handleOverview(event);
                        }}
                        required
                      ></textarea>
                      <label className="label-name">
                        <span className="content-name">Overview</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-12 m-0">
                    <div className="form">
                      <textarea
                        name={`eligibility_${lang.value}`}
                        value={lang.value === "en" ? eligibility_en : eligibility_hi}     
                        required
                        onChange={(event) => {
                          handleEligibility(event);
                        }}
                      ></textarea>
                      <label className="label-name">
                        <span className="content-name">Eligibility</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-12 m-0">
                    <div className="form">
                      <textarea
                        name={`how_to_apply_${lang.value}`}
                        value={lang.value === "en" ? how_to_apply_en : how_to_apply_hi}     
                        onChange={(event) => {
                          handleHowToApply(event);
                        }}
                        required
                      ></textarea>
                      <label className="label-name">
                        <span className="content-name">How to Apply</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-12 m-0">
                    <div className="form">
                      <textarea
                        name={`eligible_programme_${lang.value}`}
                        required
                        value={lang.value === "en" ? eligible_programme_en : eligible_programme_hi}  
                        onChange={(event) => {
                          handleEligibleProgramme(event);
                        }}
                      ></textarea>
                      <label className="label-name">
                        <span className="content-name">Eligible Programme</span>
                      </label>
                    </div>
                  </div>
                
                </div>
              ))}
            </div>
            </fieldset>
                )}
                if (isEdit && language.value=== lang.value) {
                  return (
              <fieldset key={lang.value}>
                <legend>{lang.label}</legend>
                <div className="row m-0">
                  {fields.map((field, idx) => (
                    <div key={idx} className="row">
                      
                      <div className="col-sm-12 m-0">
                        <div className="form">
                          <input
                            type="text"
                            name="name"   
                            value={name}         
                            required
                            onChange={(event) => {
                              handleNameChange(event);
                            }}
                          />
                          <label className="label-name">
                            <span className="content-name">Name</span>
                          </label>
                        </div>
                      </div>
    
                      <div className="col-sm-12 m-0">
                        <div className="form">
                          <textarea
                            name="overview"
                            value={overview}
                            onChange={(event) => {
                              handleOverview(event);
                            }}
                            required
                          ></textarea>
                          <label className="label-name">
                            <span className="content-name">Overview</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 m-0">
                        <div className="form">
                          <textarea
                            name="eligibility"
                            value={eligibility}
                            required
                            onChange={(event) => {
                              handleEligibility(event);
                            }}
                          ></textarea>
                          <label className="label-name">
                            <span className="content-name">Eligibility</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 m-0">
                        <div className="form">
                          <textarea
                            name="how_to_apply"
                            value={how_to_apply}
                            onChange={(event) => {
                              handleHowToApply(event);
                            }}
                            required
                          ></textarea>
                          <label className="label-name">
                            <span className="content-name">How to Apply</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 m-0">
                        <div className="form">
                          <textarea
                            name="eligible_programme"
                            required
                            value={eligible_programme}
                            onChange={(event) => {
                              handleEligibleProgramme(event);
                            }}
                          ></textarea>
                          <label className="label-name">
                            <span className="content-name">Eligible Programme</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                </fieldset>
                    )}
                })}
                 <div className="col-sm-12 m-0">
                    <div className="form">
                      <textarea
                        name={`scholarship_value`}
                        required
                        value={scholarship_value}
                        onChange={(event) => {
                          handleScholarshipValue(event);
                        }}
                      ></textarea>
                      <label className="label-name">
                        <span className="content-name">Scholarship Value</span>
                      </label>
                    </div>
                  </div>
           <div className="col-sm-12 m-0">
                    <div className="form">
                      <select
                  name="selectedCountry"
                  required
                  value={selectedCountry}
                  onChange={(event) => {
                  handleCountryChange(event);
                  }}
                >
                  <option value="">Select country</option>
                  {countries.map((con: CountryT) => (
                    <option key={con.id} value={con.id}>
                      {con.name}
                    </option>
                  ))}
                </select>
                      <label className="label-name">
                        <span className="content-name">Country</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-12 m-0">
                    <div className="form">
                      <select
                  name="selectedDegree"
                  required
                  value={selectedDegree}
                  onChange={(event) => {
                  handleDegreeChange(event);
                  }}
                >
                  <option value="">Select degree</option>
                  {degrees.map((deg: DegreeT) => (
                    <option key={deg.id} value={deg.id}>
                      {deg.name}
                    </option>
                  ))}
                </select>
                      <label className="label-name">
                        <span className="content-name">Degree</span>
                      </label>
                    </div>
                    {/* <div className="form"  style={{ overflow: "visible" }}>
                    <Multiselect
                      options={degrees}
                      value = {degrees}
                      displayValue="name"
                      closeIcon="cancel"
                      showCheckbox="true"
                      onSelect={onSelect} 
                      placeholder="Select degree"
                      style={{
                        searchBox: {
                          border: "none",
                          padding: 0,
                          marginTop: "20px",
                          height: "50px",
                        },
                        inputField: {
                          paddingTop: 0,
                        },
                        option: {
                          borderBottom: "1px solid #dbdbdb",
                          padding: "0 10px",
                        },
                      }}
                    />
                    </div> */}
                  </div>
                  <div className="col-sm-12 m-0">
                    <div className="form">
                    <input
                        type="text"
                        name="url"
                        value= {url}
                        required
                        onChange={(event) => {
                          handleUrlChange(event);
                        }}
                      />
                      <label className="label-name">
                        <span className="content-name">URL</span>
                      </label>
                    </div>
                  </div>
        </form>
      </ModalPopup>
     <Datatable
        title="Scholarship"
        description="You can view scholarship here"
        isShow={loader}
        currentPage={currentPage}
        pageSize={perPage}
        dataCount={arrayCount}
        callbackMethod={changePage}
        callbackSelectMethod={selectPageSize}
      >
       
         <div className="row ">
          <div className="col-sm-2">
            <Select
              value={language}
              options={LANGUAGE}
              onChange={languageHandler}
            />
          </div>
          <div className="col-sm-10">
            <div className="float-right">
              <button
                id="download-csv"
                className="btn btn-sm btn-outline-primary"
                onClick={()=>ExportExcel(scholarships, "Scholarships")}
              >
                <i className="fa fa-file-excel-o" /> Download
              </button>
            </div>
          </div>
        </div>
        <table className="datatable-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Overview</th>
              <th>Eligibility</th>
              <th>How to Apply</th>
              <th>Programme</th>
              <th>Scholarship Value</th>
              {/* <th>URL</th> */}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {scholarships.length ? (
              scholarships.map((scholarship: ScholarshipT, idx: number) => (
                <tr key={scholarship.id}>
                  <td>{scholarship.id}</td>
                  <td><a href={scholarship.url} target="_blank">{scholarship.name}</a></td>
                  <td style={{whiteSpace: 'pre-line', width: '150px'}}>{scholarship.overview}</td>
                  <td style={{whiteSpace: 'pre-line', width: '150px'}}>{scholarship.eligibility}</td>
                  <td style={{whiteSpace: 'pre-line', width: '150px'}}>{scholarship.how_to_apply}</td>
                  <td style={{whiteSpace: 'pre-line', width: '150px'}}>{scholarship.eligible_programme}</td>
                  <td style={{whiteSpace: 'pre-line', width: '150px'}}>{scholarship.scholarship_value}</td>
                  {/* <td>{scholarship.url}</td> */}
                  <td>
                  <i
                    className="fa fa-edit"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      modalRef.current?.updateModal();
                      editScholarship(scholarship);
                    }}
                  ></i><br/>
                  <i
                    className="fa fa-trash"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      deleteScholarship(scholarship);
                    }}
                  ></i>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8}>No records found</td>
              </tr>
            )}
          </tbody>
        </table>
      </Datatable>
    </div>
  );
};

export default Scholarship;
