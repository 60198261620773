export const MenuJSON = [
  {
    "title": "Dashboard",
    "path": "/dashboard",
    "icon": "fa fa-tachometer",
    "role": ['admin', 'sales', 'marketing', 'manager', 'content'],
    "children": []
  },
  {
    "title": "Master Entry",
    "path": "/berkman-choice",
    "icon": "fa fa-cog",
    "role": ['admin'],
    "children": [
      {
        "title": "Advertisements",
        "path": "/advertisements",
        "icon": "fa fa-bullhorn",
        "role": ['admin', 'marketing'],
        "children": []
      },
      {
        "title": "Alumni",
        "path": "/alumni",
        "icon": "fa fa-users",
        "role": ['admin'],
        "children": []
      },
      {
        "title": "Berkman Choice",
        "path": "/berkman-choice",
        "icon": "fa fa-tachometer",
        "role": ['admin'],
      },
      {
        "title": "Campus",
        "path": "/campus",
        "icon": "fa fa-university",
        "role": ['admin', 'content'],
        "children": []
      },
      {
        "title": "Career",
        "path": "/career",
        "icon": "fa fa-tachometer",
        "role": ['admin'],
      },
      {
        "title": "Career Details",
        "path": "/career-detail/view",
        "icon": "fa fa-list",
        "role": ['admin', 'content'],
        "children": []
      },
      {
        "title": "Career Identifications",
        "path": "/career-identification",
        "icon": "fa fa-tachometer",
        "role": ['admin'],
      },
      {
        "title": "Career Test",
        "path": "/test",
        "icon": "fa fa-cogs",
        "role": ['admin'],
        "children": []
      },
      {
        "title": "Career-Trait",
        "path": "/career-trait",
        "icon": "fa fa-tachometer",
        "role": ['admin'],
      },
      {
        "title": "Color",
        "path": "/color",
        "icon": "fa fa-tachometer",
        "role": ['admin'],
      },
      {
        "title": "Country",
        "path": "/country",
        "icon": "fa fa-tachometer",
        "role": ['admin'],
      },
      {
        "title": "Degree",
        "path": "/degree",
        "icon": "fa fa-tachometer",
        "role": ['admin'],
      },
      {
        "title": "Featured Education",
        "path": "/featuredEducation",
        "icon": "fa fa-globe",
        "role": ['admin'],
        // "children": []
      },
      {
        "title": "Grade",
        "path": "/grade",
        "icon": "fa fa-tachometer",
        "role": ['admin'],
      },
      {
        "title": "Hobbies",
        "path": "/hobbies",
        "icon": "fa fa-tachometer",
        "role": ['admin'],
      },
      {
        "title": "Personality Trait",
        "path": "/personality",
        "icon": "fa fa-tachometer",
        "role": ['admin'],
      },
      {
        "title": "Scholarship",
        "path": "/scholarship",
        "icon": "fa fa-globe",
        "role": ['admin'],
        // "children": []
      },
      {
        "title": "Subjects",
        "path": "/subject",
        "icon": "fa fa-tachometer",
        "role": ['admin'],
      },
      {
        "title": "Sub Career",
        "path": "/sub-career",
        "icon": "fa fa-tachometer",
        "role": ['admin'],
      },
      {
        "title": "Webinar URL",
        "path": "/webinar",
        "icon": "fa fa-globe",
        "role": ['admin'],
        "children": []
      },

    ]
  },
  {
    "title": "Counselling",
    "path": "/counseling",
    "icon": "fa fa-handshake-o",
    "role": ['admin', 'sales'],
    "children": []
  },
  {
    "title": "Users",
    "path": "/users",
    "icon": "fa fa-user",
    "role": ['admin'],
    "children": []
  },
  {
    "title": "Career alert",
    "path": "/career-alert",
    "icon": "fa fa-bell",
    "role": ['admin', 'marketing'],
    "children": []
  },
  {
    "title": "Events / Campaign",
    "path": "/create-events",
    "icon": "fa fa-calendar",
    "role": ['admin', 'marketing'],
    "children": [
      {
        "title": "Create Events",
        "path": "/create-events",
        "icon": "",
        "role": ['admin', 'marketing'],
      },
      {
        "title": "View Response",
        "path": "/view-responses",
        "icon": "",
        "role": ['admin', 'marketing'],
      },
    ]
  },
  {
    "title": "Bulk Email",
    "path": "/bulk-email",
    "icon": "fa fa-envelope",
    "role": ['admin', 'marketing'],
    "children": []
  },
  {
    "title": "Payment",
    "path": "/payment",
    "icon": "fa fa-rupee",
    "role": ['admin', 'sales', 'manager'],
    "children": []
  },
  {
    "title": "Quiz Winner",
    "path": "/quiz-winner",
    "icon": "fa fa-trophy",
    "role": ['admin', 'manager'],
    "children": []
  },
  {
    "title": "Coupons",
    "path": "/coupon",
    "icon": "fa fa-gift",
    "role": ['admin'],
    "children": [
      {
        "title": "Create Coupon",
        "path": "/create-coupon",
        "icon": "",
        "role": ['admin', 'marketing'],
      },
      {
        "title": "Redeem Coupon",
        "path": "/redeem-coupon",
        "icon": "",
        "role": ['admin', 'marketing'],
      },
    ]
  }
];
