/* eslint-disable no-useless-concat */
import React from "react";
import dateFormat from "dateformat";
import { Datatable } from "../components/common";
import { Api, ExportExcel } from "../service";
import { FeedbackT } from "../interface";
import { PER_PAGE_LIMIT, FEEDBACK, USER_BASE_URL } from "../constant";
import axios from "axios";
class Feedback extends React.Component {
  state = {
    feedbacks: [],
    arrayCount: 0,
    currentPage: 1,
    loader: false,
    searchItem: "",
    searchOptions: [],
    perPage: PER_PAGE_LIMIT,
  };
  changePage = (selectedPage: number) => {
    this.setState(
      {
        currentPage: selectedPage,
      },
      () => {
        this.getFeedbacks(selectedPage, this.state.perPage);
      }
    );
  };
  selectPageSize = (event) => {
    this.setState({ perPage: parseInt(event.target.value), currentPage: 1 });
    this.getFeedbacks(this.state.currentPage, parseInt(event.target.value));
  };
  componentDidMount() {
    this.getFeedbacksCount();
    this.getFeedbacks(this.state.currentPage, this.state.perPage);
  }

  getFeedbacksCount = () => {
    Api.get(USER_BASE_URL, FEEDBACK.COUNT)
      .then((res) => {
        this.setState({ arrayCount: res.data.count });
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
      });
  };
  getFeedbacks = (currentPage, pageSize) => {
    this.setState({ loader: true });
    let limit = pageSize;
    let offset = (this.state.currentPage - 1) * limit;
    Api.get(USER_BASE_URL, `${FEEDBACK.FEEDBACKUSERDETAILS}/null/${limit}/${offset}`)
      .then((res) => {
        const feedbacks: FeedbackT[] = res.data;
        this.setState({ feedbacks: feedbacks, loader: false });
      })
      .catch((error) => {
        this.setState({ loader: false });
        console.log(JSON.stringify(error));
      });
  };
  getUserdetails = (fb) => {
    return (
      <>
        <span>Name: {fb.name}</span>
        <br />
        <span>Contact: {fb.contact}</span>
        <br />
        <span>Email: {fb.email}</span>
      </>
    );
  };
  handleSearch = async (event) => {
    let search = event.target.value || null;
    let cancelToken: any;

    this.setState({ searchItem: search });
    if (typeof cancelToken != typeof undefined) {      
      cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    try {
      let limit = this.state.perPage;
      let offset = (this.state.currentPage - 1) * limit;
      const users = await Api.search(
        USER_BASE_URL, 
        `${FEEDBACK.FEEDBACKUSERDETAILS}/${search}/${limit}/${offset}`,
        { cancelToken: cancelToken.token }
      );

      if (users.data.length > 0 && search !== null) {
        this.setState({
          feedbacks: users.data,
          loader: false,
          arrayCount: 0,
        });
      } else {
        this.getFeedbacksCount();
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  render() {
    return (
      <div className="component-content">
        <Datatable
          title="Feedback"
          description="You can view user feedbacks"
          isShow={this.state.loader}
          currentPage={this.state.currentPage}
          pageSize={this.state.perPage}
          dataCount={this.state.arrayCount}
          callbackMethod={this.changePage}
          callbackSelectMethod={this.selectPageSize}
        >
          <div className="row">
            <div className="col-sm-4">
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by name | contact | email"
                  value={this.state.searchItem}
                  onChange={this.handleSearch}
                />
                <span
                  style={{
                    position: "absolute",
                    float: "right",
                    marginTop: "-29px",
                    marginLeft: "96%",
                    color: "darkgray",
                    cursor: "pointer",
                  }}
                >
                  <h6
                    onClick={(e) => {
                      this.handleSearch(e);
                      this.setState({ searchItem: "", arrayCount: this.state.arrayCount });
                    }}
                  >
                    x
                  </h6>
                </span>
              </div>
            </div>
            <div className="col-sm-8">
            <div className="float-right">
              <button
                id="download-csv"
                className="btn btn-sm btn-outline-primary"
                onClick={() => ExportExcel(this.state.feedbacks, "Feedback")}
              >
                <i className="fa fa-file-excel-o" /> Download
              </button>
            </div>
          </div>
            <hr />
          </div>
          <table className="datatable-table">
            <thead>
              <tr>
                <th style={{ width: "4%" }}>#</th>
                <th>User Contact</th>
                <th>Rating</th>
                <th>Message</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {this.state.feedbacks.length ? (
                this.state.feedbacks.map((fb: FeedbackT) => (
                  <tr key={fb.id}>
                    <td>{fb.id}</td>
                    <td>{this.getUserdetails(fb)}</td>
                    <td>{fb.ratings}</td>
                    <td>{fb.message}</td>
                    <td>{dateFormat(fb.created_date, "mmmm dS, yyyy")}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>No records found</td>
                </tr>
              )}
            </tbody>
          </table>
        </Datatable>
      </div>
    );
  }
}

export default Feedback;
