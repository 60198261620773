import React, { useState, useEffect, useRef } from "react";
import Switch from "react-switch";
import dateFormat from "dateformat";
import { ModalPopup, Datatable } from "../../components/common";
import {
  CAREER,
  LANGUAGE,
  PAGE_SIZE,
  CreateURL,
  FEATUREDEDUCATION,
  DATA_BASE_URL,
  S3_URL,
  UPLOAD
} from "../../constant";
import {
  FeaturedEducationT,
  MappedCareerT,
  SubCareerT,
  LanguageT
} from "../../interface";
import { Api, Helper, FileUpload } from "../../service";
import { toast } from "react-toastify";
import Select from "react-select";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import _ from "lodash";

const FeaturedEducation = (props: any) => {
  const [careers, setCareers] = useState<any[]>([]);
  const [subcareers, setSubCareers] = useState<any[]>([]);
  const [arrayCount, setArrayCount] = useState(0);
  const [perPage, setPerPage] = useState(PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(1);
  const [language, setLanguage] = useState(LANGUAGE[0]);
  const [title, setTitle] = useState("");
  const [careerid, setCareerid] = useState(0);
  const [subCareerid, setSubCareerid] = useState(0);
  const [focusedInput, setFocusedInput] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [url, setUrl] = useState("");
  const [priority, setPriority] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [careerId, setCareerId] = useState(0);
  const modalRef: any = useRef(null);
  const [subCareerId, getSubCareerId] = useState(0);
  const [featuredEducationId, getfeaturedEducationId] = useState(0);
  const [imageBlob, getImage] = useState<Blob>(new Blob());
  const [imageName, setImageName] = useState("");
  const [logo, getLogo] = useState("");
  const [featureEducations, getFeaturedEducations] = useState<
    FeaturedEducationT[]
  >([]);
  const [mappedCareer, getMappedCareer] = useState<MappedCareerT[]>([]);
  const [subCareer, getSubCareer] = useState<SubCareerT[]>([]);
  const [fields, setFields] = useState([
    {
      title_en: "",
      title_hi: "",
      language_code: "",
      featured_id: "",
      logo: "",
      featured_url: "",
      start_date: "",
      end_date: "",
      priority: "",
      career_id: "",
      sub_career_id: "",
    },
  ]);

  const [nameEn, setNameEn] = useState("");
  const [nameHi, setNameHi] = useState("");
  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case 'title_en':
        setNameEn(event.target.value)
        break;
      case 'title_hi':
        setNameHi(event.target.value);
        break;
      case 'title':
        setTitle(event.target.value);
        break;
      default: console.log(event.target.name);
    }
  };
  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
  };
  const handlePrioritChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPriority(parseInt(event.target.value));
  };

  const pageLoad = (language: any) => {
    setLanguage(language);
    Api.all(DATA_BASE_URL, [
      `${FEATUREDEDUCATION.FEATUREDEDUCATIONLIST}?filter[where][language_code]=${language.value}`,
    ])
      .then((res: any) => {
        getFeaturedEducation(1, perPage, language);
      })
      .catch((error) => {
        console.log(error.message);
      });

    Api.all(DATA_BASE_URL, [
      `${CAREER.MERGESUBCAREERWITHCAREER}/${language.value}`,
    ])
      .then((res: any) => {
        const careers: MappedCareerT[] = res[0].data;
        getMappedCareer(careers);
        setCareers(careers);

      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const languageHandler = (lang: any) => {
    pageLoad(lang);
    setCurrentPage(1);
  };

  const changePage = (selectedPage: number) => {
    setCurrentPage(selectedPage);
    setLanguage(language);
    getFeaturedEducation(selectedPage, perPage, language);
  };

  const selectPageSize = (event) => {
    setPerPage(parseInt(event.target.value));
    setCurrentPage(1);
    getFeaturedEducation(currentPage, parseInt(event.target.value), language);
  };

  const getFeaturedEduCount = async () => {
    try {
      const countRes = await Api.get(
        DATA_BASE_URL,
        FEATUREDEDUCATION.FEATUREDEDUCATIONCOUNT
      );
      if (countRes && countRes.data) {
        setArrayCount(countRes.data.count);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getFeaturedEducation = async (
    currentPage: number = 1,
    pageSize,
    language
  ) => {
    let limit = pageSize;
    try {
      let skip = (currentPage - 1) * limit;
      const res = await Api.get(
        DATA_BASE_URL,
        `${FEATUREDEDUCATION.FEATUREDEDUCATIONLIST}?filter[where][language_code]=${language.value}&filter[limit]=${limit}&filter[skip]=${skip}`
      );
      if (res && res.data) {
        getFeaturedEducations(res.data);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getCareers = async () => {
    try {
      const { data: careerData } = await Api.get(
        DATA_BASE_URL,
        `${CAREER.MERGESUBCAREERWITHCAREER}/${language.value}`
      );
      setCareers(careerData);
    } catch (err) {
      console.log(JSON.stringify(err));
    }
  };

  const getSubCareers = async () => {
    try {
      const { data: subCareerData } = await Api.get(
        DATA_BASE_URL,
        `${CAREER.SUBCAREER}?filter[where][language_code]=${language.value}`
      );
    } catch (err) {
      console.log(JSON.stringify(err));
    }
  };

  useEffect(() => {
    getFeaturedEduCount();
    getFeaturedEducation(1, 50, language);
    getCareers();
    getSubCareers();
  }, [language]);

  const handleSubmit = (e: any) => {
    isEdit ? updateFeaturedEducation(e) : addFeaturedEducation(e);
  };
  const handleBlob = (featuredObj) => {
    const imgReq: any = {
      url: UPLOAD + "/featuredEducation",
      fileName: featuredObj.logo,
      blob: imageBlob,
    };
    let blobReq: any = [{ ...imgReq }];
    return blobReq;
  }
  const addFeaturedEducation = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    setIsEdit(false);
    const featuredObj = {
      career_id: careerId,
      sub_career_id: subCareerId,
      featured_url: url,
      is_active: true,
      priority: priority,
      start_date: startDate,
      end_date: endDate,
      logo: Helper.getFileName(imageName, imageBlob),
    };

    try {
      const featuredRes = await Api.post(
        DATA_BASE_URL,
        FEATUREDEDUCATION.FEATUREDEDUCATION,
        featuredObj
      );
      let blobReq = await handleBlob(featuredObj);
      await FileUpload(blobReq);
      const ObjInsertT = [{
        featured_id: featuredRes.data.id,
        language_code: 'en',
        title: nameEn
      }, {
        featured_id: featuredRes.data.id,
        language_code: 'hi',
        title: nameHi
      }];
      Api.post(DATA_BASE_URL, FEATUREDEDUCATION.FEATUREDEDUCATIONBULKUPLOAD, ObjInsertT)
        .then(res => {
          console.log(res);
        }).catch(error => {
          console.log(error.message);
        })

      toast.success("Scholarship added successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      toast.error(`${error}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const updateFeaturedEducation = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    setIsEdit(true);

    const featuredObj = {
      career_id: careerId,
      sub_career_id: subCareerId,
      featured_url: url,
      is_active: true,
      priority: priority,
      start_date: startDate,
      end_date: endDate,
      logo: Helper.getFileName(imageName, imageBlob),
    };
    if (Helper.getFileName(imageName, imageBlob) === "") {
      delete featuredObj.logo;
    }
   
    try {
      const featuredRes = await Api.patch(
        DATA_BASE_URL,
        FEATUREDEDUCATION.FEATUREDEDUCATIONBYID + `/${featuredEducationId}`,
        featuredObj
      );
      if (imageBlob) {
        let blobReq = await handleBlob(featuredObj);
        await FileUpload(blobReq);
      }
      const featuredTransEducationObj = {
        featured_id: featuredEducationId,
        languageCode: language.value,
        title: title
      };

      const featuredTransTrans = await Api.patch(
        DATA_BASE_URL,
        FEATUREDEDUCATION.UPDATEFEATUREDEDUCATION,
        featuredTransEducationObj
      );
      getFeaturedEducation(currentPage, perPage, language);
    } catch (error) {
      toast.error(`${error}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleInputFieldChange = (index, event) => {
    const values = [...fields];
    values[index][event.target.name] = event.target.value;
    setFields(values);
  };

  const isActive = true;
  const handleSwitch = async (nextState, event, Aid) => {
    if (!Aid) return;
    try {
      const advObj = {
        is_active: nextState,
        id: Aid
      };
      const response = await Api.patch(
        DATA_BASE_URL,
        FEATUREDEDUCATION.UPDATEFEATUREDEDUCATIONSTATUS + `/${Aid}`,
        advObj
      );
      if (response.status === 204) {
        getFeaturedEducation(currentPage, perPage, language);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const resetForm = () => { 
    setIsEdit(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case "start_date":
        setStartDate(event.target.value);
        break;
      case "end_date":
        setEndDate(event.target.value);
        break;
    }
  };
  const getCareerName = (id: number) => {
    const filtered = careers.filter((career) => career.id === id);
    if (filtered.length) {
      return filtered[0].name;
    }
    return "All";
  };

  const getSubCareerName = (id: number | 0) => {
    const filtered = subcareers.filter((subcareer) => subcareer.id === id);
    if (filtered.length) {
      setSubCareerid(filtered[0].name);
      return filtered[0].name;
    }
    return "All";
  };

  const onImageUpload = (event: any) => {
    let file = event.target.files[0];
    if (!file) return;
    setImageName(file.name.split(".").slice(0, -1).join("."));
    const fTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (fTypes.every((type) => file.type !== type)) {
      alert(`${file.type} is not a supported format`);
      return;
    }
    getImage(file);
    showImagePreview(file);
  };
  const showImagePreview = (image: Blob) => {
    let previewImg: any = document.getElementById("preview_image");
    previewImg.src = "";
    const reader = new FileReader();
    reader.addEventListener("load", (event: any) => {
      previewImg.src = event.target.result;
    });
    reader.readAsDataURL(image);
  };
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    switch (event.target.name) {
      case "career_id":
        let careerId = parseInt(event.target.value);
        setCareerId(careerId);
        const filterCareer: MappedCareerT = careers.filter(
          (res: MappedCareerT) => res.id === careerId
        )[0];
        setCareerid(filterCareer.career_id);
        getSubCareer(filterCareer.sub_career);
        break;
      case "sub_career_id":
        let subCareerIdOnChange = parseInt(event.target.value);
        getSubCareerId(subCareerIdOnChange);
        break;
    }
  };

  const editFeaturedEducation = (featureEducations) => {
    if (!featureEducations.id) return;

    setIsEdit(true);
    setTitle(featureEducations.title);
    setCareerId(featureEducations.career_id);
    setPriority(featureEducations.priority);
    setUrl(featureEducations.featured_url);
    setStartDate(featureEducations.start_date);
    setEndDate(featureEducations.end_date);
    getSubCareerId(featureEducations.sub_career_id);
    getLogo(featureEducations.logo);
    getfeaturedEducationId(featureEducations.id);

    const filterCareer: MappedCareerT = careers.filter(
      (res: MappedCareerT) => res.id === featureEducations.career_id
    )[0];
    getSubCareer(filterCareer?.sub_career);
  }
  return (
    <div className="component-content">
      <ModalPopup
        className="primary"
        ref={modalRef}
        buttonLabel="Add More"
        popupTitle={`${isEdit ? "Edit" : "Add"} Feadured Education`}
        reset={resetForm}
        callbackMethod={(event: React.FormEvent<HTMLFormElement>) => {
          handleSubmit(event);
          resetForm();
        }}
      >
        <form onSubmit={() => handleSubmit} method="post">
          <div className="row mb-1">
            <div className="col-sm-4">
              <label className="label-name">
                <span className="">Logo</span>
              </label>
            </div>
            <div className="col-sm-8">
              <div className="form">
                <input
                  type="file"
                  accept="image/*"
                  style={{
                    width: "65px",
                    height: "70px",
                    padding: 0,
                    opacity: 0,
                  }}
                  onChange={onImageUpload}
                />
                <img
                  id="preview_image"
                  alt="Logo"
                  src={
                    logo
                      ? S3_URL + "featured/logo/" + logo
                      : require("../../assets/images/campus.png").default
                  }
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "65px",
                    height: "70px",
                    borderRadius: "5px",
                    pointerEvents: "none",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-sm">
              <div className="form ">
                <select
                  name="career_id"
                  value={careerId}
                  onChange={(event) => {
                    handleSelectChange(event);
                  }}
                  required
                >
                  <option value="">Select career</option>
                  {careers.map((car: MappedCareerT, i) => (
                    <option key={i} value={car.id}>
                      {car.name}
                    </option>
                  ))}
                </select>
                <label className="label-name">
                  <span className="content-name">Career Name</span>
                </label>
              </div>
            </div>
          </div>
          <div className={subCareer?.length ? "row mb-2" : "row mb-2 d-none"}>
            <div className="col-sm">
              <div className="form">
                <select
                  name="sub_career_id"
                  value={subCareerId}
                  onChange={(event) => {
                    handleSelectChange(event);
                  }}
                >
                  <option value="">Select sub career</option>
                  {subCareer?.map((car: SubCareerT, i) => (
                    <option key={i} value={car.id}>
                      {car.name}
                    </option>
                  ))}
                </select>
                <label className="label-name">
                  <span className="content-name">Sub Career Name</span>
                </label>
              </div>
            </div>
          </div>
          <div className="row m-0">
            <div className="row">
              <div className="col-sm-12 m-0">
                {LANGUAGE.map((lang: LanguageT) => {
                  if (!isEdit) {
                    return (
                      <fieldset key={lang.value}>
                        <legend>{lang.label}</legend>
                        <div key={lang.value} className="row">
                          <div className="col-sm">
                            <div className="form ">
                              <input type="text" name={`title_${lang.value}`} value={'en' === lang.value ? nameEn : nameHi} onChange={handleChangeTitle} required />
                              <label className="label-name">
                                <span className="content-name">
                                  Title
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    )
                  } if (isEdit && lang.value === language.value) {
                    return (
                      <fieldset key={lang.value}>
                        <legend>{lang.label}</legend>
                        <div key={lang.value} className="row">
                          <div className="col-sm">
                            <div className="form ">
                              <input type="text" name={`title`} value={title} onChange={handleChangeTitle} required />
                              <label className="label-name">
                                <span className="content-name">
                                  Title
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    )
                  }
                })}
              </div>
              <div className="col-sm-12 m-0">
                <div className="form">
                  <input
                    type="text"
                    name="priority"
                    value={priority}
                    required
                    onChange={(event) => handlePrioritChange(event)}
                  />
                  <label className="label-name">
                    <span className="content-name">Priority</span>
                  </label>
                </div>
              </div>
              <div className="col-sm-12 m-0">
                <div className="form">
                  <input
                    type="text"
                    name="featured_url"
                    required
                    value={url}
                    onChange={(event) => handleUrlChange(event)}
                  />
                  <label className="label-name">
                    <span className="content-name">URL</span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  <div className="date-picker" style={{ marginTop: "20px" }}>
                    <DateRangePicker
                      startDate={startDate ? moment(startDate) : null}
                      startDateId="start"
                      endDate={endDate ? moment(endDate) : null}
                      endDateId="end"
                      onDatesChange={({ startDate, endDate }) => {
                        setStartDate(startDate);
                        setEndDate(endDate);
                      }}
                      focusedInput={focusedInput}
                      onFocusChange={(e) => setFocusedInput(e)}
                      displayFormat="DD-MMM-YYYY"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ModalPopup>
      <Datatable
        title="Featured Education"
        description="You can view featured education here"
        currentPage={currentPage}
        pageSize={perPage}
        dataCount={arrayCount}
        callbackMethod={changePage}
        callbackSelectMethod={selectPageSize}
      >
        <div className="row">
          <div className="col-sm-2">
            <Select
              value={language}
              options={LANGUAGE}
              onChange={languageHandler}
            />
          </div>
        </div>
        <table className="datatable-table">
          <thead>
            <tr>
              <th>Id</th>
              <th>Logo</th>
              <th>Title</th>
              <th>Career</th>
              <th>Subcareer</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {featureEducations.length ? (
              featureEducations.map((fe: FeaturedEducationT, idx: number) => (
                <tr key={fe.id}>
                  <td>{fe.id}</td>
                  <td>
                    {fe.logo ? (
                      <img
                        className="image-logo"
                        src={
                          S3_URL + "featured/logo/" + fe.logo
                        }
                        width="100"
                        alt="featured_logo"
                      />
                    ) : (
                      "No preview"
                    )}
                  </td>
                  <td>
                    <a href={fe.featured_url} target="_blank">
                      {fe.title}
                    </a>
                  </td>
                  <td>
                    {fe.career_id === 0 ? "All" : getCareerName(fe.career_id)}
                  </td>
                  <td>
                    {fe.sub_career_id === 0 ? "All" : getSubCareerName(fe.sub_career_id)}
                  </td>
                  <td>{dateFormat(fe.start_date, "mmmm dS, yyyy")}</td>
                  <td>{dateFormat(fe.end_date, "mmmm dS, yyyy")}</td>
                  <td>
                    <Switch
                      onChange={(checked, event) =>
                        handleSwitch(checked, event, fe.id)
                      }
                      checked={fe.is_active == 1 ? isActive : !isActive}
                      onColor="#9FE6A0"
                      onHandleColor="#519872"
                      handleDiameter={20}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={12}
                      width={40}
                      className="react-switch"
                      id="material-switch"
                    />
                  </td>
                  <td>
                    <i
                      className="fa fa-edit"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        modalRef.current?.updateModal();
                        editFeaturedEducation(fe);
                      }}
                    ></i>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={9}>No records found</td>
              </tr>
            )}
          </tbody>
        </table>
      </Datatable>
    </div>
  );
};
export default FeaturedEducation;