/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Multiselect } from "multiselect-react-dropdown";
import Select from "react-select";
import { ModalPopup, Datatable } from "../../components/common";
import { Api, Helper } from "../../service";
import {
  CareerTraitT,
  MultiSelectItemT,
  ColorT,
  LanguageT,
} from "../../interface";
import {
  COLOR,
  CreateURL,
  CAREERTRAIT,
  PAGE_SIZE,
  DATA_BASE_URL,
  LANGUAGE,
} from "../../constant";
const selectedIds = new Set<number>();

const CareerTrait = (props: any) => {
  const [language, setLanguage] = useState(LANGUAGE[0]);
  const [loader, showLoader] = useState(false);
  const [colors, setColors] = useState<ColorT[]>([]);
  const [careerTrait, setCareerTrait] = useState<CareerTraitT[]>([]);
  const [selectedValues, setSelectedValues] = useState<MultiSelectItemT[]>([]);
  const [name_en, getNameEn] = useState("");
  const [name, getName] = useState("");
  const [name_hi, getNameHi] = useState("");
  const [shortName, getShortName] = useState("");
  const [id, setId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [arrayCount, setArrayCount] = useState(0);
  const [perPage, setPerPage] = useState(PAGE_SIZE);
  const onSelect = (
    selectedList: MultiSelectItemT[],
    selectedItem: MultiSelectItemT
  ) => {
    selectedIds.add(selectedItem.id);
    setSelectedValues(selectedList);
  };

  const onRemove = (
    selectedList: MultiSelectItemT[],
    removedItem: MultiSelectItemT
  ) => {
    selectedIds.delete(removedItem.id);
    setSelectedValues(selectedList);
  };
  const [isEdit, setisEdit] = useState(false);
  const modalRef: any = useRef(null);
  const getColors = () => {
    Api.get(DATA_BASE_URL, COLOR.COLOR)
      .then((res) => {
        const colors: ColorT[] = res.data;
        setColors(colors);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const languageHandler = (lang) => {
    pageLoader(lang);
    setCurrentPage(1);
    setLanguage(lang);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case "name_en":
        getNameEn(event.target.value);
        break;
      case "name_hi":
        getNameHi(event.target.value);
        break;
      case "short_name":
        getShortName(event.target.value);
        break;
      case "name":
        getName(event.target.value);
        break;
    }
  };

  const clearSet = (item) => {
    selectedIds.clear();
    selectedIds.delete(item);
  };
  const resetForm = () => {
    setisEdit(false);
    getNameHi("");
    getNameEn("");
    getShortName("");
    Array.from(selectedIds).forEach(clearSet);
  };
  useEffect(() => {
    pageLoader(language);
    getColors();
  }, []);

  const pageLoader = (lang) => {
    getCareerTrait(currentPage, perPage, lang.value);
    setLanguage(lang);
    getCount();
  };

  const getColorName = (cids: any[number]) => {
    return Helper.makeStringFromArray(colors, "id", cids);
  };

  const getCareerTrait = (currentPage, pageSize, lang) => {
    showLoader(true);
    setLanguage(lang);
    let limit = pageSize;
    let skip = (currentPage - 1) * limit;
    Api.get(
      DATA_BASE_URL,
      `${CAREERTRAIT.CAREERTRAIT}?filter[where][language_code]=${lang}&filter[limit]=${limit}&filter[skip]=${skip}`
    )
      .then((res) => {
        setCareerTrait(res.data);
        showLoader(false);
      })
      .catch((error) => {
        console.log(error.message);
        showLoader(false);
      });
  };

  const getCount = () => {
    Api.get(DATA_BASE_URL, CAREERTRAIT.COUNT)
      .then((res) => {
        setArrayCount(res.data.count);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const changePage = (selectedPage: number) => {
    setCurrentPage(selectedPage);
    getCareerTrait(selectedPage, perPage, language);
  };

  const selectPageSize = (event) => {
    setPerPage(parseInt(event.target.value));
    setCurrentPage(1);
    getCareerTrait(currentPage, parseInt(event.target.value), language);
  };

  const handleSubmit = (e) => {
    isEdit ? updateCareerTraitById(e) : addCareerTrait(e);
  };
  const addCareerTrait = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const colorObj = {
      color_id: Array.from(selectedIds),
    };
    Api.post(DATA_BASE_URL, CAREERTRAIT.CAREERTRAIT, colorObj)
      .then((res: any) => {
        const ctObj = [
          {
            career_trait_id: res.data.id,
            name: name_en,
            short_name: shortName,
            language_code: "en",
          },
          {
            career_trait_id: res.data.id,
            name: name_hi,
            short_name: shortName,
            language_code: "hi",
          },
        ];
        Api.post(DATA_BASE_URL, CAREERTRAIT.CAREERTRAITTRANSLATION, ctObj)
          .then((resT: any) => {
            const instertedObj = {
              id:
                language?.value === "en"
                  ? resT?.data[0]?.id
                  : resT?.data[1]?.id,
              career_trait_id: res.data.id,
              language_code: language?.value,
              name: language?.value === "en" ? name_en : name_hi,
              short_name: shortName,
            };
            const updatedRes = [instertedObj, ...careerTrait];
            setCareerTrait(updatedRes);
            getCareerTrait(1, perPage, language.value);
          })
          .catch((error) => {
            console.log(error.message);
          });
        resetForm();
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const editCareerTrait = (ct) => {
    if (!ct.id) return;

    setisEdit(true);
    const result: CareerTraitT[] = careerTrait.filter(
      (res) => res.id === ct.id
    );

    if (result) {
      setId(ct.id);
      getShortName(result[0].short_name);
      getName(result[0].name);
      const filteredColors = colors.filter(
        (color: any) => result[0].color_id?.indexOf(color.id) !== -1
      );
      for (let item of filteredColors) {
        selectedIds.add(item.id);
      }
      setSelectedValues(filteredColors);
    }
  };

  const updateCareerTraitById = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const ctObj: CareerTraitT = {
      name: name,
      short_name: shortName,
    };
    const colorObj = {
      color_id: Array.from(selectedIds),
    };

    Api.patch(
      DATA_BASE_URL,
      CreateURL(CAREERTRAIT.UPDATECAREERTRAIT, { id: id }),
      colorObj
    )
      .then((res) => {})
      .catch((error) => {
        console.log(error.message);
      });
    Api.patch(
      DATA_BASE_URL,
      CreateURL(CAREERTRAIT.CAREERTRAITBYID, { id: id }),
      ctObj
    )
      .then((res) => {
        if (res.status === 204) {
          const result = careerTrait.filter((res) => res.id !== id);
          const Array = [{ ...ctObj, id: id }, ...result];
          Array.sort((a: any, b: any) => a.id - b.id);
          setCareerTrait(Array);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <div className="component-content">
      <ModalPopup
        className="primary"
        ref={modalRef}
        buttonLabel="Add More"
        popupTitle={`${isEdit ? "Edit" : "Add"} Career Trait`}
        reset={resetForm}
        callbackMethod={(event: React.FormEvent<HTMLFormElement>) => {
          handleSubmit(event);
          resetForm();
        }}
      >
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-12">
              <div className="form" style={{ overflow: "visible" }}>
                <Multiselect
                  options={colors} // Options to display in the dropdown
                  selectedValues={selectedValues} // Preselected value to persist in dropdown
                  onSelect={onSelect} // Function will trigger on select event
                  onRemove={onRemove} // Function will trigger on remove event
                  displayValue="name"
                  closeIcon="cancel"
                  showCheckbox="true"
                  style={{
                    searchBox: {
                      border: "none",
                      padding: 0,
                      marginTop: "20px",
                      height: "50px",
                    },
                    inputField: {
                      paddingTop: 0,
                    },
                    option: {
                      borderBottom: "1px solid #dbdbdb",
                      padding: "0 10px",
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <div className="form">
                <input
                  type="text"
                  name="short_name"
                  value={shortName}
                  onChange={handleChange}
                  required
                />
                <label className="label-name">
                  <span className="content-name">Short Name</span>
                </label>
              </div>
            </div>
          </div>
          {LANGUAGE.map((lang: LanguageT) => {
            if (isEdit && lang.value === language.value) {
              return (
                <fieldset key={lang.value}>
                  <legend>{lang.label}</legend>
                  <div className="row">
                    <div className="col-sm">
                      <div className="form ">
                        <input
                          style={{ paddingTop: "0" }}
                          type="text"
                          name={`name`}
                          value={name}
                          onChange={handleChange}
                          required
                        />
                        <label className="label-name">
                          <span className="content-name">Name</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>
              );
            }
            if (!isEdit) {
              return (
                <fieldset key={lang.value}>
                  <legend>{lang.label}</legend>
                  <div className="row">
                    <div className="col-sm">
                      <div className="form ">
                        <input
                          style={{ paddingTop: "0" }}
                          type="text"
                          name={`name_${lang.value}`}
                          value={lang.value === "en" ? name_en : name_hi}
                          onChange={handleChange}
                          required
                        />
                        <label className="label-name">
                          <span className="content-name">Name</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>
              );
            }
          })}
        </form>
      </ModalPopup>
      <Datatable
        title="Career Trait"
        description="You can view career trait here"
        isShow={loader}
        currentPage={currentPage}
        pageSize={perPage}
        dataCount={arrayCount}
        callbackMethod={changePage}
        callbackSelectMethod={selectPageSize}
      >
        <div className="row ">
          <div className="col-sm-2">
            <Select
              value={language}
              options={LANGUAGE}
              onChange={languageHandler}
            />
          </div>
        </div>
        <table className="datatable-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Short Name</th>
              <th>Color</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {careerTrait.length ? (
              careerTrait.map((ct: CareerTraitT) => (
                <tr key={ct.id}>
                  <td>{ct.id}</td>
                  <td>{ct.name}</td>
                  <td>{ct.short_name}</td>
                  <td>{getColorName(ct.color_id)}</td>
                  <td>
                    <i
                      className="fa fa-edit"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        modalRef.current?.updateModal();
                        editCareerTrait(ct);
                      }}
                    ></i>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5}>No records found</td>
              </tr>
            )}
          </tbody>
        </table>
      </Datatable>
    </div>
  );
};

export default CareerTrait;
