import React, { useEffect, useMemo, useState } from "react";
import { Loader, Search } from "../../components/common";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import XLSX from "xlsx";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Api } from "../../service";
import { BULKEMAIL, USER_BASE_URL, MAIL_BASE_URL } from "../../constant";
import { ScheduleEmailModal } from "./scheduleEmailModal";
import { BulkEmailT } from "interface";
import "./BulkEmail.css";
const _ = require("lodash");

const BulkEmail = (props) => {
  const [loader, showLoader] = useState(false);
  const [configModal, setConfigModal]: any = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [templateOption, setTemplateOption] = useState([]);
  const [usersList, getUsersList] = useState<any[]>([]);
  const [campaign, setCampaign] = useState("");
  const [configObject, setConfigObject] = useState({});
  const [workBookItem, setWorkBookItem] = useState<any[]>([]);
  const [subjectLine, setSubjectLine] = useState("");
  const [batchStart, setBatchStart] = useState("");
  const [batchTime, setBatchTime] = useState("");
  const [duration, setDuration] = useState("");
  const [eligibility, setEligibility] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [zoomLink, setZoomLink] = useState("");
  const [zoomId, setZoomId] = useState("");
  const [zoomPassword, setZoomPassword] = useState("");
  const [date1, setDate1] = useState("");
  const [time1, setTime1] = useState("");
  const [date1course1time, setDate1course1time] = useState("");
  const [date1course2time, setDate1course2time] = useState("");
  const [date2, setDate2] = useState("");
  const [time2, setTime2] = useState("");
  const [date2course1time, setDate2course1time] = useState("");
  const [date2course2time, setDate2course2time] = useState("");
  const [date3, setDate3] = useState("");
  const [time3, setTime3] = useState("");
  const [date4, setDate4] = useState("");
  const [time4, setTime4] = useState("");
  const [date5, setDate5] = useState("");
  const [time5, setTime5] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [counsellingPrice, setCounsellingPrice] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [enableButton, setEnableButton] = useState(-1);
  const [previewHtml, setPreviewHtml] = useState("");
  const [templateModal, setTemplateModal] = useState(false);
  const [userModal, setUserModal] = useState(false);
  const [testEmailModal, setTestEmailModal] = useState(false);
  const [fields, setFields] = useState([{ email: "", name: "Amrita" }]);
  const [testEmailError, setTestEmailError] = useState("");
  const [search, setSearch] = useState("");
  const [listType, setListType] = useState("new");
  const [duplicates, getDuplicate] = useState<any>([]);
  const [scheduleModal, toggleScheduleModal] = useState(false);

  useEffect(() => {
    pageLoad();
  }, []);

  const pageLoad = async () => {
    try {
      showLoader(true);
      const templateData = await Api.get(MAIL_BASE_URL, BULKEMAIL.BULKEMAILTEMPLATENAMES);
      setTemplateList(templateData.data);
      const options: any = [];
      templateData.data.map((v) => {
        let obj: any = { label: v, value: v };
        return options.push(obj);
      });
      setTemplateOption(options);
      showLoader(false);
    } catch (error) {
      if ((error.status = 500)) {
        toast.error(`${JSON.stringify(error.message)}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        showLoader(false);
      }
    }
  };

  const getTemplate = async (templateName: string) => {
    try {
      const templateData = await Api.get(
        MAIL_BASE_URL, 
        `${BULKEMAIL.BULKEMAILTEMPLATE}/${templateName}`
      );
      setPreviewHtml(templateData.data);
      setTemplateModal(true);
    } catch (error) {
      toast.error(`${JSON.stringify(error.message)}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const checkDuplicates = (data) => {
    const result: any = [];
    const map = new Map();
    for (const item of data) {
      if (!map.has(item.email)) {
        map.set(item.email, true); // set any value to Map
        result.push({
          name: item.name,
          contact: item.contact,
          email: item.email,
        });
      }
    }
    return result;
  };

  const getUserData = async (campaign_type, list) => {
    try {
      showLoader(true);
      let userList: any = [];
      if (campaign_type === "SegmentB") {
        userList = await Api.get(USER_BASE_URL, `${BULKEMAIL.SEGMENTB}`);
      } else if (campaign_type === "SegmentC") {
        userList = await Api.get(USER_BASE_URL, `${BULKEMAIL.SEGMENTC}`);
      } else {
        userList = await Api.get(
          USER_BASE_URL, 
          `${BULKEMAIL.BULKEMAIL}?filter[where][campaign_type]=${campaign_type}`
        );
        getDuplicate(userList.data);
      }
      if (
        list === "new" &&
        campaign_type !== "SegmentB" &&
        campaign_type !== "SegmentC"
      )
        getUsersList([]);
      else getUsersList(userList.data);
      showLoader(false);
    } catch (error) {
      showLoader(false);
      toast.error(`${JSON.stringify(error.message)}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const inputHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    inputName: string
  ) => {
    switch (inputName) {
      case "Subject":
        setSubjectLine(event.target.value);
        break;
      case "BatchStart":
        setBatchStart(event.target.value);
        break;
      case "BatchTime":
        setBatchTime(event.target.value);
        break;
      case "Duration":
        setDuration(event.target.value);
        break;
      case "Eligibility":
        setEligibility(event.target.value);
        break;
      case "Coupon":
        setCouponCode(event.target.value);
        break;
      case "Date1":
        setDate1(event.target.value);
        break;
      case "Time1":
        setTime1(event.target.value);
        break;
      case "Date1Course1Time":
        setDate1course1time(event.target.value);
        break;
      case "Date1Course2Time":
        setDate1course2time(event.target.value);
        break;
      case "Date2":
        setDate2(event.target.value);
        break;
      case "Time2":
        setTime2(event.target.value);
        break;
      case "Date2Course1Time":
        setDate2course1time(event.target.value);
        break;
      case "Date2Course2Time":
        setDate2course2time(event.target.value);
        break;
      case "Date3":
        setDate3(event.target.value);
        break;
      case "Time3":
        setTime3(event.target.value);
        break;
      case "Date4":
        setDate4(event.target.value);
        break;
      case "Time4":
        setTime4(event.target.value);
        break;
      case "Date5":
        setDate5(event.target.value);
        break;
      case "Time5":
        setTime5(event.target.value);
        break;
      case "ZoomLink":
        setZoomLink(event.target.value);
        break;
      case "ZoomId":
        setZoomId(event.target.value);
        break;
      case "ZoomPassword":
        setZoomPassword(event.target.value);
        break;
      case "SchoolName":
        setSchoolName(event.target.value);
        break;
      case "CounsellingPrice":
        setCounsellingPrice(event.target.value);
        break;
      default:
        console.log("No method called");
        break;
    }
  };

  const testSendEmail = (idx, title) => {
    setEnableButton(idx);
    setCampaign(title);
    setTestEmailModal(!testEmailModal);
  };

  const addFields = () => {
    if (fields.length < 5) {
      setFields([...fields, { email: "", name: "Amrita" }]);
    }
  };

  const removeFields = (i) => {
    const values = [...fields];
    if (values.length > 1) {
      values.splice(i, 1);
    }
    setFields(values);
  };

  const handleListChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue("");
    setShowDropdown(false);
    setListType(event.target.value);
    if (event.target.value === "new") {
      getDuplicate([...usersList]);
      getUsersList([]);
    }
    if (event.target.value === "existing") {
      getUserData(campaign, event.target.value);
    }
  };

  const toggle = () => {
    setUserModal(!userModal);
    setSelectedValue("");
    setShowDropdown(false);
    resetForm();
  };
  const toggleTemplateModal = () => {
    setTemplateModal(!templateModal);
  };
  const toggleConfigModal = () => {
    setConfigModal(!configModal);
    resetConfigForm();
  };
  const toggleTestEmail = () => {
    setTestEmailModal(!testEmailModal);
    setFields([{ email: "", name: "" }]);
  };

  const handleInputFieldChange = (index, event) => {
    const values = [...fields];
    values[index][event.target.name] = event.target.value;
    setFields(values);
  };

  const sendMailTestMail = async (campaign) => {
    try {
      let emailsList = fields.filter((field) => field.email !== "");
      const resp = await Api.post(MAIL_BASE_URL, BULKEMAIL.SENDEMAIL, {
        templateName: campaign,
        emails: emailsList,
        mailConfig: configObject,
      });
      if (resp?.status === 200) {
        toast.success("Test mail sent!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTestEmailModal(!testEmailModal);
        setFields([{ email: "", name: "" }]);
      }
    } catch (error) {
      setTestEmailError(error.message);
      toast.error(`${JSON.stringify(error.message)}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleAllCheck = (evt: any) => {
    let checked = evt.target.checked;
    const updatedList: any = usersList.map((user: any) => {
      user["selected"] = checked;
      return user;
    });
    getUsersList(updatedList);
  };

  const checkSelectedRow = (evt: any, userId: any) => {
    const updatedList: any = usersList.map((user: any) => {
      if (user.email === userId) {
        user["selected"] = evt.target.checked;
      } else user["selected"] = false;
      return user;
    });
    getUsersList(updatedList);
  };

  const renderHtmlFile = () => {
    return <div dangerouslySetInnerHTML={{ __html: previewHtml }} />;
  };

  const renderConfigForm = () => {
    return (
      <form data-testid="config-form">
        <div className="row">
          <div className="col-sm-12">
            <div className="form">
              <input
                className="subject"
                type="text"
                name="subjectLine"
                value={subjectLine}
                onChange={(e) => inputHandler(e, "Subject")}
                required
              />
              <label className="label-name">
                <span className="content-name">Subject for email</span>
              </label>
            </div>
          </div>
        </div>
        {(campaign === "WebinarCommunication1" ||
          campaign === "WebinarCommunication2") && (
          <div className="row">
            <div className="col-sm-12">
              <div className="form">
                <input
                  type="text"
                  name="schoolName"
                  value={schoolName}
                  onChange={(e) => inputHandler(e, "SchoolName")}
                />
                <label className="label-name">
                  <span className="content-name">School Name</span>
                </label>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form">
                <input
                  type="text"
                  name="counsellingPrice"
                  value={counsellingPrice}
                  onChange={(e) => inputHandler(e, "CounsellingPrice")}
                />
                <label className="label-name">
                  <span className="content-name">Counselling Price</span>
                </label>
              </div>
            </div>
          </div>
        )}
        {(campaign === "EmotionalSocialQuotient" ||
          campaign === "IntelligenceCreativeQuotient" ||
          campaign === "ReminderSpiritualQuotient" ||
          campaign === "ReminderTechQuotient") && (
          <div className="row">
            <div className="col-sm-12">
              <div className="form">
                <input
                  data-testid="batchStart"
                  type="text"
                  name="zoomLink"
                  value={zoomLink}
                  onChange={(e) => inputHandler(e, "ZoomLink")}
                />
                <label className="label-name">
                  <span className="content-name">Zoom Link</span>
                </label>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form">
                <input
                  data-testid="batchTime"
                  type="text"
                  name="zoomId"
                  value={zoomId}
                  onChange={(e) => inputHandler(e, "ZoomId")}
                />
                <label className="label-name">
                  <span className="content-name">Zoom Id</span>
                </label>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form">
                <input
                  type="text"
                  name="zoomPassword"
                  value={zoomPassword}
                  onChange={(e) => inputHandler(e, "ZoomPassword")}
                />
                <label className="label-name">
                  <span className="content-name">Zoom Password</span>
                </label>
              </div>
            </div>
          </div>
        )}
        {campaign === "PrepCourse" && (
          <div className="row">
            <div className="col-sm-12">
              <div className="form">
                <input
                  data-testid="duration"
                  type="text"
                  name="duration"
                  value={duration}
                  onChange={(e) => inputHandler(e, "Duration")}
                />
                <label className="label-name">
                  <span className="content-name">Duration</span>
                </label>
              </div>
            </div>

            <div className="col-sm-12">
              <div className="form">
                <input
                  data-testid="eligibilty"
                  type="text"
                  name="eligibility"
                  value={eligibility}
                  onChange={(e) => inputHandler(e, "Eligibility")}
                />
                <label className="label-name">
                  <span className="content-name">Eligibility</span>
                </label>
              </div>
            </div>
          </div>
        )}
        {(campaign === "EmployabilitySkills-Students" ||
          campaign === "EmployabilitySkills-Schools" ||
          campaign === "PGD-Students" ||
          campaign === "PGD-Teachers" ||
          campaign === "PrepCourse" ||
          campaign === "EmployabilitySkills-3600") && (
          <div className="row">
            <div className="col-sm-12">
              <div className="form">
                <input
                  type="text"
                  name="batchStart"
                  value={batchStart}
                  onChange={(e) => inputHandler(e, "BatchStart")}
                />
                <label className="label-name">
                  <span className="content-name">Event / Batch Start Date</span>
                </label>
              </div>
            </div>
          </div>
        )}
        {campaign === "PrepCourse" && (
          <div className="row">
            <div className="col-sm-12">
              <div className="form">
                <input
                  type="text"
                  name="batchTime"
                  value={batchTime}
                  onChange={(e) => inputHandler(e, "BatchTime")}
                />
                <label className="label-name">
                  <span className="content-name">Event / Batch Time</span>
                </label>
              </div>
            </div>
          </div>
        )}
        {campaign === "EmployabilitySkills-3600" && (
          <div className="row">
            <div className="col-sm-12">
              <div className="form">
                <input
                  data-testid="couponCode"
                  type="text"
                  name="couponCode"
                  value={couponCode}
                  onChange={(e) => inputHandler(e, "Coupon")}
                />
                <label className="label-name">
                  <span className="content-name">Coupon code</span>
                </label>
              </div>
            </div>
          </div>
        )}
        {(campaign === "CareerSpecialisationExpo" ||
          campaign === "EmotionalSocialQuotient" ||
          campaign === "IntelligenceCreativeQuotient" ||
          campaign === "ReminderSpiritualQuotient" ||
          campaign === "ReminderTechQuotient") && (
          <div className="row">
            <div className="col-sm-12">
              <div className="form">
                <input
                  type="text"
                  name="date1"
                  value={date1}
                  onChange={(e) => inputHandler(e, "Date1")}
                />
                <label className="label-name">
                  <span className="content-name">Date 1</span>
                </label>
              </div>
            </div>
            {campaign === "CareerSpecialisationExpo" ? (
              <div style={{ width: "100%" }}>
                <div className="col-sm-12">
                  <div className="form">
                    <input
                      type="text"
                      name="date1course1time"
                      value={date1course1time}
                      onChange={(e) => inputHandler(e, "Date1Course1Time")}
                    />
                    <label className="label-name">
                      <span className="content-name">Date 1 course 1 time</span>
                    </label>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form">
                    <input
                      type="text"
                      name="date1course2time"
                      value={date1course2time}
                      onChange={(e) => inputHandler(e, "Date1Course2Time")}
                    />
                    <label className="label-name">
                      <span className="content-name">Date 1 course 2 time</span>
                    </label>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-sm-12">
                <div className="form">
                  <input
                    type="text"
                    name="time1"
                    value={time1}
                    onChange={(e) => inputHandler(e, "Time1")}
                  />
                  <label className="label-name">
                    <span className="content-name">Time 1</span>
                  </label>
                </div>
              </div>
            )}
            <div className="col-sm-12">
              <div className="form">
                <input
                  type="text"
                  name="date2"
                  value={date2}
                  onChange={(e) => inputHandler(e, "Date2")}
                />
                <label className="label-name">
                  <span className="content-name">Date 2</span>
                </label>
              </div>
            </div>
            {campaign === "CareerSpecialisationExpo" ? (
              <div style={{ width: "100%" }}>
                <div className="col-sm-12">
                  <div className="form">
                    <input
                      type="text"
                      name="date2course1time"
                      value={date2course1time}
                      onChange={(e) => inputHandler(e, "Date2Course1Time")}
                    />
                    <label className="label-name">
                      <span className="content-name">Date 2 course 1 time</span>
                    </label>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form">
                    <input
                      type="text"
                      name="date2course2time"
                      value={date2course2time}
                      onChange={(e) => inputHandler(e, "Date2Course2Time")}
                    />
                    <label className="label-name">
                      <span className="content-name">Date 2 course 2 time</span>
                    </label>
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ width: "100%" }}>
                <div className="col-sm-12">
                  <div className="form">
                    <input
                      type="text"
                      name="time2"
                      value={time2}
                      onChange={(e) => inputHandler(e, "Time2")}
                    />
                    <label className="label-name">
                      <span className="content-name">Time 2</span>
                    </label>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form">
                    <input
                      type="text"
                      name="date3"
                      value={date3}
                      onChange={(e) => inputHandler(e, "Date3")}
                    />
                    <label className="label-name">
                      <span className="content-name">Date 3</span>
                    </label>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form">
                    <input
                      type="text"
                      name="time3"
                      value={time3}
                      onChange={(e) => inputHandler(e, "Time3")}
                    />
                    <label className="label-name">
                      <span className="content-name">Time 3</span>
                    </label>
                  </div>
                </div>
              </div>
            )}
            {campaign !== "EmotionalSocialQuotient" &&
              campaign !== "IntelligenceCreativeQuotient" && (
                <div style={{ width: "100%" }}>
                  <div className="col-sm-12">
                    <div className="form">
                      <input
                        type="text"
                        name="date4"
                        value={date4}
                        onChange={(e) => inputHandler(e, "Date4")}
                      />
                      <label className="label-name">
                        <span className="content-name">Date 4</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form">
                      <input
                        type="text"
                        name="time4"
                        value={time4}
                        onChange={(e) => inputHandler(e, "Time4")}
                      />
                      <label className="label-name">
                        <span className="content-name">Time 4</span>
                      </label>
                    </div>
                  </div>
                </div>
              )}
            {campaign !== "EmotionalSocialQuotient" &&
              campaign !== "IntelligenceCreativeQuotient" &&
              campaign !== "ReminderSpiritualQuotient" && (
                <div style={{ width: "100%" }}>
                  <div className="col-sm-12">
                    <div className="form">
                      <input
                        type="text"
                        name="date5"
                        value={date5}
                        onChange={(e) => inputHandler(e, "Date5")}
                      />
                      <label className="label-name">
                        <span className="content-name">Date 5</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form">
                      <input
                        type="text"
                        name="time5"
                        value={time5}
                        onChange={(e) => inputHandler(e, "Time5")}
                      />
                      <label className="label-name">
                        <span className="content-name">Time 5</span>
                      </label>
                    </div>
                  </div>
                </div>
              )}
          </div>
        )}
      </form>
    );
  };

  const handleFile = (event) => {
    try {
      const file = event.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target?.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data: any = XLSX.utils.sheet_to_json(ws, { raw: false });
        if (data.length > 0) {
          setWorkBookItem(data);
          let dataList: any = [];
          if (listType === "new") {
            dataList = data;
          } else if (listType === "existing") {
            dataList = checkDuplicates([...data, ...usersList]);
          }
          getUsersList(dataList);
        } else {
          toast.error("Data cannot be empty", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      };
    } catch (error) {
      toast.error(`${JSON.stringify(error.message)}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const UploadUsersList = async (segment: any, list: any) => {
    let userData: any[] = [];
    list.forEach((item) => {
      let obj = {};
      obj = {
        name: item.name,
        contact: item.contact,
        email: item.email,
        campaign_type: segment,
      };
      userData.push(obj);
    });
    try {
      await Api.post(MAIL_BASE_URL, BULKEMAIL.BULKEMAIL, userData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = (event) => {
    event.preventDefault();
    const obj = {
      subject: subjectLine,
      batchStart: batchStart,
      batchTime: batchTime,
      duration: duration,
      eligibility: eligibility,
      couponCode: couponCode,
      date1: date1,
      date2: date2,
      date3: date3,
      date4: date4,
      date5: date5,
      time1: time1,
      time2: time2,
      time3: time3,
      time4: time4,
      time5: time5,
      date1course1time: date1course1time,
      date1course2time: date1course2time,
      date2course1time: date2course1time,
      date2course2time: date2course2time,
      zoomLink: zoomLink,
      zoomId: zoomId,
      zoomPassword: zoomPassword,
      schoolName: schoolName,
      counsellingPrice: counsellingPrice,
    };
    setConfigObject(obj);
    setConfigModal(false);
  };

  const getUserDetails = (email) => {
    const list: any[] = workBookItem.filter((e: any) => e.email === email);
    return list;
  };

  const sendMail = async (segment, workBookItem) => {
    try {
      if (
        workBookItem.length > 0 &&
        segment !== "SegmentB" &&
        segment !== "SegmentC"
      ) {
        if (duplicates.length === 0) {
          await UploadUsersList(segment, workBookItem);
        }
        if (duplicates.length > 0) {
          const uploadData = _.map(workBookItem, "email");
          const existingData = _.map(duplicates, "email");
          const uniqueData = uploadData.filter(
            (x: String) => !existingData.includes(x)
          );
          if (uniqueData.length > 0) {
            let list: any = [];
            uniqueData.map((l: any) => list.push(...getUserDetails(l)));
            if (list.length > 0) await UploadUsersList(segment, list);
          }
        }
      }
      if (!usersList.length) return;
      const userEmails: any[] = [];
      const uniqueList = checkDuplicates(usersList);
      uniqueList.forEach((user: any) => {
        let obj = {};
        obj = { email: user.email, name: user.name };
        userEmails.push(obj);
      });

      if (!userEmails.length) {
        alert("Please select atleast 1 user");
        return;
      }

      const resp = await Api.post(MAIL_BASE_URL, BULKEMAIL.SENDEMAIL, {
        templateName: segment,
        emails: userEmails,
        mailConfig: configObject,
      });
      if (resp?.status === 200) {
        toast.success("Reminder mail sent to the users", {
          position: toast.POSITION.TOP_RIGHT,
        });
        resetForm();
        resetConfigForm();
        setUserModal(false);
        getUsersList([]);
      }
    } catch (err) {
      toast.error(`${JSON.stringify(err.message)}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.log(err.message);
    }
  };

  const configData = (idx: number, title: string) => {
    setEnableButton(idx);
    setCampaign(title);
    setConfigModal(true);
  };

  const resetForm = () => {
    setSelectedValue("");
    setShowDropdown(false);
    setListType("new");
    getUsersList([]);
    resetConfigForm();
  };

  const resetConfigForm = () => {
    setBatchStart("");
    setSubjectLine("");
    setBatchTime("");
    setDuration("");
    setEligibility("");
    setCouponCode("");
    setDate1("");
    setDate1course1time("");
    setDate1course2time("");
    setDate2("");
    setDate2course1time("");
    setDate2course2time("");
    setDate3("");
    setDate4("");
    setDate5("");
    setTime1("");
    setTime2("");
    setTime3("");
    setTime4("");
    setTime5("");
    setZoomLink("");
    setZoomId("");
    setZoomPassword("");
    setSchoolName("");
    setCounsellingPrice("");
  };

  const selectHandler = (event) => {
    setSelectedValue(event);
    getUserData(event.value, listType);
  };

  const users = useMemo(() => {
    let usersData = usersList;
    if (search) {
      usersData = usersData.filter(
        (user: any) =>
          user.name?.toLowerCase().includes(search.toLowerCase()) ||
          user.contact?.includes(search) ||
          user.email?.toLowerCase().includes(search.toLowerCase())
      );
    }
    return usersData;
  }, [usersList, search]);

  const renderUserData = () => {
    return (
      <div>
        {campaign !== "SegmentB" && campaign !== "SegmentC" && (
          <div className="row mt-1">
            <div className="col-sm-12">
              <label className="label-name">
                <input
                  type="radio"
                  name="listtype"
                  value="new"
                  className="k-radio"
                  checked={listType === "new" && true}
                  onChange={handleListChange}
                />
                New list
              </label>{" "}
              <label className="label-name">
                <input
                  type="radio"
                  name="listtype"
                  value="existing"
                  className="k-radio"
                  checked={listType === "existing" && true}
                  onChange={handleListChange}
                />
                Existing list
              </label>
            </div>
          </div>
        )}
        {campaign !== "SegmentB" &&
          campaign !== "SegmentC" &&
          listType === "new" && (
            <div className="row">
              <div className="col-sm-12 float-right">
                <div className="add-user-wrapper pull-right">
                  <input
                    type="file"
                    accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={(e) => {
                      handleFile(e);
                    }}
                  />
                  <Button color="primary" size="sm">
                    <i className="fa fa-plus" /> Add user
                  </Button>
                </div>
              </div>
            </div>
          )}
        {campaign !== "SegmentB" &&
          campaign !== "SegmentC" &&
          listType === "existing" && (
            <div className="row">
              <div className="copy-list col-sm-5">
                <label>
                  <input
                    type="checkbox"
                    name="dropdown"
                    checked={showDropdown}
                    onChange={(e) => {
                      setShowDropdown(e.target.checked);
                    }}
                  />{" "}
                  Select users from other campaign
                </label>
              </div>
              <div className="col-sm-4">
                {showDropdown === true && (
                  <Select
                    value={selectedValue}
                    options={templateOption}
                    onChange={selectHandler}
                  />
                )}
              </div>
              <div className="col-sm-3">
                <div className="add-user-wrapper pull-right">
                  <input
                    type="file"
                    accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={(e) => {
                      handleFile(e);
                    }}
                  />
                  <Button color="primary" size="sm">
                    <i className="fa fa-plus" /> Add user
                  </Button>
                </div>
              </div>
            </div>
          )}
        <div className="row">
          <div className="col-sm-12">
            <Search
              onSearch={(value) => {
                setSearch(value);
              }}
            />
          </div>
        </div>
        <table className="datatable-table">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  onChange={(evt) => handleAllCheck(evt)}
                />
              </th>
              <th>#</th>
              <th>Name</th>
              <th>Contact</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {users.length ? (
              users.map((user: BulkEmailT, idx: number) => (
                <tr key={idx}>
                  <td>
                    <input
                      type="checkbox"
                      checked={
                        user.selected === undefined
                          ? false
                          : user.selected === false
                          ? false
                          : true
                      }
                      onChange={(e) => checkSelectedRow(e, user.email)}
                    />
                  </td>
                  <td>{idx + 1}</td>
                  <td>{user.name}</td>
                  <td>{user.contact}</td>
                  <td>{user.email}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8}>No records found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const openCronModal = () => {
    toggleScheduleModal(!scheduleModal);
  }

  return (
    <div className="component-content">
      <Modal className="user-modal" isOpen={templateModal} size="lg">
        <ModalHeader toggle={toggleTemplateModal}>{campaign}</ModalHeader>
        <ModalBody className="csv-modal-body">{renderHtmlFile()}</ModalBody>
        <ModalFooter>
          <Button color="warning" size="sm" onClick={toggleTemplateModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal className="user-modal" isOpen={configModal} size="xs">
        <ModalHeader toggle={toggleConfigModal}>{campaign}</ModalHeader>
        <ModalBody className="csv-modal-body">{renderConfigForm()}</ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={!subjectLine}
            onClick={handleSave}
            size="sm"
          >
            Save
          </Button>
          {""}
          <Button color="warning" size="sm" onClick={toggleConfigModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {/* Todo : Move allnmodal in seprate file */}
      {/* <TestEmailModal configObj={configObject} modalState={testEmailModal} /> */}
      <Modal className="user-modal" isOpen={testEmailModal} size="lg">
        <ModalHeader toggle={toggleTestEmail}>{campaign}</ModalHeader>
        <ModalBody className="csv-modal-body">
          <div>
            <h6>
              <i id="error-msg">Test up to maximum 5 Email Id</i>
            </h6>
          </div>
          <form>
            {testEmailError !== "" && testEmailError}
            {fields.map((field, idx) => (
              <div key={idx} className="row ml-1">
                <div className="col-sm-10">
                  <div className="form">
                    <input
                      type="text"
                      name="email"
                      value={field.email}
                      onChange={(event) => handleInputFieldChange(idx, event)}
                      required
                    />
                    <label className="label-name">
                      <span className="content-name">Email Id</span>
                    </label>
                  </div>
                </div>
                <div className="col-sm-1 icons">
                  <i className="fa fa-plus" onClick={addFields}></i>
                </div>
                <div className="col-sm-1 icons">
                  <i
                    className="fa fa-minus align-items-center"
                    onClick={() => removeFields(idx)}
                  ></i>
                </div>
              </div>
            ))}
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            size="sm"
            onClick={() => sendMailTestMail(campaign)}
            disabled={fields[0]["email"] === ""}
          >
            <i className="fa fa-envelope" /> Send Mail
          </Button>
          {""}
          <Button color="warning" size="sm" onClick={toggleTestEmail}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal className="user-modal" isOpen={userModal} size="lg">
        <ModalHeader toggle={toggle}>{campaign}</ModalHeader>
        <ModalBody className="csv-modal-body">{renderUserData()}</ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            size="sm"
            onClick={() => sendMail(campaign, workBookItem)}
          >
            <i className="fa fa-envelope" /> Send Mail
          </Button>
          {""}
          <Button color="warning" size="sm" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <ScheduleEmailModal {...props} configObj={configObject} modalState={scheduleModal} />
      <Loader className="spinner" show={loader} />
      <ToastContainer />
      <div className="datatable-panel">
        <div className="datatable-header">
          <div className="datatable-header-title row">
            <div className="col-8">
              <h4 className="datatable-title">Bulk email </h4>
              <p className="datatable-subtitle">
                You can send bulk email to users
              </p>
            </div>
            <div className="col-4 download-button">
              <a
                title="download sample excel file"
                href="./assets/sample.xlsx"
                download
                type="button"
                className="btn btn-md btn-outline-light pull-right sample-download"
              >
                <i className="fa fa-download" /> Sample XLSX
              </a>
            </div>
          </div>
        </div>
        {templateList &&
          templateList.map((title: any, idx: number) => (
            <Card
              key={idx}
              className="accordion-toggle collapsed shrink email-card"
            >
              <CardBody className="email-card-header">
                <CardTitle>
                  <div className="row">
                    <div className="col-sm-5">
                      <h5 className="m-2">{title}</h5>
                    </div>
                    <div className="col-sm-7">
                      <div className="pull-right">
                        <Button
                          color="warning"
                          size="sm"
                          onClick={() => {
                            getTemplate(title);
                            setCampaign(title);
                          }}
                        >
                          <i className="fa fa-eye" /> Preview
                        </Button>{" "}
                        <Button
                          color="warning"
                          size="sm"
                          onClick={() => {
                            configData(idx, title);
                          }}
                        >
                          <i className="fa fa-gear" /> Configuration
                        </Button>{" "}
                        <Button
                          color="warning"
                          size="sm"
                          onClick={() => {
                            testSendEmail(idx, title);
                          }}
                          disabled={subjectLine === "" || enableButton !== idx}
                        >
                          <i className="fa fa-envelope-o" /> Test Send Mail
                        </Button>{" "}
                        <Button
                          color="warning"
                          size="sm"
                          onClick={() => {
                            setCampaign(title);
                            setUserModal(!userModal);
                            getUserData(title, listType);
                          }}
                          disabled={subjectLine === "" || enableButton !== idx}
                        >
                          <i className="fa fa-envelope" /> Send Mail
                        </Button>{" "}
                        <Button
                          color="warning"
                          size="sm"
                          onClick={() => openCronModal()}
                          disabled={subjectLine === "" || enableButton !== idx}
                        >
                          <i className="fa fa-envelope" /> Schedule Mail
                        </Button>
                      </div>
                    </div>
                  </div>
                </CardTitle>
              </CardBody>
            </Card>
          ))}
      </div>
    </div>
  );
};

export default BulkEmail;
