/* eslint-disable array-callback-return */
import { Api } from "../service";
import { USER_BASE_URL } from "../constant";

export const FileUpload = async (paramsArray) => {
  const urls: string[] = [];
  const blobs: object[] = [];
  paramsArray.length > 0 && paramsArray.map((params: any) => {
    if (params?.url.length) {
      urls.push(params?.url);
    }
    if (params.blob) {
      let formData: FormData = new FormData();
      formData.append("image", params.blob, params.fileName);
      blobs.push(formData);
    }
  });
  try {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const response = await Api.allPost(USER_BASE_URL, urls, blobs, config);
    return response.map((res: any) => res.data);
  } catch (error) {
    console.log(error);
    alert(error)
  }
};
