import React, { createRef } from "react";
import { Multiselect } from "multiselect-react-dropdown";
import { ModalPopup, Datatable } from "../../components/common";
import Select from "react-select";
import { Api, Helper } from "../../service";
import {
  PAGE_SIZE,
  CreateURL,
  COLOR,
  PERSONALITY,
  LANGUAGE,
  DATA_BASE_URL,
} from "../../constant";
import {
  PersonalityT,
  ColorT,
  MultiSelectItemT,
  LanguageT,
} from "../../interface";
import Swal from "sweetalert2";

type MyProps = {};
type MyState = {
  personality: PersonalityT[];
  arrayCount: number;
  colors: ColorT[];
  currentPage: number;
  loader: boolean;
  currentId: number;
  formInput: {
    color_ids: any[number];
    name_en: string;
    name_hi: string;
    name: string;
    selectedValue: MultiSelectItemT[];
  };
  perPage: number;
  lang?: string;
};

class Personality extends React.Component<MyProps, MyState> {
  selectedIds = new Set();
  isEdit: any = false;
  constructor(props: any) {
    super(props);
    this.state = {
      formInput: {
        name_en: "",
        name_hi: "",
        name: "",
        selectedValue: [],
        color_ids: "",
      },
      personality: [],
      colors: [],
      currentId: 0,
      arrayCount: 0,
      currentPage: 1,
      loader: false,
      perPage: PAGE_SIZE,
      lang: LANGUAGE[0].value,
    };
  }
  modalRef: any = createRef();
  handleSubmit = (e: any) => {
    this.isEdit ? this.updatePersonalityById(e) : this.addPersonality(e);
  };
  languageHandler = (lang) => {
    this.getPersonality(this.state.currentPage, this.state.perPage, lang);
    // this.getHobbies(this.state.currentPage, this.state.perPage, lang);
    this.state.lang = lang.value;
  };
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      formInput: {
        ...this.state.formInput,
        [name]: value,
      },
    });
  };
  onSelect = (
    selectedList: MultiSelectItemT[],
    selectedItem: MultiSelectItemT
  ) => {
    this.selectedIds.add(selectedItem.id);
    this.setState({
      formInput: {
        ...this.state.formInput,
        selectedValue: selectedList,
        color_ids: [...this.selectedIds],
      },
    });
  };
  onRemove = (
    selectedList: MultiSelectItemT[],
    removedItem: MultiSelectItemT
  ) => {
    this.selectedIds.delete(removedItem.id);
    this.setState({
      formInput: {
        ...this.state.formInput,
        selectedValue: selectedList,
        color_ids: [...this.selectedIds],
      },
    });
  };
  changePage = (selectedPage: number) => {
    this.setState(
      {
        currentPage: selectedPage,
      },
      () => {
        this.getPersonality(selectedPage, this.state.perPage, LANGUAGE);
      }
    );
  };
  selectPageSize = (event) => {
    this.setState({
      perPage: parseInt(event.target.value),
      currentPage: 1,
    });
    this.getPersonality(
      this.state.currentPage,
      parseInt(event.target.value),
      LANGUAGE
    );
  };
  componentDidMount() {
    this.getColors();
    this.getPersonalityCount();
    this.getPersonality(
      this.state.currentPage,
      this.state.perPage,
      LANGUAGE[0]
    );
  }
  getColors = () => {
    this.setState({ loader: true });
    Api.get(DATA_BASE_URL, COLOR.COLOR)
      .then((res) => {
        const colors: ColorT[] = res.data;
        this.setState({ colors: colors, loader: false });
      })
      .catch((error) => {
        this.setState({ loader: false });
      });
  };
  getPersonalityCount = async () => {
    try {
      const countRes = await Api.get(
        DATA_BASE_URL,
        PERSONALITY.PERSONALITYCOUNT
      );
      if (countRes && countRes.data) {
        this.setState({ arrayCount: countRes.data.count });
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };
  getPersonality = (currentPage, pageSize, lang) => {
    this.setState({ loader: true });
    let limit = this.state.perPage;
    let skip = (currentPage - 1) * limit;

    Api.get(
      DATA_BASE_URL,
      `${PERSONALITY.PERSONALITY}?filter[where][language_code]=${lang.value}&filter[limit]=${pageSize}&filter[skip]=${skip}`
    )
      .then((res) => {
        const personality: PersonalityT[] = res.data;
        this.setState({ personality: personality, loader: false });
      })
      .catch((error) => {
        this.setState({ loader: false });
      });
  };
  addPersonality(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const choiceObj: PersonalityT = {
      color_id: [...this.state.formInput.color_ids],
    };
    Api.post(DATA_BASE_URL, PERSONALITY.PERSONALITY, choiceObj)
      .then((res) => {
        const choiceTransObj = [
          {
            name: this.state.formInput.name_en,
            language_code: "en",
            personality_id: res.data.id,
          },
          {
            language_code: "hi",
            name: this.state.formInput.name_hi,
            personality_id: res.data.id,
          },
        ];

        Api.post(
          DATA_BASE_URL,
          PERSONALITY.PERSONALITYTRANSLATION,
          choiceTransObj
        )
          .then((res) => {
            if (res.status === 200) {
              this.setState({
                personality: [...this.state.personality, res.data],
              });
              this.resetForm();
            }
            this.getPersonality(
              this.state.currentPage,
              this.state.perPage,
              LANGUAGE[0]
            );
          })
          .catch((error) => {
            console.log(error.message);
          });
      })
      .catch((error) => {
        console.log(error.message);
      });
  }
  reteriveColor = (arr: number[]) => {
    let colors: any = [...this.state.colors];
    return colors.filter((c1: any) => arr.some((c2) => c1.id === c2));
  };
  editPersonality(personality: PersonalityT) {
    if (!personality.id) return;
    this.isEdit = true;
    this.setState({ currentId: personality.id });
    personality.color_id.forEach((colorId) => this.selectedIds.add(colorId));
    this.setState({
      formInput: {
        color_ids: personality.color_id,
        name: personality.name,
        selectedValue: this.reteriveColor(personality.color_id),
      },
    });
  }
  updatePersonalityById(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const personalityObj: PersonalityT = {
      color_id: [...this.state.formInput.color_ids],
    };
    const personalityObjAdd = {
      name: this.state.formInput.name,
      color_id: [...this.state.formInput.color_ids],
    };
    Api.patch(
      DATA_BASE_URL,
      CreateURL(PERSONALITY.PERSONALITYID, { id: this.state.currentId }),
      personalityObj
    )
      .then((res) => {
        const personalityTObj = {
          name: this.state.formInput.name,
          language_code: this.state.lang,
          personality_id: this.state.currentId,
        };

        Api.patch(
          DATA_BASE_URL,
          PERSONALITY.PERSONALITYTRANSLATIONID,
          personalityTObj
        )
          .then((res) => {
            if (res.status === 204) {
              const result: PersonalityT[] = this.state.personality.filter(
                (res: any) => res.id !== this.state.currentId
              );
              const Array = [
                ...result,
                { id: this.state.currentId, ...personalityObjAdd },
              ];
              Array.sort((a: any, b: any) => a.id - b.id);
              this.setState({ personality: Array, loader: false });
              this.resetForm();
            }
          })
          .catch((error) => {
            console.log(error.message);
          });

        if (res.status === 204) {
          const result: PersonalityT[] = this.state.personality.filter(
            (res: any) => res.id !== this.state.currentId
          );
          const Array = [
            ...result,
            { id: this.state.currentId, ...personalityObj },
          ];
          Array.sort((a: any, b: any) => a.id - b.id);
          this.setState({ personality: Array, loader: false });
          this.resetForm();
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  deletePersonality(personality_id: number | undefined) {
    if (!personality_id) return;
    Swal.fire({
      title: "Are you sure?",
      text: `You will not be able to recover the personality with ID ${personality_id}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ loader: true });
        Api.delete(
          DATA_BASE_URL,
          CreateURL(PERSONALITY.DELETE, { id: personality_id })
        )
          .then((res) => {
            if (res.status === 204) {
              const result = this.state.personality.filter((res: any) => {
                return res.id !== personality_id;
              });
              this.setState({
                personality: result,
                currentPage: this.state.currentPage,
                loader: false,
              });
              Swal.fire(
                "Deleted!",
                `personality ID ${personality_id} has been deleted.`,
                "success"
              );
            }
          })
          .catch((error) => {
            this.setState({ loader: false });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          "Cancelled",
          `Your personality ID ${personality_id} detail is safe :)`,
          "info"
        );
      }
    });
  }
  getColorName(cids: any[number]) {
    return Helper.makeStringFromArray(this.state.colors, "id", cids);
  }
  resetForm = () => {
    this.isEdit = false;
    this.setState({
      formInput: {
        name_en: "",
        name_hi: "",
        name: "",
        selectedValue: [],
        color_ids: "",
      },
    });
  };
  render() {
    return (
      <div className="component-content">
        <ModalPopup
          ref={this.modalRef}
          className="primary"
          buttonLabel="Add More"
          popupTitle={`${this.isEdit ? "Edit" : "Add"} Personality`}
          reset={this.resetForm}
          callbackMethod={(event: React.FormEvent<HTMLFormElement>) =>
            this.handleSubmit(event)
          }
        >
          <form onSubmit={this.handleSubmit}>
            {LANGUAGE.map((lang: LanguageT) => {
              if (this.isEdit && lang.value === this.state.lang) {
                return (
                  <fieldset key={lang.value}>
                    <legend>{lang.label}</legend>
                    <div key={lang.value} className="row">
                      <div className="col-sm">
                        <div className="form ">
                          <input
                            type="text"
                            name={`name`}
                            value={this.state.formInput.name}
                            onChange={this.handleChange}
                            required
                          />
                          <label className="label-name">
                            <span className="content-name">
                              Choice Name({lang.label})
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                );
              }
              if (!this.isEdit) {
                return (
                  <fieldset key={lang.value}>
                    <legend>{lang.label}</legend>
                    <div key={lang.value} className="row">
                      <div className="col-sm">
                        <div className="form ">
                          <input
                            type="text"
                            name={`name_${lang.value}`}
                            value={
                              lang.value === "en"
                                ? this.state.formInput.name_en
                                : this.state.formInput.name_hi
                            }
                            onChange={this.handleChange}
                            required
                          />
                          <label className="label-name">
                            <span className="content-name">
                              Choice Name({lang.label})
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                );
              }
            })}
            <div className="row">
              <div className="col-sm">
                <div className="form" style={{ overflow: "visible" }}>
                  <Multiselect
                    options={this.state.colors} // Options to display in the dropdown
                    selectedValues={this.state.formInput.selectedValue} // Preselected value to persist in dropdown
                    onSelect={this.onSelect} // Function will trigger on select event
                    onRemove={this.onRemove} // Function will trigger on remove event
                    displayValue="name"
                    closeIcon="cancel"
                    showCheckbox="true"
                    style={{
                      searchBox: {
                        border: "none",
                        padding: 0,
                        marginTop: "20px",
                        height: "50px",
                      },
                      inputField: {
                        paddingTop: 0,
                      },
                      option: {
                        borderBottom: "1px solid #dbdbdb",
                        padding: "0 10px",
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </form>
        </ModalPopup>
        <Datatable
          title="Personality"
          description="You can view berkman personality choice"
          isShow={this.state.loader}
          currentPage={this.state.currentPage}
          pageSize={this.state.perPage}
          dataCount={this.state.arrayCount}
          callbackMethod={this.changePage}
          callbackSelectMethod={this.selectPageSize}
        >
          <div className="row ">
            <div className="col-sm-2">
              <Select
                value={this.state.lang}
                options={LANGUAGE}
                onChange={this.languageHandler}
              />
            </div>
          </div>
          <table className="datatable-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Career Choice</th>
                <th>Color</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.state.personality.length ? (
                this.state.personality.map(
                  (choice: PersonalityT, idx: number) => (
                    <tr key={choice.id}>
                      <td>{choice.id}</td>
                      <td>{choice.name}</td>
                      <td>{this.getColorName(choice.color_id)}</td>
                      <td>
                        <i
                          className="fa fa-edit"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.modalRef.current.updateModal();
                            this.editPersonality(choice);
                          }}
                        ></i>
                        {/* <i
                          className="fa fa-trash"
                          style={{ cursor: "pointer" }}
                          title="Clone"
                          onClick={() => this.deletePersonality(choice.id)}
                        ></i> */}
                      </td>
                    </tr>
                  )
                )
              ) : (
                <tr>
                  <td colSpan={4}>No records found</td>
                </tr>
              )}
            </tbody>
          </table>
        </Datatable>
      </div>
    );
  }
}

export default Personality;
