/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment, useRef } from "react";
import { Datatable, ModalPopup } from "../../components/common";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Api, FileUpload, Helper } from "../../service";
import { MappedCareerT, CareerDetailT, SubCareerT } from "../../interface";
import {
  CAREER,
  CreateURL,
  CAREERDETAIL,
  S3_URL,
  LANGUAGE,
  PAGE_SIZE,
  UPLOAD,
  DATA_BASE_URL
} from "../../constant";
import classnames from "classnames";
import Select from "react-select";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";

const ViewCareerDetail = () => {
  const [loader, showLoader] = useState(false);
  const [mappedCareer, getMappedCareer] = useState<MappedCareerT[]>([]);
  const [careerDetail, getCareerDetail] = useState<CareerDetailT[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [arrayCount, setArrayCount] = useState(0);
  const [activeTab, setActiveTab] = useState("1");
  const [language, setLanguage] = useState(LANGUAGE[0]);
  const modalRef: any = useRef(null);
  const [careerId, getCareerId] = useState(0);
  const [subCareer, getSubCareer] = useState<SubCareerT[]>([]);
  const [isEdit, setisEdit] = useState(false);
  const [overview, getOverview] = useState("");
  const [subCareerId, getSubCareerId] = useState(0);
  const [eligibility, getEligibility] = useState("");
  const [entranceExam, getEntranceExam] = useState("");
  const [specialization, getSpecialization] = useState("");
  const [keyQuality, getKeyQuality] = useState("");
  const [postEffect, getPostEffect] = useState("");
  const [pathway, setPathway] = useState("");
  const [imageBlob, getImage] = useState<Blob>(new Blob());
  const [currentId, setCurrentId] = useState(0);
  const [fields, setFields] = useState([
    {
      overview: "",
      language_code: "",
      eligibility: "",
      entrance_exam: "",
      specialization: "",
      key_quality: "",
      post_effect: "",
    },
  ]);
  const [perPage, setPerPage] = useState(PAGE_SIZE);

  const resetForm = () => {
    setCurrentId(0);
    setFields([
      {
        overview: "",
        language_code: "",
        eligibility: "",
        entrance_exam: "",
        specialization: "",
        key_quality: "",
        post_effect: "",
      },
    ]);
    setisEdit(false);
    getCareerId(0);
    getSubCareerId(0);
    getSubCareer([]);
    getOverview("");
    getEligibility("");
    getEntranceExam("");
    getSpecialization("");
    getKeyQuality("");
    getPostEffect("");
    setPathway("");
  };

  const handleSubmit = (event) => {
    isEdit ? updateCareerDetail(event) : addDetail(event);
  };

  const handleCareerChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let careerId = parseInt(event.target.value);
    getCareerId(careerId);
    const subCareers = mappedCareer.filter((res: MappedCareerT) => {
      return res.id === careerId;
    })[0]["sub_career"];
    getSubCareer(subCareers);
  };

  const handleSubCareerChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    let subCareerId = parseInt(event.target.value);
    getSubCareerId(subCareerId);
  };

  const handleOverview = (value: string) => {
    getOverview(value);
  };

  const handleEligibility = (value: string) => {
    getEligibility(value);
  };

  const handleEntranceExam = (value: string) => {
    getEntranceExam(value);
  };

  const handleSpecialization = (value: string) => {
    getSpecialization(value);
  };

  const handleKeyQuality = (value: string) => {
    getKeyQuality(value);
  };

  const handlePost = (value: string) => {
    getPostEffect(value);
  };

  const handleOverviewChange = (index: number, value: string) => {
    const values = [...fields];
    values[index]["overview"] = value;
    setFields(values);
  };

  const handleEligibilityChange = (index: number, value: string) => {
    const values = [...fields];
    values[index]["eligibility"] = value;
    setFields(values);    
  };

  const handleEntranceExamChange = (index: number, value: string) => {
    const values = [...fields];
    values[index]["entrance_exam"] = value;
    setFields(values);
  };

  const handleSpecializationChange = (index: number, value: string) => {
    const values = [...fields];
    values[index]["specialization"] = value;
    setFields(values);
  };

  const handleKeyQualityChange = (index: number, value: string) => {
    const values = [...fields];
    values[index]["key_quality"] = value;
    setFields(values);
  };

  const handlePostEffectChange = (index: number, value: string) => {
    const values = [...fields];
    values[index]["post_effect"] = value;
    setFields(values);
  };

  const handleInputFieldChange = (index, event) => {
    const values = [...fields];
    values[index][event.target.name] = event.target.value;
    setFields(values);
  };

  const addFields = () => {
    if (fields.length < LANGUAGE.length) {
      setFields([
        ...fields,
        {
          overview: "",
          language_code: "",
          eligibility: "",
          entrance_exam: "",
          specialization: "",
          key_quality: "",
          post_effect: "",
        },
      ]);
    }
  };

  const removeFields = (i) => {
    const values = [...fields];
    if (values.length > 1) {
      values.splice(i, 1);
    }
    setFields(values);
  };

  const onImageUpload = (event: any) => {
    let file = event.target.files[0];
    if (!file) return;
    const fTypes = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
    if (fTypes.every((type) => file.type !== type)) {
      alert(`${file.type} is not a supported format`);
      return;
    }
    getImage(file);
    showImagePreview(file);
  };
  const showImagePreview = (image: Blob) => {
    let previewImg: any = document.getElementById("preview_image");
    previewImg.src = "";
    const reader = new FileReader();
    reader.addEventListener("load", (event: any) => {
      previewImg.src = event.target.result;
    });
    reader.readAsDataURL(image);
  };

  useEffect(() => {
    Api.get(DATA_BASE_URL, `${CAREER.MERGESUBCAREERWITHCAREER}/en`).then((res) => {
      const careers: MappedCareerT[] = res.data;
      getMappedCareer(careers);
    });
  }, []);

  const getPathwayName = (careerId: number, subCareerId: number): string => {
    let pathwayName: string = "";
    let newName: string = "";
    alert(careerId)
    if (careerId) {
      pathwayName = mappedCareer.filter(
        (res: MappedCareerT) => res.id === careerId
      )[0]["name"];
    }
    if (subCareerId) {
      pathwayName = subCareer.filter(
        (res: SubCareerT) => res.id === subCareerId
      )[0]["name"];
    }
    if (pathwayName) {
      newName = Helper.getFileName(pathwayName, imageBlob);
    }
    return newName;
  };

  const handleBlob = (detailObj) => {
    const imgReq: any = {
      url: UPLOAD + "/pathwayen",
      fileName: detailObj.pathway,
      blob: imageBlob,
    };
    let blobReq: any = [{ ...imgReq }];
    return blobReq;
  };

  const addDetail = async (event) => {
    event.preventDefault();
    const detailObj = {
      career_id: careerId,
      sub_career_id: subCareerId,
      pathway: getPathwayName(careerId, subCareerId),
    };
    const object:any = [...fields]
    try {
      showLoader(true);
      const response = await Api.post(DATA_BASE_URL, CAREERDETAIL.DETAIL, detailObj);
      if (response.status === 200) {
        let blobReq = await handleBlob(detailObj);
        object.forEach((r: any) => {
          r["career_detail_id"] = response.data.id;
        });
        await FileUpload(blobReq);
        const translationRes = await Api.post(
          DATA_BASE_URL, 
          CAREERDETAIL.CAREERDETAILBULKUPLOAD,
          object
        );
        const data = translationRes.data.filter(
          (res) => res.language_code === language.value
        );
        delete data[0].career_detail_id;
        delete data[0].id;
        getCareerDetail([{ ...response.data, ...data[0] }, ...careerDetail]);
        resetForm();
        showLoader(false);
      }
    } catch (error) {
      showLoader(false);
      alert(error);
    }
  };

  const editCareerDetail = (careerDetail) => {
    if (!careerDetail.id) return;
    setisEdit(true);
    setCurrentId(careerDetail.id);
    getCareerId(careerDetail.career_id);
    if (careerDetail.career_id) {
      const subCareers = mappedCareer.filter((res: MappedCareerT) => {
        return res.id === careerDetail.career_id;
      })[0]["sub_career"];
      getSubCareer(subCareers);
    } else {
      alert("Career not found");
      setisEdit(false);
    }
    
    getSubCareerId(careerDetail.sub_career_id);
    getOverview(careerDetail.overview);
    getEligibility(careerDetail.eligibility);
    getEntranceExam(careerDetail.entrance_exam);
    getSpecialization(careerDetail.specialization);
    getKeyQuality(careerDetail.key_quality);
    getPostEffect(careerDetail.post_effect);
    setPathway(careerDetail.pathway);
  };

  const updateCareerDetail = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const obj = {
      id: currentId,
      career_id: careerId,
      sub_career_id: subCareerId,
      overview: overview,
      eligibility: eligibility,
      entrance_exam: entranceExam,
      specialization: specialization,
      key_quality: keyQuality,
      post_effect: postEffect,
      language_code: language.value,
      pathway: pathway,
    };
    try {
      showLoader(true);
      await Api.post(DATA_BASE_URL, CAREERDETAIL.UPDATECAREERDETAIL, obj);
      let result = careerDetail.filter((res) => res.id !== currentId);
      if(obj.pathway !== ""){
        let blobReq = await handleBlob(obj);
        await FileUpload(blobReq);
      }
      const ArrayData: any = [...result, obj];
      ArrayData.sort((a: any, b: any) => b.id - a.id);
      getCareerDetail(ArrayData);
      setCurrentPage(currentPage);
      showLoader(false);
    } catch (err) {
      showLoader(false);
      alert(err);
    }
  };

  const changePage = (selectedPage: number) => {
    setCurrentPage(selectedPage);
    getCareerDt(selectedPage, language, perPage);
  };

  const selectPageSize = (event) => {
    setPerPage(parseInt(event.target.value));
    setCurrentPage(1);
    getCareerDt(currentPage, language, parseInt(event.target.value));
  }

  useEffect(() => {
    pageLoad(language);
  }, []);

  const pageLoad = (language: any) => {
    setLanguage(language);
    getMappedCareers(language);
    getCareerDetailCount();
    getCareerDt(currentPage, language, perPage);
  };

  const languageHandler = (language) => {
    pageLoad(language);
  };

  const getMappedCareers = (lang) => {
    showLoader(true);
    Api.get(DATA_BASE_URL, `${CAREER.MERGESUBCAREERWITHCAREER}/${lang.value}`)
      .then((res: any) => {
        showLoader(false);
        const careers: MappedCareerT[] = res.data;
        getMappedCareer(careers);
      })
      .catch((err) => {
        showLoader(false);
        console.log(err);
      });
  };

  const getCareerSubCareerName = (
    careerId: number,
    subCareerId: number | undefined
  ) => {
    const filterCareer: MappedCareerT = mappedCareer.filter(
      (res: MappedCareerT) => res.id === careerId
    )[0];
    const filterSubCareer: SubCareerT =
      filterCareer?.sub_career &&
      filterCareer?.sub_career.filter(
        (res: SubCareerT) => res.id === subCareerId
      )[0];
    return filterSubCareer
      ? `Career: ${filterCareer?.name} \n SubCareer: ${filterSubCareer?.name}`
      : `Career: ${filterCareer?.name}`;
  };

  const getCareerDetailCount = async () => {
    try {
      const countRes = await Api.get(DATA_BASE_URL, CAREERDETAIL.COUNT);
      if (countRes?.data) {
        setArrayCount(countRes?.data?.count);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getCareerDt = async (currentPage, language, pageSize) => {
    setLanguage(language);
    try {
      showLoader(true);
      let limit = pageSize
      let skip = (currentPage - 1) * limit;
      const res = await Api.get(
        DATA_BASE_URL, 
        `${CAREERDETAIL.CAREERDETAILS}/'${language.value}'/${limit}/${skip}`
      );
      if (res?.data) {
        getCareerDetail(res?.data);
      }
      showLoader(false);
    } catch (error) {
      showLoader(false);
      alert(error)
      console.log(JSON.stringify(error));
    }
  };

  const deleteCareerDetail = (id: number | undefined) => {
    if (!id) return false;
    Swal.fire({
      title: "Are you sure?",
      text: `You will not be able to recover the career-detail with ID ${id}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        Api.delete(DATA_BASE_URL, CreateURL(CAREERDETAIL.DELETE, { id: id }))
          .then((res) => {
            if (res.status === 204) {
              const updatedResult = careerDetail.filter((r) => r.id !== id);
              getCareerDetail(updatedResult);

              Swal.fire(
                "Deleted!",
                `Career detail ID ${id} has been deleted.`,
                "success"
              );
            }
          })
          .catch((error) => {
            alert(error);
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          "Cancelled",
          `Your Career detail ID ${id} detail is safe :)`,
          "info"
        );
      }
    });
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleDetail = (index: number) => {
    let infoDiv: any = document.getElementById("accordion" + index);
    let trBlock: any = document.getElementById("hide-table-padding" + index);
    let classArray = Array.from(infoDiv?.classList);
    if (classArray.indexOf("collapse") !== -1) {
      infoDiv?.classList.remove("collapse");
      infoDiv?.parentElement?.parentElement?.previousSibling?.classList.remove(
        "shrink"
      );
      trBlock?.classList.remove("d-none");
    } else {
      infoDiv?.classList.add("collapse");
      infoDiv?.parentElement?.parentElement?.previousSibling?.classList.add(
        "shrink"
      );
      trBlock?.classList.add("d-none");
    }
  };

  return (
    <div className="component-content">
      <ModalPopup
        className="primary career-details-modal"
        ref={modalRef}
        buttonLabel="Add More"
        popupTitle={`${isEdit ? "Edit" : "Add"} career-detail`}
        reset={resetForm}
        callbackMethod={(event: React.FormEvent<HTMLFormElement>) => {
          handleSubmit(event);
        }}
      >
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-2">
              <label className="label-name">Career Name</label>
            </div>
            <div className="col-sm-4">
              <select
                style={{ height: "50px" }}
                name="career_id"
                value={careerId}
                onChange={(event) => {
                  handleCareerChange(event);
                }}
                required
              >
                <option value="">Select career</option>
                {mappedCareer.map((car: MappedCareerT) => (
                  <option key={car.id + car.name} value={car.id}>
                    {car.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className={subCareer.length ? "row mt-2" : "row d-none"}>
            <div className="col-sm-2">
              <label className="label-name">Sub Career Name</label>
            </div>
            <div className="col-sm-4">
              <select
                style={{ height: "50px" }}
                name="sub_career_id"
                value={subCareerId}
                onChange={(event) => {
                  handleSubCareerChange(event);
                }}
              >
                <option value="">Select sub career</option>
                {subCareer.map((car: SubCareerT) => (
                  <option key={car.id + car.name} value={car.id}>
                    {car.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {isEdit ? (
            <>
              <div className="row mb-1">
                <div className="col-sm-2">
                  <label className="label-name">Overview</label>
                </div>
                <div className="col-sm-10">
                  <ReactQuill value={overview} onChange={handleOverview} />
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-sm-2">
                  <label className="label-name">Eligibility</label>
                </div>
                <div className="col-sm-10">
                  <ReactQuill
                    value={eligibility}
                    onChange={handleEligibility}
                  />
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-sm-2">
                  <label className="label-name">Entrace Exam</label>
                </div>
                <div className="col-sm-10">
                  <ReactQuill
                    value={entranceExam}
                    onChange={handleEntranceExam}
                  />
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-sm-2">
                  <label className="label-name">Specialization</label>
                </div>
                <div className="col-sm-10">
                  <ReactQuill
                    value={specialization}
                    onChange={handleSpecialization}
                  />
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-sm-2">
                  <label className="label-name">Key Quality</label>
                </div>
                <div className="col-sm-10">
                  <ReactQuill value={keyQuality} onChange={handleKeyQuality} />
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-sm-2">
                  <label className="label-name">Post Effect</label>
                </div>
                <div className="col-sm-10">
                  <ReactQuill value={postEffect} onChange={handlePost} />
                </div>
              </div>
            </>
          ) : (
            <div>
              {fields.map((field, idx) => (
                <div key={idx}>
                  <div className="row my-2">
                    <div className="col-sm-2">
                      <label className="label-name">Language</label>
                    </div>
                    <div className="col-sm-4">
                      <select
                        style={{ height: "50px" }}
                        name="language_code"
                        value={field.language_code}
                        onChange={(event) => handleInputFieldChange(idx, event)}
                        required
                      >
                        <option value="">Select language</option>
                        {LANGUAGE.map((l: any) => (
                          <option key={l.value} value={l.value}>
                            {l.label}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-sm-1 icons">
                      <i className="fa fa-plus" onClick={addFields}></i>
                    </div>
                    <div className="col-sm-1 icons">
                      <i
                        className="fa fa-minus align-items-center"
                        onClick={() => removeFields(idx)}
                      ></i>
                    </div>
                  </div>
                  <div className="row mb-1">
                    <div className="col-sm-2">
                      <label className="label-name">Overview</label>
                    </div>
                    <div className="col-sm-10">
                      <ReactQuill
                        value={field.overview}
                        onChange={(event) => handleOverviewChange(idx, event)}
                      />
                    </div>
                  </div>
                  <div className="row mb-1">
                    <div className="col-sm-2">
                      <label className="label-name">Eligibility</label>
                    </div>
                    <div className="col-sm-10">
                      <ReactQuill
                        value={field.eligibility}
                        onChange={(event) =>
                          handleEligibilityChange(idx, event)
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-1">
                    <div className="col-sm-2">
                      <label className="label-name">Entrance Exam</label>
                    </div>
                    <div className="col-sm-10">
                      <ReactQuill
                        value={field.entrance_exam}
                        onChange={(event) =>
                          handleEntranceExamChange(idx, event)
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-1">
                    <div className="col-sm-2">
                      <label className="label-name">Specialization</label>
                    </div>
                    <div className="col-sm-10">
                      <ReactQuill
                        value={field.specialization}
                        onChange={(event) =>
                          handleSpecializationChange(idx, event)
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-1">
                    <div className="col-sm-2">
                      <label className="label-name">Key Quality</label>
                    </div>
                    <div className="col-sm-10">
                      <ReactQuill
                        value={field.key_quality}
                        onChange={(event) => handleKeyQualityChange(idx, event)}
                      />
                    </div>
                  </div>
                  <div className="row mb-1">
                    <div className="col-sm-2">
                      <label className="label-name">Post Effect</label>
                    </div>
                    <div className="col-sm-10">
                      <ReactQuill
                        value={field.post_effect}
                        onChange={(event) => handlePostEffectChange(idx, event)}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="row mb-2">
            <div className="col-sm-2">
              <label className="label-name">Upload Pathway</label>
            </div>
            <div className="col-sm-4">
              <input type="file" accept="image/*" onChange={onImageUpload} />
            </div>
            <div className="col-sm-3">
              <img
                id="preview_image"
                src={
                  pathway
                    ? S3_URL + "pathway/en/" + pathway
                    : require("../../assets/images/dummy.png").default
                }
                width="100"
                height="65"
                alt=""
              />
            </div>
          </div>
        </form>
      </ModalPopup>
      <Datatable
        title="Career Detail"
        description="You can view career detail"
        isShow={loader}
        currentPage={currentPage}
        pageSize={perPage}
        dataCount={arrayCount}
        callbackMethod={changePage}
        callbackSelectMethod={selectPageSize}
      >
        <div className="row ">
          <div className="col-sm-2">
            <Select
              value={language}
              options={LANGUAGE}
              onChange={languageHandler}
            />
          </div>
        </div>
        <table className="datatable-table">
          <thead>
            <tr>
              <th style={{ width: "10%" }}>#</th>
              <th style={{ width: "40%" }}>Career Detail</th>
              <th>Pathway</th>
              <th>More</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {careerDetail.length ? (
              careerDetail.map((res: CareerDetailT, idx: number) => (
                <Fragment key={res.id}>
                  <tr className="accordion-toggle collapsed shrink">
                    <td>{res.id}</td>
                    <td>
                      {getCareerSubCareerName(res.career_id, res.sub_career_id)}
                    </td>
                    <td>
                      <a
                        href={S3_URL + "pathway/en/" + res.pathway}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={S3_URL + "pathway/en/" + res.pathway}
                          alt={res.pathway}
                          width="65"
                          height="60"
                        />
                      </a>
                    </td>
                    <td
                      className="expand-button"
                      onClick={() => toggleDetail(idx)}
                    ></td>
                    <td>
                      <i
                        className="fa fa-edit"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          modalRef.current?.updateModal();
                          editCareerDetail(res);
                        }}
                      ></i>
                      <br />
                      <i
                        className="fa fa-trash"
                        onClick={() => deleteCareerDetail(res.id)}
                      ></i>
                    </td>
                  </tr>
                  <tr
                    id={"hide-table-padding" + idx}
                    style={{ backgroundColor: "#eeeeee" }}
                    className="d-none"
                  >
                    <td colSpan={4}>
                      <div id={"accordion" + idx} className="collapse in">
                        <Nav tabs>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "1",
                              })}
                              onClick={() => {
                                toggle("1");
                              }}
                            >
                              Overview
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "2",
                              })}
                              onClick={() => {
                                toggle("2");
                              }}
                            >
                              Eligibility
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "3",
                              })}
                              onClick={() => {
                                toggle("3");
                              }}
                            >
                              Entrance Exam
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "4",
                              })}
                              onClick={() => {
                                toggle("4");
                              }}
                            >
                              Specialization
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "5",
                              })}
                              onClick={() => {
                                toggle("5");
                              }}
                            >
                              Key Quality
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "6",
                              })}
                              onClick={() => {
                                toggle("6");
                              }}
                            >
                              Post Effect
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "7",
                              })}
                              onClick={() => {
                                toggle("7");
                              }}
                            >
                              Skills Needed
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent
                          activeTab={activeTab}
                          style={{ padding: "15px 30px" }}
                        >
                          <TabPane tabId="1">
                            <div className="row">
                              <div
                                className="col-sm-12"
                                dangerouslySetInnerHTML={{
                                  __html: res.overview,
                                }}
                              />
                            </div>
                          </TabPane>
                          <TabPane tabId="2">
                            <div className="row">
                              <div
                                className="col-sm-12"
                                dangerouslySetInnerHTML={{
                                  __html: res.eligibility,
                                }}
                              />
                            </div>
                          </TabPane>
                          <TabPane tabId="3">
                            <div className="row">
                              <div
                                className="col-sm-12"
                                dangerouslySetInnerHTML={{
                                  __html: res.entrance_exam,
                                }}
                              />
                            </div>
                          </TabPane>
                          <TabPane tabId="4">
                            <div className="row">
                              <div
                                className="col-sm-12"
                                dangerouslySetInnerHTML={{
                                  __html: res.specialization,
                                }}
                              />
                            </div>
                          </TabPane>
                          <TabPane tabId="5">
                            <div className="row">
                              <div
                                className="col-sm-12"
                                dangerouslySetInnerHTML={{
                                  __html: res.key_quality,
                                }}
                              />
                            </div>
                          </TabPane>
                          <TabPane tabId="6">
                            <div className="row">
                              <div
                                className="col-sm-12"
                                dangerouslySetInnerHTML={{
                                  __html: res.post_effect,
                                }}
                              />
                            </div>
                          </TabPane>
                          <TabPane tabId="7">
                            <div className="row">
                              <div
                                className="col-sm-12"
                                dangerouslySetInnerHTML={{
                                  __html: res.skills_needed,
                                }}
                              />
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                    </td>
                  </tr>
                </Fragment>
              ))
            ) : (
              <tr>
                <td colSpan={7}>No records found</td>
              </tr>
            )}
          </tbody>
        </table>
      </Datatable>
    </div>
  );
};

export default ViewCareerDetail;
