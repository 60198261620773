import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export function ScheduleEmailModal(props) {
  const { configObj, modalState } = props;
  const startCron = () => {};
  return (
    <Modal className="user-modal" isOpen={modalState} size="lg">
      <ModalHeader toggle={modalState}>{"jhjkj"}</ModalHeader>
      <ModalBody className="csv-modal-body">
        <form>
          <input />
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          size="sm"
          onClick={() => startCron()}
          disabled={configObj["email"] === ""}
        >
          <i className="fa fa-envelope" /> Start CRON
        </Button>
        {""}
        <Button color="warning" size="sm" onClick={() => props.openCronModal()}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}
