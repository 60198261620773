import React, { createRef } from "react";
import { ModalPopup, Datatable } from "../../components/common";
import { Api } from "../../service";
import {
  CreateURL,
  GRADE,
  LANGUAGE,
  PAGE_SIZE,
  DATA_BASE_URL,
} from "../../constant";
import { GradeT, LanguageT } from "../../interface";
import Select from "react-select";
class Grade extends React.Component {
  state = {
    formInput: {
      standard_en: "",
      standard_hi: "",
      standard: "",
      name: "",
      name_en: "",
      name_hi: "",
    },
    grades: [],
    currentId: 0,
    loader: false,
    showInput: false,
    perPage: PAGE_SIZE,
    currentPage: 1,
    arrayCount: 0,
    lang: LANGUAGE[0],
  };
  isEdit = false;
  modalRef: any = createRef();
  languageHandler = (lang) => {
    this.getGrades(this.state.currentPage, this.state.perPage, lang);
    this.state.lang = lang;
  };
  handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.updateStateValue<React.ChangeEvent<HTMLSelectElement>>(event);
    if (event.target.value === "Add More") {
      this.setState({ showInput: true });
      this.setState({
        formInput: {
          standard_en: "",
          standard_hi: "",
          standard: "",
        },
      });
    } else {
      const standard = event.target.value;
      const standardArr = standard.split("-");
      const standardEn = standardArr[0];
      const standardHi = standardArr[1];
      this.setState({ showInput: false });
      this.setState({
        formInput: {
          standard_en: standardEn,
          standard_hi: standardHi,
          standard: standard,
        },
      });
    }
  };
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.updateStateValue<React.ChangeEvent<HTMLInputElement>>(event);
  };
  updateStateValue = <T,>(event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      formInput: {
        ...this.state.formInput,
        [name]: value,
      },
    });
  };
  componentDidMount() {
    this.getGrades(this.state.currentPage, this.state.perPage, this.state.lang);
    this.getCount();
  }
  getCount = () => {
    Api.get(DATA_BASE_URL, GRADE.COUNT)
      .then((res) => {
        this.setState({ arrayCount: res.data.count });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  changePage = (selectedPage: number) => {
    this.setState({ currentPage: selectedPage });
    this.getGrades(selectedPage, this.state.perPage, this.state.lang);
  };
  selectPageSize = (event) => {
    this.setState({
      perPage: parseInt(event.target.value),
      currentPage: 1,
    });
    this.getGrades(
      this.state.currentPage,
      parseInt(event.target.value),
      this.state.lang
    );
  };
  getGrades = (currentPage, pageSize, lang) => {
    this.setState({ loader: true });
    let limit = pageSize;
    let skip = (currentPage - 1) * limit;
    this.setState({ loader: true });
    Api.get(
      DATA_BASE_URL,
      `${GRADE.GRADE}?filter[where][language_code]=${lang.value}&filter[limit]=${limit}&filter[skip]=${skip}`
    )
      .then((res) => {
        const grades: GradeT[] = res.data;
        this.setState({ grades: grades, loader: false });
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({ loader: false });
      });
  };
  handleSubmit = (e: any) => {
    this.isEdit ? this.updateGradeById(e) : this.addGrade(e);
  };
  addGrade = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const setData = (grades: GradeT) => {
      this.setState({
        formInput: {
          name_en: grades.name_en,
          name_hi: grades.name_hi,
          name: grades.name,
          standard: grades.standard,
        },
      });
    };
    // const gradeObj: GradeT = { ...this.state.formInput };
    const gradeObj = {};
    Api.post(DATA_BASE_URL, GRADE.GRADE, gradeObj)
      .then((res) => {
        const standard = this.state.formInput.standard.split("-");
        const gradeObjInst = [
          {
            grade_id: res.data.id,
            language_code: "en",
            standard: standard[0],
            name: this.state.formInput.name_en,
          },
          {
            grade_id: res.data.id,
            language_code: "hi",
            standard: standard[1],
            name: this.state.formInput.name_hi,
          },
        ];
        Api.post(DATA_BASE_URL, GRADE.GRADETRANSLATION, gradeObjInst)
          .then((resT) => {
            console.log("added");
          })
          .catch((error) => {
            console.log(error.message);
          });
        if (res.status === 200) {
          this.setState({
            grades: [...this.state.grades, res.data],
            loader: false,
          });
          this.getGrades(
            this.state.currentPage,
            this.state.perPage,
            this.state.lang
          );
          this.resetForm();
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  editGrade = (grade: GradeT) => {
    if (!grade.id) return;
    this.isEdit = true;
    this.setState({ currentId: grade.id });

    this.setState({
      formInput: {
        standard_en: grade.standard_en,
        standard_hi: grade.standard_hi,
        standard: grade.standard,
        name: grade.name,
        name_en: grade.name_en,
        name_hi: grade.name_hi,
      },
    });
  };
  updateGradeById = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const gradeObj: GradeT = { ...this.state.formInput };
    Api.patch(
      DATA_BASE_URL,
      CreateURL(GRADE.GRADEBYID, { id: this.state.currentId }),
      gradeObj
    )
      .then((res) => {
        if (res.status === 204) {
          const result = this.state.grades.filter(
            (res: any) => res.id !== this.state.currentId
          );
          const Array = [...result, { id: this.state.currentId, ...gradeObj }];
          Array.sort((a, b) => a.id - b.id);
          this.setState({ grades: Array, loader: false });
          this.resetForm();
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  deleteGrade = (grade_id: number | undefined) => {
    if (!grade_id) return;
    this.setState({ loader: true });
    Api.delete(DATA_BASE_URL, CreateURL(GRADE.DELETE, { id: grade_id }))
      .then((res) => {
        if (res.status === 204) {
          const result = this.state.grades.filter((res: any) => {
            return res.id !== grade_id;
          });
          this.setState({
            grades: result,
            loader: false,
            currentPage: this.state.currentPage,
          });
        }
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({ loader: false });
      });
  };
  resetForm = () => {
    this.isEdit = false;
    this.setState({ showInput: false });
    this.setState({
      formInput: {
        standard_en: "",
        standard_hi: "",
        name_en: "",
        name_hi: "",
      },
    });
  };
  render() {
    return (
      <div className="component-content">
        <ModalPopup
          ref={this.modalRef}
          className="primary"
          buttonLabel="Add More"
          reset={this.resetForm}
          popupTitle={`${this.isEdit ? "Edit" : "Add"} Grade`}
          callbackMethod={(event: React.FormEvent<HTMLFormElement>) =>
            this.handleSubmit(event)
          }
        >
          <form onSubmit={this.handleSubmit}>
            {LANGUAGE.map((lang: LanguageT) => {
              if (this.isEdit && lang.value === this.state.lang.value) {
                return (
                  <div key={lang.value}>
                    {" "}
                    <div className="row">
                      <div className="col-sm">
                        <div className="form">
                          <select
                            id="standard"
                            name="standard"
                            value={
                              this.state.formInput.standard_en +
                              "-" +
                              this.state.formInput.standard_hi
                            }
                            onChange={this.handleSelectChange}
                            required
                          >
                            <option value="">Select standard</option>
                            <option value="School-विद्यालय">
                              School(विद्यालय)
                            </option>
                            <option value="Higher Education-उच्च शिक्षा">
                              Higher Education(उच्च शिक्षा)
                            </option>
                            <option value="Working-कार्यरत">
                              Working(कार्यरत)
                            </option>
                            <option value="Add More">Add more</option>
                          </select>
                          <label className="label-name">
                            <span className="content-name">Standard</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <fieldset>
                      <legend>{lang.label}</legend>
                      <div className="row">
                        <div className="col-sm">
                          <div className="form ">
                            <input
                              type="text"
                              name={`name`}
                              value={this.state.formInput.name}
                              onChange={this.handleChange}
                              required
                            />
                            <label className="label-name">
                              <span className="content-name">Name</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </fieldset>{" "}
                  </div>
                );
              }
              if (!this.isEdit) {
                return (
                  <div key={lang.value}>
                    <div className="row">
                      <div className="col-sm">
                        <div className="form">
                          <select
                            id="standard"
                            name="standard"
                            value={
                              this.state.formInput.standard_en +
                              "-" +
                              this.state.formInput.standard_hi
                            }
                            onChange={this.handleSelectChange}
                            required
                          >
                            <option value="">Select standard</option>
                            <option value="School-विद्यालय">
                              School(विद्यालय)
                            </option>
                            <option value="Higher Education-उच्च शिक्षा">
                              Higher Education(उच्च शिक्षा)
                            </option>
                            <option value="Working-कार्यरत">
                              Working(कार्यरत)
                            </option>
                            <option value="Add More">Add more</option>
                          </select>
                          <label className="label-name">
                            <span className="content-name">Standard</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <fieldset>
                      <legend>{lang.label}</legend>
                      <div className="row">
                        <div className="col-sm">
                          <div className="form ">
                            <input
                              type="text"
                              name={`name_${lang.value}`}
                              onChange={this.handleChange}
                              required
                            />
                            <label className="label-name">
                              <span className="content-name">Name</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                );
              }
            })}
            {this.state.showInput
              ? LANGUAGE.map((lang: LanguageT) => (
                  <div key={lang.value} className="row">
                    <div className="col-sm">
                      <div className="form">
                        <input
                          type="text"
                          name={`standard_${lang.value}`}
                          value={
                            lang.value === "en"
                              ? this.state.formInput.standard_en
                              : this.state.formInput.standard_hi
                          }
                          onChange={this.handleChange}
                          required
                        />
                        <label className="label-name">
                          <span className="content-name">
                            Standard({lang.label})
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </form>
        </ModalPopup>
        <Datatable
          title="Grade"
          description="You can view grades"
          isShow={this.state.loader}
          dataCount={this.state.arrayCount}
          currentPage={this.state.currentPage}
          pageSize={this.state.perPage}
          callbackMethod={this.changePage}
          callbackSelectMethod={this.selectPageSize}
        >
          <div className="row ">
            <div className="col-sm-2">
              <Select
                value={this.state.lang}
                options={LANGUAGE}
                onChange={this.languageHandler}
              />
            </div>
          </div>
          <table className="datatable-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Standard</th>
                <th>Grade Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.state.grades.length ? (
                this.state.grades.map((grade: GradeT, i) => (
                  <tr key={grade.id}>
                    <td>{grade.id}</td>
                    <td>{grade.standard}</td>
                    <td>{grade.name}</td>
                    <td>
                      <i
                        className="fa fa-edit"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.editGrade(grade);
                          this.modalRef.current.updateModal();
                        }}
                      ></i>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5}>No records found</td>
                </tr>
              )}
            </tbody>
          </table>
        </Datatable>
      </div>
    );
  }
}

export default Grade;
