import React, { useState, useEffect, useRef } from "react";
import { ModalPopup, Datatable } from "../components/common";
import { Api } from "../service";
import { WebinarURLT } from "../interface";
import {
  CreateURL,
  WEBINARURL,
  PAGE_SIZE,
  USER_BASE_URL
} from "../constant";

const WebinarURL = () => {
  const [loader, showLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [arrayCount, setArrayCount] = useState(0);
  const [isEdit, setisEdit] = useState(false);
  const [perPage, setPerPage] = useState(PAGE_SIZE);
  const [currentId, setId] = useState(0);
  const [speakerName, setSpeakerName] = useState("");
  const [webinarURL, setWebinarURL] = useState("");
  const [urls, setUrls] = useState<WebinarURLT[]>([]);
  const modalRef: any = useRef(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, inputType: string) => {
    switch(inputType){
      case "speakerName":
        setSpeakerName(e.target.value);
        break;
      case 'webinarURL':
        setWebinarURL(e.target.value);
        break;
    }
  }

  const handleSubmit = (e: any) => {
    isEdit ? updateWebinarDetails(e) : addWebinarURL(e);
  };

  const resetForm = () => {
    setisEdit(false);
    setSpeakerName("");
    setWebinarURL("");
  }

  const changePage = (selectedPage: number) => {
    setCurrentPage(selectedPage);
    getWebinarURL(selectedPage, perPage);
  };

  const selectPageSize = (event) => {
    setPerPage(parseInt(event.target.value));
    setCurrentPage(1);
    getWebinarURL(currentPage, parseInt(event.target.value));
  };

  const getWebinarURL = async (currentPage: number = 1, pageSize: number) => {
    showLoader(true);
    let skip = (currentPage - 1) * pageSize;
    try{
      const {data} = await Api.get(
        USER_BASE_URL, 
        `${WEBINARURL.GET}?filter[limit]=${pageSize}&filter[skip]=${skip}`,
        );
        showLoader(false);
        setUrls(data);
        setCurrentPage(currentPage);
    }catch(error){
      showLoader(false);
      alert(error?.message);
    }
  }

  const getWebinarURLCount = async () => {
    try{
      const {data} = await Api.get(USER_BASE_URL, WEBINARURL.COUNT);
      setArrayCount(data.count);
    }catch(error){
      alert(error?.message);
    }
  }

  const addWebinarURL = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const wObj: WebinarURLT = {
        speaker_name: speakerName,
        url: webinarURL,
      };
      const {data, status} = await Api.post(USER_BASE_URL, WEBINARURL.CREATE, wObj);
      if (status === 200) {
        setUrls([...urls, data]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const editWebinarURL = (wObj: WebinarURLT) => {
    if (!wObj.id) return;
    setId(wObj.id);
    setisEdit(true);
    setSpeakerName(wObj.speaker_name);
    setWebinarURL(wObj.url);
  };

  const updateWebinarDetails = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const pObj: WebinarURLT = {
        speaker_name: speakerName,
        url: webinarURL,
      };
      const response = await Api.patch(
        USER_BASE_URL, 
        CreateURL(WEBINARURL.UPDATE, { id: currentId }),
        pObj
      );
      if (response.status === 204) {
        const result = urls.filter(res => res.id !== currentId);
        const ArrayData = [...result, {...pObj, id: currentId}]
        ArrayData.sort((a:any,b:any) => a.id - b.id);
        setUrls(ArrayData);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getWebinarURL(currentPage,perPage);
    getWebinarURLCount();
  }, [currentPage, perPage]);

 
  
  return (
    
    <div className="component-content">
      <ModalPopup
        className="primary"
        isHide={true}
        ref={modalRef}
        buttonLabel="Add More"
        popupTitle={`${isEdit ? "Edit" : "Add"} Webinar URL`}
        reset={resetForm}
        callbackMethod={(event: React.FormEvent<HTMLFormElement>) => {
          handleSubmit(event);
          resetForm();
        }}
      >
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-12">
                <div className="form">
                  <input
                    type="text"
                    name="speakerName"
                    value={speakerName}
                    onChange={(e) => handleChange(e, "speakerName")}
                    required
                  />
                  <label className="label-name">
                    <span className="content-name">Speaker Name</span>
                  </label>
                </div>
            </div>
            <div className="col-sm-12">
                <div className="form">
                  <input
                    type="text"
                    name="webinarURL"
                    value={webinarURL}
                    onChange={(e) => handleChange(e, "webinarURL")}
                    required
                  />
                  <label className="label-name">
                    <span className="content-name">Webinar URL</span>
                  </label>
                </div>
            </div>
          </div>
        </form>
      </ModalPopup>
      <Datatable
        title="Webinar URL"
        description="Track all monthly webinar URL"
        isShow={loader}
        currentPage={currentPage}
        pageSize={PAGE_SIZE}
        dataCount={arrayCount}
        callbackMethod={changePage}
        callbackSelectMethod={selectPageSize}
      >
        <table className="datatable-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Speaker Name</th>
              <th>Webinar URL</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {urls.length ? (
              urls.map((p: WebinarURLT) => (
                <tr key={p.id}>
                  <td>{p.id}</td>
                  <td>{p.speaker_name}</td>
                  <td>{p.url}</td>
                  <td>
                    <i
                      className="fa fa-edit"
                      style={{ cursor: "pointer"}}
                      onClick={() => {
                        modalRef.current?.updateModal();
                        editWebinarURL(p);
                      }}
                    ></i>
                    
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3}>No records found</td>
              </tr>
            )}
          </tbody>
        </table>
      </Datatable>
    </div>
  );
};

export default WebinarURL;
