/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { ModalPopup, Datatable } from "../../components/common";
import { Api, ExportExcel } from "../../service";
import { EventPopupT } from "../../interface";
import { CreateURL, EVENTPOPUP, LANGUAGE, PAGE_SIZE, USER_BASE_URL } from "../../constant";
import dateFormat from "dateformat";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import ReactQuill from "react-quill";
import Parser from "html-react-parser";
import Switch from "react-switch";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";

const EventPopup = () => {
  const [loader, showLoader] = useState(false);
  const [eventPopups, setEventPopups] = useState<EventPopupT[]>([]);
  const [currentId, setId] = useState(0);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [tagLine, setTagLine] = useState("");
  const [btnText, setBtnText] = useState("");
  const [eventUrl, setEventUrl] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventTime, setEventTime] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [screenName, setScreenName] = useState("");
  const [isExternal, setIsExternal] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [arrayCount, setArrayCount] = useState(0);
  const [focusedInput, setFocusedInput] = useState(null);
  const [isEdit, setisEdit] = useState(false);
  const [isClone, setIsClone] = useState(false);
  const modalRef: any = useRef(null);
  const [searchItem, setSearchItem] = useState("");
  const [language, setLanguage] = useState(LANGUAGE[0]);
  const [perPage, setPerPage] = useState(PAGE_SIZE);
  const [fields, setFields] = useState([
    { title: "", language_code: "", description: "", tagline: "", btntext: "" },
  ]);
  const isActive = true;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case "title":
        setTitle(event.target.value);
        break;
      case "tagline":
        setTagLine(event.target.value);
        break;
      case "btntext":
        setBtnText(event.target.value);
        break;
      case "event_date":
        setEventDate(event.target.value);
        break;
      case "event_time":
        setEventTime(event.target.value);
        break;
      case "event_url":
        setEventUrl(event.target.value);
        break;
      case "start_date":
        setStartDate(event.target.value);
        break;
      case "end_date":
        setEndDate(event.target.value);
        break;
    }
  };

  const handleDescriptionChange = (value) => {
    setDescription(value);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    switch (event.target.name) {
      case "event_display_screen":
        setScreenName(event.target.value);
        break;
      case "event_url":
        setEventUrl(event.target.value);
        break;
    }
  };
  const resetForm = () => {
    setisEdit(false);
    setIsClone(false);
    setTitle("");
    setDescription("");
    setEventTime("");
    setEventDate("");
    setEventUrl("");
    setStartDate("");
    setEndDate("");
    setScreenName("");
    setIsExternal(false);
    setTagLine("");
    setBtnText("");
    setId(0);
    setFields([
      {
        title: "",
        language_code: "",
        description: "",
        tagline: "",
        btntext: "",
      },
    ]);
  };

  const changePage = (selectedPage: number) => {
    setCurrentPage(selectedPage);
    getEventPopup(selectedPage, language, perPage);
  };

  const selectPageSize = (event) => {
    setPerPage(parseInt(event.target.value));
    setCurrentPage(1);
    getEventPopup(currentPage, language, parseInt(event.target.value));
  };

  useEffect(() => {
    pageLoad(language);
  }, []);

  const pageLoad = (lang) => {
    setLanguage(lang);
    getEventPopup(currentPage, lang, perPage);
    getCount();
  };

  const languageHandler = (lang: any) => {
    pageLoad(lang);
    setCurrentPage(1);
  };

  const handleInputFieldChange = (index, event) => {
    const values = [...fields];
    values[index][event.target.name] = event.target.value;
    setFields(values);
  };

  const handleInputDescriptionChange = (value: string, index: number) => {
    const values = [...fields];
    values[index]["description"] = value;
    setFields(values);
  };

  const addFields = () => {
    if (fields.length < LANGUAGE.length) {
      setFields([
        ...fields,
        {
          title: "",
          language_code: "",
          description: "",
          tagline: "",
          btntext: "",
        },
      ]);
    }
  };

  const removeFields = (i) => {
    const values = [...fields];
    if (values.length > 1) {
      values.splice(i, 1);
    }
    setFields(values);
  };

  const getEventPopup = (currentPage, language, pageSize) => {
    let limit = pageSize;
    let skip = (currentPage - 1) * limit;
    showLoader(true);
    Api.get(USER_BASE_URL,
      `${EVENTPOPUP.EVENTPOPUPLIST}/'${language.value}'/null/${limit}/${skip}`
    )
      .then((res: any) => {
        const eventPopups: EventPopupT[] = res.data;
        setEventPopups(eventPopups);
        showLoader(false);
      })
      .catch((error) => {
        showLoader(false);
        alert(error.message);
      });
  };

  const getCount = () => {
    Api.get(USER_BASE_URL,`${EVENTPOPUP.COUNT}`)
      .then((res: any) => {
        setArrayCount(res.data.count);
      })
      .catch((error) => {
        showLoader(false);
        alert(error.message);
      });
  };

  const handleSearch = async (event) => {
    let cancelToken: any;
    let search = event.target.value || null;
    setSearchItem(search);
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    try {
      let limit = perPage;
      let skip = (currentPage - 1) * limit;
      const events = await Api.search(
        USER_BASE_URL,
        `${EVENTPOPUP.EVENTPOPUPLIST}/'${language.value}'/${search}/${limit}/${skip}`,
        { cancelToken: cancelToken.token }
      );
      if (events?.data?.length > 0 && search !== null) {
        setEventPopups(events.data);
        setArrayCount(0);
      } else{
        setEventPopups(events.data);
        getCount();
      } 
    } catch (error) {
      console.log(error);
      
    }
  };

  const handleSubmit = (e: any) => {
    isEdit ? updateEventPopupsById(e) : addEventPopups(e);
  };

  const addEventPopups = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const epObj = {
        event_date: eventDate,
        event_time: eventTime,
        event_url: eventUrl,
        start_date: startDate,
        end_date: endDate,
        is_active: true,
        event_display_screen: screenName,
        is_external_url: isExternal,
      };
      const object = [...fields];
      const addresponse = await Api.post(USER_BASE_URL, EVENTPOPUP.EVENTPOPUP, epObj);
      object.forEach((res) => (res["event_popup_id"] = addresponse.data.id));
      const addTranslationRes = await Api.post(
        USER_BASE_URL,
        EVENTPOPUP.EVENTPOPUPTRANSLATIONBULKUPLOAD,
        object
      );
      const data = addTranslationRes.data.filter(
        (res) => res.language_code === language.value
      );
      delete data[0].id;
      delete data[0].event_popup_id;
      setEventPopups([{ ...addresponse.data, ...data[0] }, ...eventPopups]);
      setIsClone(false);
    } catch (error) {
      console.log(error);
    }
  };

  const editEventPopups = (epObj) => {
    if (!epObj.id) return;
    setisEdit(true);
    setId(epObj.id);
    setTitle(epObj.title);
    setDescription(epObj.description);
    setTagLine(epObj.tagline);
    setBtnText(epObj.btntext);
    setEventDate(epObj.event_date);
    setEventTime(epObj.event_time);
    setEventUrl(epObj.event_url);
    setStartDate(epObj.start_date);
    setEndDate(epObj.end_date);
    setScreenName(epObj.event_display_screen);
    setIsExternal(epObj.is_external_url === 0 ? false : true);
  };

  const cloneEventPopups = async (epObj) => {
    if (!epObj.id) return;
    setIsClone(true);
    setId(epObj.id);
    setTitle(epObj.title);
    setDescription(epObj.description);
    setTagLine(epObj.tagline);
    setBtnText(epObj.btnText);
    const res = await Api.get(
      USER_BASE_URL,
      `${EVENTPOPUP.EVENTPOPUPTRANSLATION}?filter[where][event_popup_id]=${epObj.id}`
    );
    const field: any = [];
    res.data.map((res: any) =>
      field.push({
        title: res.title,
        description: res.description,
        tagline: res.tagline,
        btntext: res.btntext,
        language_code: res.language_code,
      })
    );
    setFields(field);
    setScreenName(epObj.event_display_screen);
    setIsExternal(epObj.is_external_url === 1 ? true : false);
    setEventUrl(epObj.event_url);
    setStartDate("");
    setEndDate("");
    setEventDate("");
    setEventTime("");
  };

  const updateEventPopupsById = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    try {
      const epObj = {
        id: currentId,
        event_date: eventDate,
        event_time: eventTime,
        event_url: eventUrl,
        start_date: startDate,
        end_date: endDate,
        is_active: true,
        event_display_screen: screenName,
        is_external_url: isExternal,
        title: title,
        language_code: language.value,
        description: description,
        tagline: tagLine,
        btntext: btnText,
      };
      await Api.post(USER_BASE_URL, EVENTPOPUP.UPDATEEVENTPOPUPS, epObj);
      let result: EventPopupT[] = eventPopups.filter(
        (res) => res.id !== currentId
      );
      const ArrayData: any = [...result, { ...epObj, id: currentId }];
      ArrayData.sort((a: any, b: any) => b.id - a.id);
      setEventPopups(ArrayData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSwitch = async (nextState, event, Aid) => {
    if (!Aid) return;
    try {
      const advObj = {
        is_active: nextState,
      };
      const response = await Api.patch(
        USER_BASE_URL,
        CreateURL(EVENTPOPUP.EVENTPOPUPBYID, { id: Aid }),
        advObj
      );
      if (response.status === 204) {
        getEventPopup(currentPage, language, perPage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteEvent = async (eventId) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You will not be able to recover the Event with ID ${eventId}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await Api.delete(
            USER_BASE_URL,
            CreateURL(EVENTPOPUP.EVENTPOPUPBYID, { id: eventId })
          );
          if (response.status === 204) {
            let result = eventPopups.filter(event => event.id !== eventId);
            setEventPopups(result);
            Swal.fire(
              "Deleted!",
              `Event ID ${eventId} has been deleted.`,
              "success"
            );
          }
        } catch (error) {
          console.log(error);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          "Cancelled",
          `Your Event ID ${eventId} detail is safe :)`,
          "info"
        );
      }
    });
  }

  return (
    <div className="component-content">
      <ModalPopup
        className="primary event-popup"
        ref={modalRef}
        buttonLabel="Add More"
        popupTitle={`${isEdit ? "Edit" : isClone ? "Clone" : "Add"} Events`}
        reset={resetForm}
        callbackMethod={(event: React.FormEvent<HTMLFormElement>) => {
          handleSubmit(event);
        }}
      >
        <form onSubmit={handleSubmit}>
          {!isEdit && (
            <div className="row m-0">
              {fields.map((field, idx) => (
                <div key={idx} className="row">
                  <div className="col-sm-9">
                    <div className="form ">
                      <select
                        name="language_code"
                        value={field.language_code}
                        onChange={(event) => handleInputFieldChange(idx, event)}
                        required
                      >
                        <option value="">Select language</option>
                        {LANGUAGE.map((l: any) => (
                          <option key={l.value} value={l.value}>
                            {l.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-1 icons">
                    <i className="fa fa-plus" onClick={addFields}></i>
                  </div>
                  <div className="col-sm-1 icons">
                    <i
                      className="fa fa-minus align-items-center"
                      onClick={() => removeFields(idx)}
                    ></i>
                  </div>
                  <div className="col-sm-12">
                    <div className="form ">
                      <input
                        type="text"
                        name="title"
                        value={field.title}
                        onChange={(event) => handleInputFieldChange(idx, event)}
                        required
                      />
                      <label className="label-name">
                        <span className="content-name">Title</span>
                      </label>
                    </div>
                  </div>

                  <div className="col-sm-12 quill-editor">
                    <div className="row m-2">
                      <div className="col-12">
                        <label className="label-name">
                          <span className="content-name">Description</span>
                        </label>
                      </div>
                    </div>
                    <ReactQuill
                      value={field.description}
                      onChange={(event) =>
                        handleInputDescriptionChange(event, idx)
                      }
                    />
                  </div>
                  <div className="col-sm-6 m-0">
                    <div className="form">
                      <input
                        type="text"
                        name="tagline"
                        value={field.tagline}
                        onChange={(event) => handleInputFieldChange(idx, event)}
                      />
                      <label className="label-name">
                        <span className="content-name">Tag Line</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-6 m-0">
                    <div className="form">
                      <input
                        type="text"
                        name="btntext"
                        value={field.btntext}
                        onChange={(event) => handleInputFieldChange(idx, event)}
                        required
                      />
                      <label className="label-name">
                        <span className="content-name">Button Text</span>
                      </label>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {isEdit && (
            <div className="row mb-2">
              <div className="col-sm-12">
                <div className="form ">
                  <input
                    type="text"
                    name="title"
                    value={title}
                    onChange={handleChange}
                    required
                  />
                  <label className="label-name">
                    <span className="content-name">Title</span>
                  </label>
                </div>
              </div>
              <div className="col-sm-12 my-2 quill-editor">
                <div className="row m-2">
                  <div className="col-12">
                    <label className="label-name">
                      <span className="content-name">Description</span>
                    </label>
                  </div>
                </div>
                <ReactQuill
                  value={description}
                  onChange={handleDescriptionChange}
                />
              </div>
              <div className="col-sm-12">
                <div className="form">
                  <input
                    type="text"
                    name="tagline"
                    value={tagLine}
                    onChange={handleChange}
                    required
                  />
                  <label className="label-name">
                    <span className="content-name">Tag Line</span>
                  </label>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form">
                  <input
                    type="text"
                    name="btntext"
                    value={btnText}
                    onChange={handleChange}
                    required
                  />
                  <label className="label-name">
                    <span className="content-name">Button Text</span>
                  </label>
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-sm">
              <div className="form">
                <input
                  type="text"
                  name="event_date"
                  value={eventDate}
                  onChange={handleChange}
                  required
                />
                <label className="label-name">
                  <span className="content-name">Event Date</span>
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <div className="form">
                <input
                  type="text"
                  name="event_time"
                  value={eventTime}
                  onChange={handleChange}
                  required
                />
                <label className="label-name">
                  <span className="content-name">Event Time</span>
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <div className="form">
                <select
                  name="event_display_screen"
                  value={screenName}
                  onChange={(event) => handleSelectChange(event)}
                  required
                >
                  <option value="">Select screen</option>
                  <option value="Dashboard">Dashboard</option>
                  <option value="CareerDashboard">Career Dashboard</option>
                  <option value="DeepDiveDetail">DeepDive Detail</option>
                </select>
                <label className="label-name">
                  <span className="content-name">Event Display Screen</span>
                </label>
              </div>
            </div>
          </div>
          <div className="row" style={{ borderBottom: "1px solid #dbdbdb" }}>
            <div className="col-sm-6">
              <div className="form">
                <span className="content-name">External URL</span>
              </div>
            </div>
            <div
              className="col-sm-6"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Switch
                onChange={(checked) => setIsExternal(checked)}
                checked={isExternal}
                onColor="#9FE6A0"
                onHandleColor="#519872"
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={12}
                width={40}
                className="react-switch"
                id="material-switch"
              />
            </div>
          </div>
          <div className="row">
            {isExternal ? (
              <div className="col-sm">
                <div className="form">
                  <input
                    type="text"
                    name="event_url"
                    value={eventUrl}
                    onChange={handleChange}
                    required
                  />
                  <label className="label-name">
                    <span className="content-name">Event URL</span>
                  </label>
                </div>
              </div>
            ) : (
              <div className="col-sm">
                <div className="form">
                  <select
                    name="event_url"
                    value={eventUrl}
                    onChange={(event) => handleSelectChange(event)}
                    required
                  >
                    <option value="">Select screen</option>
                    <option value="Dashboard">Dashboard</option>
                    <option value="CareerDashboard">CareerDashboard</option>
                    <option value="GetInTouch">GetInTouch</option>
                    <option value="PlanList">PlanList</option>
                    <option value="DeepDiveDetail">DeepDiveDetail</option>
                    <option value="UserProfile">UserProfile</option>
                  </select>
                  <label className="label-name">
                    <span className="content-name">Select navigate screen</span>
                  </label>
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-sm">
              <div className="date-picker" style={{ marginTop: "20px" }}>
                <DateRangePicker
                  startDate={startDate ? moment(startDate) : null}
                  startDateId="start"
                  endDate={endDate ? moment(endDate) : null}
                  endDateId="end"
                  onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(e) => setFocusedInput(e)}
                  displayFormat="DD-MMM-YYYY"
                />
              </div>
            </div>
          </div>
        </form>
      </ModalPopup>
      <Datatable
        title="Create Events / Campaign"
        description="You can create events / campaign here"
        isShow={loader}
        currentPage={currentPage}
        pageSize={PAGE_SIZE}
        dataCount={arrayCount}
        callbackMethod={changePage}
        callbackSelectMethod={selectPageSize}
      >
        {" "}
        <div className="row ">
          <div className="col-sm-2">
            <Select
              value={language}
              options={LANGUAGE}
              onChange={languageHandler}
            />
          </div>
          <div className="col-sm-4">
            <input
              name="search"
              className="form-control"
              placeholder="Search by title"
              value={searchItem}
              onChange={handleSearch}
            />
            <span
              style={{
                position: "absolute",
                float: "right",
                marginTop: "-29px",
                marginLeft: "86%",
                color: "darkgray",
                cursor: "pointer",
              }}
            >
              <h6
                onClick={(e) => {
                  handleSearch(e);
                  setSearchItem("");
                }}
              >
                x
              </h6>
            </span>
          </div>
          <div className="col-sm-6">
            <div className="float-right">
              <button
                id="download-csv"
                className="btn btn-sm btn-outline-primary"
                onClick={()=>ExportExcel(eventPopups, "Event-popups")}
              >
                <i className="fa fa-file-excel-o" /> Download
              </button>
            </div>
          </div>
        </div>
        <table className="datatable-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Description</th>
              <th>Event Date and Time</th>
              <th>Event Page</th>
              <th>External URL</th>
              <th>Event Url</th>
              <th>From</th>
              <th>To</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {eventPopups.length ? (
              eventPopups.map((ep: EventPopupT) => (
                <tr key={ep.id}>
                  <td>{ep.id}</td>
                  <td>{ep.title}</td>
                  <td>{Parser(ep.description)}</td>
                  <td>
                    {ep.event_date} <br />
                    Time: {ep.event_time}
                  </td>
                  <td>{ep.event_display_screen}</td>
                  <td>{ep.is_external_url ? "Yes" : "No"}</td>
                  <td>
                    {ep.event_url.includes(".") ? (
                      <a
                        href={ep.event_url}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {ep.event_url}
                      </a>
                    ) : (
                      <span>{ep.event_url}</span>
                    )}
                  </td>
                  <td>{dateFormat(ep.start_date, "mmmm dS, yyyy")}</td>
                  <td>{dateFormat(ep.end_date, "mmmm dS, yyyy")}</td>
                  <td>
                    <Switch
                      onChange={(checked, event) =>
                        handleSwitch(checked, event, ep.id)
                      }
                      checked={ep.is_active === true ? isActive : !isActive}
                      onColor="#9FE6A0"
                      onHandleColor="#519872"
                      handleDiameter={20}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={12}
                      width={40}
                      className="react-switch"
                      id="material-switch"
                    />
                  </td>
                  <td>
                    <i
                      className="fa fa-edit"
                      style={{ cursor: "pointer" }}
                      title="Edit"
                      onClick={() => {
                        modalRef.current?.updateModal();
                        editEventPopups(ep);
                      }}
                    ></i>
                    <br />
                    <i
                      className="fa fa-clipboard"
                      style={{ cursor: "pointer" }}
                      title="Clone"
                      onClick={() => {
                        modalRef.current?.updateModal();
                        cloneEventPopups(ep);
                      }}
                    ></i>
                     <i
                      className="fa fa-trash"
                      style={{ cursor: "pointer" }}
                      title="Clone"
                      onClick={
                      () => deleteEvent(ep.id)
                      }
                    ></i>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={10}>No records found</td>
              </tr>
            )}
          </tbody>
        </table>
      </Datatable>
    </div>
  );
};

export default EventPopup;
