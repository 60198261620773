import axios from 'axios';

export const Api = {   
    post(baseUrl: any, url: string, obj: any, config: any = null){
        return axios.post(baseUrl + url, obj, config);
    },
    patch(baseUrl: any, url: string, obj: any) {
        return axios.patch(baseUrl + url, obj);
    },
    delete(baseUrl: any, url: string){
        return axios.delete(baseUrl + url);
    },
    update(baseUrl: any, url: string, obj: any){
        return axios.put(baseUrl + url, obj);
    },
    get(baseUrl: any, url: string){
        return axios.get(baseUrl + url);
    },
    all(baseUrl: any, url: any[string]){
        const promiseArray: Array<Promise<void>> = [];
        url.forEach((item: string) => {
            promiseArray.push(axios.get(baseUrl + item));
        });
        return axios.all([...promiseArray])
    },
    allPost(baseUrl: any, url: any[string], params: any, config: any){
        const promiseArray: Array<Promise<void>> = [];
        url.forEach((item: string, i:number) => {
            promiseArray.push(axios.post(baseUrl + item, params[i], config));
        });
        return axios.all([...promiseArray])
    },
    search(baseUrl: any, url: string, obj: any) {
        return axios.get(baseUrl + url, obj);
    },
}