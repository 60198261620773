import React from "react";
import PaginationComponent from "react-reactstrap-pagination";
import ICSErrorBoundary from "../ICSErrorBoundary";
import { Loader } from "../Loader/Loader";

export const Datatable = (props: any) => {
  const {
    title,
    description,
    children,
    currentPage,
    pageSize,
    dataCount,
    callbackMethod,
    isShow,
    callbackSelectMethod,
  } = props;
  const customPageSize = [
    { label: "10 / page", value: 10 },
    { label: "20 / page", value: 20 },
    { label: "50 / page", value: 50 },
    { label: "100 / page", value: 100 },
    { label: "All Data", value: 100000 }
  ];
  const currentItems = () => {
    if (currentPage * pageSize > dataCount) return dataCount;
    else return currentPage * pageSize;
  };
  const handleClick = (selectedPage: number) => {
    callbackMethod(selectedPage);
  };
  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    callbackSelectMethod(event);
  };

  return (
    <ICSErrorBoundary>
      <div className="datatable-panel">
        <div className="datatable-header">
          <div className="datatable-header-title">
            <h4 className="datatable-title">{title}</h4>
            <p className="datatable-subtitle">{description}</p>
          </div>
        </div>
        <div className="datatable-container">
          <div className="datatable-maindiv">
            {children}
            <Loader show={isShow} />
          </div>
          {dataCount > 0 &&
          
          <div className="custom-pagination">
            <div className="total-count">
              <p>
                {currentItems()} of {dataCount} items
              </p>
            </div>
            <PaginationComponent
              totalItems={dataCount}
              pageSize={pageSize}
              maxPaginationNumbers={7}
              firstPageText={"<<"}
              previousPageText={"<"}
              nextPageText={">"}
              lastPageText={">>"}
              defaultActivePage={currentPage}
              onSelect={handleClick}
            />
            <div className="pagination-select">
              <select
                onChange={(event) => handleSelect(event)}
                value={pageSize}
              >
                {customPageSize.map((page, i) => {
                  return (
                    <option key={i} value={page.value}>
                      {page.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>}
        </div>
      </div>
    </ICSErrorBoundary>
  );
};
