/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { ModalPopup, Datatable } from "../../components/common";
import { Api } from "../../service";
import { CareerIdentificationT, LanguageT } from "../../interface";
import {
  CAREERIDENTIFICATION,
  LANGUAGE,
  PAGE_SIZE,
  DATA_BASE_URL,
} from "../../constant";

const CareerIdentification = (props: any) => {
  const [language, setLanguage] = useState(LANGUAGE[0]);
  const [loader, showLoader] = useState(false);
  const [careerIdentification, setCareerIdentification] = useState<
    CareerIdentificationT[]
  >([]);
  const [nameEn, setNameEn] = useState("");
  const [nameHi, setNameHi] = useState("");
  const [name, setName] = useState("");
  const [currentId, setId] = useState(0);
  const [isEdit, setisEdit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [arrayCount, setArrayCount] = useState(0);
  const [perPage, setPerPage] = useState(PAGE_SIZE);

  const modalRef: any = useRef(null);
  const languageHandler = (lang) => {
    pageLoadData(lang);
    setCurrentPage(1);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case "name_en":
        setNameEn(event.target.value);
        break;
      case "name_hi":
        setNameHi(event.target.value);
        break;
      case "name":
        setName(event.target.value);
        break;
    }
  };
  const resetForm = () => {
    setisEdit(false);
    setNameEn("");
    setNameHi("");
  };
  useEffect(() => {
    pageLoadData(language);
  }, []);

  const getCareerIdentification = (currentPage, pageSize, lang) => {
    showLoader(true);
    setLanguage(lang);
    let limit = pageSize;
    let skip = (currentPage - 1) * limit;
    Api.get(
      DATA_BASE_URL,
      `${CAREERIDENTIFICATION.CAREERIDENTIFICATION}?filter[where][language_code]=${lang.value}&filter[limit]=${limit}&filter[skip]=${skip}`
    )
      .then((res) => {
        setCareerIdentification(res?.data);
        showLoader(false);
      })
      .catch((error) => {
        alert(error.message);
        showLoader(false);
      });
  };

  const getCount = () => {
    Api.get(DATA_BASE_URL, CAREERIDENTIFICATION.COUNT)
      .then((res: any) => setArrayCount(res.data.count))
      .catch((error) => alert(error.message));
  };
  const pageLoadData = (lang) => {
    getCareerIdentification(currentPage, perPage, lang);
    getCount();
    setLanguage(lang);
  };
  const changePage = (selectedPage: number) => {
    setCurrentPage(selectedPage);
    getCareerIdentification(selectedPage, perPage, language);
  };

  const selectPageSize = (event) => {
    setPerPage(parseInt(event.target.value));
    setCurrentPage(1);
    getCareerIdentification(
      currentPage,
      parseInt(event.target.value),
      language
    );
  };

  const handleSubmit = (e: any) => {
    isEdit ? updateCareerIdentificationById(e) : addCareerIdentification(e);
  };
  const addCareerIdentification = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const cIObj = {};

    Api.post(DATA_BASE_URL, CAREERIDENTIFICATION.CAREERIDENTIFICATION, cIObj)
      .then((res) => {
        const updatedRes = [...careerIdentification, res.data];
        const insObj = [
          {
            name: nameEn,
            career_identification_id: res.data.id,
            language_code: "en",
          },
          {
            name: nameHi,
            career_identification_id: res.data.id,
            language_code: "hi",
          },
        ];
        Api.post(
          DATA_BASE_URL,
          CAREERIDENTIFICATION.CAREERIDENTIFICATIONBULKUPLOAD,
          insObj
        )
          .then((resT) => {
            getCareerIdentification(currentPage, perPage, language);
          })
          .catch((error) => {
            alert(error.message);
          });
        setCareerIdentification(updatedRes);
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const editCareerIdentification = (ciObj) => {
    if (!ciObj.id) return;
    setisEdit(true);
    setId(ciObj.id);
    setNameEn(ciObj.name_en);
    setNameHi(ciObj.name_hi);
    setName(ciObj.name);
  };
  const updateCareerIdentificationById = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const ciObj = {
      language_code: language.value,
      name: name,
      career_identification_id: currentId,
    };
    Api.patch(
      DATA_BASE_URL,
      CAREERIDENTIFICATION.CAREERIDENTIFICATIONTRANSLATIONUPDATE,
      ciObj
    )
      .then((res) => {
        if (res.status === 200) {
          const result = careerIdentification.filter(
            (res) => res.id !== currentId
          );
          const Array = [{ ...ciObj, id: currentId }, ...result];
          Array.sort((a: any, b: any) => a.id - b.id);
          getCareerIdentification(currentPage, perPage, language);
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  // const deleteCareerTrait = (ci_id: (number | undefined)) => {
  //     if(!ci_id) return;
  //     Api.delete(CreateURL(CAREERIDENTIFICATION.DELETE, {id : ci_id}))
  //         .then(res => {
  //             if (res.status === 204) {
  //                 const result: CareerIdentificationT[] = careerIdentification.filter(res => res.id !== ci_id);
  //                 getCareerIdentification(result);
  //             }
  //         }).catch(error => {
  //             alert(error.message);
  //         });
  // }

  return (
    <div className="component-content">
      <ModalPopup
        className="primary"
        ref={modalRef}
        buttonLabel="Add More"
        popupTitle={
          isEdit ? "Edit Career Identification" : "Add Career Identification"
        }
        reset={resetForm}
        callbackMethod={(event: React.FormEvent<HTMLFormElement>) => {
          handleSubmit(event);
          resetForm();
        }}
      >
        <form onSubmit={handleSubmit}>
          {LANGUAGE.map((lang: LanguageT) => {
            if (isEdit && lang.value === language.value) {
              return (
                <div key={lang.value} className="row">
                  <div className="col-sm">
                    <div className="form ">
                      <input
                        type="text"
                        name={`name`}
                        value={name}
                        onChange={handleChange}
                        required
                      />
                      <label className="label-name">
                        <span className="content-name">Name({lang.label})</span>
                      </label>
                    </div>
                  </div>
                </div>
              );
            }
            if (!isEdit) {
              return (
                <div key={lang.value} className="row">
                  <div className="col-sm">
                    <div className="form ">
                      <input
                        type="text"
                        name={`name_${lang.value}`}
                        onChange={handleChange}
                        required
                      />
                      <label className="label-name">
                        <span className="content-name">Name({lang.label})</span>
                      </label>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </form>
      </ModalPopup>
      <Datatable
        title="Career Identification"
        description="You can view career identification here"
        isShow={loader}
        currentPage={currentPage}
        pageSize={perPage}
        dataCount={arrayCount}
        callbackMethod={changePage}
        callbackSelectMethod={selectPageSize}
      >
        <div className="row ">
          <div className="col-sm-2">
            <Select
              value={language}
              options={LANGUAGE}
              onChange={languageHandler}
            />
          </div>
        </div>
        <table className="datatable-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {careerIdentification.length ? (
              careerIdentification.map((ci: CareerIdentificationT) => (
                <tr key={ci.id}>
                  <td>{ci.id}</td>
                  <td>{ci.name} </td>
                  <td>
                    <i
                      className="fa fa-edit"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        modalRef.current?.updateModal();
                        editCareerIdentification(ci);
                      }}
                    ></i>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5}>No records found</td>
              </tr>
            )}
          </tbody>
        </table>
      </Datatable>
    </div>
  );
};

export default CareerIdentification;
