import React from "react";
import { RouteComponentProps } from "react-router";
import { ModalPopup, Datatable } from "../../components/common";
import { Api } from "../../service";
import { CreateURL, QUESTION, LANGUAGE, DATA_BASE_URL } from "../../constant";
import { DeepdiveT } from "../../interface";
import Select from "react-select";
import Swal from "sweetalert2";

interface RouteComponetPath {
  path?: string;
}

class DeepDive extends React.Component<{}> {

  state = {
    formInput: {
      que_id: 0,
      que_label: "",
      que_sequence: 0,
    },
    loader: false,
    questions: [],
    fields: [
      {
        que_label: "",
        language_code: "",
      },
    ],
    language: LANGUAGE[0],
    isEdit: false,
  };
  params: any = {};
  test_id: number = 0;
  modalRef:any = React.createRef();
  
  constructor(props: any | RouteComponentProps<RouteComponetPath>) {
    super(props);
    this.params = (
      this.props as RouteComponentProps<RouteComponetPath>
    ).match.params;
    this.test_id = Number(this.params?.id);
}

  languageHandler = (lang: string) => {
    this.setState({ language: lang });
    this.getQuestions(lang);
  };

  addFields = () => {
    if (this.state.fields.length < LANGUAGE.length) {
      this.setState({
        fields: [
          ...this.state.fields,
          {
            que_label: "",
            language_code: "",
          },
        ],
      });
    }
  };

  removeFields = (i) => {
    const values = [...this.state.fields];
    if (values.length > 1) {
      values.splice(i, 1);
    }
    this.setState({ fields: values });
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      formInput: {
        ...this.state.formInput,
        [name]: value,
      },
    });
  };

  handleFieldInputChange = (index, event) => {
    const values = [...this.state.fields];
    values[index][event.target.name] = event.target.value;
    this.setState({
      fields: values,
    });
  };
  componentDidMount() {
    this.getQuestions(LANGUAGE[0]);
  }
  getQuestions = (lang) => {
    this.setState({ language_code: lang, loader: true });
    Api.get(
      DATA_BASE_URL, 
      `${QUESTION.DEEPDIVEQUESTIONAIRE}/${this.test_id}/'${lang.value}'/null`
    )
      .then((res) => {
        const questions: DeepdiveT[] = res.data;
        this.setState({ questions: questions, loader: false });
      })
      .catch((error) => {
        this.setState({ loader: false });
        alert(error.message);
      });
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    this.state.isEdit ? this.updateQuestion(event) : this.addQuestion(event);
  }

  addQuestion = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.setState({ loader: true });
    const queObj = {
      test_id: this.test_id,
      que_sequence: Number(this.state.formInput.que_sequence) || 0,
    };
    try {
      const response = await Api.post(DATA_BASE_URL, QUESTION.QUESTION, queObj);
      if (response.status === 200) {
        this.state.fields.forEach((field: any) => {
          field.que_id = response.data.que_id;
        });
        const responseT = await Api.post(
          DATA_BASE_URL, 
          QUESTION.QUESTIONTRANSLATION,
          this.state.fields
        );
        const data = responseT.data.filter((res) => res.language_code === this.state.language.value);
        let ArrayData = [{ ...response.data, ...data[0] },
        ...this.state.questions]
        ArrayData.sort((a: any, b: any) => a.que_sequence - b.que_sequence);
        this.setState({
          questions: ArrayData,
          loader: false,
        });
      }
    } catch (error) {
      this.setState({ loader: false });
      alert(error.message);
    }
  };

  editQuestion = (ques) => {
    this.modalRef?.updateModal();
    if (!ques.que_id) return;
    this.setState({
      formInput: {
        que_id: ques.que_id,
        que_label: ques.que_label,
        que_sequence: ques.que_sequence,
      },
      isEdit: true,
    });
  };

  updateQuestion = async (event) => {
    event.preventDefault();
    const obj = {
      ...this.state.formInput,
      language_code: this.state.language.value,
    };
    try {
      await Api.post(DATA_BASE_URL, QUESTION.UPDATEQUESTIONAIRE, obj);
      let result = this.state.questions.filter(
        (res: any) => res.que_id !== this.state.formInput.que_id
      );
      const ArrayData: any = [...result, obj];
      ArrayData.sort((a: any, b: any) => a.que_sequence - b.que_sequence);
      this.setState({
        questions: ArrayData,
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  deleteQuestion = (que_id: number | undefined) => {
    if (!que_id) return;
    Swal.fire({
      title: "Are you sure?",
      text: `You will not be able to recover the question`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
    Api.delete(DATA_BASE_URL, CreateURL(QUESTION.DELETE, { id: que_id }))
      .then((res) => {
        if (res.status === 204) {
          const data = this.state.questions.filter((r: any) => r.que_id !== que_id);
          this.setState({
            questions: data,
          });
        }
      })
      .catch((error) => {
        alert(error.message);
      });
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        "Cancelled",
        `Your question is safe :)`,
        "info"
      );
    }
  });
  };

  resetForm = () => {
    this.setState({
      formInput: {
        que_label: "",
        que_sequence: 0,
      },
      fields: [
        {
          que_label: "",
          language_code: "",
        },
      ],
	  isEdit: false
    });
  };
  render() {
    return (
      <div className="component-content">
        <ModalPopup
          className="primary"
          buttonLabel="Add More"
          ref={ref => (this.modalRef = ref)}
          popupTitle={`${this.state.isEdit ? "Edit" : "Add"} Question`}
          reset={this.resetForm}
          callbackMethod={(event: React.FormEvent<HTMLFormElement>) =>
            this.handleSubmit(event)
          }
        >
          <form onSubmit={this.handleSubmit}>
			  {this.state.isEdit === true ?                 
			  <div className="row">
                  <div className="col-sm">
                    <div className="form ">
                      <input
                        type="text"
                        name="que_label"
                        value={this.state.formInput.que_label}
                        onChange={this.handleChange}
                        required
                      />
                      <label className="label-name">
                        <span className="content-name">Question</span>
                      </label>
                    </div>
                  </div>
                </div> : <>	
            {this.state.fields.map((field: any, idx: number) => (
              <div key={idx}>
                <div className="row">
                  <div className="col-sm-10">
                    <div className="form ">
                    <select
                        name="language_code"
                        value={field.language_code}
                        onChange={(e) => this.handleFieldInputChange(idx, e)}
                        required
                      >
                        <option value="">Select language</option>
                        {LANGUAGE.map((l: any) => (
                          <option key={l.value} value={l.value}>
                            {l.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-1 icons">
                    <i className="fa fa-plus" onClick={this.addFields}></i>
                  </div>
                  <div className="col-sm-1 icons">
                    <i
                      className="fa fa-minus align-items-center"
                      onClick={() => this.removeFields(idx)}
                    ></i>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm">
                    <div className="form ">
                      <input
                        type="text"
                        name="que_label"
                        value={field.que_label}
                        onChange={(e) => this.handleFieldInputChange(idx, e)}
                        required
                      />
                      <label className="label-name">
                        <span className="content-name">Question</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            ))}
			</>}
            <div className="row">
              <div className="col-sm">
                <div className="form ">
                  <input
                    type="text"
                    name="que_sequence"
                    value={this.state.formInput.que_sequence}
                    onChange={this.handleChange}
                    maxLength={2}
                    required
                  />
                  <label className="label-name">
                    <span className="content-name">Question Sequence</span>
                  </label>
                </div>
              </div>
            </div>
          </form>
        </ModalPopup>
        <Datatable
          title="Question"
          description="You can view berkman test question"
          isShow={this.state.loader}
        >
          <div className="row ">
            <div className="col-sm-2">
              <Select
                value={this.state.language}
                options={LANGUAGE}
                onChange={this.languageHandler}
              />
            </div>
          </div>
          <table className="datatable-table">
            <thead>
              <tr>
                <th>Question</th>
                <th>Sequence</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="">
              {this.state.questions.length ? (
                this.state.questions.map((que: DeepdiveT) => (
                  <tr key={que.que_id}>
                    <td>{que.que_label}</td>
                    <td>{que.que_sequence}</td>
                    <td style={{cursor: "pointer"}}>
                      <i
                        className="fa fa-edit"
                        onClick={() => {
                          this.editQuestion(que);
                        }}
                      />
                      <i
                        className="fa fa-trash"
                        onClick={() => this.deleteQuestion(que.que_id)}
                      ></i>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3}>No records found</td>
                </tr>
              )}
            </tbody>
          </table>
        </Datatable>
      </div>
    );
  }
}

export default DeepDive;
