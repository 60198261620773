import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { stopReportingRuntimeErrors } from "react-error-overlay";
const NODE_ENV = process.env.NODE_ENV;

if (NODE_ENV === "development") {
  stopReportingRuntimeErrors(); // disables error overlays
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
