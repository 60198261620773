import {Api} from './Api';
import {USER, USER_BASE_URL} from '../constant';

export const AuthService = {
    isloggedIn: false,
    loggedIn(loginObj) {
        return Api.post(USER_BASE_URL, USER.LOGIN, loginObj);
    },
    loggedOut() {
        this.isloggedIn = false;
        window.localStorage.removeItem('userDetail');
        window.localStorage.removeItem('accessToken');
    },
    checkLoggedIn() {
        const token = window.localStorage.getItem('accessToken');
        return (token || this.isloggedIn) ? true : false;
    },
    getAccessToken() {
        return "jasdf89a89sfassdfasd89f";
    }
};