import React, { Component } from "react";
import { Switch } from "react-router-dom";

import PrivateRoute from './PrivateRoute'
import Dashboard from './pages/dashboard/Dashboard';
import User from './pages/User';
import Counseling from './pages/Counseling';
import Feedback from './pages/Feedback';
import Country from './pages/masterEntry/Country';
import Degree from './pages/masterEntry/Degree';
import Campus from './pages/campus/Campus';
import Grade from './pages/masterEntry/Grade';
import Subject from './pages/masterEntry/Subject';
import Hobby from './pages/masterEntry/Hobby';
import Color from './pages/masterEntry/Color';
import Test from './pages/Test';
import BerkmanChoice from './pages/masterEntry/BerkmanChoice';
import Personality from './pages/masterEntry/Personality';
import DeepDive from './pages/question/DeepDive';
import Question from './pages/question/Question';
import Career from './pages/masterEntry/Career';
import SubCareer from './pages/masterEntry/SubCareer';
import CareerTrait from './pages/masterEntry/CareerTrait';
import ViewCareerDetail from './pages/careerDetail/ViewCareerDetail';
import Alumni from './pages/Alumni';
import ChangePassword from './pages/login/ChangePassword';
import CareerIdentification from './pages/masterEntry/CareerIdentification'
import Advertisements from "./pages/Advertisements";
import EventPopup from "./pages/events/CreateEvents";
import SegmentEmail from "./pages/bulkEmail";
import ViewResponse from "pages/events/ViewResponse";
import Payment from "./pages/Payment";
import QuizWinner from "./pages/QuizWinner";
import WebinarURL from "./pages/WebinarURL";
import CreateCoupon from "./pages/coupon/CreateCoupon";
import RedeemCoupon from "./pages/coupon/RedeemCoupon";
import Scholarship from "./pages/scholarship/Scholarship";
import FeaturedEducation from "./pages/featuredEducation/FeaturedEducation";
import CareerAlert from './pages/careerAlert/CareerAlert';
export default class Routes extends Component {
    render() {
        return (
            <Switch >
                <PrivateRoute path="/dashboard" component={Dashboard} />
                <PrivateRoute path="/users" component={User} />
                <PrivateRoute path="/counseling" component={Counseling} />
                <PrivateRoute path="/feedback" component={Feedback} />
                <PrivateRoute path="/country" component={Country} />
                <PrivateRoute path="/degree" component={Degree} />
                <PrivateRoute path="/campus" component={Campus} />
                <PrivateRoute path="/grade" component={Grade} />
                <PrivateRoute path="/subject" component={Subject} />
                <PrivateRoute path="/hobbies" component={Hobby} />
                <PrivateRoute path="/test" component={Test} />
                <PrivateRoute path="/berkman-choice" component={BerkmanChoice} />
                <PrivateRoute path="/color" component={Color} />
                <PrivateRoute path="/personality" component={Personality} />
                <PrivateRoute path="/questions/deepdive/test/:id/:name/:type" component={DeepDive} />
                <PrivateRoute path="/questions/general/test/:id/:name/:type" component={Question} />
                <PrivateRoute path="/career" component={Career} />
                <PrivateRoute path="/sub-career" component={SubCareer} />
                <PrivateRoute path="/career-trait" component={CareerTrait} />
                <PrivateRoute exact path="/career-detail/view" component={ViewCareerDetail} />
                <PrivateRoute path="/alumni" component={Alumni} />
                <PrivateRoute path="/change-password" component={ChangePassword} />
                <PrivateRoute path="/career-identification" component={CareerIdentification} />
                <PrivateRoute path="/advertisements" component={Advertisements} />
                <PrivateRoute path="/create-events" component={EventPopup} />
                <PrivateRoute path="/view-responses" component={ViewResponse} />
                <PrivateRoute path="/bulk-email" component={SegmentEmail} />
                <PrivateRoute path="/payment" component={Payment} />
                <PrivateRoute path="/quiz-winner" component={QuizWinner} />
                <PrivateRoute path="/webinar" component={WebinarURL} />
                <PrivateRoute path="/create-coupon" component={CreateCoupon} />
                <PrivateRoute path="/redeem-coupon" component={RedeemCoupon} />
                <PrivateRoute path="/scholarship" component={Scholarship} />
                <PrivateRoute path="/featuredEducation" component={FeaturedEducation} />
                <PrivateRoute path="/career-alert" component={CareerAlert} />
            </Switch>
        )
    }
}
