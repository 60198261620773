import React, { useState, useEffect } from "react";
import { Datatable } from "../components/common";
import { Api } from "../service";
import { QuizWinnerT } from "../interface";
import {
  QUIZWINNER,
  PAGE_SIZE,
  USER_BASE_URL
} from "../constant";
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import dateFormat from "dateformat";
import moment from "moment";

const QuizWinner = () => {
  const [loader, showLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [arrayCount, setArrayCount] = useState(0);
  const [perPage, setPerPage] = useState(PAGE_SIZE);
  const [winners, setWinners] = useState<QuizWinnerT[]>([]);
  const [date, setDate] = useState();
  const [focused, setFocused] = useState(false);

  const changePage = (selectedPage: number) => {
    setCurrentPage(selectedPage);
    getWinners(selectedPage, perPage);
  };

  const selectPageSize = (event) => {
    setPerPage(parseInt(event.target.value));
    setCurrentPage(1);
    getWinners(currentPage, parseInt(event.target.value));
  };

  useEffect(() => {
    getWinners(currentPage, perPage);
    getWinnersCount();
  }, [currentPage, perPage]);

  const getWinners = (currentPage: number = 1, pageSize: number) => {
		showLoader(true);
    let skip = (currentPage - 1) * pageSize;
    Api.get(
      USER_BASE_URL, 
      `${QUIZWINNER.GET}?filter[limit]=${pageSize}&filter[skip]=${skip}`,
      )
      .then((res: any) => {
        showLoader(false);
        let filterData = res.data.map((winner: any) =>  {
          if(winner['voucher_date'] === "0000-00-00 00:00:00"){
            winner['voucher_date'] = null;
            winner['focus'] = false;
          }
          return winner;
        });
        setWinners(filterData);
        setCurrentPage(currentPage)
      })
      .catch((error) => {
        showLoader(false);
        alert(error.message);
      });
  };

  const getWinnersCount = () => {
    Api.get(USER_BASE_URL, QUIZWINNER.COUNT)
    .then((res: any) => {
      showLoader(false);
      setArrayCount(res.data.count);
    })
    .catch((error) => {
      showLoader(false);
      alert(error.message);
    });
  }

  const updateVoucherDetail = async (e, p: QuizWinnerT) => {
    try {
      const obj = {
        userId: p.userid,
        voucherDate: moment(date).format("YYYY-MM-DD HH:MM:ss")
      }
      const {data} = await Api.post(USER_BASE_URL, `quiz-winners/updatevoucher`, obj);
      if(data?.affectedRows > 0){
        const updated = winners.filter((w) => w.id === p.id);
        updated[0].voucher_date = obj.voucherDate;
        const updatedList = [...winners, ...updated];
        setWinners(updatedList);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const setCurrentFocused = (idx: number) => {
    if(winners[idx]['focus'] === true){
      winners[idx]['focus'] = false;
      winners[idx]['isUpdated'] = true;
      setFocused(false);
    } else {
      winners[idx]['focus'] = true;
      setFocused(true);
    }
    setWinners(winners);
  }

  return (
    <div className="component-content">
      <Datatable
        title="Quiz Winner"
        description="You can track every week winner details here"
        isShow={loader}
        currentPage={currentPage}
        pageSize={PAGE_SIZE}
        dataCount={arrayCount}
        callbackMethod={changePage}
        callbackSelectMethod={selectPageSize}
      >
        <table className="datatable-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Winner Name</th>
              <th>Claimed</th>
              <th>Voucher Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {winners.length ? (
              winners.map((p: QuizWinnerT, index: number) => (
                <tr key={p.id}>
                  <td>{p.id}</td>
                  <td>{<>{'UserId: '+p.userid}<br />
                    {p.name}<br />
                    {p.contact || 'Not available'}<br />
                    {p.email}</>}
                  </td>
                  <td>{p.claimed ? 'Yes' : 'No'}</td>
                  <td className="voucher-date-input">{p.claimed ? p.voucher_date ? dateFormat(p.voucher_date, "mmmm dS, yyyy") : <SingleDatePicker
                      displayFormat={'DD-MM-YYYY HH:MM:ss'}
                      small={true}
                      date={p.isUpdated ? date : ""} // momentPropTypes.momentObj or null
                      onDateChange={date => setDate(date)} // PropTypes.func.isRequired
                      focused={p.focus ? focused: ""}
                      onFocusChange={() => setCurrentFocused(index)}
                      numberOfMonths={1}
                      withPortal={false}
                      id={"voucer-date-"+p.id} // PropTypes.string.isRequired,
                    /> : '-'}
                  </td>
                  <td>
                    {!p.voucher_date && p.claimed !== false ? <button 
                      type="button"
                      className="btn btn-sm btn-warning mb-1"
                      onClick={(e) => updateVoucherDetail(e, p)}>
                        Update
                    </button> : null}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5}>No records found</td>
              </tr>
            )}
          </tbody>
        </table>
      </Datatable>
    </div>
  );
};

export default QuizWinner;
