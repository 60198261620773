/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react";
import { Datatable } from "../components/common";
import { USER, PER_PAGE_LIMIT, API, LANGUAGE, GRADE, USER_BASE_URL,DATA_BASE_URL } from "../constant";
import { Api, ExportExcel } from "../service";
import { UserT } from "../interface";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import axios from "axios";
import Select from "react-select";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [grades, setGrades] = useState([]);
  const [arrayCount, setArrayCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, showLoader] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [userResult, setUserResult] = useState({});
  const [perPage, setPerPage] = useState(PER_PAGE_LIMIT);
  const [searchItem, setSearchItem] = useState("");
  const [language, setLanguage] = useState(LANGUAGE[0]);
  const [gradeName, setGradeName] = useState([]);
  const [subscribe, setSubscribe] = useState([]);

  const SUBSCRIBE = [
    { label: "Subscribed", value: 1 },
    { label: "Unsubscribed", value: 0 },
  ];

  const changePage = (selectedPage: number) => {
    setCurrentPage(selectedPage);
    if(language.length === 0 && gradeName.length === 0 && subscribe.length === 0) getUsers(selectedPage, perPage);
    else if (gradeName.length === 0 && subscribe.length === 0) {
      languageHandler(selectedPage, perPage, language);
    } else if(language.length === 0 && subscribe.length === 0){
      gradeHandler(selectedPage, perPage, gradeName);
    } else if(language.length === 0 && gradeName.length === 0) {
      subscribeHandler(selectedPage, perPage, subscribe);
    }
  };

  const selectPageSize = (event) => {
    setPerPage(parseInt(event.target.value));
    setCurrentPage(1);
    if(language.length === 0 && gradeName.length === 0 && subscribe.length === 0) getUsers(currentPage,  parseInt(event.target.value));
    else if (gradeName.length === 0 && subscribe.length === 0) {
      languageHandler(currentPage,  parseInt(event.target.value), language);
    } else if(language.length === 0 && subscribe.length === 0){
      gradeHandler(currentPage,  parseInt(event.target.value), gradeName);
    } else if(language.length === 0 && gradeName.length === 0) {
      subscribeHandler(currentPage,  parseInt(event.target.value), subscribe);
    }
  };

  const gradeHandler = async (current, pageSize, grade: any) => {
    if (grade === null) {
      getUsers(1, perPage);
      setLanguage([]);
      setSubscribe([]);
      setGradeName([])
    } else {
      setLanguage([]);
      setSubscribe([]);
      setGradeName(grade);
      setCurrentPage(current);
      setPerPage(pageSize);
      try {
        let limit = pageSize;
        let offset = (current - 1) * limit;
        const users = await Api.get(
          USER_BASE_URL, 
          `${USER.USERDETAILS}/null/null/null/${grade.value}/${limit}/${offset}`
        );
        if (users.data.length > 0) {
          Api.get(USER_BASE_URL, `${USER.COUNT}?where[grade]=${grade.value}`)
          .then((res) => {
            setArrayCount(res.data.count);
          })
          .catch((error) => {
            console.log(JSON.stringify(error));
          });
          setUsers(users.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const languageHandler = async (current, pageSize, lang: any) => {
    if (lang === null) {
      getUsers(1, perPage);
      setSubscribe([]);
      setGradeName([]);
      setLanguage([]);
      
    } else {
    setSubscribe([]);
    setGradeName([]);
    setLanguage(lang);
    setCurrentPage(current);
    setPerPage(pageSize);
    try {
      let limit = pageSize;
      let offset = (current - 1) * limit;
      const users = await Api.get(
        USER_BASE_URL, 
        `${USER.USERDETAILS}/null/'${lang.value}'/null/0/${limit}/${offset}`
      );
      if (users.data.length > 0) {
        Api.get(USER_BASE_URL, `${USER.COUNT}?where[default_language]=${lang.value}`)
        .then((res) => {
          setArrayCount(res.data.count);
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
        });
        setUsers(users.data);
      }
    } catch (error) {
      console.log(error);
    }
  }
  };

  const subscribeHandler = async (current, pageSize, subs: any) => {
    if (subs === null) {
      getUsers(1, perPage);
      setSubscribe([]);
      setGradeName([]);
      setLanguage([]);
    } else {
    setLanguage([]);
    setGradeName([]);
    setSubscribe(subs);
    setCurrentPage(current);
    setPerPage(pageSize);
    try {
      let limit = pageSize;
      let offset = (current - 1) * limit;
      const users = await Api.get(
        USER_BASE_URL, 
        `${USER.USERDETAILS}/null/null/${subs.value}/0/${limit}/${offset}`
      );
      if (users.data.length > 0) {
        Api.get(USER_BASE_URL, `${USER.COUNT}?where[subscribe]=${subs.value}`)
        .then((res) => {
          setArrayCount(res.data.count);
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
        });
        setUsers(users.data);
      }
    } catch (error) {
      console.log(error);
    }
  }
  };

  useEffect(() => {
    getUsersCount();
    getUsers(currentPage, perPage);
    getGrades();
  }, []);

  const getUsersCount = () => {
    Api.get(USER_BASE_URL, USER.COUNT)
      .then((res) => {
        setArrayCount(res.data.count);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
      });
  };
  const getUsers = (current, pageSize) => {
    showLoader(true);
    let limit = pageSize;
    let skip = (current - 1) * limit;
    Api.get(USER_BASE_URL, `${USER.USERDETAILS}/null/null/null/0/${pageSize}/${skip}`)
      .then((res) => {
        showLoader(false);
        setUsers(res.data);
      })
      .catch((error) => {
        showLoader(false);
        console.log(JSON.stringify(error));
      });
  };

  const getGrades = () => {
    Api.get(DATA_BASE_URL, `${GRADE.GRADE}`)
      .then((res) => {
        const gradename: any = [];
        res.data.map((res: any) => {
          return gradename.push({
            label: res.name,
            value: res.id,
          });
        });
        setGrades(gradename);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getGradeName = (gradeId: number) => {
    const result: any = grades.filter((res: any) => res.value === gradeId);
    return result.length ? result[0].label : "";
  };

  const getUserResult = (id: number | undefined, lang: string | undefined) => {
    if (!id && !lang) return;
    Api.get(USER_BASE_URL, `${API.USERRESULT}/${id}/` + encodeURIComponent(`${lang}`))
      .then((res) => {
        setUserResult(res.data[0]);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
      });
  };

  const toggleDetail = (index: number) => {
    let infoDiv: any = document.getElementById("accordion" + index);
    let trBlock: any = document.getElementById("hide-table-padding" + index);
    let classArray = Array.from(infoDiv?.classList);
    var accordionCount = document.getElementsByClassName("accordion-block");
    for (let i = 0; i < accordionCount.length; i++) {
      accordionCount[i].className += " d-none";
    }
    if (classArray.indexOf("collapse") !== -1) {
      infoDiv?.classList.remove("collapse");
      infoDiv?.parentElement?.parentElement?.previousSibling?.classList.remove(
        "shrink"
      );
      trBlock?.classList.remove("d-none");
    } else {
      infoDiv?.classList.add("collapse");
      infoDiv?.parentElement?.parentElement?.previousSibling?.classList.add(
        "shrink"
      );
      trBlock?.classList.add("d-none");
    }
  };

  const toggle = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const createBlocks = (arr: any[]) => {
    if (arr?.length) {
      return (
        arr && arr.map((name: any, i: number) => <div key={i}>{name}</div>)
      );
    } else {
      return <div>User has not attemted General Test</div>;
    }
  };

  const showTestResult = (testType: string, data: any) => {
    if (testType === "General") {
      if (data?.test_type) {
        return (
          <div>
            <div className="show-blocks">
              Test Type: <span className="test-type">{data?.test_type}</span>
            </div>
            <div className="show-blocks">
              Careers: {createBlocks(data?.careers)}
            </div>
            <div className="show-blocks">
              Personality: {createBlocks(data?.traits)}
            </div>
          </div>
        );
      } else {
        return <div>User has not attemted General Test</div>;
      }
    } else if (testType === "Subjects") {
      if (data?.test_type) {
        return (
          <div>
            <div className="show-blocks">{createBlocks(data?.subjects)}</div>
          </div>
        );
      } else {
        return <div>User has not attemted General Test</div>;
      }
    } else if (testType === "Hobbies") {
      if (data?.test_type) {
        return (
          <div>
            <div className="show-blocks">{createBlocks(data?.hobbies)}</div>
          </div>
        );
      } else {
        return <div>User has not attemted General Test</div>;
      }
    } else {
      if (data?.length) {
        return (
          <table>
            <thead>
              <tr>
                <td>Career Name</td>
                <td>Score</td>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((res: any, idx: number) => (
                  <tr key={idx}>
                    <td>{res?.name}</td>
                    <td>{res?.score}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        );
      } else {
        return <div>User has not attemted Deepdive Test</div>;
      }
    }
  };

  const handleSearch = async (event) => {
    setCurrentPage(1);
    let cancelToken: any;
    let search = event.target.value || null;
   
    setSearchItem(search);
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    try {
      let limit = PER_PAGE_LIMIT;
      let offset = (currentPage - 1) * limit;
      const users = await Api.search(
        USER_BASE_URL,
        `${USER.USERDETAILS}/${search}/null/null/0/${limit}/${offset}`,
        { cancelToken: cancelToken.token }
      );
      if (users.data.length > 0) {
        setUsers(users.data);
        setArrayCount(0);
      } else {
        getUsersCount();
        getUsers(1, perPage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="component-content" style={{ marginTop: "60px" }}>
      <Datatable
        title="User"
        description="You can view user information"
        isShow={loader}
        currentPage={currentPage}
        pageSize={perPage}
        dataCount={arrayCount}
        callbackMethod={changePage}
        callbackSelectMethod={selectPageSize}
      >
        <div className="row">
          <div className="col-sm-4">
            <div style={{ position: "relative" }}>
              <input
                type="text"
                value={searchItem}
                className="form-control"
                placeholder="Search by name | contact | email"
                onChange={(e) => handleSearch(e)}
              />
              {searchItem && (
                <span
                  style={{
                    position: "absolute",
                    float: "right",
                    marginTop: "-29px",
                    marginLeft: "96%",
                    color: "darkgray",
                    cursor: "pointer",
                  }}
                >
                  <h6
                    onClick={(e) => {
                      handleSearch(e);
                      setSearchItem("");
                    }}
                  >
                    x
                  </h6>
                </span>
              )}
            </div>
          </div>
          <div className="col-sm-2">
            <Select
            isClearable={true}
              placeholder={<div>Select language</div>}
              value={language}
              options={LANGUAGE}
              onChange={(e) => languageHandler(currentPage, perPage, e)}
            />
          </div>
          <div className="col-sm-2">
            <Select
              isClearable={true}
              placeholder={<div>Select grades</div>}
              value={gradeName}
              options={grades}
              onChange={(e) => gradeHandler(currentPage, perPage, e)}
            />
          </div>
          <div className="col-sm-2">
            <Select
            isClearable={true}
              placeholder={<div>Select subscribe</div>}
              value={subscribe}
              options={SUBSCRIBE}
              onChange={(e) => subscribeHandler(currentPage, perPage, e)}
            />
          </div>
          <div className="col-sm-2">
            <div className="float-right">
              <button
                id="download-csv"
                className="btn btn-sm btn-outline-primary"
                onClick={() => ExportExcel(users, "Users")}
              >
                <i className="fa fa-file-excel-o" /> Download
              </button>
            </div>
          </div>
        </div>
        <table className="datatable-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Contact</th>
              <th>Email</th>
              <th>Grade</th>
              <th>Subscribe</th>
              <th>Default Language</th>
              <th>...</th>
            </tr>
          </thead>
          <tbody>
            {users.length ? (
              users.map((user: UserT, idx: number) => (
                <Fragment key={user.id}>
                  <tr className="accordion-toggle collapsed shrink">
                    <td>{user.id}</td>
                    <td>{user.name}</td>
                    <td>{user.contact}</td>
                    <td>{user.email}</td>
                    <td>{getGradeName(user.grade)}</td>
                    <td>{user.subscribe ? "Yes" : "No"}</td>
                    <td>
                      {user.default_language
                        ? user.default_language === "en"
                          ? "English"
                          : "Hindi"
                        : "English"}
                    </td>
                    <td
                      className="expand-button"
                      onClick={() => {
                        toggleDetail(idx);
                        getUserResult(user.id, user.default_language);
                      }}
                    ></td>
                  </tr>
                  <tr
                    id={"hide-table-padding" + idx}
                    className="d-none accordion-block"
                    style={{ backgroundColor: "#eeeeee" }}
                  >
                    <td colSpan={12}>
                      <div id={"accordion" + idx} className="collapse in">
                        <Nav tabs>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "1",
                              })}
                              onClick={() => {
                                toggle("1");
                              }}
                            >
                              Subjects
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "2",
                              })}
                              onClick={() => {
                                toggle("2");
                              }}
                            >
                              Hobbies
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "3",
                              })}
                              onClick={() => {
                                toggle("3");
                              }}
                            >
                              Test Result
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "4",
                              })}
                              onClick={() => {
                                toggle("4");
                              }}
                            >
                              Deep Dive Result
                            </NavLink>
                          </NavItem>
                        </Nav>

                        <TabContent
                          activeTab={activeTab}
                          style={{ padding: "15px 30px" }}
                        >
                          <TabPane tabId="1">
                            <div className="row">
                              <div className="col-sm-12">
                                {showTestResult(
                                  "Subjects",
                                  userResult["general_test"]
                                )}
                              </div>
                            </div>
                          </TabPane>
                          <TabPane tabId="2">
                            <div className="row">
                              <div className="col-sm-12">
                                {showTestResult(
                                  "Hobbies",
                                  userResult["general_test"]
                                )}
                              </div>
                            </div>
                          </TabPane>
                          <TabPane tabId="3">
                            <div className="row">
                              <div className="col-sm-12">
                                {showTestResult(
                                  "General",
                                  userResult["general_test"]
                                )}
                              </div>
                            </div>
                          </TabPane>
                          <TabPane tabId="4">
                            <div className="row">
                              <div className="col-sm-12">
                                {showTestResult(
                                  "Deepdive",
                                  userResult["deepdive_test"]
                                )}
                              </div>
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                    </td>
                  </tr>
                </Fragment>
              ))
            ) : (
              <tr>
                <td colSpan={8}>No records found</td>
              </tr>
            )}
          </tbody>
        </table>
      </Datatable>
    </div>
  );
};

export default Users;
