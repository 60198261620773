/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./Sidebar.css";
import { MenuJSON } from "./SidebarData";
import {version} from '../../../../package.json';
class Sidebar extends Component {
  state = {
    toggleIndex: null,
  };
  userDetail: any = window.localStorage.getItem("userDetail");

  componentDidMount() {
    const url = window.location.pathname;
    MenuJSON.map((menu, i) => {
      if(url === menu.path) {
        this.setState({ toggleIndex: i });
      } 
      else {
        menu.children.map((submenu) => {
          if(url === submenu.path) {            
            this.setState({ toggleIndex: i})
          }
        })
        }
    })
  }

  toggleMenu(e, index) {
    e.preventDefault();
    if (this.state.toggleIndex === index) {
      return this.setState({ toggleIndex: null });
    } else this.setState({ toggleIndex: index });
  }

  render() {
    return (
      <aside className="side-nav" id="show-side-navigation">
        <div className="heading">
          <NavLink to="/dashboard" activeClassName="active" onClick={(e) => this.setState({ toggleIndex: 0 })}>
            <img src={require("../../../assets/images/logo.png").default} alt="logo" />
          </NavLink>
          <div className="info">
            <h3 style={{marginBottom: 0}}>
              <NavLink to="/dashboard" activeClassName="active" onClick={(e) => this.setState({ toggleIndex: 0 })}>Admin Panel</NavLink>
            </h3>
              <h6 className="ml-2">Version: {version}</h6>
          </div>
        </div>
        <ul className="categories">
          {MenuJSON.map((item, index:any) => {
            return item.role.map((role: string) => {
              if (role === JSON.parse(this.userDetail).role) {
                if (item.children.length === 0) {
                  return (
                    <li key={index}>
                      <NavLink
                        to={item.path}
                        activeClassName={this.state.toggleIndex === index ? "active" : ""} 
                        onClick={(e) => this.setState({ toggleIndex: index })}
                      >
                        <i className={item.icon} aria-hidden="true"></i>
                        {item.title}
                      </NavLink>
                    </li>
                  );
                } else {
                  return (
                    <li key={index} className="drop-down-li">
                      <a className={this.state.toggleIndex === index ? "active" : ""} onClick={(e) => this.toggleMenu(e, index)} href={item.path}>
                        <i className={item.icon} aria-hidden="true"></i>
                        {item.title}{" "}
                        {this.state.toggleIndex === index ? (
                          <i className="fa fa-caret-up" aria-hidden="true"></i>
                        ) : (
                          <i
                            className="fa fa-caret-down"
                            aria-hidden="true"
                          ></i>
                        )}
                      </a>
                      <ul
                        className="side-nav-dropdown"
                        style={
                          this.state.toggleIndex === index && item.children.length > 0
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >{this.state.toggleIndex === index && (
                        <>
                        {item.children.map((child, i) => {
                          return (
                            <li key={i}>
                              <NavLink to={child.path} activeClassName="active">
                                {child.title}
                              </NavLink>
                            </li>
                          );
                        })}</>)}
                      </ul>
                    </li>
                  );
                }
              }
            });
          })}
        </ul>
      </aside>
    );
  }
}

export default Sidebar;
