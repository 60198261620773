export const USER_BASE_URL = process.env.REACT_APP_BASE_URL;
export const DATA_BASE_URL = process.env.REACT_APP_DATA_URL;
export const MAIL_BASE_URL = process.env.REACT_APP_MAIL_URL;
export const S3_URL = process.env.REACT_APP_S3_URL;

export const CreateURL = (url: string, params: any) => {
  for (const prop in params) {
    url = url.replace(prop, params[prop]);
  }
  return url;
};

export const ALUMNI = {
  ALUMNI: `alumni`,
  DELETE: `alumni/id`,
  UPLOAD: `alumni/upload`,
  ALUMNICOUNT: `alumni/count`,
  ALUMNIBYID: `alumni/id`,
  ALUMNITRANSLATION: `alumni-translation`,
  ALUMNITRANSLATIONBYID: `alumni-translation/id`,
  ALUMNITRANSLATIONBULKUPLOAD: `alumni-translation/bulk-upload`,
  ALUMNILIST: `alumni-list`,
  UPDATEALUMNI: `updatealumni`,
};

export const ADVERTISEMENTS = {
  ADVERTISEMENTS: `advertisements`,
  UPLOAD: `advertisements/upload`,
  ADVERTISEMENTSBYID: `advertisements/id`,
  COUNT: `advertisements/count`,
};

export const BERKMAN = {
  BERKMAN: `berkman-choices`,
  BERKMANTRANSLATION: `berkman-choices/bulk-upload`,
  BERKMANCOUNT: `berkman-choices/count`,
  BERKMANBYID: `berkman-choices/id`,
  DELETE: `berkman-choices/id`,
};

export const BULKEMAIL = {
  BULKEMAIL: `bulk-email`,
  BULKEMAILCOUNT: `bulk-email/count`,
  BULKEMAILTEMPLATENAMES: `templatenames`,
  SENDEMAIL: `bulk-email`,
  BULKEMAILTEMPLATE: `template`,
  SEGMENTB: `users/segmentB`,
  SEGMENTC: `users/segmentC`,
};

export const COLOR = {
  COLOR: `colors`,
  COLORBYID: `colors/id`,
  DELETE: `colors/id`,
  PUT: `colors/id`,
  COUNT: `colors/count`,
};

export const COUNSELING = {
  COUNSELING: `counselings`,
  DELETE: `counselings/id`,
  COUNT: `counselings/count`,
  COUNSELINGUSERS: `counselings/userdetails`,
  GENERATEREPORT: `api/downloadreport`,
};

export const CAREER = {
  CAREER: `careers`,
  SUBCAREER: `sub-careers`,
  MAPPEDWITHSUBCAREER: `careers/sub-careers`,
  MERGESUBCAREERWITHCAREER: `careers/merge-sub-careers`,
  DELETE: `careers/id`,
  SUBCAREERDELETE: `sub-careers/id`,
  SUBCAREERCOUNT: `sub-careers/count`,
  CAREERCOUNT: `careers/count`,
  CAREERBYID: `careers/id`,
  CAREERTRANSLATION: `careers/translation`,
  CAREERTRANSLATIONBULKUPLOAD: "career-translation/bulk-upload",
  CAREERTRANSLATIONBYID: "career-translation",
  UPDATECAREER: "updatecareer",
  SUBCAREERTRANSLATIONBULKUPLOAD: "sub-career-translation/bulk-upload",
  SUBCAREERTRANSLATION: "sub-career-translation",
  UPDATESUBCAREER: "updatesubcareer",
};

export const CAREERDETAIL = {
  DETAIL: `career-details`,
  DELETE: `career-details/id`,
  COUNT: `career-details/count`,
  CAREERDETAILS: `careerdetails`,
  CAREERDETAILBULKUPLOAD: `career-details/bulk-upload`,
  UPDATECAREERDETAIL: `updatecareerdetails`,
};

export const COUNTRY = {
  COUNTRY: `countries`,
  COUNTRYTRANSLATION: `countries/bulk-upload`,
  COUNTRYBYID: `countries`,
  COUNTRYTRANSLATIONBYID: `countries/translation`,
  DELETE: `countries/id`,
  COUNTRYCOUNT: `countries/count`,
};

export const CAMPUS = {
  CAMPUS: `campuses`,
  UPLOAD: `campuses/upload`,
  CAMPUSBYID: `campuses/id`,
  CAMPUSCOUNT: `campuses/count`,
  CAMPUSBULKUPLOAD: "campuses/bulk-upload",
  CAMPUSTRANSLATIONBULKUPLOAD: "campuses-translation/bulk-upload",
  DOWNLOADALLCAMPUSES: "all-campuses",
  CAMPUSTRANSLATION: "campuses-translation",
  MERGEDCAMPUS: `mergedCampuses`,
  CAMPUSLIST: `campus-list`,
  CAMPUSTRANSLATIONBYID: `campuses-translation/id`,
  UPDATECAMPUS: `updatecampus`,
};

export const CAREERTRAIT = {
  CAREERTRAIT: `career-traits`,
  CAREERTRAITBYID: `career-traits/id`,
  UPDATECAREERTRAIT :`update-career-traits/id`,
  DELETE: `career-trait/id`,
  COUNT: `career-traits/count`,
  CAREERTRAITTRANSLATION: `career-traits/bulk-upload`,
};

export const CAREERIDENTIFICATION = {
  CAREERIDENTIFICATION: `career-identifications`,
  CAREERIDENTIFICATIONBULKUPLOAD: "career-identifications/bulk-upload",
  CAREERIDENTIFICATIONBYID: `career-identifications/id`,
  CAREERIDENTIFICATIONTRANSLATIONUPDATE: `update-career-identifications`,
  DELETE: `career-identifications/cid`,
  COUNT: `career-identifications/count`,
};

export const DASHBOARD = {
  USERCOUNT: `dashboard/users/count/days`,
  COUNSELINGCOUNT: `dashboard/counseling/count/days`,
  PAYMENTCOUNT: `dashboard/payment/count/days`,
};

export const DEGREE = {
  DEGREE: `degrees`,
  DEGREEUPDATE: `update-degree`,
  DEGREETRANSALATION: `degrees/bulk-upload`,
  DEGREEBYID: `degrees/id`,
  DELETE: `degrees/id`,
  COUNT: `degrees/count`,
};

export const EVENTPOPUP = {
  EVENTPOPUP: `event-popups`,
  EVENTPOPUPBYID: `event-popups/id`,
  COUNT: `event-popups/count`,
  EVENTPOPUPTRANSLATION: `event-popups-translation`,
  EVENTPOPUPTRANSLATIONBYID: `event-popups-translation/id`,
  EVENTPOPUPTRANSLATIONBULKUPLOAD: `event-popups-translation/bulk-upload`,
  EVENTUSERSREGISTRATIONLIST: `event-popups/user-registration`,
  EVENTPOPUPLIST: `events-list`,
  UPDATEEVENTPOPUPS: `updateevents`,
};

export const FEEDBACK = {
  FEEDBACK: `feedbacks`,
  COUNT: `feedbacks/count`,
  FEEDBACKUSERDETAILS: `feedbacks/userdetails`,
};

export const GRADE = {
  GRADE: `grades`,
  GRADEALL: `grades/all`,
  GRADETRANSLATION: `grades/bulk-upload`,
  GRADEBYID: `grades/id`,
  DELETE: `grades/id`,
  COUNT: `grades/count`,
};

export const HOBBY = {
  HOBBY: `hobbies`,
  HOBBYBULKUPLOAD: `hobbies/bulk-upload`,
  HOBBYBYID: `hobbies/id`,
  DELETE: `hobbies/id`,
  HOBBYCOUNT: "hobbies/count",
};

export const PAYMENT = {
  CREATE: `payments`,
  GET: `payments/detail`,
  COUNT: `payments/count`,
  UPDATE: `payments/id`,
};

export const PRODUCT = {
  GET: `products`,
};

export const PERSONALITY = {
  PERSONALITY: `personality`,
  PERSONALITYTRANSLATION: `personality-translation/bulk-upload`,
  PERSONALITYID: `personality/id`,
  PERSONALITYTRANSLATIONID: `personality-translation`,
  DELETE: `personality/id`,
  PERSONALITYCOUNT: `personality/count`,
};

export const QUESTION = {
  QUESTION: `questionaires`,
  DELETE: `questionaires/id`,
  QUESTIONLIST: `questionaire-list`,
  QUESTIONTRANSLATION: "questionaire-translation/bulk-upload",
  UPDATEQUESTIONAIRE: "updatequestions",
  DEEPDIVEQUESTIONAIRE: "deepdive-questionaire-list",
};

export const SUBJECT = {
  SUBJECT: `subjects`,
  SUBJECTTRANSLATION: `subjects/bulk-upload`,
  SUBJECTBYID: `subjects/id`,
  DELETE: `subjects/id`,
  SUBJECTCOUNT: `subjects/count`,
};

export const TEST = {
  TEST: `tests`,
  TESTQUESTION: `tests/tid/questionaires/lang`,
  DELETE: `tests/id`,
  TESTCOUNT: `tests/count`,
  TESTTRANSLATION: `tests-translation`,
  TESTTRANSLATIONBULKUPLOAD: `tests-translation/bulk-upload`,
  TESTLIST: "tests-list",
  UPDATETEST: "updatetests",
};

export const USER = {
  LOGIN: `login-users/login`,
  CHANGEPASSWORD: `login-users/change-password`,
  USER: `users`,
  COUNT: `users/count`,
  USERDETAILS: "users/userdetails",
};

export const QUIZWINNER = {
  CREATE: `quiz-winners`,
  GET: `quiz-winners`,
  COUNT: `quiz-winners/count`,
  UPDATE: `quiz-winners/id`,
};

export const WEBINARURL = {
  CREATE: `webinars`,
  DELETE : `delete-webinars`,
  GET: `webinars`,
  COUNT: `webinars/count`,
  UPDATE: `webinars/id`,
};

export const COUPON = {
  CREATE: `coupons`,
  GET: `coupons`,
  COUNT: `coupons/count`,
  COUPONSBYID:`coupons/id`,
  UPDATE: `coupons/id`,
  REDEEMLIST:`coupons/redeem/userlist`,
};

export const API = {
  USERRESULT: `api/userResults`,
};

export const SCHOLARSHIP = {
  SCHOLARSHIP: `scholarships`,
  UPLOAD: `scholarships/upload`,
  SCHOLARSHIPBYID: `scholarships/id`,
  SCHOLARSHIPCOUNT: `scholarships/count`,
  SCHOLARSHIPBULKUPLOAD: "scholarships/bulk-upload",
  SCHOLARSHIPTRANSLATIONBULKUPLOAD: "scholarships-translation/bulk-upload",
  DOWNLOADALLSCHOLARSHIP: "all-scholarships",
  SCHOLARSHIPTRANSLATION: "scholarships-translation",
  SCHOLARSHIPLIST: `scholarships`,
  SCHOLARSHIPTRANSLATIONBYID: `scholarships-translation/id`,
  UPDATESCHOLARSHIP: `updatescholarships`,
  UPDATESCHOLARSHIPTRANSALATION: `scholarships-transalation-update/id`,
};

export const FEATUREDEDUCATION = {
  FEATUREDEDUCATION: `featured-educations`,
  UPLOAD: `featured-educations/upload`,
  FEATUREDEDUCATIONBYID: `featured-educations-byid`,
  UPDATEFEATUREDEDUCATIONSTATUS: `featured-educations-status-byid`,
  FEATUREDEDUCATIONCOUNT: `featured-educations/count`,
  FEATUREDEDUCATIONBULKUPLOAD: "featured-educations/bulk-upload",
  FEATUREDEDUCATIONTRANSLATIONBULKUPLOAD: "featured-educations-translation/bulk-upload",
  DOWNLOADALLFEATUREDEDUCATION: "all-featured-educationss",
  FEATUREDEDUCATIONTRANSLATION: "featured-educations-translation",
  FEATUREDEDUCATIONLIST: `featured-educations`,
  FEATUREDEDUCATIONTRANSLATIONBYID: `featured-educations-translation/id`,
  UPDATEFEATUREDEDUCATION: `updatefeatured-educations`,
};

export const UPLOAD = `upload`;
