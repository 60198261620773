import React, { useState } from 'react';
import history from '../../createBrowserHistory';
import { Api, AuthService } from '../../service';
import { USER, USER_BASE_URL } from '../../constant';
import { LoginUserT, ConfirmPasswordT } from '../../interface';

const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[.~@$!%*#?&])[A-Za-z\d.~@$!%*#?&]{8,}$/gm;
const passwordError = "Password should be 8 characters with uppercase & lowercase letters, numbers & special characters";
function ChangePassword() {
	const [password, setPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
	};
	const handleNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNewPassword(event.target.value);
	};
	const handleConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
		setConfirmPassword(event.target.value);
	};

	const updatePassword = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (newPassword !== confirmPassword) {
			alert("New and confirm password should be same");
			return;
		}
		const passObj: ConfirmPasswordT = {
			password: password,
			newPassword: newPassword,
			confirmPassword: confirmPassword,
		};
		Api.post(USER_BASE_URL, USER.CHANGEPASSWORD, passObj)
			.then(res => {
				const result: LoginUserT = res.data;
				if (result.success) {
					AuthService.loggedOut();
					history.replace('/login');
				}
			}).catch(error => {
				alert(error.message);
			})
	}
	return (
		<div className="change-password-page">
			<div className="datatable-panel">
				<div className="datatable-header">
					<h4 className="datatable-title">Change Password</h4>
				</div>
				<div className="datatable-container">
					<div className="datatable-maindiv">
						<form onSubmit={updatePassword}>
							<div className="row">
								<div className="col-sm">
									<div className="form ">
										<input type="password" name="password" onChange={handlePassword} autoComplete="off" required />
										<label htmlFor="text" className="label-name">
											<span className="content-name">
												Currtent Password
									    </span>
										</label>
										<i className="fa fa-lock" aria-hidden="true"></i>
									</div>
								</div>

							</div>
							<div className="row">
								<div className="col-sm">
									<div className="form ">
										<input type="password" name="newPassword" onChange={handleNewPassword} autoComplete="off" required />
										<label htmlFor="text" className="label-name">
											<span className="content-name">
												New Password
									    </span>
										</label>
										<i className="fa fa-lock" aria-hidden="true"></i>
									</div>
									{newPassword !== "" && !newPassword.match(passwordPattern) && <span id="error-msg">{passwordError}</span> }
								</div>
							</div>
							<div className="row">
								<div className="col-sm">
									<div className="form ">
										<input type="password" name="confirmPassword" onChange={handleConfirmPassword} autoComplete="off" required />
										<label htmlFor="text" className="label-name">
											<span className="content-name">
												Confirm New Password
									    </span>
										</label>
										<i className="fa fa-lock" aria-hidden="true"></i>
									</div>
									{confirmPassword !== "" && !confirmPassword.match(passwordPattern) && <span id="error-msg">{passwordError}</span> }

								</div>
							</div>
							<div className="row">
								<div className="col-sm">
									<button className="form-control" type="submit" disabled={!confirmPassword.match(passwordPattern) || !newPassword.match(passwordPattern)}>
										Update Password
				         			</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}
export default ChangePassword;
