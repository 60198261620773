import React, {useEffect, useState} from "react";
import SmallTile from "./components/SmallTile";
// import MediumTile from "./components/MediumTile";
import "./Dashboard.css";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {CreateURL, DASHBOARD ,USER_BASE_URL} from  "../../constant"
import { Api } from "../../service";
const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
  },
];

const data01 = [
	{
	  "name": "Group A",
	  "value": 400
	},
	{
	  "name": "Group B",
	  "value": 300
	},
	{
	  "name": "Group C",
	  "value": 300
	},
	{
	  "name": "Group D",
	  "value": 200
	},
	{
	  "name": "Group E",
	  "value": 278
	},
	{
	  "name": "Group F",
	  "value": 189
	}
  ];
  const data02 = [
	{
	  "name": "Group A",
	  "value": 2400
	},
	{
	  "name": "Group B",
	  "value": 4567
	},
	{
	  "name": "Group C",
	  "value": 1398
	},
	{
	  "name": "Group D",
	  "value": 9800
	},
	{
	  "name": "Group E",
	  "value": 3908
	},
	{
	  "name": "Group F",
	  "value": 4800
	}
  ];

const Dashboard = () => {
  const [userCount, setUserCount] = useState(0);
  const [counselingCount, setCounselingCount] = useState(0);
  const [paymentCount, setPaymentCount] = useState(0);

  const getPageData = async () => {
    const resp: any = await Api.all(USER_BASE_URL,[
      CreateURL(DASHBOARD.USERCOUNT, {days: 0}),
      CreateURL(DASHBOARD.COUNSELINGCOUNT, {days: 0}),
      CreateURL(DASHBOARD.PAYMENTCOUNT, {days: 0}),
    ]);
    if(resp[0].data){
      setUserCount(resp[0].data.total.count);
      setCounselingCount(resp[1].data.total.count);
      setPaymentCount(resp[2].data.total.count);
    }
  }

  useEffect(() => {
    getPageData();
  }, []);

  return (
    <div className="component-content">
      <div className="row">
        <div className="col-sm-4">
          <SmallTile
            icon="fa fa-user"
            title="Registered Students"
            content={userCount}
          />
        </div>
        <div className="col-sm-4">
          <SmallTile 
            icon="fa fa-handshake-o" 
            title="Counselled Students" 
            content={counselingCount}
          />
        </div>
        <div className="col-sm-4">
          <SmallTile
            icon="fa-credit-card"
            title="Payments Collected"
            content={paymentCount}
          />
        </div>
      </div>
      <div className="row" style={{"background": "white", "padding": "20px", "margin": "9px"}}>
        <div className="col-sm-4">
          {/* <MediumTile canvasId="myChart" /> */}
          <BarChart width={330} height={250} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="pv" fill="#8884d8" />
            <Bar dataKey="uv" fill="#82ca9d" />
          </BarChart>
        </div>
        <div className="col-sm-4">
          <LineChart
            width={330}
            height={250}
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="pv" stroke="#8884d8" />
            <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
          </LineChart>
        </div>
        <div className="col-sm-4">
          <PieChart width={330} height={250}>
            <Pie
              data={data01}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={50}
              fill="#8884d8"
            />
            <Pie
              data={data02}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              fill="#82ca9d"
              label
            />
          </PieChart>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
