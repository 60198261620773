/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";
import { Multiselect } from "multiselect-react-dropdown";
import { ModalPopup, Datatable } from "../../components/common";
import { Api, Helper } from "../../service";
import {
  COLOR,
  CAREER,
  LANGUAGE,
  PAGE_SIZE,
  DATA_BASE_URL,
} from "../../constant";
import {
  SubCareerT,
  ColorT,
  MultiSelectItemT,
  MappedCareerT,
} from "../../interface";
import Select from "react-select";
const selectedIds = new Set<number>();

const Career = () => {
  const [isEdit, setIsEdit] = useState(false);
  const modalRef: any = useRef();
  const [careers, setCareers] = useState<MappedCareerT[]>([]);
  const [colors, setColors] = useState<ColorT[]>([]);
  const [careerId, setCareerId] = useState(0);
  const [arrayCount, setArrayCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(PAGE_SIZE);
  const [language, setLanguage] = useState(LANGUAGE[0]);
  const [fields, setFields] = useState([
    { name: "", detail: "", language_code: "" },
  ]);
  const [selectedValues, setSelectedValues] = useState<MultiSelectItemT[]>([]);
  const [name, setName] = useState("");
  const [detail, setDetail] = useState("");

  const handleSubmit = (e: any) => {
    isEdit ? updateCareerById(e) : addCareer(e);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case "name":
        setName(event.target.value);
        break;

      case "detail":
        setDetail(event.target.value);
    }
  };

  const onSelect = (
    selectedList: MultiSelectItemT[],
    selectedItem: MultiSelectItemT
  ) => {
    selectedIds.add(selectedItem.id);
    setSelectedValues(selectedList);
  };

  const onRemove = (
    selectedList: MultiSelectItemT[],
    removedItem: MultiSelectItemT
  ) => {
    selectedIds.delete(removedItem.id);
    setSelectedValues(selectedList);
  };

  const getCareerCount = async () => {
    try {
      const countRes = await Api.get(DATA_BASE_URL, CAREER.CAREERCOUNT);
      if (countRes && countRes.data) {
        setArrayCount(countRes.data.count);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };
  const changePage = (selectedPage: number) => {
    setCurrentPage(selectedPage);
    getCareers(selectedPage, language, perPage);
    getColors();
  };

  const selectPageSize = (event) => {
    setPerPage(parseInt(event.target.value));
    getCareers(1, language, parseInt(event.target.value));
  };

  useEffect(() => {
    pageLoad(language);
  }, []);

  const pageLoad = (lang) => {
    getColors();
    getCareers(currentPage, lang, perPage);
    getCareerCount();
  };
  const languageHandler = (lang) => {
    pageLoad(lang);
    setCurrentPage(1);
  };
  const getColors = () => {
    Api.get(DATA_BASE_URL, COLOR.COLOR)
      .then((res) => {
        const colors: ColorT[] = res.data;
        setColors(colors);
      })
      .catch((error) => {
        alert(error);
      });
  };
  const getCareers = (currentPage, lang, pageSize) => {
    setLanguage(lang);
    setPerPage(pageSize);
    setCurrentPage(currentPage);
    let skip = (currentPage - 1) * pageSize;
    setLoader(true);
    Api.get(
      DATA_BASE_URL,
      `${CAREER.MAPPEDWITHSUBCAREER}/${lang.value}?filter[limit]=${pageSize}&filter[skip]=${skip}`
    )
      .then((res) => {
        const careers: MappedCareerT[] = res.data;
        setCareers(careers);
        setLoader(false);
      })
      .catch((error) => {
        alert(error);
        setLoader(false);
      });
  };
  const getColorName = (cids: any) => {
    if (typeof cids === "object") {
      return Helper.makeStringFromArray<ColorT>(colors, "id", cids);
    } else {
      return Helper.getValueFromArray<ColorT>(colors, "id", cids, "name");
    }
  };
  const addCareer = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const careerResponse = await Api.post(DATA_BASE_URL, CAREER.CAREER, {
        color_id: Array.from(selectedIds),
      });
      if (careerResponse.status === 200) {
        fields.forEach((r: any) => {
          r["career_id"] = careerResponse.data.id;
        });
        const CareerTranslationResponse = await Api.post(
          DATA_BASE_URL,
          CAREER.CAREERTRANSLATIONBULKUPLOAD,
          fields
        );
        const data = CareerTranslationResponse.data.filter(
          (res) => res.language_code === language.value
        );
        let ArrayData = [
          { ...careerResponse.data, ...data[0], sub_career: [] },
          ...careers,
        ];
        ArrayData.sort((a: any, b: any) => a.name - b.name);
        setCareers(ArrayData);
        resetForm();
      }
    } catch (error) {
      alert(error);
    }
  };

  const editCareer = (career) => {
    if (!career.id) return;
    setIsEdit(true);
    setCareerId(career.career_id);
    setName(career.name);
    setDetail(career.detail);
    const filteredColors = colors.filter(
      (color: any) => career.color_id?.indexOf(color.id) !== -1
    );
    for (let item of filteredColors) {
      selectedIds.add(item.id);
    }
    setSelectedValues(filteredColors);
  };

  const updateCareerById = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const careerObj = {
        id: careerId,
        language_code: language.value,
        name: name,
        detail: detail,
        color_id: Array.from(selectedIds) || "[]",
      };
      await Api.post(DATA_BASE_URL, CAREER.UPDATECAREER, careerObj);
      setCurrentPage(currentPage);
      getCareers(currentPage, language, perPage);
      // let result = careers.filter((res) => res.id !== careerId);
      // const ArrayData: any = [...result, {...careerObj, sub_career:[]}];
      // ArrayData.sort((a: any, b: any) => a.name - b.name);
      // setCareers(ArrayData);
      // setCurrentPage(currentPage);
    } catch (error) {
      alert(error);
    }
  };

  // const deleteCareer = (career_id: number | undefined) => {
  //   if (!career_id) return false;
  //   Api.delete(CreateURL(CAREER.DELETE, { id: career_id }))
  //     .then((res) => {
  //       if (res.status === 204) {
  //         // getCareers();
  //       }
  //     })
  //     .catch((error) => {
  //       alert(error);
  //     });
  // };

  const handleInputFieldChange = (index, event) => {
    const values = [...fields];
    values[index][event.target.name] = event.target.value;
    setFields(values);
  };

  const addFields = () => {
    if (fields.length < LANGUAGE.length) {
      setFields([...fields, { name: "", detail: "", language_code: "" }]);
    }
  };

  const removeFields = (i) => {
    const values = [...fields];
    if (values.length > 1) {
      values.splice(i, 1);
    }
    setFields(values);
  };

  const resetForm = () => {
    setIsEdit(false);
    selectedIds.clear();
    setName("");
    setDetail("");
    setFields([{ name: "", detail: "", language_code: "" }]);
    setSelectedValues([]);
  };

  // const getBadgeColor = (color_id: number) => {
  //   let cName: string = getColorName(color_id);
  //   return `badge ics-career-badge ${cName.length ? cName.toLowerCase() : ""}`;
  // };

  const formatSubCareer = (arr: SubCareerT[]) => {
    //return Object.values(arr).map((k: any, i: number) => <div key={k['id'] + i + k['color_id']} className={getBadgeColor(k['color_id'])}>{k['name']}</div>);
    return Object.values(arr).map((k: any, i: number) => <div key={k['id'] + i}>{k['name']}</div>);
  }

  return (
    <div className="component-content">
      <ModalPopup
        className="primary"
        ref={modalRef}
        buttonLabel="Add More"
        popupTitle={`${isEdit ? "Edit" : "Add"} Career`}
        reset={resetForm}
        callbackMethod={(event: React.FormEvent<HTMLFormElement>) =>
          handleSubmit(event)
        }
      >
        <form onSubmit={handleSubmit}>
          <div>
            {!isEdit ? (
              <div className="row">
                {fields.map((field, idx) => (
                  <div key={idx} className="row">
                    <div className="col-sm-4">
                      <div className="form ">
                        <select
                          name="language_code"
                          value={field.language_code}
                          onChange={(event) =>
                            handleInputFieldChange(idx, event)
                          }
                          required
                        >
                          <option value="">Select language</option>
                          {LANGUAGE.map((l: any) => (
                            <option key={l.value} value={l.value}>
                              {l.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6 ">
                      <div className="form">
                        <input
                          type="text"
                          name="name"
                          value={field.name}
                          onChange={(event) =>
                            handleInputFieldChange(idx, event)
                          }
                          required
                        />
                        <label className="label-name">
                          <span className="content-name">Career Name</span>
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-1 icons">
                      <i className="fa fa-plus" onClick={addFields}></i>
                    </div>
                    <div className="col-sm-1 icons">
                      <i
                        className="fa fa-minus align-items-center"
                        onClick={() => removeFields(idx)}
                      ></i>
                    </div>
                    <div className="col-sm-12">
                      <div className="form">
                        <input
                          type="text"
                          name="detail"
                          value={field.detail}
                          onChange={(event) =>
                            handleInputFieldChange(idx, event)
                          }
                          required
                        />
                        <label className="label-name">
                          <span className="content-name">Description</span>
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form ">
                      <input
                        type="text"
                        name="name"
                        value={name}
                        onChange={handleChange}
                        required
                      />
                      <label className="label-name">
                        <span className="content-name">Career Name</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form ">
                      <input
                        type="text"
                        name="detail"
                        value={detail}
                        onChange={handleChange}
                        required
                      />
                      <label className="label-name">
                        <span className="content-name">Detail</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="form" style={{ overflow: "visible" }}>
                <Multiselect
                  options={colors} // Options to display in the dropdown
                  selectedValues={selectedValues} // Preselected value to persist in dropdown
                  onSelect={onSelect} // Function will trigger on select event
                  onRemove={onRemove} // Function will trigger on remove event
                  displayValue="name"
                  closeIcon="cancel"
                  showCheckbox="true"
                  style={{
                    searchBox: {
                      border: "none",
                      padding: 0,
                      marginTop: "20px",
                      height: "50px",
                    },
                    inputField: {
                      paddingTop: 0,
                    },
                    option: {
                      borderBottom: "1px solid #dbdbdb",
                      padding: "0 10px",
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      </ModalPopup>
      <Datatable
        title="Career"
        currentPage={currentPage}
        pageSize={perPage}
        description="You can view career"
        isShow={loader}
        dataCount={arrayCount}
        callbackMethod={changePage}
        callbackSelectMethod={selectPageSize}
      >
        <div className="row ">
          <div className="col-sm-2">
            <Select
              value={language}
              options={LANGUAGE}
              onChange={languageHandler}
            />
          </div>
        </div>
        <table className="datatable-table">
          <thead>
            <tr>
              <th style={{ width: "4%" }}>#</th>
              <th>Career Name</th>
              <th>Description</th>
              <th>Sub Careers</th>
              <th>Color</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {careers.length ? (
              careers.map((car: MappedCareerT, index: number) => (
                <tr key={car.id}>
                  <td>{car.id}</td>
                  <td>{car.name}</td>
                  <td>{car.detail}</td>
                  <td>{formatSubCareer(car.sub_career)}</td>
                  <td>{getColorName(car.color_id)}</td>
                  <td>
                    <i
                      className="fa fa-edit"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        modalRef.current.updateModal();
                        editCareer(car);
                      }}
                    ></i>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6}>No records found</td>
              </tr>
            )}
          </tbody>
        </table>
      </Datatable>
    </div>
  );
};

export default Career;
