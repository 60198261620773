import React from 'react';
import history from '../../createBrowserHistory';
import { Api, AuthService } from '../../service';
import { LoginUserT } from '../../interface';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { USER_BASE_URL, MAIL_BASE_URL } from '../../constant';
const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[.~@$!%*#?&])[A-Za-z\d.~@$!%*#?&]{8,}$/gm;
const passwordError = "Password should be 8 characters with uppercase & lowercase letters, numbers & special characters";
class LoginPage extends React.Component {
  state = {
    formInput: {
      email: "",
      password: ""
    },
    emailInput: {
      emailId: "",
    },
    modalPopup: {
      openModal: false,
    },
    emailIdError: "",
    resetSuccessMessage: ""
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {    
    const name = event.target.name;
    const value = event.target.value;
  
    this.setState({
      formInput: {
        ...this.state.formInput,
        [name]: value,
      },
    });
  };
  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({emailIdError: ""})    
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      emailInput: {
        ...this.state.emailInput,
        [name]: value,
      },
    });
  };

  formSubmit = (event: React.KeyboardEvent) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();
      this.login(event);
    }
  };
  login = (event: React.KeyboardEvent | React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if(!this.state.formInput.password.match(passwordPattern)) return;
    let errorMsg: any = document.getElementById("error-msg");
    const loginObj: LoginUserT = {
      username: this.state.formInput.email,
      password: this.state.formInput.password,
    };
    AuthService.loggedIn(loginObj)
      .then((res) => {
        if (res.status === 200) {
          errorMsg.classList.add("d-none");
          AuthService.isloggedIn = true;
          window.localStorage.setItem("userDetail", JSON.stringify(res.data));
          window.localStorage.setItem(
            "accessToken",
            AuthService.getAccessToken()
          );
          history.replace("/dashboard");
        }
      })
      .catch((error) => {
        errorMsg.classList.remove("d-none");
      });
  };
  emailModal = () => {
    return (
      <Modal isOpen={this.state.modalPopup.openModal}>
        <ModalHeader>Reset Username/Password</ModalHeader>
        <ModalBody>
          {!this.state.resetSuccessMessage ? (
          <form>
            <div className="row">
              <div className="col-sm">
                <div className="form ">
                  <input
                    name="emailId"
                    onChange={this.handleEmailChange}
                    required
                  />
                  <label htmlFor="text" className="label-name">
                    <span className="content-name">Email</span>
                  </label>
                </div>
                {this.state.emailIdError && (<span id="error-msg">{this.state.emailIdError}</span>)}
              </div>
            </div>
          </form>

          ): <p>{this.state.resetSuccessMessage}</p>}
        </ModalBody>
        <ModalFooter>
          {!this.state.resetSuccessMessage && (
          <Button
            color="primary"
            disabled={this.state.emailInput.emailId === ""}
            onClick={(event) => this.handleSubmit(event)}
            size="sm"
          >
            Submit
          </Button>)}
          {""}
          {!this.state.resetSuccessMessage ? (
          <Button
            color="primary"
            onClick={() => {
              this.setState({
                emailInput: { emailId: "" },
                modalPopup: { openModal: false },
              });
            }}
            size="sm"
          >
            Cancel
          </Button>
          ) : (<Button color="warning" style={{color: "#133B84", background:"#FFC118"}} onClick={()=>{this.setState({modalPopup:{openModal: false}})}}>Ok</Button> )}
        </ModalFooter>
      </Modal>
    );
  };

  generateHash = () => {
    let result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 30; i++) {
      result += characters.charAt(Math.floor(Math.random() * 30));
    }
    return result;
  };

  getHashToken = async (email) => {
    try {
      const tokenObj = {
        email: email,
        hash_token: this.generateHash(),
      };
      const resp = await Api.post(USER_BASE_URL,"users-hashes", tokenObj);
      if (resp?.data) {
        return tokenObj.hash_token;
      }
    } catch (error) {
      console.log(error);
    }
  };
  handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      let obj = {
        email: this.state.emailInput.emailId,
        token: await this.getHashToken(this.state.emailInput.emailId),
        channel: 'admin'
      };
      const resp = await Api.post(MAIL_BASE_URL, "email/user/forgot-password", obj);
      if (resp.data.status === 200) {
        this.setState({
          resetSuccessMessage: `Password reset email has been sent to ${obj.email}. Click on the reset your password link in the email to reset password!`,
          emailInput: {emailId: ""}
        });
      } if(resp.data.status === 401){
        this.setState({emailIdError: resp.data.message})
      }
    } catch (error) {
      this.setState({emailIdError: error.message})
    }
  };
  openEmailPopup = () => {
    this.setState({
      modalPopup: {
        openModal: true,
      },
    });
  };
  render() {
    return (
      <div id="ics-login-container">
        <div className="login-page">
          <div className="logo text-center">
            <img
              className="logo-image"
              style={{ width: "80px", marginBottom: "20px" }}
              src={require("../../assets/images/logo.png").default}
              alt="logo"
            />
          </div>
          <div className="datatable-panel">
            <div className="datatable-header">
              <h4 className="datatable-title">Admin Panel</h4>
            </div>
            {this.emailModal()}
            <div className="datatable-container">
              <div className="datatable-maindiv login-block">
                <form onSubmit={this.login}>
                  <div id="error-msg" className="d-none text-center">
                    Username or Password is incorrect.
                  </div>
                  <div className="row">
                    <div className="col-sm">
                      <div className="form ">
                        <input
                          type="text"
                          name="email"
                          onChange={this.handleChange}
                          required
                        />
                        <label htmlFor="text" className="label-name">
                          <span className="content-name">
                            User Name or Mail Id
                          </span>
                        </label>
                        <i className="fa fa-user" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm">
                      <div className="form ">
                        <input
                          type="password"
                          name="password"
                          onChange={this.handleChange}
                          onKeyDown={(event) => this.formSubmit(event)}
                          required
                        />
                        <label htmlFor="text" className="label-name">
                          <span className="content-name">Password</span>
                        </label>
                        <i className="fa fa-lock" aria-hidden="true"></i>
                      </div>
                      {this.state.formInput.password !== "" && !this.state.formInput.password.match(passwordPattern) ? <span id="error-msg">{passwordError}</span> : ""}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm">
                      <p className="forget-password">
                        <a
                          type="button"
                          onClick={this.openEmailPopup}
                          href="/#"
                        >
                          Forgotten Username/Password
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm">
                      <Button className="form-control" type="submit" disabled={this.state.formInput.password === "" || !this.state.formInput.password.match(passwordPattern)} >
                        Login
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginPage;
