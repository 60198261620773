import {Api} from './Api';

export const CrudService = {
    async addRow(baseUrl: any, path: string, obj: any) {
        try {
            const response = await Api.post(baseUrl, path, obj);
            if(response?.status === 200){
                return response.data;
            }
        } catch (error) {
            console.log(JSON.stringify(error));
        }
    },
    async deleteRow(baseUrl: any, path: string) {
        try {
            const response = await Api.delete(baseUrl, path);
            if(response?.status === 204){
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.log(JSON.stringify(error));
        }
    },
    async updateById(baseUrl: any, path: string, obj: any) {
        try {
            const response = await Api.patch(baseUrl, path, obj);
            if(response?.status === 204){
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.log(JSON.stringify(error));
        }
    },
    async getCount(baseUrl: any, path: string) {
        try {
            const {data: countRes} = await Api.get(baseUrl, path);
            return countRes;
        } catch (error) {
            console.log(JSON.stringify(error));
        }
    },
    async getData(baseUrl: any, path: string) {
        try {
            const {data: response} = await Api.get(baseUrl, path);
            return response;
        } catch (error) {
            console.log(JSON.stringify(error));
        }
    }
};