/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { ModalPopup, Datatable } from "../../components/common";
import { Api } from "../../service";
import {
  PAGE_SIZE,
  CreateURL,
  COUNTRY,
  LANGUAGE,
  DATA_BASE_URL,
} from "../../constant";
import { CountryT, LanguageT } from "../../interface";
import Select from "react-select";
const Country = (props: any) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [language, setLanguage] = useState(LANGUAGE[0]);
  const [loader, showLoader] = useState(false);
  const [countries, getCountries] = useState<CountryT[]>([]);
  const [arrayCount, setArrayCount] = useState(0);
  const [isEdit, setisEdit] = useState(false);
  const [perPage, setPerPage] = useState(PAGE_SIZE);
  const [id, setId] = useState();
  const languageHandler = (lang) => {
    getCountry(currentPage, perPage, lang);
    setLanguage(lang);
    setCurrentPage(1);
  };
  const modalRef: any = useRef(null);

  const [code, setCode] = useState("");
  const [country_id, setCountryId] = useState("");
  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
  };

  const [nameEn, setNameEn] = useState("");
  const [nameHi, setNameHi] = useState("");
  const [name, setName] = useState("");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case "name_en":
        setNameEn(event.target.value);
        break;
      case "name_hi":
        setNameHi(event.target.value);
        break;
      case "name":
        setName(event.target.value);
        break;
    }
  };
  const resetForm = () => {
    setisEdit(false);
    setNameEn("");
    setNameHi("");
    setCode("");
  };

  useEffect(() => {
    getCountryCount();
    getCountry(currentPage, perPage, language);
    setLanguage(language);
  }, []);

  const changePage = (selectedPage: number) => {
    setCurrentPage(selectedPage);
    getCountry(selectedPage, perPage, language);
  };
  const selectPageSize = (event: any) => {
    setPerPage(parseInt(event.target.value));
    setCurrentPage(1);
    getCountry(1, parseInt(event.target.value), language);
  };
  const handleSubmit = (e) => {
    isEdit ? updateCountryById(e) : addCountry(e);
  };
  const getCountryCount = async () => {
    try {
      const countRes = await Api.get(DATA_BASE_URL, COUNTRY.COUNTRYCOUNT);
      if (countRes && countRes.data) {
        setArrayCount(countRes.data.count);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getCountry = async (currentPage: number, pageSize, lang) => {
    showLoader(true);
    try {
      setLanguage(lang);
      let limit = pageSize;
      let skip = (currentPage - 1) * limit;
      const response: any = await Api.get(
        DATA_BASE_URL,
        `${COUNTRY.COUNTRY}?filter[where][language_code]=${lang.value}&filter[limit]=${limit}&filter[skip]=${skip}`
      );
      if (response && response.data) {
        getCountries(response.data);
      }
      showLoader(false);
    } catch (error) {
      showLoader(false);
      console.log(JSON.stringify(error));
    }
  };

  const addCountry = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const countryObj: CountryT = {
      code: code,
      name_en: nameEn,
      name_hi: nameHi,
      name: name,
    };
    const countryTabObj = {
      code: code,
    };
    Api.post(DATA_BASE_URL, COUNTRY.COUNTRY, countryTabObj)
      .then((res) => {
        const countryInsObj = [
          {
            country_id: res.data.id,
            language_code: "en",
            name: nameEn,
          },
          {
            country_id: res.data.id,
            language_code: "hi",
            name: nameHi,
          },
        ];
        Api.post(DATA_BASE_URL, COUNTRY.COUNTRYTRANSLATION, countryInsObj)
          .then((resT) => {
            console.log("added");
          })
          .catch((error) => {
            console.log(error.message);
          });

        getCountries([countryObj, ...countries]);
        getCountry(currentPage, perPage, language);
        resetForm();
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const editCountry = (country) => {
    if (!country.id) return;
    setisEdit(true);
    setId(country.id);
    setCode(country.code);
    setNameEn(country.name_en);
    setNameHi(country.name_hi);
    setName(country.name);
    setCountryId(country.id);
  };
  const updateCountryById = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const countryObj: CountryT = {
      id: id,
      code: code,
      name_en: nameEn,
      name_hi: nameHi,
      name: name,
    };
    const countryUpdateObj = { id: country_id, code: code };
    const countryTranslationUpdateObj = {
      country_id: id,
      name: name,
      language_code: language.value,
    };

    Api.patch(
      DATA_BASE_URL,
      COUNTRY.COUNTRYBYID + `/${countryUpdateObj.id}`,
      countryUpdateObj
    )
      .then((res) => {
        Api.patch(
          DATA_BASE_URL,
          CreateURL(COUNTRY.COUNTRYTRANSLATIONBYID, {
            id: countryTranslationUpdateObj.id,
          }),
          countryTranslationUpdateObj
        )
          .then((resT) => {
            console.log("updated");
          })
          .catch((error) => {
            console.log(error.message);
          });
        if (res.status === 204) {
          const result = countries.filter((res) => res.id !== id);
          const Array = [{ ...countryObj, id: id }, ...result];
          Array.sort((a: any, b: any) => a.id - b.id);
          getCountries(Array);
          setCurrentPage(currentPage);
          resetForm();
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  // const deleteCountry = (country_id: (number | undefined)) => {
  // 	if(!country_id) return;
  // 	Api.delete(CreateURL(COUNTRY.DELETE, {id: country_id}))
  // 		.then(res => {
  // 			const filterResult = countries.filter(res => res.id !== country_id);
  //             getCountries(filterResult);
  // 		}).catch(error => {
  // 			console.log(error.message);
  // 		})
  // }
  return (
    <div className="component-content">
      <ModalPopup
        className="primary"
        ref={modalRef}
        buttonLabel="Add More"
        reset={resetForm}
        popupTitle={`${isEdit ? "Edit" : "Add"} Country`}
        callbackMethod={(event: React.FormEvent<HTMLFormElement>) =>
          handleSubmit(event)
        }
      >
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm">
              <div className="form ">
                <input
                  type="text"
                  name="name"
                  value={code}
                  onChange={handleCodeChange}
                  required
                />
                <label className="label-name">
                  <span className="content-name">Country Code</span>
                </label>
              </div>
            </div>
          </div>
          {/* { LANGUAGE.map((lang:LanguageT) =>
                    <div key={lang.label} className="row">
                        <div className="col-sm">
                            <div className="form ">
                                <input type="text" name={`name_${lang.value}`} value={lang.value === 'en' ? nameEn : nameHi} onChange={ lang.value === 'en' ? handleNameChangeEn : handleNameChangeHi} required />
                                <label  className="label-name">
                                    <span className="content-name">
                                       Name({lang.label})
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    )} */}
          {LANGUAGE.map((lang: LanguageT) => {
            if (isEdit && lang.value === language.value) {
              return (
                <fieldset key={lang.value}>
                  <legend>{lang.label}</legend>
                  <div className="row">
                    <div className="col-sm">
                      <div className="form ">
                        <input
                          type="text"
                          name={`name`}
                          value={name}
                          onChange={handleChange}
                          required
                        />
                        <label className="label-name">
                          <span className="content-name">
                            Name({lang.label})
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>
              );
            }
            if (!isEdit) {
              return (
                <fieldset key={lang.value}>
                  <legend>{lang.label}</legend>
                  <div className="row">
                    <div className="col-sm">
                      <div className="form ">
                        <input
                          type="text"
                          name={`name_${lang.value}`}
                          onChange={handleChange}
                          required
                        />
                        <label className="label-name">
                          <span className="content-name">
                            Name({lang.label})
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>
              );
            }
          })}
        </form>
      </ModalPopup>
      <Datatable
        title="Country"
        description="You can view country"
        isShow={loader}
        currentPage={currentPage}
        pageSize={perPage}
        dataCount={arrayCount}
        callbackMethod={changePage}
        callbackSelectMethod={selectPageSize}
      >
        <div className="row ">
          <div className="col-sm-2">
            <Select
              value={language}
              options={LANGUAGE}
              onChange={languageHandler}
            />
          </div>
        </div>
        <table className="datatable-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Country Code</th>
              <th>Country Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {countries.length ? (
              countries.map((country: CountryT) => (
                <tr key={country.id}>
                  <td>{country.id}</td>
                  <td>{country.code}</td>
                  <td>{country.name} </td>
                  <td>
                    <i
                      className="fa fa-edit"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        modalRef.current?.updateModal();
                        editCountry(country);
                      }}
                    ></i>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3}>No records found</td>
              </tr>
            )}
          </tbody>
        </table>
      </Datatable>
    </div>
  );
};

export default Country;
