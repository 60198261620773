export const PAGE_SIZE = 50;

export const PER_PAGE_LIMIT = 100;

export const LANGUAGE = [
  { label: "English", value: "en" },
  { label: "Hindi", value: "hi" },
];

export const SORTORDER = {
  desc: "DESC",
  asc: "ASC",
};
