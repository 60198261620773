import React, { useState, useEffect, useRef } from "react";
import { ModalPopup, Datatable } from "../components/common";
import { Api, ExportExcel } from "../service";
import { PaymentT } from "../interface";
import {
  CreateURL,
  PAYMENT,
  PRODUCT,
  PAGE_SIZE,
  USER_BASE_URL
} from "../constant";
import dateFormat from "dateformat";
import _ from "lodash";

const Payment = () => {
  const [loader, showLoader] = useState(false);
  const [payments, setPayments] = useState<PaymentT[]>([]);
  const [prodList, setProdList] = useState<any[]>([]);
  const [currentId, setId] = useState(0);
  const [username, setUsername] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [prodName, setProdName] = useState("");
  const [prodPrice, setProdPrice] = useState("");
  const [payMode, setPayMode] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [arrayCount, setArrayCount] = useState(0);
  const [isEdit, setisEdit] = useState(false);
  const [perPage, setPerPage] = useState(PAGE_SIZE);

  const modalRef: any = useRef(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case "username":
        setUsername(event.target.value);
        break;
      case "contact":
        setContact(event.target.value);
        break;
      case "email":
        setEmail(event.target.value);
        break;
      case "prodPrice":
        setProdPrice(event.target.value);
        break;
    }
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    switch (event.target.name) {
        case "prodName":
            setProdName(event.target.value);
            const filterArr = prodList.filter(p => p.id === parseInt(event.target.value));
            if(filterArr?.length){
                setProdPrice(filterArr[0].price);
            }
            break;
        case "payMode":
            setPayMode(event.target.value);
            break;
    }
  }

  const resetForm = () => {
    setisEdit(false);
    setUsername("");
    setContact("");
    setEmail("");
    setProdName("");
    setProdPrice("");
    setPayMode("");
    setId(0);
  };

  const changePage = (selectedPage: number) => {
    setCurrentPage(selectedPage);
    getPayments(selectedPage, perPage);
  };

  const selectPageSize = (event) => {
    setPerPage(parseInt(event.target.value));
    setCurrentPage(1);
    getPayments(currentPage, parseInt(event.target.value));
  };

  useEffect(() => {
    getProdname();
    getPayments(currentPage,perPage);
    getPaymentsCount();
  }, [currentPage, perPage]);

  const getProdname = async () => {
      try{
        const resp = await Api.get(USER_BASE_URL, `${PRODUCT.GET}?filter[where][language_code]=en`);
        if(resp?.data){
            setProdList(resp.data);
        }
      } catch (err) {
          console.log(err);
      }
  }

  const getPayments = (currentPage: number = 1, pageSize: number) => {
		showLoader(true);
    let skip = (currentPage - 1) * pageSize;
    Api.get(
      USER_BASE_URL, 
      `${PAYMENT.GET}?filter[limit]=${pageSize}&filter[skip]=${skip}`,
      )
      .then((res: any) => {
        showLoader(false);
        setPayments(res.data);
        setCurrentPage(currentPage)
      })
      .catch((error) => {
        showLoader(false);
        alert(error.message);
      });
  };

  const getPaymentsCount = () => {
    Api.get(USER_BASE_URL, PAYMENT.COUNT)
    .then((res: any) => {
      showLoader(false);
      setArrayCount(res.data.count);
    })
    .catch((error) => {
      showLoader(false);
      alert(error.message);
    });
  }

  const getProdNames = (arr) => {
    if(!arr.includes('[')){
      return arr;
    } else {
      const result = _.differenceWith(prodList, JSON.parse(arr), (x,y) => x.product_id !== y.id);
      return result[0]?.name;
    }
  }

  const handleSubmit = (e: any) => {
    isEdit ? updatePaymentsById(e) : addPayments(e);
  };

  const addPayments = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const pObj: PaymentT = {
        user_id: 0,
        username: username,
        contact: contact,
        email: email,
        order_id: "-",
        payment_id: "-",
        pay_status: true,
        products: `[{"id": ${prodName},"price": ${prodPrice}}]`,
        total_amount: prodPrice,
        pay_mode: payMode,
      };
      const response = await Api.post(USER_BASE_URL, PAYMENT.CREATE, pObj);
      if (response.status === 200) {
        setPayments([...payments, ...response.data])
      }
    } catch (error) {
      console.log(error);
    }
  }

  const editPayments = (pObj) => {
    if (!pObj.id) return;
    setId(pObj.id);
    setisEdit(true);
    setUsername(pObj.username);
    setContact(pObj.contact);
    setEmail(pObj.email);
    setProdName(JSON.parse(pObj.products)[0].id);
    setProdPrice(pObj.total_amount);
    setPayMode(pObj.pay_mode || "App");
  };

  const updatePaymentsById = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    try {
      const pObj: PaymentT = {
        username: username,
        contact: contact,
        email: email,
        products: `[{"id": ${prodName},"price": ${prodPrice}}]`,
        total_amount: prodPrice,
        pay_mode: payMode,
      };
      const response = await Api.patch(
        USER_BASE_URL, 
        CreateURL(PAYMENT.UPDATE, { id: currentId }),
        pObj
      );
      if (response.status === 204) {
        const result = payments.filter(res => res.id !== currentId);
        const ArrayData = [...result, {...pObj, id: currentId}]
        ArrayData.sort((a:any,b:any) => a.id -  b.id);
        setPayments(ArrayData);
      }
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <div className="component-content">
      <ModalPopup
        className="primary"
        ref={modalRef}
        buttonLabel="Add More"
        popupTitle={`${isEdit ? "Edit" : "Add"} Payment`}
        reset={resetForm}
        callbackMethod={(event: React.FormEvent<HTMLFormElement>) => {
          handleSubmit(event);
          resetForm();
        }}
      >
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-12">
              <div className="form">
                <select
                  name="prodName"
                  value={prodName}
                  onChange={(event) => {
                    handleSelectChange(event);
                  }}
                  required
                >
                  <option value="">Select product</option>
                  {prodList.map((prod: any, i) => (
                    <option key={prod.id} value={prod.id}>
                      {prod.name}
                    </option>
                  ))}
                  <option value={0}>School Counselling</option>
                  <option value={0}>Seminars/Webinars</option>
                  <option value={0}>Teachers Training</option>
                </select>
              </div>
            </div>
            <div className="col-sm-12">
                <div className="form ">
                  <input
                    type="text"
                    name="prodPrice"
                    value={prodPrice}
                    onChange={handleChange}
                    required
                  />
                  <label className="label-name">
                    <span className="content-name">Product Price</span>
                  </label>
                </div>
            </div>
            <div className="col-sm-12">
              <div className="form">
                <select
                  name="payMode"
                  value={payMode}
                  onChange={(event) => {
                    handleSelectChange(event);
                  }}
                  required
                >
                  <option value="">Payment Mode</option>
                  <option value={"BankTransfer"}>Bank Transfer/Net Banking</option>
                  <option value={"Cash"}>Cash</option>
                  <option value={"GPay"}>GPay</option>
                  <option value={"Paytm"}>Paytm</option>
                  <option value={"PhonePe"}>PhonePe</option>
                </select>
              </div>
            </div>
            <div className="col-sm-12">
                <div className="form ">
                  <input
                    type="text"
                    name="username"
                    value={username}
                    onChange={handleChange}
                    required
                  />
                  <label className="label-name">
                    <span className="content-name">Username</span>
                  </label>
                </div>
            </div>
            <div className="col-sm-12">
                <div className="form ">
                  <input
                    type="text"
                    name="contact"
                    value={contact}
                    onChange={handleChange}
                  />
                  <label className="label-name">
                    <span className="content-name">Contact</span>
                  </label>
                </div>
            </div>
            <div className="col-sm-12">
                <div className="form ">
                  <input
                    type="text"
                    name="email"
                    value={email}
                    onChange={handleChange}
                  />
                  <label className="label-name">
                    <span className="content-name">Email</span>
                  </label>
                </div>
            </div>
          </div>
        </form>
      </ModalPopup>
      <Datatable
        title="Payments"
        description="Track all payments here"
        isShow={loader}
        currentPage={currentPage}
        pageSize={perPage}
        dataCount={arrayCount}
        callbackMethod={changePage}
        callbackSelectMethod={selectPageSize}
      >
        <div className="row">
        <div className="col-sm-12 mr-1">
            <div className="float-right">
              <button
                id="download-csv"
                className="btn btn-sm btn-outline-primary"
                onClick={()=>ExportExcel(payments, "Payments")}
              >
                <i className="fa fa-file-excel-o" /> Download
              </button>
            </div>
          </div>
        </div>
        <table className="datatable-table">
          <thead>
            <tr>
              <th>#</th>
              <th>User Detail</th>
              <th>Product Name</th>
              <th>Amount</th>
              <th>Mode</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {payments.length ? (
              payments.map((p: PaymentT) => (
                <tr key={p.id}>
                  <td>{p.id}</td>
                  <td>
                  {(p.pay_mode !== 'App') ? <>
                  {p.username}<br />
                  {p.contact}<br />
                  {p.email}
                  </> : <>
                    {p.appuser}<br />
                    {p.appcontact}<br />
                    {p.appemail}
                  </>}
                  </td>
                  <td>{getProdNames(p.products)}</td>
                  <td>{p.total_amount}</td>
                  <td>{p.pay_mode || 'App'}</td>
                  <td>{dateFormat(p.pay_date, "mmmm dS, yyyy")}</td>
                  <td>
                    {(p.pay_mode !== null) ? <i
                      className="fa fa-edit"
                      style={{ cursor: "pointer"}}
                      onClick={() => {
                        modalRef.current?.updateModal();
                        editPayments(p);
                      }}
                    ></i> : null}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6}>No records found</td>
              </tr>
            )}
          </tbody>
        </table>
      </Datatable>
    </div>
  );
};

export default Payment;
