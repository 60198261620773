import React, { createRef } from "react";
import { ModalPopup, Datatable } from "../../components/common";
import { Api } from "../../service";
import {
  PAGE_SIZE,
  CreateURL,
  HOBBY,
  LANGUAGE,
  DATA_BASE_URL,
} from "../../constant";
import { HobbyT, LanguageT } from "../../interface";
import Select from "react-select";
class Hobby extends React.Component {
  state = {
    formInput: {
      name_en: "",
      name_hi: "",
      name: "",
    },
    hobbies: [],
    currentId: 0,
    arrayCount: 0,
    currentPage: 1,
    loader: false,
    perPage: PAGE_SIZE,
    lang: LANGUAGE[0],
  };
  isEdit = false;
  modalRef: any = createRef();

  handleSubmit = (e: any) => {
    this.isEdit ? this.updateHobbiesById(e) : this.addHobby(e);
  };
  languageHandler = (lang) => {
    this.getHobbies(this.state.currentPage, this.state.perPage, lang);
    this.state.lang = lang;
  };
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      formInput: {
        ...this.state.formInput,
        [name]: value,
      },
    });
  };
  changePage = (selectedPage: number) => {
    this.setState(
      {
        currentPage: selectedPage,
      },
      () => {
        this.getHobbies(selectedPage, this.state.perPage, this.state.lang);
      }
    );
  };
  selectPageSize = (event) => {
    this.setState({
      perPage: parseInt(event.target.value),
      currentPage: 1,
    });
    this.getHobbies(
      this.state.currentPage,
      parseInt(event.target.value),
      this.state.lang
    );
  };
  componentDidMount() {
    this.getHobbies(
      this.state.currentPage,
      this.state.perPage,
      this.state.lang
    );
    this.getHobbiesCount();
  }
  getHobbiesCount = async () => {
    const countRes = await Api.get(DATA_BASE_URL, HOBBY.HOBBYCOUNT);
    if (countRes && countRes.data) {
      this.setState({ arrayCount: countRes.data.count });
    }
  };
  getHobbies = (currentPage, pageSize, lang) => {
    this.setState({ loader: true });
    let limit = pageSize;
    let skip = (currentPage - 1) * limit;
    Api.get(
      DATA_BASE_URL,
      `${HOBBY.HOBBY}?filter[where][language_code]=${lang.value}&filter[limit]=${limit}&filter[skip]=${skip}`
    )
      .then((res) => {
        const hobbies: HobbyT[] = res.data;
        this.setState({ hobbies: hobbies, loader: false });
      })
      .catch((error) => {
        this.setState({ loader: false });
        console.log(error.message);
      });
  };
  addHobby = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    Api.post(DATA_BASE_URL, HOBBY.HOBBY, {})
      .then((res) => {
        const hobbiesInsObj = [
          {
            name: this.state.formInput.name_en,
            language_code: "en",
            hobby_id: res.data.id,
          },
          {
            name: this.state.formInput.name_hi,
            language_code: "hi",
            hobby_id: res.data.id,
          },
        ];
        Api.post(DATA_BASE_URL, HOBBY.HOBBYBULKUPLOAD, hobbiesInsObj)
          .then((resT) => {
            const updatedData = [...resT.data, this.state.hobbies];
            this.setState({ hobbies: updatedData });
          })
          .catch((error) => {
            console.log(error.message);
          });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  editHobby = (hobby: HobbyT) => {
    if (!hobby.id) return;
    this.isEdit = true;
    this.setState({ currentId: hobby.id });
    this.setState({
      formInput: {
        name_en: hobby.name_en,
        name_hi: hobby.name_hi,
        name: hobby.name,
      },
    });
  };
  updateHobbiesById = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // const hobbiesObj: HobbyT = {...this.state.formInput};
    const hobbiesObj = {
      name: this.state.formInput.name,
    };
    Api.patch(
      DATA_BASE_URL,
      CreateURL(HOBBY.HOBBYBYID, { id: this.state.currentId }),
      hobbiesObj
    )
      .then((res) => {
        if (res.status === 204) {
          const result = this.state.hobbies.filter(
            (res: any) => res.id !== this.state.currentId
          );
          const Array = [
            ...result,
            { id: this.state.currentId, ...hobbiesObj },
          ];
          Array.sort((a, b) => a.id - b.id);
          this.setState({ hobbies: Array, loader: false });
          console.log("updated");
          this.resetForm();
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  deleteHobby = (hobby_id: number | undefined) => {
    if (!hobby_id) return;
    Api.delete(DATA_BASE_URL, CreateURL(HOBBY.DELETE, { id: hobby_id }))
      .then((res) => {
        if (res.status === 204) {
          const result = this.state.hobbies.filter((res: any) => {
            return res.id !== hobby_id;
          });
          this.setState({ hobbies: result, showLoader: false });
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  resetForm = () => {
    this.isEdit = false;
    this.setState({
      formInput: {
        name_en: "",
        name_hi: "",
        name: "",
      },
    });
  };
  render() {
    return (
      <div className="component-content">
        <ModalPopup
          ref={this.modalRef}
          className="primary"
          buttonLabel="Add More"
          popupTitle={`${this.isEdit ? "Edit" : "Add"} Hobby`}
          reset={this.resetForm}
          callbackMethod={(event: React.FormEvent<HTMLFormElement>) =>
            this.handleSubmit(event)
          }
        >
          <form onSubmit={this.handleSubmit}>
            {LANGUAGE.map((lang: LanguageT) => {
              if (this.isEdit && lang.value === this.state.lang.value) {
                return (
                  <fieldset key={lang.value}>
                    <legend>{lang.label}</legend>
                    <div key={lang.value} className="row">
                      <div className="col-sm">
                        <div className="form ">
                          <input
                            type="text"
                            name={`name`}
                            value={this.state.formInput.name}
                            onChange={this.handleChange}
                            required
                          />
                          <label className="label-name">
                            <span className="content-name">Name</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                );
              }
              if (!this.isEdit) {
                return (
                  <fieldset key={lang.value}>
                    <legend>{lang.label}</legend>
                    <div key={lang.value} className="row">
                      <div className="col-sm">
                        <div className="form ">
                          <input
                            type="text"
                            name={`name_${lang.value}`}
                            onChange={this.handleChange}
                            required
                          />
                          <label className="label-name">
                            <span className="content-name">Name</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                );
              }
            })}
          </form>
        </ModalPopup>
        <Datatable
          title="Hobby"
          description="You can view different hobbies"
          isShow={this.state.loader}
          currentPage={this.state.currentPage}
          pageSize={this.state.perPage}
          dataCount={this.state.arrayCount}
          callbackMethod={this.changePage}
          callbackSelectMethod={this.selectPageSize}
        >
          <div className="row ">
            <div className="col-sm-2">
              <Select
                value={this.state.lang}
                options={LANGUAGE}
                onChange={this.languageHandler}
              />
            </div>
          </div>
          <table className="datatable-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Hobby</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.state.hobbies.length ? (
                this.state.hobbies.map((hobby: HobbyT) => (
                  <tr key={hobby.id}>
                    <td>{hobby.id}</td>
                    <td>{hobby.name} </td>
                    <td>
                      <i
                        className="fa fa-edit"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.editHobby(hobby);
                          this.modalRef.current.updateModal();
                        }}
                      ></i>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3}>No records found</td>
                </tr>
              )}
            </tbody>
          </table>
        </Datatable>
      </div>
    );
  }
}

export default Hobby;
