import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom'; 
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import history from '../../../createBrowserHistory';
import { AuthService } from '../../../service';

const UserDropDown = () => {
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);

  let details = window.localStorage.getItem('userDetail');
  let userDetail = details ? JSON.parse(details): null;
  const logout = () => {
    AuthService.loggedOut();
    history.replace('/login');
  }
  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret>
        <span style={{ textTransform: "capitalize" }}>Hi {userDetail?.username}</span>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem><NavLink to="/change-password">Change Password</NavLink></DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={() => logout()}>Log out</DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  );
}

function Header(props: any){
  const { location } = props;
  const toggleMenu = () => {
    document.querySelector(".side-nav")?.classList.toggle('show-side-nav');
    document.querySelector("#contents")?.classList.toggle('margin');
    document.querySelector(".navbar")?.classList.toggle('width-100');
  }
    return (
      <nav className="navbar navbar-default">
        <div className="container-fluid" style={{position:"relative"}}>
          <div className="navbar-header">
            <Link className="navbar-brand" to="/">{(location.pathname).substr(1)}</Link>
          </div>
          <div className="navbar-collapse navbar-right" id="navbar-collapse">
            <UserDropDown />
            <div onClick={() => toggleMenu()} >
              <i data-show="show-side-navigation1" className="fa fa-bars show-side-btn"></i>
            </div>
          </div>
        </div>
      </nav>
  );
}

export default Header;
