import React, { createRef } from "react";
import Select from "react-select";
import { ModalPopup, Datatable } from "../../components/common";
import { CrudService } from "../../service";
import {
  PAGE_SIZE,
  CreateURL,
  BERKMAN,
  LANGUAGE,
  DATA_BASE_URL,
} from "../../constant";
import { BerkmanChoiceT, LanguageT } from "../../interface";

class BerkmanChoice extends React.Component {
  state = {
    formInput: {
      choiceA_en: "",
      choiceB_en: "",
      choiceA_hi: "",
      choiceB_hi: "",
      choiceA: "",
      choiceB: "",
    },
    berkmanChoice: [],
    arrayCount: 0,
    currentId: 0,
    currentPage: 1,
    language: LANGUAGE[0],
    loader: false,
    perPage: PAGE_SIZE,
  };
  isEdit = false;
  modalRef: any = createRef();
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      formInput: {
        ...this.state.formInput,
        [name]: value,
      },
    });
  };
  setCurrentPage = (currPage: number) => {
    this.setState({ currentPage: currPage });
  };
  languageHandler = (lang) => {
    this.pageLoader(lang);
    this.setCurrentPage(1);
  };
  pageLoader = (lang) => {
    this.setState({ language: lang });
    this.getBerkmanChoiceCount();
    this.getBerkmanChoices(
      this.state.currentPage,
      this.state.perPage,
      lang.value
    );
  };
  changePage = (selectedPage: number) => {
    this.setState(
      {
        currentPage: selectedPage,
      },
      () => {
        this.getBerkmanChoices(
          selectedPage,
          this.state.perPage,
          this.state.language.value
        );
      }
    );
  };
  selectPageSize = (event) => {
    this.setState({
      perPage: parseInt(event.target.value),
      currentPage: 1,
    });
    this.getBerkmanChoices(
      this.state.currentPage,
      parseInt(event.target.value),
      this.state.language.value
    );
  };
  handleSubmit = (e: any) => {
    this.isEdit ? this.updateBerkmanChoiceById(e) : this.addBerkmanChoice(e);
  };
  componentDidMount() {
    this.getBerkmanChoiceCount();
    this.getBerkmanChoices(
      this.state.currentPage,
      this.state.perPage,
      this.state.language.value
    );
  }
  getBerkmanChoiceCount = async () => {
    const countRes = await CrudService.getCount(
      DATA_BASE_URL,
      BERKMAN.BERKMANCOUNT
    );
    if (countRes && countRes.count) {
      this.setState({ arrayCount: countRes.count });
    }
  };
  getBerkmanChoices = async (currentPage, pageSize, lang) => {
    this.setState({ loader: true });
    let limit = pageSize;
    let skip = (currentPage - 1) * limit;
    const response = await CrudService.getCount(
      DATA_BASE_URL,
      `${BERKMAN.BERKMAN}?filter[where][language_code]=${lang}&filter[limit]=${limit}&filter[skip]=${skip}`
    );
    this.setState({ loader: false });
    if (response) {
      this.setState({ berkmanChoice: response });
    }
  };
  addBerkmanChoice = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const choiceberkman = {};

    const response = await CrudService.addRow(
      DATA_BASE_URL,
      BERKMAN.BERKMAN,
      choiceberkman
    );
    const choiceObjs = [
      {
        berkman_id: response.id,
        language_code: "en",
        choiceA: this.state.formInput.choiceA_en,
        choiceB: this.state.formInput.choiceB_en,
      },
      {
        berkman_id: response.id,
        language_code: "hi",
        choiceA: this.state.formInput.choiceA_hi,
        choiceB: this.state.formInput.choiceB_hi,
      },
    ];

    // const responseT = await CrudService.addRow(
    //   DATA_BASE_URL,
    //   BERKMAN.BERKMANTRANSLATION,
    //   choiceObjs
    // );

    if (response) {
      let addData = {};
      if (this.state.language.value === "en") {
        addData = {
          id: response.id,
          choiceA: this.state.formInput.choiceA_en,
          choiceB: this.state.formInput.choiceB_en,
        };
      } else {
        addData = {
          id: response.id,
          choiceA: this.state.formInput.choiceA_hi,
          choiceB: this.state.formInput.choiceB_hi,
        };
      }
      let newChoices = [...this.state.berkmanChoice, addData];
      this.setState({ berkmanChoice: newChoices });
      this.resetForm();
    }
  };
  editBerkmanChoice = (choice) => {
    if (!choice.id) return;
    this.isEdit = true;
    this.setState({
      formInput: {
        choiceA: choice.choiceA,
        choiceB: choice.choiceB,
      },
      currentId: choice.id,
      berkman_id: choice.id,
    });
  };
  updateBerkmanChoiceById = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // const choiceObj: any = {
    //   ...this.state.formInput,
    // };
    const choiceObj: any = {
      // id: this.state.currentId,
      choiceA: this.state.formInput.choiceA,
      choiceB: this.state.formInput.choiceB,
    };
    // const choiceObj1: any = {
    //   id: this.state.currentId,
    //   choiceA: this.state.formInput.choiceA,
    //   choiceB: this.state.formInput.choiceB,
    // };
    const response = await CrudService.updateById(
      DATA_BASE_URL,
      CreateURL(BERKMAN.BERKMANBYID, { id: this.state.currentId }),
      choiceObj
    );
    if (response) {
      const filterChoices = this.state.berkmanChoice.filter(
        (ch: BerkmanChoiceT) => ch.id !== this.state.currentId
      );

      const updatedRoles = [
        ...filterChoices,
        { id: this.state.currentId, ...choiceObj },
      ];
      updatedRoles.sort((a: any, b: any) => a.id - b.id);
      this.setState({ berkmanChoice: updatedRoles });
      this.resetForm();
    }
  };
  deleteBerkmanChoice = async (choiceId: number | undefined) => {
    if (!choiceId) return;
    const response = await CrudService.deleteRow(
      DATA_BASE_URL,
      CreateURL(BERKMAN.DELETE, { id: choiceId })
    );
    if (response) {
      const filterResult = this.state.berkmanChoice.filter(
        (ch: BerkmanChoiceT) => ch.id !== choiceId
      );
      this.setState({ berkmanChoice: filterResult });
    }
  };
  resetForm = () => {
    this.isEdit = false;
    this.setState({
      formInput: {
        formInput: {
          choiceA_en: "",
          choiceB_en: "",
          choiceA_hi: "",
          choiceB_hi: "",
        },
        currentId: 0,
        berkman_id: 0,
      },
    });
  };
  render() {
    return (
      <div className="component-content">
        <ModalPopup
          ref={this.modalRef}
          className="primary"
          buttonLabel="Add More"
          reset={this.resetForm}
          popupTitle={`${this.isEdit ? "Edit" : "Add"} Berkman Choice`}
          callbackMethod={(event: React.FormEvent<HTMLFormElement>) =>
            this.handleSubmit(event)
          }
        >
          <form onSubmit={this.handleSubmit}>
            {LANGUAGE.map((lang: LanguageT, index: number) => {
              if (this.isEdit && lang.value === this.state.language.value) {
                return (
                  <fieldset key={lang.value}>
                    <legend>{lang.label}</legend>
                    <div className="form">
                      <input
                        type="text"
                        style={{ paddingTop: "0" }}
                        name={`choiceA`}
                        value={this.state.formInput.choiceA}
                        onChange={this.handleChange}
                        required
                      />
                      <label className="label-name">
                        <span className="content-name">Choice A</span>
                      </label>
                    </div>
                    <div className="form">
                      <input
                        type="text"
                        style={{ paddingTop: "0" }}
                        name={`choiceB`}
                        value={this.state.formInput.choiceB}
                        onChange={this.handleChange}
                        required
                      />
                      <label className="label-name">
                        <span className="content-name">Choice B</span>
                      </label>
                    </div>
                  </fieldset>
                );
              } else if (!this.isEdit) {
                return (
                  <fieldset key={lang.value}>
                    <legend>{lang.label}</legend>
                    <div className="form">
                      <input
                        type="text"
                        style={{ paddingTop: "0" }}
                        name={`choiceA_${lang.value}`}
                        value={
                          lang.value === "en"
                            ? this.state.formInput.choiceA_en
                            : this.state.formInput.choiceA_hi
                        }
                        onChange={this.handleChange}
                        required
                      />
                      <label className="label-name">
                        <span className="content-name">Choice A</span>
                      </label>
                    </div>
                    <div className="form">
                      <input
                        type="text"
                        style={{ paddingTop: "0" }}
                        name={`choiceB_${lang.value}`}
                        value={
                          lang.value === "en"
                            ? this.state.formInput.choiceB_en
                            : this.state.formInput.choiceB_hi
                        }
                        onChange={this.handleChange}
                        required
                      />
                      <label className="label-name">
                        <span className="content-name">Choice B</span>
                      </label>
                    </div>
                  </fieldset>
                );
              }
            })}
          </form>
        </ModalPopup>
        <Datatable
          title="Berkman Choice"
          description="You can view Berkman Choices here"
          isShow={this.state.loader}
          currentPage={this.state.currentPage}
          pageSize={this.state.perPage}
          dataCount={this.state.arrayCount}
          callbackMethod={this.changePage}
          callbackSelectMethod={this.selectPageSize}
        >
          <div className="row ">
            <div className="col-sm-2">
              <Select
                value={this.state.language}
                options={LANGUAGE}
                onChange={this.languageHandler}
              />
            </div>
          </div>
          <table className="datatable-table">
            <thead>
              <tr>
                <th style={{ width: "4%" }}>#</th>
                <th>Choice A</th>
                <th>Choice B</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.state.berkmanChoice.length ? (
                this.state.berkmanChoice.map((choice: BerkmanChoiceT) => (
                  <tr key={choice.id}>
                    <td>{choice.id}</td>
                    <td>{choice.choiceA}</td>
                    <td>{choice.choiceB}</td>
                    <td>
                      <i
                        className="fa fa-edit"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.modalRef.current.updateModal();
                          this.editBerkmanChoice(choice);
                        }}
                      ></i>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>No records found</td>
                </tr>
              )}
            </tbody>
          </table>
        </Datatable>
      </div>
    );
  }
}
export default BerkmanChoice;
