import XLSX from "xlsx";

export const ExportExcel = (data,filename) => {
    if(data.length>0){
      const ws1 = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws1, `${filename}`);
      XLSX.writeFile(wb, `${filename}.xlsx`);
    } else {
      alert("No records found")
    }
  };